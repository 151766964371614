import { colors, theme } from '@styles/constants'
import { button, buttonText } from '@styles/mixins/button'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
	container: {
		height: '100%',
	},
	content: {
		padding: 20,
		gap: 30
	},
	title: {
		color: theme.light.colors.titleColor,
		fontWeight: '500',
		fontSize: 28
	},
	subTitle: {
		color: colors.black,
		fontSize: 22,
		fontWeight: '500'
	},
	text: {
		fontSize: 18
	},
	containerItem: {
		padding: 30,
		borderRadius: 30,
		gap: 20,
		backgroundColor: theme.light.colors.mainContainer
	},
	button: {
		...({
			...button(theme.light.colors.successDark).button,
		} as object),
		paddingVertical: 10,
		paddingHorizontal: 20,
		flexDirection: 'row',
		gap: 8,
	},
	buttonText: {
		...({
			...buttonText().buttonText,
		} as object),
		fontWeight: 'bold',
	},
	header: {
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	sectionContent: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: 8,
		borderWidth: 1,
		borderColor: colors.gray,
		borderRadius: 4,
		backgroundColor: colors.white
	},
	linkButtonsContainer: {
		flexDirection: 'row',
		gap: 8,
	},
	linkButton: {
		paddingLeft: 8,
		borderLeftWidth: 1,
		borderLeftColor: colors.gray,
	},
	buttonContainer: {
		alignItems: 'flex-end',
	},
})