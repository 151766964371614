import { Notification } from '@utils/interfaces'
import { reduxActionType } from '@utils/reduxTypes'

interface NotificationState {
    notifications: Notification[];
}

const initialState: NotificationState = {
	notifications: []
}

export const notificationReducer = (
	state = initialState,
	action: reduxActionType
): (typeof initialState) => {
	switch (action.type) {
	case 'SET_NOTIFICATIONS': {
		return { notifications: action.payload }
	}
	case 'READ_NOTIFICATION': {
		const updatedNotifications = state.notifications.map(notification => {
			if (notification.notificationHistoryKey === action.payload) {
				return {...notification, isRead: true}
			}
			return notification
		})

		return { ...state, notifications: updatedNotifications }
	}
	case 'ADD_NOTIFICATION': {
		return {
			...state,
			notifications: [...state.notifications, action.payload]
		}
	}

	default: return state
	}
}