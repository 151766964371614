import { Input } from '@components/Input'
import { SelectDropDown } from '@components/SelectDropDown/SelectDropdown'
import { rootReducerType } from '@reducers/combineReducers'
import { axiosBFFServer } from '@services/connectionServer'
import { colors, theme } from '@styles/constants'
import { CaseStateType, SnackbarInitialState, SnackbarTypes } from '@utils/interfaces'
import { isPartnerOrAdvisor } from '@utils/utils'
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, TouchableOpacity, View } from 'react-native'
import Icon from 'react-native-vector-icons/FontAwesome'
import { useSelector } from 'react-redux'
import { DataRenderer } from '../CaseStatesRender/CaseStatesRender'
import { Loader } from '@components/Loader/Loader'
import { createStateSchema } from '@utils/formValidation'
import { snackbarInitialState } from '@utils/constants'
import { Snackbar } from '@components/Snackbar/Snackbar'
import { styles } from './styles'
import { Popup } from '@components/Popup'
import { UseErrorMessages } from '@components/Common/UseErrorMessage'

export const CaseStateBox = () => {
	const { t } = useTranslation()
	const { getErrorMessage } = UseErrorMessages()
	const [caseStates, setCaseStates] = useState<CaseStateType[]>([])
	const [isVisible, setIsVisible] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const [uniqueStatuses, setUniqueStatuses] = useState([''])
	const [ snackbar, setSnackbar ] = useState<SnackbarInitialState>(snackbarInitialState)
	const [isDeleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false)
	const [caseStateToDelete, setCaseStateToDelete] = useState<string | null>(null)

	const { roles: userRoles, language } = useSelector((state: rootReducerType) => state.userReducer)

	const companyKeyForAPI = isPartnerOrAdvisor(userRoles)
		? useSelector((state:rootReducerType) => state.companyReducer.key)
		: useSelector((state:rootReducerType) => state.companyReducer.currentCompany.companyKey)
	const { language: companyLanguage } = useSelector((state: rootReducerType) => state.companyReducer.settings)

	const initialValues = ({
		status: '',
		state: ''
	}) as CaseStateType

	const getCaseStatus = () => {
		axiosBFFServer
			.post(`/caseState/search?companyKey=${companyKeyForAPI}&page=1&size=100`)
			.then((response) => {
				setCaseStates(response.data.pageResults)
				setIsLoading(false)
			})
			.catch(error => {
				const errorMessage = getErrorMessage(error, t('errors.caseState'))
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})})
	}

	const onSubmit = (values) => {
		setIsLoading(true)
		const valuesToSend = values as CaseStateType

		axiosBFFServer
			.post(`/caseState?companyKey=${companyKeyForAPI}&page=1&size=100`, {
				...valuesToSend,
				companyLanguage,
			})
			.then((resp) => {
				setCaseStates([...caseStates, resp.data])
				setIsLoading(false)
				setIsVisible(false)
			})
			.catch((error) =>{ 	
				const errorMessage = getErrorMessage(error, t('errors.caseStateAdd'))
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})})
	}

	const handleDeleteCaseState = (caseStateKey) => {
		setCaseStateToDelete(caseStateKey)
		setDeleteConfirmationVisible(true)
	}

	const handlePopupConfirm = () => {
		if (caseStateToDelete) {
			axiosBFFServer
				.delete(`/caseState/${caseStateToDelete}?companyKey=${companyKeyForAPI}`)
				.then((resp) => {
					const updatedData = caseStates.filter((item) => item.caseStateKey !== caseStateToDelete)
					setCaseStates(updatedData)
				})
				.catch((error) => {
					const errorMessage = getErrorMessage(error, t('errors.caseStateDel'))
					setSnackbar({
						isVisible: true,
						type: SnackbarTypes.ERROR,
						message: errorMessage
					})}
				)
		}
		setDeleteConfirmationVisible(false)
	}

	useEffect(() => {
		const obj = caseStates.map(state => state.status)
		const uniqueStatus = Array.from(new Set(obj))
		setUniqueStatuses(uniqueStatus)
	}, [caseStates])

	useEffect(() => {
		getCaseStatus()
	}, [language])
  
	return (
		<View style={styles.caseManagement}>
			<View>
				<Text style={styles.caseTitle}>{t('caseStateTitle')}</Text>
			</View>
			{isLoading && <Loader/> }
			<TouchableOpacity style={styles.buttonCreate} onPress={() => setIsVisible(!isVisible)}>
				<Icon name={isVisible ? 'minus' : 'plus'} color={theme.light.colors.linkColor} size={16} />
				<Text>{t('createNewState')}</Text>
			</TouchableOpacity>
			{isVisible &&
				
				<Formik initialValues={initialValues} validationSchema={createStateSchema} onSubmit={onSubmit}>
					{(formikProps) => (
						<View style={styles.createStatusContainer}>
							<View style={styles.inputField}>
								<Text style={styles.label}>{t('fieldLabels.status')}</Text>
								<SelectDropDown 
									data={uniqueStatuses} 
									defaultButtonText={t('searchStatus')}
									fieldName={'status'} 
									value={formikProps.values.status}
									handleFormValueChange={formikProps.setFieldValue}   
									onBlur={() => formikProps.setFieldTouched('status', true)}    
								/>
								{formikProps.touched['status'] && formikProps.errors['status'] && (
									<Text style={styles.errorText}>{`*${formikProps.errors['status']}`}</Text>
								)}
							</View>
							<View style={styles.inputField}>
								<Text style={styles.label}>{t('fieldLabels.state')}</Text>
								<Input
									placeholder={t('searchState')}
									name={'state'} 
									onChange={formikProps.setFieldValue}
									onBlur={() => formikProps.setFieldTouched('state', true)}       
								/>
								{formikProps.touched['state'] && formikProps.errors['state'] && (
									<Text style={styles.errorText}>{`*${formikProps.errors['state']}`}</Text>
								)}
							</View>
							<View style={styles.footer}>
								<TouchableOpacity 
									style={styles.button}
									onPress={() => formikProps.handleSubmit()}
								>
									<Icon name="check" size={18} color={colors.white} />
									<Text style={styles.buttonText}>{t('submit')}</Text>
								</TouchableOpacity>
							</View>
						</View> 
					)}
				</Formik>
			}
			<DataRenderer data={caseStates} onDelete={handleDeleteCaseState} />
			<Popup
				isVisible={isDeleteConfirmationVisible}
				onClose={() => setDeleteConfirmationVisible(false)}
				onConfirm={handlePopupConfirm} 
				popupTitle={t('popupTitle')}
				textConfirm={t('delete')}
			>
			</Popup>
			<Snackbar
				visible={snackbar.isVisible}
				onDismiss={() => setSnackbar(snackbarInitialState)}
				type={snackbar.type}
				message={snackbar.message}
			/>	
		</View>
	)
}
