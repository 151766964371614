import { theme } from '@styles/constants'
import { buttonText } from '@styles/mixins/button'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
	exportLogs: {
		display: 'flex',
		flexDirection: 'row',
		alignSelf: 'flex-end',
		marginLeft: 'auto',
		minWidth: 150,
		shadowColor: theme.light.colors.mainColor,
		shadowOffset: { width: 0, height: 1 },
		shadowOpacity: 0.2,
		shadowRadius: 2,
		elevation: 3,
		borderRadius: 6,
		backgroundColor: theme.light.colors.primaryDark,
	},
	buttonText: {
		...({
			...buttonText().buttonText,
		} as object),
		paddingVertical: 10,
		paddingHorizontal: 20,
	},
	disabled: {
		opacity: 0.5,
		cursor: 'arrow'
	},
})