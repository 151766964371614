import { Input } from '@components/Input'
import React, {  useEffect, useState } from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import { SnackbarTypes, ViewTypes,  EditCaseDetails, CaseSources, SeverityType, SnackbarInitialState, File } from '@utils/interfaces'
import { useTranslation } from 'react-i18next'
import { colors } from '@styles/constants'
import Icon from 'react-native-vector-icons/FontAwesome'
import { useLocation, useNavigate } from 'react-router-dom'
import { SelectDropDown } from '@components/SelectDropDown/SelectDropdown'
import { Formik } from 'formik'
import { axiosBFFServer, axiosBFFServerFormData } from '@services/connectionServer'
import { getLinkAndDocumentsForm, getObjectDifference, isPartnerOrAdvisor } from '@utils/utils'
import { AssignHandlerCase } from '@components/AssignHandlerToCase/AssignHandlerToCase'
import useScreenDimensions from '@components/Common/UseScreenDimensions'
import { iv, screenType, snackbarInitialState } from '@utils/constants'
import { Snackbar } from '@components/Snackbar/Snackbar'
import { useSelector } from 'react-redux'
import { rootReducerType } from '@reducers/combineReducers'
import FileCard from '@components/FileCard/FileCard'
import * as DocumentPicker from 'expo-document-picker'
import { encryptValueWithAES } from '@utils/encryption'
import { styles } from './styles'
import { UseErrorMessages } from '@components/Common/UseErrorMessage'

export const CaseEdit = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { getErrorMessage } = UseErrorMessages()

	const screenDimensions = useScreenDimensions()
	const isMobile = screenDimensions.width < screenType.phone
	const isTablet = screenDimensions.width < screenType.tablet

	const { roles, email, language } = useSelector((state: rootReducerType) => state.userReducer)
	const { companyKey } = useSelector((state: rootReducerType) => state.companyReducer.currentCompany)
	const location = useLocation()
	const { caseCreatedDetails, companyKeyLP, secretKey } = location.state

	const valuesToEdit= ({
		severity: caseCreatedDetails.severity,
		caseStateKey: caseCreatedDetails.caseState.caseStateKey,
		source: caseCreatedDetails.source,
		subject: caseCreatedDetails.subject,
		description: caseCreatedDetails.description,
	})

	const [ snackbar, setSnackbar ] = useState<SnackbarInitialState>(snackbarInitialState)
	const [ uploadedFiles, setUploadedFiles ] = useState<File[]>([])
	const [caseStates, setCaseStates] = useState<Array<{ label: string, value: string }>>([])
	
	const caseKey = caseCreatedDetails.caseKey
	const companyKeyForAPI = isPartnerOrAdvisor(roles) ? companyKeyLP : companyKey

	const getCaseStatus = () => {
		axiosBFFServer
			.post(`/caseState/search?companyKey=${companyKeyForAPI}&page=1&size=100`)
			.then((response) => {
				setCaseStates(response.data.pageResults.map(status => ({
					label: status.state,
					value: status.caseStateKey
				})))
			})
			.catch((error) => 	{
				const errorMessage = getErrorMessage(error, t('errors.caseState'))
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}
	
	
	const onSubmitEdit = (values: EditCaseDetails) => {
		const valuesEdited = getObjectDifference(values, valuesToEdit)
		const caseDetailsChanged = {
			...valuesEdited,
			...(!!valuesEdited.subject && { subject: encryptValueWithAES(secretKey, iv, valuesEdited.subject) }),
			...(!!valuesEdited.description && { description: encryptValueWithAES(secretKey, iv, valuesEdited.description) })
		}
		const documentsUploaded = !!uploadedFiles.length

		axiosBFFServer
			.patch(`/case/${caseKey}`, caseDetailsChanged)
			.then(async response => {
				if (response.status === 200) {
					if (documentsUploaded) {
						const { uploadDocUrl, fileDataToSend } = await getLinkAndDocumentsForm(uploadedFiles, secretKey, iv)
						await axiosBFFServerFormData
							.post(`${uploadDocUrl}/${caseKey}?authorEmail=${email}`, fileDataToSend)
							.then((documentResponse) => {
								documentResponse.status === 201 && 
                                    navigate(`/${ViewTypes.COMPANY}/cases/case-details/${caseKey}`)
							})
							.catch((error) => {
								let errorMessage = getErrorMessage(error, t('errors.uploadFiles'))
								if (error.response.status === 409) {
									errorMessage = t('errors.fileSameName')
								} else if (error.response.status === 422 && error.response.data.message.includes('size')) {
									errorMessage = t('errors.fileSize')
								} else if (error.response.status === 422 && error.response.data.message.includes('type')) {
									errorMessage = t('errors.fileType')
								}
								setSnackbar({
									isVisible: true,
									type: SnackbarTypes.ERROR,
									message: errorMessage
								})
							})
					} else {
						navigate(`/${ViewTypes.COMPANY}/cases/case-details/${caseKey}`)
					}
				} else throw new Error()
			})
			.catch(error => {
				const errorMessage = getErrorMessage(error, t('editCaseError'))
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}

	const pickDocument = async () => {
		const result = await DocumentPicker.getDocumentAsync({
			type: '*/*',
			multiple: true,
			copyToCacheDirectory: true
		})
	
		if (result.output && result.output.length > 0){
			const files = Object.values(result.output) as File[]
			setUploadedFiles([ ...uploadedFiles, ...files ])
		}
	}

	useEffect(()=> {
		getCaseStatus()
	}, [companyKey, language])

	return (
		<View style={isMobile || isTablet ? styles.editContainer_mobile : styles.editContainer}>
			<View style={styles.header}>
				<TouchableOpacity 
					style={styles.backButton}
					onPress={() => navigate(`/${ViewTypes.COMPANY}/cases/case-details/${caseKey}`)}>
					<Icon style={styles.backIcon} name="arrow-left" size={18} />
					<Text style={styles.backText}>{t('back')}</Text>
				</TouchableOpacity>
			</View>
			<View style={styles.content}>
				<View style={styles.actionContainer}>
					<Formik initialValues={valuesToEdit} validationSchema={null} onSubmit={onSubmitEdit}>
						{(formikProps) => (
							<View style={styles.form}>
								<View style={styles.header_form}>
									<Text style={styles.title}>{t('EditCaseTitle')}</Text>
								</View>
					
								<View style={styles.formFieldWrapper}>
									<Text style={styles.labelText}>{t('fieldLabels.status')}</Text>
									<SelectDropDown 
										dropDownItems={caseStates}
										defaultButtonText={'status'}
										value={caseStates.find(status => status.value === formikProps.values.caseStateKey)}
										fieldName={'caseStateKey'}
										handleFormValueChange={formikProps.setFieldValue}
										onBlur={() => formikProps.setFieldTouched('caseStateKey', true)}
										areItemsObjects={true}
									/>
								</View>

								<View style={styles.formFieldWrapper}>
									<Text style={styles.labelText}>{t('fieldLabels.severity')}</Text>
									<SelectDropDown 
										data={Object.values(SeverityType)}
										defaultButtonText={'severity'}
										value={formikProps.values.severity}
										fieldName={'severity'}
										handleFormValueChange={
											formikProps.setFieldValue}
										onBlur={() => formikProps.setFieldTouched('severity', true)}
									/>
								</View> 

								<View style={styles.formFieldWrapper}>
									<Text style={styles.labelText}>{t('fieldLabels.source')}</Text>
									<SelectDropDown 
										data={Object.values(CaseSources)}
										defaultButtonText={'source'}
										value={formikProps.values.source}
										fieldName={'source'}
										handleFormValueChange={
											formikProps.setFieldValue
										}
										onBlur={() => formikProps.setFieldTouched('source', true)}
									/>
								</View>

								<View style={styles.formFieldWrapper}>
									<Text style={styles.labelText}>{t('fieldLabels.subject')}</Text>
									<Input
										placeholder={t('subject')}
										name='subject'
										value={formikProps.values.subject}
										onChange={
											formikProps.setFieldValue
										}
										onBlur={() => formikProps.setFieldTouched('subject', true)} />
								</View>

								<View style={styles.formFieldWrapper}>
									<Text style={styles.labelText}>{t('fieldLabels.description')}</Text>
									<Input
										placeholder={t('description')}
										multiline={true}
										name='description'
										value={formikProps.values.description}
										onChange={
											formikProps.setFieldValue}
										onBlur={() => formikProps.setFieldTouched('description', true)} />
								</View>

								<View>
									<Text style={styles.labelText}>{t('files')}</Text>				
									{
										!!uploadedFiles.length && (
											<View style={styles.fileContainer}>
												{uploadedFiles.map((file, index) => 
													<FileCard
														key={index}
														name={file.name}
														index={index}
														uploadFiles={uploadedFiles}
														setUploadFiles={setUploadedFiles}
													/>
												)}
											</View>
										)
									}
								</View>

								<View style={styles.footer}>
									<TouchableOpacity 
										style={styles.buttonDark}
										onPress={pickDocument}>
										<Icon style={styles.icon} name="upload" size={18} />
										<Text style={styles.buttonText}>{t('uploadFile')}</Text>
									</TouchableOpacity>
									<TouchableOpacity 
										style={styles.button}
										onPress={() => formikProps.handleSubmit()}
									>
										<Icon name="check" size={18} color={colors.white} />
										<Text style={styles.buttonText}>{t('submit')}</Text>
									</TouchableOpacity>
								</View>
							</View>

						)}
					</Formik>
				</View>
				<View style={styles.handlersContainer}>
					<AssignHandlerCase caseCreatedDetails={caseCreatedDetails}/>
				</View>
			</View>
			
			<Snackbar
				visible={snackbar.isVisible}
				onDismiss={() => setSnackbar(snackbarInitialState)}
				type={snackbar.type}
				message={snackbar.message}
			/>
		</View>
	)
}
