import { StyleSheet } from 'react-native'
import { colors, theme } from '@styles/constants'

export const styles = StyleSheet.create({
	modalContainer: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: colors.shadeBackground,
	},
	modalContent: {
		backgroundColor: colors.white,
		padding: 20,
		borderRadius: 8,
		width: '60%',
		height: '80%',
		overflowY: 'auto',
	},
	modalContentMobile: {
		backgroundColor: colors.white,
		padding: 20,
		borderRadius: 8,
		width: '90%',
		height: '90%',
		overflowY: 'auto',
	},
	row: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginBottom: 16,
	},
	inputField: {
		flex: 1,
		paddingRight: 20
	},
	singleInput: {
		width: '50%',
		paddingRight: 20
	},
	checkboxField: {
		flexDirection: 'row',
		alignItems: 'center',
		gap: 10,
		padding: 6,
	},
	checkboxLabel : {
		fontSize: 16,
	},
	inputFieldMultiline: {
		gap: 4,
		width: '97%'
	},
	label: {
		fontSize: 16,
		marginBottom: 8
	},
	buttonsContainer: {
		flexDirection: 'row',
		justifyContent: 'flex-end',
		marginTop: 16
	},
	cancelButton: {
		marginRight: 16,
		padding: 8
	},
	cancelButtonText: {
		color: theme.light.colors.linkColor,
		fontWeight: '600'
	},
	createButton: {
		padding: 8,
		backgroundColor: theme.light.colors.primaryDark,
		borderRadius: 6,
		justifyContent: 'center',
		width: 120,
	},
	buttonText: {
		color: theme.light.colors.linkColorActive,
		fontWeight: 'bold',
		fontSize: 16,
		textAlign: 'center'
	},
	editCompanyContent: {
		justifyContent: 'flex-start',
		gap: 3,
		backgroundColor: colors.white,
		paddingVertical: 10,
		paddingHorizontal: 20,
		borderRadius: 8,
		width: '60%',
		height: '80%',
		overflowY: 'scroll',
	},
	text: {
		fontSize: 16,
		paddingLeft: 6,
		color: colors.darkShades
	},
	errorText: {
		color: colors.error,
		fontSize: 12,
	},
	textContainer: {
		flexDirection: 'row',
		gap: 12
	},
	dropdownDisabled: {
		flex: 1,
		width: '100%',
		backgroundColor: theme.light.colors.boxBorder,
		borderColor: theme.light.colors.mainColor,
		borderWidth: 1,
		borderRadius: 4,
		padding: 8,
		paddingTop: 6,
		paddingBottom: 10,
		maxHeight: 50
	},
	gridInputs: {
		display: 'grid',
		gridTemplateColumns: 'repeat(2,1fr)',
		gap: 15,
		paddingRight: 20
	}
})
