import { reduxActionType } from '@utils/reduxTypes'

const initialState = {
	key: '',
	email: ''
}

export const companyUserKeyReducer = (
	state = initialState,
	action: reduxActionType
): (typeof initialState) => {
	const newState = JSON.parse(JSON.stringify(state))
	switch (action.type) {
	case 'SET_COMPANY_USER_KEY': {
		newState.key = action.payload
		return newState
	}
	case 'SET_COMPANY_USER_EMAIL':{
		newState.email = action.payload
		return newState
	}
	default: return state
	}
}