import { colors, theme } from '@styles/constants'
import { button, buttonText } from '@styles/mixins/button'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
	container: {
		alignSelf: 'stretch',
	},
	formContainer: {
		gap: 10,
	},
	label: {
		fontSize: 16,
		marginBottom: 8,
	},
	errorText: {
		color: colors.error,
		fontSize: 12,
	},
	submitButton: {
		...({
			...button(theme.light.colors.primaryDark).button,
		} as object),
		borderRadius: 6,
		justifyContent: 'center',
		flexGrow: 1,
		shadowColor: theme.light.colors.mainColor,
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.2,
		shadowRadius: 2,
		elevation: 2,
		marginTop: 20,
	},
	submitButtonText: {
		...({
			...buttonText().buttonText,
		} as object),
		fontSize: 18,
		fontWeight: 'bold',
	},
})
