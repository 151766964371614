import { reduxActionType } from '@utils/reduxTypes'

const initialState = {
	key: '',
	keyEdit: '',
	advisors: [],
	advisorEmail: ''
}

export const setAdvisorKeyReducer = (
	state = initialState,
	action: reduxActionType
): (typeof initialState) => {
	const newState = JSON.parse(JSON.stringify(state))
	switch (action.type) {
	case 'SET_ADVISOR_KEY': {
		newState.key = action.payload
		return newState
	}
	case 'SET_EDIT_ADVISOR_KEY': {
		newState.keyEdit = action.payload
		return newState
	}
	case 'SET_ADVISORS': {
		newState.advisors = action.payload
		return newState
	}
	case 'SET_EDIT_ADVISOR_EMAIL':{
		newState.advisorEmail = action.payload
		return newState
	}
	default: return state
	}
}