import React, { useEffect, useState } from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import Icon from 'react-native-vector-icons/FontAwesome'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import useScreenDimensions from '@components/Common/UseScreenDimensions'
import { DepartmentModal } from '@components/DepartmentModal/DepartmentModal'
import { Department } from '@components/Department/Department'
import { Snackbar } from '@components/Snackbar/Snackbar'
import { Loader } from '@components/Loader/Loader'
import { axiosBFFServer } from '@services/connectionServer'
import { rootReducerType } from '@reducers/combineReducers'
import { screenType, snackbarInitialState } from '@utils/constants'
import { CategoryType, CompanyUser, DepartmentFormValues, DepartmentType, SnackbarInitialState, SnackbarTypes } from '@utils/interfaces'
import { colors } from '@styles/constants'
import { setCurrentCompany } from '@actions/companyActions'
import { styles } from './style'
import { checkIfPermissionExist, isPartnerOrAdvisor } from '@utils/utils'
import { UseErrorMessages } from '@components/Common/UseErrorMessage'

export const Departments = () => {
	const [ departmentModal, setDepartmentModal ] = useState<{
        isVisible: boolean
        department?: DepartmentType
    }>({ isVisible: false })
	const [ departments, setDepartments ] = useState<DepartmentType[]>([])
	const [ categories, setCategories ] = useState<CategoryType[]>([])
	const [ users, setUsers ] = useState<CompanyUser[]>([])
	const [ isLoading, setIsLoading ] = useState(true)
	const [ snackbar, setSnackbar ] = useState<SnackbarInitialState>(snackbarInitialState)

	const dispatch = useDispatch()
	const { t } = useTranslation()
	const screenDimensions = useScreenDimensions()
	const { getErrorMessage } = UseErrorMessages()
	const isMobileScreen = screenDimensions.width < screenType.phone

	const companyKey = useSelector((state: rootReducerType) => state.companyReducer.key)
	const { currentCompany } = useSelector((state: rootReducerType) => state.companyReducer)
	const { permissions: userPermissions, roles: userRoles, language } = useSelector((state: rootReducerType) => state.userReducer)
	
	const companyUsers = users.map(user => ({ ...user, fullName: `${user.firstName} ${user.lastName}`}))
	const companyAssignedCategories = departments.map(department => ({
		departmentKey: department.departmentKey,
		categories: department.categories
	}))

	useEffect(() => {
		getDepartments()
		getCategories()
	}, [language])

	useEffect(() => {
		if (departments.length) {
			dispatch(setCurrentCompany({
				...currentCompany,
				departments,
			}))
		}
	}, [departments])

	const getDepartments = async () => {
		axiosBFFServer
			.get(isPartnerOrAdvisor(userRoles)
				? `/company/${companyKey}`
				: '/company/current'
			)
			.then(response => {
				setDepartments(response.data.departments)
				setUsers(response.data.users)
				setIsLoading(false)
			})
			.catch(error => {
				const	errorMessage = getErrorMessage(error, t('errors.listDepartments'))
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}

	const getCategories = async () => {
		axiosBFFServer
			.get(`/category/all/${companyKey}`)
			.then(response => {
				setCategories(response.data)
			})
			.catch(error => {
				const	errorMessage = getErrorMessage(error, t('errors.listCategories'))
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}

	const updateDepartmentName = async (departmentKey: string, name: string) => {
		const reqBody = {
			companyKey,
			name
		}

		return await axiosBFFServer
			.patch(`/department/${departmentKey}`, reqBody)
			.then(response => {
				if (response.status === 200) return
			})
	}

	const assignCategories = async (departmentKey: string, categoriesKeys: string[]) => {
		const reqBody = {
			companyKey,
			departmentKey,
			categoriesKeys
		}

		return await axiosBFFServer
			.post('/department/assign/categories', reqBody)
			.then(response => {
				if (response.status === 201) return
			})
	}

	const assignUsers = async (departmentKey: string, usersKeys: string[]) => {
		const reqBody = {
			companyKey,
			departmentKey,
			companyUsersKeys: usersKeys
		}

		return await axiosBFFServer
			.post('/department/assign/users', reqBody)
			.then(response => {
				if (response.status === 201) return
			})
	}

	const unassignCategories = async (departmentKey: string, categoriesKeys: string[]) => {
		const reqBody = {
			companyKey,
			departmentKey,
			categoriesKeys
		}

		return await axiosBFFServer
			.post('/department/unassign/categories', reqBody)
			.then(response => {
				if (response.status === 201) return
			})
	}

	const unassignUsers = async (departmentKey: string, usersKeys: string[]) => {
		const reqBody = {
			companyKey,
			departmentKey,
			companyUsersKeys: usersKeys
		}

		return await axiosBFFServer
			.post('/department/unassign/users', reqBody)
			.then(response => {
				if (response.status === 201) return
			})
	}

	const editDepartment = async (department, departmentUpdated) => {
		const departmentData = {
			...department,
			categoriesKeys: department.categories.map(category => category.categoryKey),
			usersKeys: department.users.map(user => user.companyUserKey),
		}

		const { departmentKey, categoriesKeys, usersKeys } = departmentData
		const { categoriesKeys: categoriesKeysNew, usersKeys: usersKeysNew } = departmentUpdated

		const assignedCategories = categoriesKeysNew.filter(categoryKey => !categoriesKeys.includes(categoryKey))
		const assignedUsers = usersKeysNew.filter(userKey => !usersKeys.includes(userKey))
		const unassignedCategories = categoriesKeys.filter(categoryKey => !categoriesKeysNew.includes(categoryKey))
		const unassignedUsers = usersKeys.filter(userKey => !usersKeysNew.includes(userKey))

		const requests: Promise<void>[] = []
		if(department.name !== departmentUpdated.name) {
			requests.push(updateDepartmentName(departmentKey, departmentUpdated.name))
		}
		if(assignedCategories.length) {
			requests.push(assignCategories(departmentKey, assignedCategories))
		}
		if(assignedUsers.length) {
			requests.push(assignUsers(departmentKey, assignedUsers))
		}
		if(unassignedCategories.length) {
			requests.push(unassignCategories(departmentKey, unassignedCategories))
		}
		if(unassignedUsers.length) {
			requests.push(unassignUsers(departmentKey, unassignedUsers))
		}
        
		await Promise.all(requests)
			.then(values => {
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.SUCCESS,
					message: t('success.editDepartment')
				})
				getDepartments()
			})
			.catch(error => {
				const	errorMessage = getErrorMessage(error, t('errors.editDepartment'))
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}

	const createDepartment = async (values) => {
		const reqBody = {
			companyKey,
			name: values.name,
			categories: values.categoriesKeys,
			users: values.usersKeys
		}

		axiosBFFServer
			.post('/department', reqBody)
			.then(response => {
				if(response.status === 201) {
					setSnackbar({
						isVisible: true,
						type: SnackbarTypes.SUCCESS,
						message: t('success.createDepartment')
					})
					getDepartments()
				}
			})
			.catch(error => {
				const errorMessage =  error.response.data.customStatus === '609' ? t('departAlreadyUsed') + ' ' + getErrorMessage(error, '') : getErrorMessage(error, t('errors.createDepartment'))
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}
    
	const handleSave = async (values: DepartmentFormValues) => {
		const { department } = departmentModal

		if(department) {
			await editDepartment(department, values)
		} else {
			await createDepartment(values)
		}

		setDepartmentModal({ isVisible: false })
	}

	return (
		<View style={styles.container}>
			<View style={[styles.headerContainer, isMobileScreen && styles.headerContainer_mobile]}>
				<Text style={styles.headerText}>{t('departments')}</Text>
				{
					checkIfPermissionExist(userPermissions,'department','add') &&
						<TouchableOpacity
							onPress={() => setDepartmentModal({ isVisible: true })}
							style={styles.button}
						>
							<Icon color={colors.white} name='plus' size={18} />
							<Text style={styles.buttonText}>{t('createDepartment')}</Text>
						</TouchableOpacity>
				}
			
			</View>
			<View style={styles.infoContainer}>
				<Text style={styles.infoContainerText}>
					{t('departmentsInfo')}
				</Text>
			</View>
			{isLoading
				? <Loader color={colors.white} />
				: !departments.length
					? <Text style={styles.noDataText}>{t('noDepartmentsAvailable')}</Text>
					: <View>
						{departments.map((department, index) => (
							<Department
								key={`d-${index}`}
								department={department}
								openModal={() => setDepartmentModal({ isVisible: true, department })}
							/>
						))}
					</View>
			}
			{departmentModal.isVisible && <DepartmentModal
				isVisible={departmentModal.isVisible}
				onClose={() => setDepartmentModal({ isVisible: false })}
				onSave={handleSave}
				department={departmentModal.department}
				users={companyUsers}
				categories={categories}
				companyAssignedCategories={companyAssignedCategories}
			/>}

			<Snackbar
				visible={snackbar.isVisible}
				onDismiss={() => setSnackbar(snackbarInitialState)}
				type={snackbar.type}
				message={snackbar.message}
			/>
		</View>
	)
}


