import React, { useEffect, useState } from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { colors, theme } from '@styles/constants'
import { useTranslation } from 'react-i18next'
import useScreenDimensions from '@components/Common/UseScreenDimensions'
import { useNavigate } from 'react-router-dom'
import FontAwesome from 'react-native-vector-icons/FontAwesome'
import { useSelector } from 'react-redux'
import { rootReducerType } from '@reducers/combineReducers'
import Icon from 'react-native-vector-icons/FontAwesome'
import { CheckBoxCustom } from '@components/CheckBox/CheckBox'
import { CompanyUserValues, FormValues, Permission, PermissionList, SnackbarInitialState } from '@utils/interfaces'
import { Input } from '@components/Input'
import { PhoneInput } from '@components/PhoneInput/PhoneInput'
import { phonePrefixes, screenType } from '@utils/constants'
import { axiosBFFServer } from '@services/connectionServer'
import { Snackbar } from '@components/Snackbar/Snackbar'
import { SnackbarTypes} from '@utils/interfaces'
import { Formik } from 'formik'
import { editCompanyUserSchema } from '@utils/formValidation'
import { snackbarInitialState } from '@utils/constants'
import { checkIfPermissionExist, createUserPermissionsList, getDate } from '@utils/utils'
import { Loader } from '@components/Loader/Loader'
import { UseErrorMessages } from '@components/Common/UseErrorMessage'

export const CompanyUserEdit = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { getErrorMessage } = UseErrorMessages()
	const [snackbar, setSnackbar] = useState<SnackbarInitialState>(snackbarInitialState)

	const companyKey = useSelector((state: rootReducerType) => state.companyReducer.key)
	const companyUserKey = useSelector((state: rootReducerType) => state.companyUserKeyReducer.key)
	const userEmail = useSelector((state: rootReducerType) => state.companyUserKeyReducer.email)
	const loggedUserEmail = useSelector((state: rootReducerType) => state.userReducer.email)
	const userPermissions = useSelector((state: rootReducerType) => state.userReducer.permissions)
	const editPermission = 	checkIfPermissionExist(userPermissions, 'companyUser', 'update')
	const hasEditPermission = editPermission || loggedUserEmail === userEmail

	const screenDimensions = useScreenDimensions()
	const isSmallScreen = screenDimensions.width < screenType.phone
	
	const [permissionsList, setPermissionsList] = useState<PermissionList[]>()
	const [permissionsKeys, setPermissionsKeys] = useState<string[]>([])
	const [initialPermissionsKeys, setInitialPermissionsKeys] = useState<string[]>([])
	const [disabledPermissions, setDisabledPermissions] = useState(false)
	const [ isLoading, setIsLoading ] = useState(true)

	const [companyUser, setCompanyUser] = useState({
		companyUserKey: '',
		email: '',
		firstName: '',
		lastName: '',
		isEmailVerified: true,
		disabledLogin: false,
		createdAt: '',
		title: '',
		phone: '',
		prefix: phonePrefixes[0]
	})
	const [initalCompanyUser, setInitialCompanyUser] = useState({
		companyUserKey: '',
		email: '',
		firstName: '',
		lastName: '',
		isEmailVerified: true,
		disabledLogin: false,
		createdAt: '',
		title: '',
		phone: '',
		prefix: phonePrefixes[0]
	})

	const initials = companyUser.firstName || companyUser.lastName ? 
		(companyUser.firstName?.charAt(0) || '') + (companyUser.lastName?.charAt(0) || '') : companyUser.email?.charAt(0)

	const getUser = () => {
		axiosBFFServer
			.get(`/company-user/${companyUserKey}?companyKey=${companyKey}`)
			.then(response => {
				setIsLoading(false)
				const prefix = response.data.phoneNumber ? response.data.phoneNumber.slice(0,3) : phonePrefixes[0]
				const phone = response.data.phoneNumber ? response.data.phoneNumber.slice(3) : ''
				const permissions: string[] = []

				response.data.permissions.length && response.data.permissions.filter((item: Permission) => !item.default).forEach((item) => permissions.push(item.permissionKey))
				setInitialPermissionsKeys(permissions)
				setPermissionsKeys(permissions)
				const currentData = {
					companyUserKey: response.data.companyUserKey,
					email: response.data.email,
					firstName: response.data.firstName,
					lastName: response.data.lastName,
					prefix: prefix,
					phone: phone,
					isEmailVerified: response.data.verifiedEmail,
					disabledLogin: response.data.disabledLogin,
					title: response.data.title || '',
					createdAt: getDate(response.data.createdAt)
				}
				setCompanyUser(currentData)
				setInitialCompanyUser(currentData)
			})
			.catch(error => {
				const errorMessage = getErrorMessage(error, '')
				setIsLoading(false)
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}

	const onSubmit = async (values: FormValues) => {
		const { firstName, lastName, email, prefix, phone, title } = values as CompanyUserValues
		const initialPhone = initalCompanyUser.prefix + initalCompanyUser.phone
	
		const fields = {
			firstName,
			lastName, 
			email,
			phoneNumber: prefix + phone,
			title
		}
		let changedFields = {}

		for (const item in fields){
			if (item === 'phoneNumber'){
				fields[item] != initialPhone ? changedFields = {...changedFields, ...{phoneNumber: fields[item]}} : changedFields
			} else {
				fields[item] != initalCompanyUser[item] ? changedFields = {...changedFields, ...{[item]: fields[item]}} : changedFields
			}
		}

		JSON.stringify(initialPermissionsKeys) !== JSON.stringify(permissionsKeys) ?  changedFields = {...changedFields, ...{permissionKeys: permissionsKeys}} : changedFields

		if (Object.keys(changedFields).length){
			setIsLoading(true)
			axiosBFFServer
				.patch(`/company-user/${companyUserKey}?companyKey=${companyKey}`, changedFields)
				.then(response => {
					if (response && response.status === 200){
						getUser()
						setSnackbar({
							isVisible: true,
							type: SnackbarTypes.SUCCESS,
							message: t('success.editCompanyUser'),
						})
					} else throw new Error()
				})
				.catch(error => {
					const errorMessage = error.response.data.customStatus === '609' ? t('emailAlreadyUsed') + ' ' + getErrorMessage(error, '') :getErrorMessage(error, t('errors.editCompanyUser'))
					setIsLoading(false)
					setSnackbar({
						isVisible: true,
						type: SnackbarTypes.ERROR,
						message: errorMessage,
					})
				})
		}
	}

	const getPermissions = async () => {
		let permissions: Permission[] = []

		await axiosBFFServer
			.get(`/permission/email/${userEmail}`)
			.then((response) => {
				permissions = response.data
			})
			.catch((error) => {
				let errorMessage = ''
				if (error.response && error.response.data.customStatus) {
					if(error.response.data.customStatus === '16000') {
						errorMessage = getErrorMessage(error, '') + ` for ${userEmail}!`
					} else errorMessage = getErrorMessage(error, '') 
					
				}
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})

		const permissionList = createUserPermissionsList(permissions)
		setPermissionsList(permissionList)
	}

	useEffect(() => {
		getPermissions()
	},[])

	useEffect(() => {
		if (companyUserKey) getUser()
	}, [companyUserKey])

	useEffect(() => {
		setPermissionsKeys(initialPermissionsKeys)
	},[initialPermissionsKeys])


	useEffect(() => {
		userEmail === loggedUserEmail && setDisabledPermissions(true)
	},[userEmail])

	return (
		<View style={styles.container}>
			<View style={styles.header}>
				<TouchableOpacity 
					style={styles.backButton}
					onPress={() => navigate(-1)}>
					<Icon style={styles.icon} name="arrow-left" size={18} />
					<Text style={styles.buttonText}>{t('back')}</Text>
				</TouchableOpacity>
			</View>
			{isLoading ?
				<Loader
					color={colors.white} /> 
				:
				<Formik 
					enableReinitialize={true} 
					initialValues={companyUser} 
					validationSchema={editCompanyUserSchema} 
					onSubmit={onSubmit}>
					{(formikProps) => (
						<View style={styles.form}>
							<View style={styles.header_form}>
								<View style={styles.profileAvatar}>
									<Text style={styles.avatarText}>{initials?.toUpperCase() || ''}</Text>
								</View>
								<View style={styles.infoContainer}>
									<View style={styles.textIconContainer}>
										<FontAwesome 
											name={'calendar'} 
											color={colors.darkShades} 
											size={18} /> 
										<Text style={styles.text}> {companyUser.createdAt ? `${t('accuntCreated')} ${companyUser.createdAt}` : ''}</Text>
									</View>
									<View style={styles.textIconContainer}>
										<FontAwesome 
											name={!companyUser.disabledLogin ? 'check' : 'warning'} 
											color={!companyUser.disabledLogin ? colors.darkShades : colors.danger} 
											size={18} /> 
										<Text style={styles.text}>{!companyUser.disabledLogin ? t('enableLogin') : t('disableLogin')}</Text>
									</View>
									<View style={styles.textIconContainer}>
										<FontAwesome 
											name={companyUser.isEmailVerified ? 'check' : 'warning'} 
											color={companyUser.isEmailVerified ? colors.darkShades : colors.danger} 
											size={18} /> 
										<Text style={styles.text}>{companyUser.isEmailVerified ? t('emailVerified') : t('emailNotVerified')}</Text>
									</View>
								</View>				
							</View>
							<View style={styles.formInputsContainer}>
								<View style={isSmallScreen ? styles.formFieldsPhone : styles.formFields}>
									<View style={isSmallScreen ? styles.formFieldsColumnPhone : styles.formFieldsColumn}>
										<View style={styles.formFieldWrapper}>
											<Text style={styles.labelText}>{t('firstName')}</Text>
											<Input
												placeholder={t('firstName')}
												name='firstName'
												value={formikProps.values['firstName']}
												editable={hasEditPermission}
												onChange={formikProps.setFieldValue}
												onBlur={() => formikProps.setFieldTouched('firstName', true)} />
											{formikProps.touched['firstName'] && formikProps.errors['firstName'] && (
												<Text style={styles.errorText}>{`*${formikProps.errors['firstName']}`}</Text>
											)}
										</View>
							
										<View style={styles.formFieldWrapper}>
											<Text style={styles.labelText}>{t('email')}</Text>
											<Input
												placeholder={t('email')}
												name='email'
												value={formikProps.values['email']}
												editable={hasEditPermission}
												onChange={formikProps.setFieldValue}
												onBlur={() => formikProps.setFieldTouched('email', true)} />
											{formikProps.touched['email'] && formikProps.errors['email'] && (
												<Text style={styles.errorText}>{`*${formikProps.errors['email']}`}</Text>
											)}
										</View>

										<View style={styles.formFieldWrapper}>
											<Text style={styles.labelText}>{t('title')}</Text>
											<Input
												placeholder={t('title')}
												name='title'
												value={formikProps.values['title']}
												editable={hasEditPermission}
												onChange={formikProps.setFieldValue}
												onBlur={() => formikProps.setFieldTouched('title', true)} />
											{formikProps.touched['title'] && formikProps.errors['title'] && (
												<Text style={styles.errorText}>{`*${formikProps.errors['title']}`}</Text>
											)}
										</View>

									</View>
									<View style={isSmallScreen ? styles.formFieldsColumnPhone : styles.formFieldsColumn}>
										<View style={styles.formFieldWrapper}>
											<Text style={styles.labelText}>{t('lastName')}</Text>
											<Input
												placeholder={t('lastName')}
												name='lastName'
												value={formikProps.values['lastName']}
												editable={hasEditPermission}
												onChange={formikProps.setFieldValue}
												onBlur={() => formikProps.setFieldTouched('lastName', true)} />
											{formikProps.touched['lastName'] && formikProps.errors['lastName'] && (
												<Text style={styles.errorText}>{`*${formikProps.errors['lastName']}`}</Text>
											)}
										</View>
		
										<View style={styles.formFieldWrapper}>
											<Text style={styles.labelText}>{t('phone')}</Text>
											<PhoneInput
												name={{
													prefix: 'prefix',
													number: 'phone',
												}}
												phone={{
													prefix: formikProps.values['prefix'],
													number: formikProps.values['phone']
												}}
												onChange={formikProps.setFieldValue}
												onBlur={formikProps.setFieldTouched}
												editable={hasEditPermission}
											/>
											{formikProps.touched['phone'] && formikProps.errors['phone'] && (
												<Text style={styles.errorText}>{`*${formikProps.errors['phone']}`}</Text>
											)}
										</View>
									</View>
								</View>
							
								<Text style={styles.labelText}>{t('permissions')}</Text>
								<View style={isSmallScreen ? styles.checkBoxContanerSmall : styles.checkBoxContainer}>
									{
										permissionsList && permissionsList.map((item) => 
											<View style={isSmallScreen ? styles.checkElementSmall : styles.checkElement}>
												<CheckBoxCustom
													text={item.text}
													name={item.name}
													permissionKey={item.permissionKey}
													disabled={disabledPermissions ? disabledPermissions : !hasEditPermission}
													hasTooltip={item.tooltip.length ? true : false}
													tooltip={item.tooltip}
													hasDropDown={item.childs.length ? true : false}
													dropdownChecksList={item.childs}
													permissionKeys={permissionsKeys}
													setPermissionKeys={setPermissionsKeys}
													initialPermissions={initialPermissionsKeys}/>
											</View>
										)
									}
								</View> 
							</View>
							{
								hasEditPermission &&
								<View style={styles.footer}>
									<TouchableOpacity 
										style={styles.button}
										onPress={() => formikProps.handleSubmit()}>
										<Icon name="check" size={18} color={colors.white} />
										<Text style={styles.buttonText}>{t('submit')}</Text>
									</TouchableOpacity>
								</View>
							}
						</View>
					)}
				</Formik>
			}
			<Snackbar
				visible={snackbar.isVisible}
				onDismiss={() => setSnackbar(snackbarInitialState)}
				type={snackbar.type}
				message={snackbar.message}
			/>
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		padding: 20,
		gap: 8,
		height: 'auto',
		backgroundColor: theme.light.colors.appContainer
	},
	header: {
		flexDirection: 'column'
	},
	form: {
		backgroundColor: theme.light.colors.mainContainer,
		height: 'auto',
		width: '85%',
		marginHorizontal: 'auto',
		marginBottom: 10,
		padding: 20,
		borderRadius: 30,
		gap: 10
	},
	header_form:{
		justifyContent: 'space-between',
		flexDirection: 'row'
	},
	formInputsContainer: {
		gap: 16
	},
	formFields: {
		justifyContent: 'space-between',
		flexDirection: 'row'
	},
	formFieldsPhone:{
		flexDirection: 'column',
		gap: 10
	},
	formFieldsColumn: {
		justifyContent: 'flex-start',
		width: '48%',
		gap: 10
	},
	formFieldsColumnPhone: {
		justifyContent: 'flex-start',
		width: '100%',
		gap: 10
	},
	backButton: {
		paddingVertical: 10,
		paddingHorizontal: 5,
		textAlign: 'left',
		flexDirection: 'row',
		alignItems: 'center',
		gap: 6,
		width: 150
	},
	buttonText: {
		color: colors.white,
		fontWeight: 'bold',
		fontSize: 16
	},
	title: {
		fontSize: 24,
		color: colors.white,
		fontWeight: 'bold'
	},
	icon: {
		color: theme.light.colors.mainColor
	},
	button: {
		backgroundColor: theme.light.colors.primaryDark,
		borderRadius: 6,
		paddingVertical: 10,
		paddingHorizontal: 20,
		width: 180,
		marginTop: 20,
		flexDirection: 'row',
		gap: 8,
		justifyContent: 'center',
		alignItems: 'center'
	},
	footer: {
		alignItems: 'flex-end',
		justifyContent: 'flex-end'
	},
	formFieldWrapper: {
		gap: 6
	},
	formFieldWrapperPerm: {
		width: '100%',
		gap: 6
	},
	labelText: {
		fontSize: 16
	},
	checkBoxContainer:{
		width: '100%',
		flexDirection: 'row',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		flexWrap: 'wrap'
	},
	checkBoxContanerSmall:{
		flexDirection: 'column'
	},
	checkElement:{
		width: '50%',
		marginBottom: 12
	},
	checkElementSmall:{
		width: '100%',
		marginBottom: 12
	},
	labelInfo: {
		flexDirection: 'row',
		gap: 8
	},
	text: {
		marginLeft: 12
	},
	textIconContainer: {
		flexDirection: 'row'
	},
	infoContainer:{
		width: '30%',
		gap: 6
	},
	profileAvatar: {
		width: 90,
		height: 90,
		backgroundColor: theme.light.colors.primaryDark,
		borderRadius: 50,
		justifyContent: 'center'
	},
	avatarText: {
		fontWeight: 'bold',
		fontSize: 28,
		color: theme.light.colors.linkColorActive,
		textAlign: 'center'
	},
	errorText: {
		color: colors.error,
		fontSize: 12,
	}
})