import { theme } from '@styles/constants'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
	container: {
		flex: 1,
		padding: 10,
		gap: 5
	},
	noNotificationsContainer: {
		flex: 1,
		justifyContent: 'center',
		alignSelf: 'center'
	},
	noNotificationsText: {
		fontSize: 16,
		fontWeight: 500,
		alignSelf: 'center',
		color: theme.light.colors.buttonLg,
	}
})
