import { colors, theme } from '@styles/constants'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		padding: 20,
		backgroundColor: theme.light.colors.appContainer,
	},
	formContainer: {
		width: '100%',
		maxWidth: 500,
		paddingHorizontal: 20,
		paddingVertical: 40,
		borderRadius: 30,
		backgroundColor: theme.light.colors.mainContainer,
		alignItems: 'center',
		shadowColor: theme.light.colors.mainColor,
		shadowOffset: { width: 0, height: 1 },
		shadowOpacity: 0.2,
		shadowRadius: 2,
		elevation: 3,
	},
	title: {
		fontSize: 24,
		fontWeight: 'bold',
		marginBottom: 20,
		color: theme.light.colors.titleColor
	},
	forgotPassword: {
		fontWeight: '500',
		color: theme.light.colors.titleColor
	},
	forgotPasswordLink: {
		alignSelf: 'flex-end'
	},
	modalContainer: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		backdropFilter: 'blur(10px)',
	},
	appName: {
		fontSize: 20,
		fontWeight: '500',
		color: theme.light.colors.titleColor
	},
	logo: {
		width: 100,
		height: 100,
		borderRadius: 50,
		justifyContent: 'center',
		alignItems: 'center'
	}
})