import { StyleSheet } from 'react-native'
import { colors, theme } from '@styles/constants'
import { button } from '@styles/mixins/button'

export const styles = StyleSheet.create({
	contentContainer: {
		flexDirection: 'row',
		borderWidth: 1,
		borderColor: theme.light.colors.boxBorder,
		backgroundColor: theme.light.colors.boxHover,
		borderRadius: 6,
		padding: 12,
		gap: 12,
	},
	contentContainer_smallScreen: {
		borderWidth: 1,
		borderColor: colors.gray,
		backgroundColor: colors.lightGray,
		borderRadius: 4,
		padding: 10,
		gap: 6,
	},
	avatarArea: {
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	profileAvatar: {
		width: 40,
		height: 40,
		backgroundColor: theme.light.colors.primaryDark,
		borderRadius: 50,
		justifyContent: 'center',
		alignItems: 'center',
		alignSelf: 'center',
	},
	avatarText: {
		fontWeight: 'bold',
		fontSize: 22,
		color: colors.white,
		textAlign: 'center'
	},
	textContainer: {
		flexDirection: 'column',
		gap: 6,
		flexGrow: 1,
	},
	mainText: {
		fontWeight: '600',
		fontSize: 16,
	},
	text: {
		fontSize: 16,
		maxHeight: 250,
		overflowX: 'hidden', 
		overflowY: 'auto',
	},
	icon: {
		color: colors.white
	},
	fileContainer: {
		backgroundColor: colors.lightGray,
		borderRadius: 4,
		justifyContent: 'space-between',
		minHeight: 40,
		padding: 5,
		gap: 10
	},
	fileItem: {
		flexDirection: 'row',
		gap: 12
	},
	fileItem_small: {
		flexDirection: 'column',
		gap: 6
	},
	fileLink: {
		color: colors.black,
		fontWeight: '500',
		textDecoration: 'none',
		wordBreak: 'break-word'
	},
	textLink: {
		color: colors.white,
		fontWeight: '500',
		textDecoration: 'none'
	},
	fileNameContainer: {
		flexDirection: 'row',
		gap: 12
	},
	iconDark: {
		color: colors.darkAccent
	},
	buttonDark: {
		backgroundColor: colors.gray,
		borderRadius: 6,
		padding: 5,
		textAlign: 'center',
		width: 130,
		flexDirection: 'row',
		gap: 8,
		justifyContent: 'center'
	},
	buttonText: {
		fontWeight: '600',
		fontSize: 16,
		color: colors.white,
	},
	leftSide: {
		alignItems: 'flex-end',
		width: '10%'
	},
	buttonsArea: {
		flexDirection: 'row',
		gap: 10
	},
	info: {
		color: colors.darkGray,
		fontSize: 12
	},
	button: {
		flexDirection: 'row',
		...({
			...button(theme.light.colors.primaryDark).button,
		} as object),
		paddingVertical: 8,
		paddingHorizontal: 16,
		textAlign: 'center',
		width: 100,
		gap: 8,
		justifyContent: 'center',
		alignSelf: 'flex-end',
	},
	inputContainer: {
		gap: 10
	},
	errorText: {
		color: colors.error,
		fontSize: 12,
	}
})
