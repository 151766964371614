import { colors, theme } from '@styles/constants'
import { button } from '@styles/mixins/button'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
	caseManagement: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-around',
		flexWrap: 'wrap',
		gap: 10,
	},
	caseTitle: {
		fontSize: 18,
		fontWeight: '500',
		color: theme.light.colors.titleColor,
	},
	buttonCreate: {
		alignItems: 'flex-end',
		flexDirection: 'row',
		width: 15,
		gap: 5,	
		justifyContent: 'space-between',
		alignSelf: 'flex-end',
		paddingRight: '15%'
	},
	buttonText: {	
		color: theme.light.colors.linkColorActive,
		fontWeight: 'bold',
		marginLeft: 5
	},
	row: {
		flexDirection: 'row',
		alignItems: 'center'
	},
	subcategory: {
		borderColor: theme.light.colors.boxBorder,
		borderWidth: 1
	},
	createStatusContainer: {
		flexDirection: 'column'
	},
	inputField: {
		flex: 1,
		paddingRight: 20
	},
	label: {
		fontSize: 16,
		marginBottom: 8,
		color: theme.light.colors.titleColor,
	},
	errorText: {
		color: colors.error,
		fontSize: 12,
	},
	button: {
		...({
			...button(theme.light.colors.primaryDark).button,
		} as object),
		borderRadius: 6,
		paddingVertical: 10,
		paddingHorizontal: 20,
		width: 180,
		marginTop: 20,
		flexDirection: 'row',
		gap: 8,
		justifyContent: 'center',
		alignItems: 'center'
	},
	footer: {
		alignItems: 'flex-end'
	}
})
