import React, { FC, useState } from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import { SelectDropDown } from '@components/SelectDropDown/SelectDropdown'
import { rootReducerType } from '@reducers/combineReducers'
import { updateFirmSettings } from '@services/apiService'
import { setCompanySettings } from '@actions/companyActions'
import { setLPSettings } from '@actions/legalPartnerKeyActions'
import { SnackbarInitialState, SnackbarTypes } from '@utils/interfaces'
import { firmSettingsSchema } from '@utils/formValidation'
import { checkIfPermissionExist } from '@utils/utils'
import { styles } from './styles'
import { languages } from '@utils/constants'

interface FirmSettingsForm {
    setSnackbar: (snackbar: SnackbarInitialState) => void
    isCompany: boolean
}

export const FirmSettingForm: FC<FirmSettingsForm> = ({
	setSnackbar,
	isCompany
}) => {
	const dispatch = useDispatch()
	const [ isDirty, setIsDirty ] = useState(false)
	const { t } = useTranslation()
	const { permissions } = useSelector((state: rootReducerType) => state.userReducer)
	const { currentCompany: { companyKey }, settings: { language: companyLanguage } } = useSelector((state: rootReducerType) => state.companyReducer)
	const { key: LPKey, settings: { language: LPLanguage } } = useSelector((state: rootReducerType) => state.legalPartnerKeyReducer)
	const hasEditFirmSettingsPermission = checkIfPermissionExist(permissions,'firmSettings','update')  
    
	const initialValues = {
		language: isCompany ? companyLanguage : LPLanguage
	}

	const onSubmit = async (values) => {
		const firmSettings = await updateFirmSettings(
			isCompany ? companyKey : LPKey,
			values.language,
			!isCompany,
		)

		if (firmSettings?.status === 200) {
			if (isCompany) {
				dispatch(setCompanySettings({
					language: firmSettings.data.language,
				}))
			} else {
				dispatch(setLPSettings({
					language: firmSettings.data.language,
				}))
			}
			setSnackbar({
				isVisible: true,
				type: SnackbarTypes.SUCCESS,
				message: t('success.updateCompanyInfo')
			})
		} else {
			setSnackbar({
				isVisible: true,
				type: SnackbarTypes.ERROR,
				message: t('errors.updateCompanyInfo')
			})
		}

		setIsDirty(false)
	}

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={firmSettingsSchema}
			onSubmit={onSubmit}
		>
			{(formikProps) => (
				<View style={styles.formData}>
					<View>
						<Text style={styles.label}>{t('languageSelect')}</Text>
						<SelectDropDown 
							data={languages}
							defaultButtonText={
								t(languages.find(language => language === formikProps.values['language']) || '')
							}
							value={formikProps.values['language']}
							fieldName={'language'}
							handleFormValueChange={(name, value) => {
								formikProps.handleChange('language')(value as string);
								(!isDirty && formikProps.values['language'] !== value) && setIsDirty(true)
							}}
							onBlur={() => formikProps.setFieldTouched('language', true)}
							displayTranslatedValue={true}
							disabled={!hasEditFirmSettingsPermission}
						/>
						{formikProps.touched['language'] && formikProps.errors['language'] && (
							<Text style={styles.errorText}>{`*${formikProps.errors['language']}`}</Text>
						)}
					</View>
					{
						hasEditFirmSettingsPermission &&
							<TouchableOpacity
								style={[styles.button, (!isDirty || Boolean(formikProps.errors.language)) && styles.disabled]}
								onPress={() => formikProps.handleSubmit()}
								disabled={!isDirty || Boolean(formikProps.errors.language)}
							>
								<Text style={styles.buttonText}>{t('saveChanges')}</Text>
							</TouchableOpacity>
					}
				</View>
			)}
		</Formik>
	)
}