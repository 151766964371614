import { reduxActionType } from '@utils/reduxTypes'

const initialState = {
	isLoading: false,
}

export const loaderReducer = (
	state = initialState,
	action: reduxActionType
): (typeof initialState) => {
	switch( action.type) {
	case 'SET_IS_LOADING': {
		return {
			...state,
			isLoading: action.payload,
		}
	}
	default: return state
	}
}
