import React, { FC } from 'react'
import {  StyleSheet, ViewStyle } from 'react-native'
import { Snackbar as ReactNativeSnackbar } from 'react-native-paper'
import { SnackbarTypes, SnackbarType } from '@utils/interfaces'
import { colors } from '@styles/constants'

interface SnackbarProps {
    visible: boolean
    onDismiss: () => void
    type?: SnackbarType
    message: string
	style?: ViewStyle
	wrapperStyle?: ViewStyle
}

export const Snackbar: FC<SnackbarProps> = ({
	visible,
	onDismiss,
	type = SnackbarTypes.INFO,
	message,
	style,
	wrapperStyle
}) => {
	return <ReactNativeSnackbar
		visible={visible}
		onDismiss={onDismiss}
		children={message}
		style={[
			styles.snackbar,
			{ backgroundColor: getSnackbarBackgroundColor(type) },
			style
		]}
		wrapperStyle={wrapperStyle}
		duration={10000}
	/>
}

const styles = StyleSheet.create({
	snackbar: {
		flex: 1,
		color: colors.white,
	}
})

const getSnackbarBackgroundColor = (type: SnackbarType) => {
	switch(type) {
	case SnackbarTypes.SUCCESS:
		return colors.success
	case SnackbarTypes.ERROR:
		return colors.error
	case SnackbarTypes.INFO:
		return colors.gray
	default:
		return colors.gray
	}
}
