import { colors, theme } from '@styles/constants'
import { button, buttonText } from '@styles/mixins/button'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
	contentContainer: {
		width: '97%',
		maxWidth: 600,
		paddingHorizontal: 20,
		paddingVertical: 40,
		borderRadius: 10,
		backgroundColor: theme.light.colors.mainContainer,
		shadowColor: colors.black,
		shadowOffset: { width: 0, height: 4 },
		shadowOpacity: 0.1,
		shadowRadius: 4,
		elevation: 4,
		alignItems: 'center',
	},
	title: {
		fontSize: 24,
		fontWeight: 'bold',
		marginBottom: 8,
		color: theme.light.colors.titleColor,
	},
	formContainer: {
		alignSelf: 'stretch',
		padding: 16,
		gap: 16,
	},
	label: {
		fontSize: 16,
	},
	field: {
		gap: 2,
	},
	checkboxField: {
		flexDirection: 'row',
		alignItems: 'center',
		gap: 10,
		padding: 6,
	},
	fieldHeader: {
		display: 'flex',
		flexDirection: 'row',
		gap: 5
	},
	errorText: {
		color: colors.error,
		fontSize: 12,
	},
	submitButton: {
		...({
			...button(theme.light.colors.primaryDark).button,
		} as object),
		borderRadius: 6,
		paddingVertical: 12,
		justifyContent: 'center',
		marginTop: 10,
		flexGrow: 1,
		shadowColor: theme.light.colors.mainContainer,
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.2,
		shadowRadius: 2,
		elevation: 3,
	},
	submitButtonText: {
		...({
			...buttonText().buttonText,
		} as object),
		fontSize: 18,
		fontWeight: 'bold'
	},
	appName: {
		fontSize: 20,
		fontWeight: '500',
		color: theme.light.colors.titleColor
	},
	logo: {
		width: 100,
		height: 100,
		borderRadius: 50,
		justifyContent: 'center',
		alignItems: 'center'
	},
	infoSection: {
		padding: 20,
		borderWidth: 1,
		borderRadius: 30,
		borderColor: theme.light.colors.boxBorder,
		gap: 5
	},
	sectionHeader: {
		fontSize: 18,
		fontWeight: '500',
		color: theme.light.colors.titleColor
	},
	tooltip: {
		padding: 5,
		borderRadius: 50
	},
	login: {
		alignSelf: 'flex-end',
		flexDirection: 'row',
		paddingHorizontal: 16,
	},
	loginText: {
		fontWeight: '500',
		color: theme.light.colors.titleColor,
	},
	loginLink: {
		textDecorationLine: 'underline',
	},
	recommendation: {
		fontSize: 10,
		fontWeight: '500',
		marginBottom: 8,
		padding: 10,
		color: theme.light.colors.mainColor,
	},
	disabled: {
		opacity: 0.5
	}
})
