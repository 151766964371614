import React, { useEffect, useState } from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import Icon from 'react-native-vector-icons/FontAwesome'
import MessageCard from '@components/MessageCard/MessageCard'
import * as DocumentPicker from 'expo-document-picker'
import FileCard from '@components/FileCard/FileCard'
import VocalRecording from '@components/VocalRecording/VocalRecording'
import { Input } from '@components/Input'
import { File, Message, MessageText, SnackbarInitialState, SnackbarTypes, audioFile, initialMessage } from '@utils/interfaces'
import useScreenDimensions from '@components/Common/UseScreenDimensions'
import { iv, screenType } from '@utils/constants'
import { axiosBFFServer } from '@services/connectionServer'
import { Formik } from 'formik'
import { createMessageSchema } from '@utils/formValidation'
import { getDateAndTime, getLinkAndDocumentsForm } from '@utils/utils'
import { decryptValueWithAES, encryptValueWithAES } from '@utils/encryption'
import { styles } from './styles'
import {useSelector} from 'react-redux'
import {rootReducerType} from '@reducers/combineReducers'
import { Loader } from '@components/Loader/Loader'
import {sendMsg, uploadFile} from '@services/apiService'
import { UseErrorMessages } from '@components/Common/UseErrorMessage'

interface Props {
	caseKey: string
	secretKey: string
	isLargeComponent?: boolean
	setSnackbar: (value: SnackbarInitialState) => void
    getCaseDocs: () => void
}

export const Messages = (props: Props) => {
	const { t } = useTranslation()
	const screenDimensions = useScreenDimensions()
	const isSmallScreen = screenDimensions.width < screenType.underSmallScreen
	const isMobileScreen = screenDimensions.width < screenType.phone
	const isUnderSmallScreen = screenDimensions.width < screenType.smallLaptop
	const [uploadedFiles, setUploadedFiles] = useState<File[]>([])
	const [vocalRecord, setVocalRecord] = useState<audioFile[]>([])
	const [newMessage,setNewMessage] = useState<string | string[]>()
	const [messages, setMessages] = useState<Message[]>([])
	const [ isLoading, setIsLoading ] = useState(true)
	const { email } = useSelector((state: rootReducerType) => state.userReducer)
	const { getErrorMessage } = UseErrorMessages()

	const getMessages = () => {
		axiosBFFServer
			.get(`/chat/${props.caseKey}`)
			.then((response) => {
				setMessages(response.data.map(message => ({
					...message,
					text: decryptValueWithAES(props.secretKey, iv, message.text)
				})
				))
				setIsLoading(false)
			})
			.catch(error => {
				const errorMessage = getErrorMessage(error, t('errors.getComments'))
				props.setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
				setIsLoading(false)
			})
	}

	const sendMessage = async (values: MessageText) => {
		const allUploadedFiles = [...uploadedFiles, ...vocalRecord.map(audio => audio.blob)]
		const areDocuments = !!allUploadedFiles.length
		setIsLoading(true)

		if (areDocuments) {
			const { uploadDocUrl, fileDataToSend } = await getLinkAndDocumentsForm(allUploadedFiles, props.secretKey, iv)
			const uploadUrl = email ? `${uploadDocUrl}/${props.caseKey}?authorEmail=${email}` : `${uploadDocUrl}/${props.caseKey}`
			
			await uploadFile(uploadUrl, fileDataToSend)
				.then(async (response) => {
					if (response.status === 201) {
						const documentKeys = response.data instanceof Array ? response?.data.map((file) => file.documentKey) : [response.data.documentKey]
						await sendMessageText(newMessage, documentKeys)
						props.getCaseDocs()

						setUploadedFiles([])
						setVocalRecord([])
					} else throw new Error()
				})
				.catch(error => {
					let errorMessage = getErrorMessage(error, t('errors.uploadFiles'))
					if (error.response.status === 409) {
						errorMessage = t('errors.fileSameName')
					}
					if (error.response.status === 422 && error.response.data.message.includes('size')) {
						errorMessage = t('errors.fileSize')
					} 
					if (error.response.status === 422 && error.response.data.message.includes('type')) {
						errorMessage = t('errors.fileType')
					}

					setIsLoading(false)
					props.setSnackbar({
						isVisible: true,
						type: SnackbarTypes.ERROR,
						message: errorMessage
					})
				})
		} else {
			await sendMessageText(newMessage, [])
		}
	}

	const sendMessageText = async (textMessage, documentKeys) => {
		const text = encryptValueWithAES(props.secretKey, iv, textMessage)

		await sendMsg(text, documentKeys, props.caseKey)
			.then(response => {
				if (response?.status === 201) {
					getMessages()
				} else throw new Error()
			})
			.catch(error => {
				const errorMessage = getErrorMessage(error, t('errors.comment'))
				props.setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
				setIsLoading(false)
			})
	}

	const pickDocument = async () => {
		const result = await DocumentPicker.getDocumentAsync({
			type: '*/*',
			multiple: true,
			copyToCacheDirectory: true
		})
	
		if (result.output && result.output.length > 0){
			const files = Object.values(result.output) as File[]
			setUploadedFiles([ ...uploadedFiles, ...files ])
		}
	}

	useEffect(() => {
		getMessages()
	},[])

	return (
		<Formik
			initialValues={initialMessage}
			validationSchema={createMessageSchema}
			onSubmit={sendMessage}
			enableReninitialize
		>
			{formikProps => (
				<View style={props.isLargeComponent ? styles.containerLarge : styles.container}>
					<Text style={styles.sectionTitle}>{t('messages')}</Text>
					{isLoading
						? <Loader />
						: messages.length
							? <View style={styles.messagesContainer}>
								{messages.map((item, index)=>
									<MessageCard
										key={index}
										caseKey={props.caseKey}
										messageKey={item.key}
										user={item.author}
										message={item.text}
										date={getDateAndTime(item.createdAt)}
										edited={item.edited}
										documents={item.documents}
										secretKey={props.secretKey}
										setSnackbar={props.setSnackbar}/>
								)}
							</View>
							: <View style={styles.noMessagesContainer}>
								<Icon style={styles.iconDark} name="search" size={28} />
								<Text style={styles.labelText}>{t('noMessages')}</Text>
								<Text style={styles.labelTextSmall}>{t('noResponse')}</Text>
							</View>
					}
					<View style={styles.formFieldWrapper}>
						<Input
							placeholder={t('writeMessage')}
							name='message'
							value={formikProps.values['message']}
							onChange={(item,value) => {
								formikProps.setFieldValue(item,value)
								setNewMessage(value)
							}}
							multiline={true}
							onBlur={() => formikProps.setFieldTouched('message', true)} />
						{formikProps.touched['message'] && formikProps.errors['message'] && (
							<Text style={styles.errorText}>{`*${formikProps.errors['message']}`}</Text>
						)}
						{
							uploadedFiles && 
					uploadedFiles.map((file,index) =>
						<FileCard
							key={index}
							name={file.name} 
							index={index}
							uploadFiles={uploadedFiles} 
							setUploadFiles={setUploadedFiles}/>
					
					)
						}
					</View>
					<View style={(isMobileScreen || isUnderSmallScreen || isSmallScreen) ? styles.footer_smallScreen : styles.footer}>
						<View style={(isMobileScreen || isUnderSmallScreen || isSmallScreen) ? styles.buttonsContainer_smallScreen : styles.buttonsContainer}>
							<View style={(isMobileScreen || isUnderSmallScreen || isSmallScreen)? styles.voiceContainer_mobile : styles.voiceContainer}>
								<VocalRecording
									setAudioRecords={setVocalRecord}
									setSnackbar={props.setSnackbar}
								/>
							</View>
							<View>
								<TouchableOpacity 
									style={isMobileScreen ? [styles.buttonDark, styles.buttonDark_mobile] : styles.buttonDark}
									onPress={pickDocument}>
									<Icon style={styles.icon} name="upload" size={18} />
									<Text style={styles.buttonText}>{t('uploadFile')}</Text>
								</TouchableOpacity>
							</View>
						</View>
						<TouchableOpacity 
							style={(isMobileScreen || isUnderSmallScreen || isSmallScreen) ? [styles.button, styles.button_mobile] : styles.button}
							disabled={isLoading}
							onPress={async() => {
								await formikProps.handleSubmit()
								formikProps.handleReset()
							}}>
							<Icon style={styles.icon} name="envelope" size={18} />
							<Text style={styles.buttonText}>{t('sendMessage')}</Text>
						</TouchableOpacity>
					</View>
				</View>
			)}
		</Formik>
	)
}

