import React from 'react'
import LoginContainer from '@containers/Login/LoginContainer'
import { useLocation } from 'react-router-dom'
import i18n from '@assets/translation/i18n'

export const Login = () => {
	const location = useLocation()
	if (location.pathname.split('/')[2]) {
		i18n.changeLanguage(location.pathname.split('/')[2])
	}
    
	return (
		<LoginContainer />
	)
}
