import React, { useEffect, useState } from 'react'
import { Subscriptions, details, subscriptionPlans } from '@utils/interfaces'
import { Image, Text, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { getDeadline } from '@utils/utils'
import { useSelector } from 'react-redux'
import { rootReducerType } from '@reducers/combineReducers'
import { styles } from './styles'
import { colors } from '@styles/constants'

interface Props {
	subscriptions: subscriptionPlans[]
	subscriptionDetails: Subscriptions
	activeSubscriptionData: details[]
	hasSavedCard: boolean
	setSubscriptionPlan: ({ subscriptionType, price }) => void
	hasSubscriptionEditPermission: boolean
	openPaymentOptionsModal: (paymentOption: boolean) => void
}

export const SubscriptionPlans = (props: Props) => {
	const { t } = useTranslation()
	const companyStatus = useSelector((state: rootReducerType) => state.companyReducer.currentCompany.status)
	const subscriptionBenefits = [t('subscriptionBenefitFreeTrial'), t('subscriptionBenefit2'), t('subscriptionBenefit3'), t('subscriptionBenefit4'), t('subscriptionBenefit5')]
	const activePlan = props.subscriptionDetails && !props.subscriptionDetails.cancelled ? props.subscriptionDetails.subscriptionType : ''
	const cancelledPlan = props.subscriptionDetails && props.subscriptionDetails.cancelled ? props.subscriptionDetails.subscriptionType : ''
	const remainingPlan = props.subscriptionDetails && companyStatus === 'CANCELLED' ? props.subscriptionDetails.subscriptionType : ''
	const language = useSelector((state: rootReducerType) => state.userReducer.language)

	const [remainingDays, setRemainingDays] = useState('')

	const checkRemainingDays = () => {
		if (props.subscriptionDetails.nextPaymentDate){
			const activeDays = getDeadline(props.subscriptionDetails.nextPaymentDate,language)
			setRemainingDays(activeDays)
		}
		
	}

	const selectSubscriptionToActivate = (subscription) => {
		props.setSubscriptionPlan({subscriptionType: subscription.subscriptionType, price: subscription.price})
		props.openPaymentOptionsModal(true)
	}

	useEffect(() => {
		checkRemainingDays()
	}, [])

	return (
		<View style={styles.container}>
			{
				props.subscriptions.map((subscr) => 
					<View style={activePlan === subscr.subscriptionType ? [styles.subscriptionItem,styles.subscriptionItemActive] : styles.subscriptionItem}>
						<View style={styles.subscriptionItemInfo}>
							<Image
								source={require('@assets/Logo.webp')}
								style={styles.logo}
							/>
							<Text style={styles.title}>
								{language === 'ro' ? `${t('subscription')} ${t(subscr.subscriptionType)}`
									: `${t(subscr.subscriptionType)} ${t('subscription')}`}
								<Text style={{color: colors.danger}}> *</Text>
							</Text>
							<View style={styles.priceContainer}>
								<Text style={styles.price}>{subscr.price}</Text>
								<Text style={styles.priceValut}>EUR</Text>
							</View>
							<View style={styles.textContainer}>
								{
									<View style={styles.benefitsItem}>
										<Text>*</Text>
										<Text style={styles.text}>{t(`subscriptionBenefit${subscr.subscriptionType}`)}</Text>
									</View>
								}
								{
									subscriptionBenefits.map((item) =>
										<View style={styles.benefitsItem}>
											<Text>*</Text>
											<Text style={styles.text}>{item}</Text>
										</View>
									)
								}
							</View>
						</View>
						{
							activePlan === subscr.subscriptionType &&
							<View style={styles.textContainer}>
								{
									companyStatus !== 'CANCELLED' &&
									<Text style={[styles.text,styles.textBold]}>
										{companyStatus === 'FREE_TRIAL'
											? `${t('freeTrialexpiresIn')} ${getDeadline(props.subscriptionDetails.nextPaymentDate,language)}!`
											: `${t('subscriptionActiveDays')} ${getDeadline(props.subscriptionDetails.nextPaymentDate,language)}!`
										}
									</Text>
								}
								
								<Text style={[styles.text,styles.textBold]}>
									{t('subscriptionDetails')}
								</Text>
								{
									props.activeSubscriptionData.map((item) =>
										<View style={styles.benefitsItem}>
											<Text style={[styles.text,styles.textBold]}>-</Text>
											<Text style={[styles.text,styles.textBold]}>{`${item.key} : ${item.title}`}</Text>
										</View>
									)
								}
							</View>
							
						}
						{
							remainingDays[0] !== '-' && cancelledPlan === subscr.subscriptionType && props.subscriptionDetails.lastPaymentDate &&
							<View style={styles.activeUntil}>
								{
									`${t('expiresIn')} ${remainingDays}`
								}
							</View>
						}
						{
							companyStatus === 'FREE_TRIAL' && cancelledPlan === subscr.subscriptionType &&
							<View style={styles.activeUntil}>
								{
									`${t('freeTrialexpiresIn')} ${remainingDays}`
								}
							</View>
						}
						{
							props.subscriptionDetails.subscriptionType === subscr.subscriptionType && props.subscriptionDetails.cancelled && props.hasSubscriptionEditPermission?
								<TouchableOpacity style={styles.button} onPress={() => selectSubscriptionToActivate(subscr)}>
									<Text style={styles.buttonText}>{ t('reactivateSubscription') }</Text>
								</TouchableOpacity>
								:
								props.subscriptionDetails.subscriptionType === subscr.subscriptionType && !props.subscriptionDetails.cancelled ?
									<View style={styles.active}>
										{ ((companyStatus === 'FREE_TRIAL')) ? t('activeFreeTrial') : t('activePlan')}
									</View>
									: props.hasSubscriptionEditPermission ?
										<TouchableOpacity style={styles.button} onPress={() => selectSubscriptionToActivate(subscr)}>
											<Text style={styles.buttonText}>
												{
													(companyStatus === 'PAYMENT_DETAILS_REQUIRED') ? t('activateFreeTrial') : props.subscriptionDetails.subscriptionType && !props.subscriptionDetails.cancelled ? t('changePlan') : t('activateSubscription')
												}
											</Text>
										</TouchableOpacity> : null
						}
					</View>
				)
			}
		</View>
	)
}
