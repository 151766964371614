import React, { FC } from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import i18n from '@assets/translation/i18n'
import { Input } from '@components/Input'
import { styles } from './styles'

interface PasswordVerificationCodeFormProps {
    isAdminOrPartnerAccount: boolean
    onSubmit: (values) => void
}

export const PasswordVerificationCodeForm: FC<PasswordVerificationCodeFormProps> = ({
	isAdminOrPartnerAccount,
	onSubmit,
}) => {
	const { t } = useTranslation()
	const passwordVerificationCodeSchema = Yup.object().shape({
		verificationCode: Yup.string()
			.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.verificationCode') })),
		...(isAdminOrPartnerAccount && { masterPassword: Yup.string()
			.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.masterPassword') })),
		})
	})
    
	return (
		<View style={styles.container}>
			<Formik
				initialValues={{
					verificationCode: '',
					masterPassword: '',
				}}
				validationSchema={passwordVerificationCodeSchema}
				onSubmit={onSubmit}
			>
				{(formikProps) => (
					<View style={styles.formContainer}>
						<View>
							<Text style={styles.label}>{t('fieldLabels.verificationCode')}</Text>
							<Input
								name='verificationCode'
								placeholder={t('fieldLabels.verificationCode')}
								value={formikProps.values['verificationCode']}
								onChange={formikProps.setFieldValue}
								onBlur={() => formikProps.setFieldTouched('verificationCode', true)}
							/>
							{formikProps.touched['verificationCode'] && formikProps.errors['verificationCode'] && (
								<Text style={styles.errorText}>{`*${formikProps.errors['verificationCode']}`}</Text>
							)}
						</View>
						{isAdminOrPartnerAccount && <View>
							<Text style={styles.label}>{t('fieldLabels.masterPassword')}</Text>
							<Input
								name='masterPassword'
								placeholder={t('fieldLabels.masterPassword')}
								value={formikProps.values['masterPassword']}
								onChange={formikProps.setFieldValue}
								onBlur={() => formikProps.setFieldTouched('masterPassword', true)}
								isSecureEntry={true}
							/>    
							{formikProps.touched['masterPassword'] && formikProps.errors['masterPassword'] && (
								<Text style={styles.errorText}>{`*${formikProps.errors['masterPassword']}`}</Text>
							)}
						</View>}
						<TouchableOpacity
							style={styles.submitButton}
							onPress={() => formikProps.handleSubmit()}
						>
							<Text style={styles.submitButtonText}>{t('send')}</Text>
						</TouchableOpacity>
					</View>
				)}
			</Formik>
		</View>
	)
}
