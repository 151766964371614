import { reduxActionType } from '@utils/reduxTypes'

const initialState = {
	key: '',
	publicKey: '',
	encodedPrivateKey: '',
	name: '',
	email: '',
	phoneNumber: '',
	streetAddress: '',
	city: '',
	zipCode: '',
	vatNumber: '',
	country: '',
	settings: {
		language: '',
	},
	encodedMasterPassword: ''
}

export const legalPartnerKeyReducer = (
	state = initialState,
	action: reduxActionType
): (typeof initialState) => {
	const newState = JSON.parse(JSON.stringify(state))
	switch (action.type) {
	case 'SET_LP_KEY': {
		newState.key = action.payload
		return newState
	}
	case 'SET_LP_KEYS': {
		return {
			...state,
			publicKey: action.payload.publicKey,
			encodedPrivateKey: action.payload.encodedPrivateKey,
		}
	}
	case 'SET_LP': {
		return {
			...state,
			...action.payload,
		}
	}
	case 'PATCH_LEGAL_PARTNER': {
		return {
			...state,
			...action.payload,
		}
	}
	case 'SET_LP_SETTINGS': {
		return {
			...state,
			settings: {
				...action.payload,
			},
		}
	}
	default: return state
	}
}