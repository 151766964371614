import { MultiSelectDropdown } from '@components/MultiSelect'
import { Snackbar } from '@components/Snackbar/Snackbar'
import { rootReducerType } from '@reducers/combineReducers'
import { axiosBFFServer } from '@services/connectionServer'
import { snackbarInitialState } from '@utils/constants'
import { SnackbarInitialState, SnackbarTypes } from '@utils/interfaces'
import { haveCommonItems, isPartnerOrAdvisor } from '@utils/utils'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'react-native'
import { useSelector } from 'react-redux'
import { styles } from './styles'
import { UseErrorMessages } from '@components/Common/UseErrorMessage'

interface Props {
	caseCreatedDetails: {
		caseKey: '',
		companyName: '',
		advisors: [],
		companyUsers: []
	}
}

interface Handlers {
	id: '',
	email: ''
}

export const AssignHandlerCase = ({caseCreatedDetails}: Props) => {
	const { t } = useTranslation()
	const { getErrorMessage } = UseErrorMessages()
	
	const [handlersForTheCase, setHandlersForTheCase ] = useState<Handlers[]>([])
	const [alreadyAssignedHandlers, setAlreadyAssignedHandlers] = useState<Handlers[]>([])
	const [alreadyAssignedHandlersKeys, setAlreadyAssignedHandlersKeys] = useState<string[]>([])
	const [ snackbar, setSnackbar ] = useState<SnackbarInitialState>(snackbarInitialState)
	
	const roles = useSelector((state: rootReducerType) => state.userReducer.roles)
	const companiesData = useSelector((state: rootReducerType) => state.companyReducer.companies)
	const {advisors, users} = useSelector((state:rootReducerType) => state.companyReducer.currentCompany)

	const caseKey = caseCreatedDetails.caseKey
	const part = caseKey.split('_')
	const companyKeyForCase = part[0]

	useEffect(() => {
		
		if(isPartnerOrAdvisor(roles)) {

			const allUsersForCompanyCase = companiesData.map((company: {companyKey, users}) => company.companyKey === companyKeyForCase ? company.users : null)
			const advisorsForCompanyCase = companiesData.map((company: {companyKey, advisors}) => company.companyKey === companyKeyForCase ? company.advisors : null)

			const uniqueArrayOfCompUsers = allUsersForCompanyCase.filter(item => item !== null).flat()
			const uniqueArrOffAdv = advisorsForCompanyCase.filter( item => item !== null).flat()

			const handler = [...uniqueArrOffAdv.map((item: {advisorKey, email}) => ({id: item.advisorKey, email: item.email})), ...uniqueArrayOfCompUsers.map((item: {companyUserKey, email}) => ({id: item.companyUserKey, email: item.email}))]

			setHandlersForTheCase(handler)

		} else {

			const handler = [...advisors.map((item: {advisorKey, email}) => ({ id: item.advisorKey, email: item.email })), ...users.map((item: {companyUserKey, email}) => ({id: item.companyUserKey, email: item.email}))]

			setHandlersForTheCase(handler)			
		}

	}, []	)

	useEffect(() => {
		
		const advForCase = caseCreatedDetails.advisors
		const companyUsersForCase = caseCreatedDetails.companyUsers
		const handlerAssignedToCase = [...advForCase.map((item: {advisorKey, email}) => ({ id: item.advisorKey, email: item.email })), ...companyUsersForCase.map((item: {companyUserKey, email}) => ({id: item.companyUserKey, email: item.email}))]

		setAlreadyAssignedHandlers(handlerAssignedToCase)
		setAlreadyAssignedHandlersKeys(handlerAssignedToCase.map(user => user.id))

	}, [])


	const handleSelect = (selected) => {

		const newSelectedUser = alreadyAssignedHandlersKeys.filter(item => !selected.includes(item))
		
		if(haveCommonItems(alreadyAssignedHandlersKeys, newSelectedUser)) {

			const alreadySelFiltered = alreadyAssignedHandlersKeys.filter(item => item !== String(newSelectedUser))
			const alreadySelFilteredEmail = alreadyAssignedHandlers.filter(item => item.id !== String(newSelectedUser))
			setAlreadyAssignedHandlers(alreadySelFilteredEmail)
			setAlreadyAssignedHandlersKeys(alreadySelFiltered)

			const isAdvisorSelectedValue = isPartnerOrAdvisor(roles) 
				? companiesData.map((company: {advisors: []}) => company.advisors).flat().some((advisor: {advisorKey}) => advisor.advisorKey === String(newSelectedUser))
				: advisors.some((advisor: {advisorKey}) => advisor.advisorKey === String(newSelectedUser))

			const isCompanyUserSelectedValue = isPartnerOrAdvisor(roles) 
				? companiesData.map((company: {users:[]}) => company.users).flat().some((compUser: {companyUserKey}) => compUser.companyUserKey === String(newSelectedUser)) 
				: users.some((compUser: {companyUserKey}) => compUser.companyUserKey === String(newSelectedUser))

			const reqBody = {
				caseKey: caseCreatedDetails.caseKey,
				...(isAdvisorSelectedValue ?  {advisors: newSelectedUser} : null),
				...(isCompanyUserSelectedValue ?  {companyUsers: newSelectedUser} : null)
			} 

			axiosBFFServer
				.post('/case/unassign/handlers', reqBody)
				.then(response => {
					if(response.status === 201) {
						setSnackbar({
							isVisible: true,
							type: SnackbarTypes.SUCCESS,
							message: t('unassignAdvisorToCaseSuccess')
						})
					}
				})
				.catch(error => {
					const errorMessage = getErrorMessage(error, t('unassignAdvisorToCaseError'))
					setSnackbar({
						isVisible: true,
						type: SnackbarTypes.ERROR,
						message: errorMessage 
					})
				})
		}

		else{

			const newSelectedUser = selected.filter(item => !alreadyAssignedHandlersKeys.includes(item))

			const alreadySelFilteredEmail = selected.map(id => {
				const matchingObject = handlersForTheCase.find(obj => obj.id === id)
				return matchingObject ?{id, email: matchingObject.email} : null
			}).filter(email => email !== null)

			setAlreadyAssignedHandlers(alreadySelFilteredEmail)
			setAlreadyAssignedHandlersKeys(selected)
		
			const isAdvisorSelectedValue = isPartnerOrAdvisor(roles) 
				? companiesData.map((company: {advisors: []}) => company.advisors).flat().some((advisor: {advisorKey}) => advisor.advisorKey === String(newSelectedUser))
				: advisors.some((advisor: {advisorKey}) => advisor.advisorKey === String(newSelectedUser))

			const isCompanyUserSelectedValue = isPartnerOrAdvisor(roles) 
				? companiesData.map((company: {users:[]}) => company.users).flat().some((compUser: {companyUserKey}) => compUser.companyUserKey === String(newSelectedUser)) 
				: users.some((compUser: {companyUserKey}) => compUser.companyUserKey === String(newSelectedUser))

			const reqBody = {
				caseKey: caseCreatedDetails.caseKey,
				...(isAdvisorSelectedValue ?  {advisors: newSelectedUser} : null),
				...(isCompanyUserSelectedValue ?  {companyUsers: newSelectedUser} : null)
			} 
	
			axiosBFFServer
				.post('/case/assign/handlers', reqBody)
				.then(response => {
					if(response.status === 201) {
						setSnackbar({
							isVisible: true,
							type: SnackbarTypes.SUCCESS,
							message: t('assignAdvisorToCaseSuccess')
						})
						
					} 
				})
				.catch(error => {
					const errorMessage = getErrorMessage(error, t('assignAdvisorToCaseErrorNotComp'))
					setSnackbar({
						isVisible: true,
						type: SnackbarTypes.ERROR,
						message: errorMessage
					})
				})
		}
	}

	const renderAssignedUsers = () => {
		return(
			alreadyAssignedHandlers.length > 0 ? alreadyAssignedHandlers.map((users => {
				return (
					<View>
						<Text>{users.email}</Text>
					</View>
				)
			})) : (
				<Text>{t('noAssignedhandlers')}</Text>
			)
		)
	}

	return (
		<View>
			<View style={styles.contentContainer}>
				<View style={styles.headerContainer}>
					<Text style={styles.headerModalText}>{t('assignHandlerTitle')}</Text>
				</View>
				<MultiSelectDropdown 
					items={handlersForTheCase} 
					selectedItems={alreadyAssignedHandlersKeys} 
					onSelectedItemsChange={handleSelect} 
					uniqueKey={'id'}  
					displayKey={'email'} 
					placeholder={t('search')}
					searchText={t('search')}			 
				/>
				<View>
					<Text style={styles.assignedUsersContainerTitle}>{'Assigned handlers: '}</Text>
					{renderAssignedUsers()}
				</View>
			</View>
			<Snackbar
				visible={snackbar.isVisible}
				onDismiss={() => setSnackbar(snackbarInitialState)}
				type={snackbar.type}
				message={snackbar.message}
			/>
		</View>
	)
}
