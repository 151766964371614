import axios from 'axios'
import localStorage from 'redux-persist/es/storage'

const axiosBFFServer = axios.create({
	withCredentials: true,
	baseURL: process.env.BFF_URL,
	headers: {
		'Content-Type': 'application/json',
	}
})

const axiosBFFServerFormData = axios.create({
	withCredentials: true,
	baseURL: process.env.BFF_URL,
	headers: {
		'Content-Type': 'multipart/form-data',
	}
})

axiosBFFServer.interceptors.response.use(response => response, error => {
	const currentPath = window.location.pathname

	if ((error.response.status === 403 || error.response.status === 401) && !currentPath.includes('/login') && !currentPath.includes('/report')) {
		global.location.replace(`${process.env.REACT_NATIVE_APP_BASE_URL}/login`)
	}

	return Promise.reject(error)
})

axiosBFFServer.interceptors.request.use(async (config) => {
	const language = await localStorage.getItem('i18nextLng')
	config.headers['Accept-Language'] = (language || 'en').toLowerCase()
    
	return config
})

axiosBFFServerFormData.interceptors.request.use(async (config) => {
	const language = await localStorage.getItem('i18nextLng')
	config.headers['Accept-Language'] = (language || 'en').toLowerCase()
    
	return config
})

export { axiosBFFServer, axiosBFFServerFormData }