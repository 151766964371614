import { colors } from '@styles/constants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text, TextInput, TouchableOpacity } from 'react-native'
import FontAwesome from 'react-native-vector-icons/FontAwesome'
import { styles } from './styles'

interface ForgotPasswordProps {
  email: string;
  onEmailChange: (text: string) => void;
  onEmailSubmit: () => void;
  isEmailSent: boolean;
  onClose: () => void;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({ email, onEmailChange, onEmailSubmit, isEmailSent, onClose }) => {
	const { t } = useTranslation()

	return (
		<View style={styles.container}>
			<View style={styles.forgotPass}>
				<TouchableOpacity style={styles.closeButton} onPress={onClose}>
					<FontAwesome name="times" color={colors.darkShades} size={20} />
				</TouchableOpacity>
				<View>
					<Text style={styles.title}>{ t('resetPassword') }</Text>
					<Text style={styles.subtitle}>{ t('resetPasswordDescription') }</Text>
				</View>
				<View style={styles.sendForm}>
					{!isEmailSent ? (
						<View style={styles.form}>
							<View>
								<Text style={styles.label}>{t('email')}</Text>
								<TextInput
									style={styles.input}
									placeholder={ t('inputPlaceholders.email') }
									onChangeText={onEmailChange}
									value={email}
									textContentType='emailAddress'
								/>
							</View>
							<TouchableOpacity disabled={!email} style={styles.button} onPress={onEmailSubmit}>
								<FontAwesome
									name={'envelope'} 
									color={colors.pureWhite} 
									size={14}
								/>
								<Text style={styles.btnText}>
									{ t('sendPasswordReset') }
								</Text>
							</TouchableOpacity>
						</View>
					) : (
						<Text style={styles.confirmationText}>{t('forgotPassEmailSend',{ email })}</Text>
					)}
				</View>
			</View>
		</View>
	)
}



export default ForgotPassword