import { Tooltip } from '@rneui/themed'
import { useState } from 'react'
import { Modal, Text, View, ViewStyle } from 'react-native'
import { colors, theme } from '@styles/constants'
import { StyleSheet } from 'react-native'
import Icon from 'react-native-vector-icons/FontAwesome'

interface Props{
	iconName: string;
	tooltip: string;
	transparentBackground?: boolean;
	style?: ViewStyle; 
	size?: number;
}


export const ItemWithTooltip = (props: Props) => {
	const [open, setOpen] = useState(false)

	return(
		<Tooltip
			visible={open}
			onOpen={() => {
				setOpen(true)
			}}
			onClose={() => {
				setOpen(false)
			}}
			ModalComponent={Modal}
			backgroundColor={colors.lightGray}
			popover={
				<Text>{props.tooltip}</Text>
			}
			containerStyle={{ width: 'auto', height: 'auto', marginBottom: 30}}> 
			
			<View style={props.style ? props.style : props.transparentBackground ? [styles.item, styles.transparentBG] : styles.item}>
				{
					props.iconName && 
					<Icon color={theme.light.colors.secondaryColor} name={props.iconName} size={props.size ? props.size : 18} />
				}
			</View>
	
		</Tooltip>
		
	)
}

const styles = StyleSheet.create({
	item:{
		backgroundColor: colors.lightGray,
		borderRadius: 4,
		alignItems: 'center',
		padding: 10
	},
	transparentBG: {
		backgroundColor: 'transparent',
		padding: 0
	}
	
})