import { t } from 'i18next'

export const UseErrorMessages = () => {

	const getErrorMessage = (error, endpointDefaultMessageKey) => {
		const generalDefaultMessageKey = 'errorMessages.default'

		if (error && error.response && error.response.data && error.response.data.customStatus) {
			const { customStatus } = error.response.data
			const key = `errorMessages.${customStatus}`
			return t(key, { defaultValue: t(generalDefaultMessageKey) })
		} else {
			return t(endpointDefaultMessageKey, { defaultValue: t(generalDefaultMessageKey) })
		}
	}

	return { getErrorMessage }
}