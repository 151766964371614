import React, { FC, useRef } from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import { Editor } from '@tinymce/tinymce-react'
import { Editor as TinyMCEEditor } from 'tinymce'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { SelectDropDown } from '@components/SelectDropDown/SelectDropdown'
import { rootReducerType } from '@reducers/combineReducers'
import { PageTemplate } from '@utils/interfaces'
import { languages } from '@utils/constants'
import { checkIfPermissionExist, isCompanyUserAdmin } from '@utils/utils'
import { styles } from './style'

interface TextEditorProps {
    id: string
    template: PageTemplate
    setLanguage: (value: string) => void
    onSave: (content: string) => void
}

export const TextEditor: FC<TextEditorProps> = ({
	id,
	template: {
		language,
		content,
	},
	setLanguage,
	onSave
}) => {
	const editorRef = useRef<TinyMCEEditor>()
	const { t } = useTranslation()

	const { roles, permissions } = useSelector((state: rootReducerType) => state.userReducer)

	const isCompanyAdmin = isCompanyUserAdmin(roles)
	const hasEditTemplatePermission = checkIfPermissionExist(permissions, 'companySettings', 'update')
    
	const handleSave = () => {
		if (editorRef.current) {
			const content = editorRef.current.getContent()
			onSave(content)
		}
	}

	return (
		<View style={styles.container}>
			<View style={styles.inputContainer}>
				<SelectDropDown 
					data={languages}
					value={languages.find(item => item === language)}
					fieldName='language'
					handleFormValueChange={(fieldName, value) => {
						setLanguage(value)
					}}
					displayTranslatedValue={true}
				/>
			</View>
			<Editor
				apiKey={process.env.TINY_MCE_API_KEY}
				id={id}
				initialValue={content}
				onInit={(event, editor) => editorRef.current = editor}
				disabled={!(isCompanyAdmin || hasEditTemplatePermission)}
				init={{
					height: 400,
					menubar: false,
					plugins: [
						'lists'
					],
					toolbar: 'undo redo | blocks | bold italic underline | ' +
                    'forecolor backcolor | alignleft aligncenter alignright alignjustify | ' +
                    'bullist numlist | outdent indent',
					content_style: 'body { font-size:14px }'
				}}
			/>
			{(isCompanyAdmin || hasEditTemplatePermission) && <View style={styles.buttonContainer}>
				<TouchableOpacity style={styles.button} onPress={handleSave}>
					<Text style={styles.buttonText}>{t('save')}</Text>
				</TouchableOpacity>
			</View>}
		</View>
	)
}
