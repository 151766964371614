import { theme } from '@styles/constants'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: 'center',
		backgroundColor: theme.light.colors.appContainer,
	},
	container_mobile: {
		flexDirection: 'column'
	},
	content: {
		backgroundColor: theme.light.colors.appContainer,
		height: 'auto',
		padding: 20,
		borderRadius: 6,
		gap: 20,
	},
	tableContainer: {
		width: '100%',
		borderRadius: 30,
		shadowColor: theme.light.colors.mainColor,
		shadowOffset: { width: 0, height: 1 },
		shadowOpacity: 0.2,
		shadowRadius: 2,
		elevation: 3,
	},
	title: {
		fontSize: 26,
		fontWeight: 'bold',
		color: theme.light.colors.titleColor
	},
	pageTitle: {
		width: '100%',
	},
	contentText: {
		fontSize: 14
	},
	dataText: {
		fontSize: 16,
	},
	smallScreenDataText: {
		fontSize: 14,
	},
	detailsContainer: {
		width: '100%',
		backgroundColor: theme.light.colors.mainContainer,
		padding: 20,
		borderRadius: 30, 
		alignItems: 'flex-start',
		gap: 10,
	},
	detailsContainerMobile: {
		width: '100%',
		backgroundColor: theme.light.colors.mainContainer,
		padding: 20,
		borderRadius: 30, 
		alignItems: 'flex-start',
		gap: 10,
		overflow: 'scroll'
	},
	detailsColumns: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-around',
		alignItems: 'flex-start',
		width: '100%',
	},
	column: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-around',
		gap: 10
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
	},
	descriptionValue: {
		flex: 7,
	},
	caseLabel: {
		color: theme.light.colors.linkColor,
		alignItems: 'flex-start',
		flex: 1,
		fontWeight: 'bold'
	},
	caseValue: {
		color: theme.light.colors.linkColor,
		alignItems: 'flex-start',
		flex: 3,
	},
	header: {
		width: '100%',
		gap: 10,
	},
	backButton: {
		paddingVertical: 10,
		paddingHorizontal: 5,
		textAlign: 'left',
		flexDirection: 'row',
		alignItems: 'center',
		gap: 6,
	},
	backButtonText: {
		color: theme.light.colors.titleColor,
		fontWeight: 'bold',
	},
	icon: {
		color: theme.light.colors.titleColor,
	},
	columnsContainer: {
		flexDirection: 'row',
		gap: 20,
	},
	columnsContainer_mobile: {
		flexDirection: 'column'
	},
	columnLeft: {
		flex: 1,
	},
	columnRight: {
		flex: 1,
		maxWidth: 370,
	},
	columnRight_mobile: {
		maxWidth: '100%'
	}
})