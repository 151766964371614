import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import { View, Text, TouchableOpacity} from 'react-native'
import { Input } from '@components/Input'
import { useSelector, useDispatch } from 'react-redux'
import { updateCompany, updatePartner } from '@services/apiService'
import { rootReducerType } from '@reducers/combineReducers'
import { patchCompany } from '@actions/companyActions'
import { patchLegalPartner } from '@actions/legalPartnerKeyActions'
import { SnackbarInitialState, SnackbarTypes } from '@utils/interfaces'
import { checkIfPermissionExist, getObjectDifference, isCompanyUserAdmin, isPartner } from '@utils/utils'
import { updateCompanyInfo } from '@utils/formValidation'
import { styles } from './styles'

interface CompanyInfoFormProps {
    setSnackbar: (snackbar: SnackbarInitialState) => void
    isCompany: boolean
}

export const CompanyInfoForm: React.FC<CompanyInfoFormProps> = ({ setSnackbar, isCompany }) => {
	const [ isDirty, setIsDirty ] = useState(false)
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const companyInfo = useSelector((state: rootReducerType) => isCompany ? state.companyReducer.currentCompany : state.legalPartnerKeyReducer)
	const userRoles = useSelector((state: rootReducerType) => state.userReducer.roles)
	const userPermissions = useSelector((state: rootReducerType) => state.userReducer.permissions)
	const hasEditCompanyPermission = checkIfPermissionExist(userPermissions,'company','update')  

	const isEditable = isCompany
		? isCompanyUserAdmin(userRoles) || hasEditCompanyPermission
		: isPartner(userRoles)

	const initialValues = {
		name: companyInfo.name || '',
		vatNumber: companyInfo.vatNumber || '',
		email: isCompany ? (companyInfo.users && companyInfo?.users[0]?.email) || '' : companyInfo.email,
		phoneNumber: companyInfo.phoneNumber || '',
		city: companyInfo.city || '',
		streetAddress: companyInfo.streetAddress || '',
		zipCode: companyInfo.zipCode || '',
	}

	const onSubmit = async (values) => {
		const reqBody = getObjectDifference(values, initialValues)

		if (isCompany) {
			const newCompany = await updateCompany(companyInfo.companyKey, { ...reqBody, nrOfEmployees: companyInfo.nrOfEmployees })
			if (newCompany?.status === 200) {
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.SUCCESS,
					message: t('success.updateCompanyInfo')
				})
				dispatch(patchCompany({
					name: newCompany.data.name,
					vatNumber: newCompany.data.vatNumber,
					email: newCompany.data.email,
					phoneNumber: newCompany.data.phoneNumber,
					city: newCompany.data.city,
					streetAddress: newCompany.data.streetAddress,
					zipCode: newCompany.data.zipCode,
				}))
			} else {
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: t('errors.updateCompanyInfo')
				})
			}
		} else {
			const newPartner = await updatePartner(reqBody, companyInfo.key)
			if (newPartner?.status === 200) {
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.SUCCESS,
					message: t('success.updateCompanyInfo')
				})
				dispatch(patchLegalPartner({
					name: newPartner.data.name,
					vatNumber: newPartner.data.vatNumber,
					email: newPartner.data.email,
					phoneNumber: newPartner.data.phoneNumber,
					city: newPartner.data.city,
					streetAddress: newPartner.data.streetAddress,
					zipCode: newPartner.data.zipCode,
				}))
			} else {
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: t('errors.updateCompanyInfo')
				}) 
			}
			
		}

		setIsDirty(false)
	}
	
	return (
		<Formik
			enableReinitialize={true} 
			initialValues={initialValues} 
			validationSchema={updateCompanyInfo} 
			onSubmit={onSubmit}
		>
			{(formikProps) => (
				<View style={styles.companyData}>
					<View style={styles.companyRow}>
						<View style={{ flex: 1 }}>
							<Text style={styles.label}>{t('companyName')}</Text>
							<Input
								name='name'
								value={formikProps.values['name']}
								onChange={(name, value) => {
									formikProps.handleChange('name')(value as string);
									(!isDirty && formikProps.values['name'] !== value) && setIsDirty(true)
								}}
								onBlur={() => formikProps.setFieldTouched('name', true)}
								editable={isEditable}
							/>
						</View>
						<View style={{ flex: 1 }}>
							<Text style={styles.label}>{t('vatNumber')}</Text>
							<Input
								name='vatNumber'
								value={formikProps.values['vatNumber']}
								onChange={(name, value) => {
									formikProps.handleChange('vatNumber')(value as string);
									(!isDirty && formikProps.values['vatNumber'] !== value) && setIsDirty(true)
								}}
								onBlur={() => formikProps.setFieldTouched('vatNumber', true)}
								editable={isEditable}
							/>
						</View>		
					</View>
					<View style={styles.companyRow}>
						<View style={{ flex: 1 }}>
							<Text style={styles.label}>{t('email')}</Text>
							<Input
								name='email'
								value={formikProps.values['email']}
								onChange={(name, value) => {
									formikProps.handleChange('email')(value as string);
									(!isDirty && formikProps.values['email'] !== value) && setIsDirty(true)
								}}
								onBlur={() => formikProps.setFieldTouched('email', true)}
								editable={isCompany ? isEditable : false}
							/>
						</View>
						<View style={{ flex: 1 }}>
							<Text style={styles.label}>{t('phone')}</Text>
							<Input
								name='phoneNumber'
								value={formikProps.values['phoneNumber']}
								onChange={(name, value) => {
									formikProps.handleChange('phoneNumber')(value as string);
									(!isDirty && formikProps.values['phoneNumber'] !== value) && setIsDirty(true)
								}}
								onBlur={() => formikProps.setFieldTouched('phoneNumber', true)}
								editable={isEditable}
							/>
						</View>	
					</View>
					<View style={styles.companyRow}>
						<View style={{ flex: 1 }}>
							<Text style={styles.label}>{t('city')}</Text>
							<Input
								name='city'
								value={formikProps.values['city']}
								onChange={(name, value) => {
									formikProps.handleChange('city')(value as string);
									(!isDirty && formikProps.values['city'] !== value) && setIsDirty(true)
								}}
								onBlur={() => formikProps.setFieldTouched('city', true)}
								editable={isEditable}
							/>
						</View>
						<View style={{ flex: 1 }}>
							<Text style={styles.label}>{t('zipCode')}</Text>
							<Input
								name='zipCode'
								value={formikProps.values['zipCode']}
								onChange={(name, value) => {
									formikProps.handleChange('zipCode')(value as string);
									(!isDirty && formikProps.values['zipCode'] !== value) && setIsDirty(true)
								}}
								onBlur={() => formikProps.setFieldTouched('zipCode', true)}
								editable={isEditable}
							/>
						</View>	
					</View>
					<View style={styles.companyRow}>
						<View style={{ flex: 0.5 }}>
							<Text style={styles.label}>{t('street')}</Text>
							<Input
								name='street'
								value={formikProps.values['streetAddress']}
								onChange={(name, value) => {
									formikProps.handleChange('streetAddress')(value as string);
									(!isDirty && formikProps.values['streetAddress'] !== value) && setIsDirty(true)
								}}
								onBlur={() => formikProps.setFieldTouched('streetAddress', true)}
								editable={isEditable}
							/>
						</View>
					</View>
					<Text style={styles.validationMessage}>
						<>{(formikProps.touched['name'] || formikProps.touched['vatNumber'] || formikProps.touched['email'] || formikProps.touched['phoneNumber'] || formikProps.touched['city'] || formikProps.touched.zipCode || formikProps.touched['streetAddress'])
                            && (formikProps.errors['name'] || formikProps.errors['vatNumber'] || formikProps.errors['email'] || formikProps.errors['phoneNumber'] || formikProps.errors['city'] || formikProps.errors['zipCode'] || formikProps.errors['streetAddress'])}</>
					</Text>
					{
						isEditable &&
							<TouchableOpacity
								style={[styles.button, (!isDirty || Boolean(formikProps.errors.name || formikProps.errors.vatNumber || formikProps.errors.email || formikProps.errors.phoneNumber || formikProps.errors.city || formikProps.errors.zipCode || formikProps.errors.streetAddress)) && styles.disabled]}
								onPress={() => formikProps.handleSubmit()}
								disabled={!isDirty || Boolean(formikProps.errors.name || formikProps.errors.vatNumber || formikProps.errors.email || formikProps.errors.phoneNumber || formikProps.errors.city || formikProps.errors.zipCode || formikProps.errors.streetAddress)}
							>
								<Text style={styles.buttonText}>{t('saveChanges')}</Text>
							</TouchableOpacity>
					}
				</View>
			)}
		</Formik>
	)
}