import React from 'react'
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native'
import Icon from 'react-native-vector-icons/FontAwesome'
import { CaseStateType } from '@utils/interfaces'
import { colors, theme } from '@styles/constants'


interface DataRendererProps {
  data: CaseStateType[];
  onDelete: (caseStateKey: string) => void;
}

export const DataRenderer: React.FC<DataRendererProps> = ({ data, onDelete }) => {
	
	const groupedData: { [status: string]: CaseStateType[] } = {}
	data.forEach(item => {
		if (groupedData[item.status]) {
			groupedData[item.status].push(item)
		} else {
			groupedData[item.status] = [item]
		}
	})

	return (
		<View>
			{Object.keys(groupedData).map((status, index) => (
				<View key={index}>
					<Text style={styles.categoryStatus}>{status}</Text>
					{groupedData[status].map((item, subIndex) => (
						<View key={subIndex} style={styles.subcategory} >
							<View style={styles.row}>
								<Icon name="indent" size={18} color={theme.light.colors.linkColor} />
								<Text style={styles.text}>{item.state}</Text>
							</View>
							<View style={styles.row}>
								<TouchableOpacity style={styles.deleteBttn} onPress={() => onDelete(item.caseStateKey)}>
									<Icon name="trash-o" size={18} color={theme.light.colors.linkColor} />
								</TouchableOpacity>
							</View>
						</View>
					))}
				</View>
			))}
		</View>
	)
}
const styles = StyleSheet.create({
	subcategory: {
		borderColor: colors.mediumBlueChart,
		borderWidth: 1,
		flexDirection: 'row', 
		justifyContent: 'space-between',
		height: 40,
		alignItems: 'center',
	},
	row: {
		flexDirection: 'row',
		alignItems: 'flex-end',
		marginLeft: 5
	},
	text: {
		marginLeft: 5
	},
	categoryStatus: {
		fontSize: 20, 
		fontWeight: '500',
		paddingBottom: '10px',
		paddingTop: '10px'
	},
	deleteBttn: {
		marginRight: 10
	}
})