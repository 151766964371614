import { StyleSheet } from 'react-native'
import { theme } from '@styles/constants'

export const styles = StyleSheet.create({
	container: {
		gap: 8
	},
	checkBoxContainer: {
		flexDirection: 'row',
		width: '100%',
		gap: 10,
		alignItems: 'center'
	},
	labelText: {
		fontSize: 16,
		paddingLeft: 6
	},
	disabled: {
		color: theme.light.colors.secondary
	},
	dropDown: {
		gap: 8,
		marginLeft: 14
	}
})
