import React, {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import { clearStorage, getISOStringFromDate, getUserAgent} from '@utils/utils'
import { createLog } from '@services/apiService'
import {useDispatch} from 'react-redux'
import {resetState} from '@actions/resetActions'
import {useSelector} from 'react-redux'
import {rootReducerType} from '@reducers/combineReducers'
import {LogEventTypes} from '@utils/interfaces'

const LogoutContainer: React.FC = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { companyKey } = useSelector((state: rootReducerType) => state.companyReducer.currentCompany)
	const { key } = useSelector((state: rootReducerType) => state.legalPartnerKeyReducer)
	const { email } = useSelector((state: rootReducerType) => state.userReducer)

	const onLogout = async () => {
		const userAgent = getUserAgent()
		const securityLogInfo = {
			companyKey: companyKey || key,
			userEmail: email,
			userAgent,
			time: new Date().toISOString(),
			event: LogEventTypes.LOGOUT,
			failureReason: ''
		}
		clearStorage()
	
		dispatch(resetState())
		await createLog(securityLogInfo, true)
		navigate('/login')
	}

	useEffect(() => {
		onLogout()
	}, [])

	return null

}

export default LogoutContainer