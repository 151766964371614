import { reduxActionType } from '@utils/reduxTypes'
import { UserInfo } from '@utils/interfaces'

export const setUserInfo = (payload: UserInfo): reduxActionType => {
	return {
		type: 'SET_USER_INFO',
		payload,
	}
}

export const patchUserInfo = (payload): reduxActionType => {
	return {
		type: 'PATCH_USER_INFO',
		payload,
	}
}

export const setUserSettings = (payload): reduxActionType => {
	return {
		type: 'SET_USER_SETTINGS',
		payload
	}
}

export const setUserLanguage = (payload) : reduxActionType => {
	return {
		type: 'SET_USER_LANGUAGE',
		payload
	}
}
