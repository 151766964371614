import { colors, theme } from '@styles/constants'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
	companyInfo: {
		flexDirection: 'column',
		padding: 50,
		backgroundColor: theme.light.colors.mainContainer,
		borderRadius: 30
	},
	left: {
		flex: 1,
		padding: 10,
		justifyContent: 'space-between'
	},
	right: {
		flex: 2,
		padding: 10,
		justifyContent: 'space-between',
		gap: 50
	},
	title: {
		fontSize: 24,
		fontWeight: 'bold',
		color: theme.light.colors.titleColor,
		marginBottom: 10,
	},
	description: {
		marginBottom: 20,
		color: theme.light.colors.titleColor,
	},
	separator: {
		width: '100%',
		borderColor: colors.dimmedMain,
		borderWidth: 1
	},
	row: {
		flexDirection: 'row',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		marginBottom: 20,
	},
	label: {
		fontWeight: 'bold',
		marginBottom: 5,
		color: theme.light.colors.titleColor,
	},
	readonlyInput: {
		height: 40,
		borderColor: colors.gray,
		borderWidth: 1,
		borderRadius: 5,
		marginBottom: 10,
		padding: 10,
		backgroundColor: colors.pureWhite,
	},
})