import { Audio } from 'expo-av'
import { ReactNode } from 'react'
import { RouteObject } from 'react-router-dom'
import { phonePrefixes } from '@utils/constants'

export type RouteType = RouteObject & {
    path: string
    name?: string
    iconName?: string
    protectedRoute: boolean,
	requiresPermission?: boolean,
}

export interface ProtectedRouteProps {
    children: ReactNode,
}

export interface UserRole {
	roleKey: string
	name: string
}

interface UserPermission {
    permissionKey: string
    action: string
    subject: string
    description: string
    apiKey: string
}

export interface UserInfo {
    accountKey: string
    email: string
    firstName: string
    lastName: string
    client: string
    roles: UserRole[]
    accountPermissions: UserPermission[]
    active: boolean
}
export interface LoginFormValues {
	email: string
	password: string
  }
  
export interface CompanyRegisterFormValues {
	firstName: string
    lastName: string
    email: string
    companyName: string
    country: string
    phonePrefix: string
    phoneNumber: string
    language: string
    numberOfEmployees: string
    street: string
    city: string
    zipCode: string
    vatNr: string
    isStateOwned: boolean
}

export interface CreateCompanyValues {
	firstName: string
	lastName: string
	phonePrefix: string
	phoneNumber: string
	email: string
	name: string
	nrOfEmployees: string
	advisors: string[]
	status?: string
	notes?: string
	language?: string
    street: string
    city: string
    zipCode: string
    vatNr: string
    isStateOwned: boolean
}

export interface CreateCaseValues {
	companyKey: string
	subject: string
	reportType: string
	description: string
	category: string
	name: string
	severity: string
	status: string
	deadline: string
	phonePrefix: string
	phoneNumber: string
	email: string 
	advisorKey: string[]
}


export type FormType = 'login' | 'companyRegister' | 'company'

export type InputType = 'textInput' | 'selectDropdown' | 'phoneInput' | 'checkbox'

export interface FormField {
	name: string
	label: string
	inputType?: InputType
	secureTextEntry?: boolean
}

export type FormValues = LoginFormValues | CompanyRegisterFormValues | CreateCompanyValues | EditCompanyValues | CreateCaseValues | CompanyUserValues| formValues | MessageText | AdvisorValues

export const initialLoginFormValues: LoginFormValues = {
	email: '',
	password: '',
}
  
export const loginFields: FormField[] = [
	{
		name: 'email',
		label: 'email',
		inputType: 'textInput',
		secureTextEntry: false,
	},
	{
		name: 'password',
		label: 'password',
		inputType: 'textInput',
		secureTextEntry: true,
	},
]

export const initialCompanyRegisterFormValues: CompanyRegisterFormValues = {
	firstName: '',
	lastName: '',
	email: '',
	companyName: '',
	country: '',
	phonePrefix: phonePrefixes[0],
	phoneNumber: '',
	language: '',
	numberOfEmployees: '',
	street: '',
	city: '',
	zipCode: '',
	vatNr: '',
	isStateOwned: false,
}

export const initialCompanyFormValues: CreateCompanyValues = {
	firstName: '',
	lastName: '',
	email: '',
	name: '',
	phonePrefix: phonePrefixes[0],
	phoneNumber: '',
	language: '',
	nrOfEmployees: '',
	advisors: [],
	street: '',
	city: '',
	zipCode: '',
	vatNr: '',
	isStateOwned: false,
}

export interface EditCompanyValues {
	name: string
	country: string
	nrOfEmployees: string
	advisorsKeys: string[]
	notes: string
    phonePrefix: string
	phoneNumber: string
    street: string
    city: string
    zipCode: string
    vatNr: string
}

export interface CompanyUserValues {
	firstName: string
	lastName: string
	email: string
	phone: string
	prefix: string
	title: string
}

export interface AdvisorValues {
	firstName: string
	lastName: string
	email: string
	phone: string
	prefix: string
	title: string
	companyKeys?: string[]
}
  
export const companyRegisterFields: FormField[] = [
	{
		name: 'firstName',
		label: 'firstNameAdministrator',
		inputType: 'textInput',
		secureTextEntry: false,
	},
	{
		name: 'lastName',
		label: 'lastNameAdministrator',
		inputType: 'textInput',
		secureTextEntry: false,
	},
	{
		name: 'email',
		label: 'email',
		inputType: 'textInput',
		secureTextEntry: false,
	},
	{
		name: 'companyName',
		label: 'companyName',
		inputType: 'textInput',
		secureTextEntry: false,
	},
	{
		name: 'country',
		label: 'country',
		inputType: 'selectDropdown',
		secureTextEntry: false,
	},
	{
		name: 'phoneNumber',
		label: 'phoneNumber',
		inputType: 'phoneInput',
		secureTextEntry: false,
	},
	{
		name: 'language',
		label: 'language',
		inputType: 'selectDropdown',
		secureTextEntry: false,
	},
	{
		name: 'numberOfEmployees',
		label: 'numberOfEmployees',
		inputType: 'selectDropdown',
		secureTextEntry: false,
	},
	{
		name: 'street',
		label: 'street',
		inputType: 'textInput',
		secureTextEntry: false,
	},
	{
		name: 'city',
		label: 'city',
		inputType: 'textInput',
		secureTextEntry: false,
	},
	{
		name: 'zipCode',
		label: 'zipCode',
		inputType: 'textInput',
		secureTextEntry: false,
	},
	{
		name: 'vatNr',
		label: 'vatNr',
		inputType: 'textInput',
		secureTextEntry: false,
	},
	{
		name: 'isStateOwned',
		label: 'isStateOwned',
		inputType: 'checkbox',
		secureTextEntry: false,
	},
]

export type CompanyStatus = 'EXPIRED' | 'ACTIVE' | 'PENDING_REGISTRATION' | 'FREE_TRIAL' | 'GRACE_PERIOD' | 'PAYMENT_DETAILS_REQUIRED' | 'CANCELLED'

export interface Company {
	id: string
	companyKey: string
	name: string
	nrOfEmployees: string
	cases: string
	activatedAt: Date
	status: CompanyStatus
	users: CompanyUser[]
	notes: string
	country: string
	advisors: Advisor[]
	departments: DepartmentType[]
	publicKey: string
	encodedPrivateKey: string
    streetAddress: string
    isStateOwned: boolean
}

export interface CompanyUser {
	companyUserKey: string
	email: string
	firstName: string
	lastName: string
	verifiedEmail?: boolean
	disabledLogin?: boolean
	title?: string
	phoneNumber?: string
	prefix?: string
	createdAt?: string
}

export interface Advisor {
	advisorKey: string
	firstName: string
	lastName: string
	email: string
	createdAt: string
	verifiedEmail: boolean
  }

export interface Case {
		tag: string,
		caseKey: string,
		companyName: string,
		subject: string,
		description: string,
		severity: string,
		caseState: CaseState,
		deadline: string,
		createdAt: string,
		lastActivity: string,
		whistleblower: {
				whistleblowerKey: string,
				firstName: string,
				lastName: string,
				email: string,
				phoneNumber: string,
				publicKey: string,
				encodedPrivateKey: string,
		},
		advisors: string,
		companyUsers: string[],
		documents: uploadedDocument[]
}

export interface CompanyCase{
	id: string
	subject: string
	category: string
	severity: string
	deadline: string
	company?: string
	lastActivity?: number | string
	caseState?: CaseState
	createdAt: string
	companyName?: string
	source?: string
	whistleblower?: Whistleblower
	status?: string
}

export interface CaseState {
caseStateKey: string,
status: string,
state: string,
translatedStatus: string
}

export interface Whistleblower {
	email: string
	encodedPrivateKey: string
	firstName: string
	lastName: string
	phoneNumber: string
	publicKey: string
	whistleblowerKey: string
}

export interface CaseTable {
	id: string
	status: string
	name: string
	severity: string
	deadline: string
	lastActivity: string
	createdAt: string
}

export interface caseDetails {
		tag: string,
		advisors: Advisor[],
		companyUsers: CompanyUser[],
		companyName: string,
		subject: string
		description: string
		whistleblower: {
		firstName: string
		lastName: string
		phoneNumber: string
		email: string
		}
		severity: string
		source: string
		caseState: CaseState
		deadline: string
		lastActivity?: number
		category: string
		documents: Documents[]
}

export interface Documents {
    documentKey: string
    documentPath: string
	file: {
        documentPath: string
        documentKey: string
        fileName: string
    }
    fileName: string
    id: string
    urlExpiryDate: string
}

export interface EditCaseDetails {
	severity: '',
	caseStateKey: '',
	source: '',
	subject: string,
	description: string
}

export interface details {
	key: string
	title: string
}

export enum SeverityType {
  MINOR = 'MINOR',
  MAJOR = 'MAJOR',
	URGENT = 'URGENT',
	SEVERE = 'SEVERE'
}

export interface TableColumn {
	key: string
	title: string
	hasSorting?: boolean
	width?: number
}
export interface radioItem {
	id: number,
	label: string,
	value: ReportTypes,
	description: string,
	descriptionLong: string
}

export interface formValues {
	subject: string,
	description: string,
	category: string,
	source?: string,
    firstName: string,
    lastName: string,
    phonePrefix: string,
    phoneNo: string,
    email: string,
}

export interface dataToSendCases {
	severity: string,
	status: string,
	deadline: string,
	advisors: string[],
	companyKey: string
}

export interface File extends Blob {
	name: string
    webkitRelativePath: string
    lastModified: number
    lastModifiedDate: Date
}
export interface RecordingState {
	recording: boolean;
}

export interface audioFile { 
	blob: Blob; 
	sound: Audio.Sound | null; 
	isPlaying: boolean 
}

export interface newAdvisor {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	prefix: string;
	companies: string[];
	permissionCategory: string;
	permissions: string[];
	title: string;
}

export interface newCompanyUser {
	firstName: string
	lastName: string
	email: string
	phoneNumber: string
	companyKey: string
}

export interface permissions {
		cases: boolean;
		viewCases: boolean;
		generalChanges: boolean;
		writeMessages: boolean;
		writeInternalNotes: boolean;
		changeUsersAcces: boolean;
		delete: boolean;
		statisticsCompany: boolean;
		usersAndDepartments: boolean;
		settingsCompany: boolean;
		securitySettingsCompany: boolean;
		companies: boolean;
		statistics: boolean;
		settings: boolean;
		securitySettings: boolean;
}

export interface checkBoxItem {
	text: string;
	name: string;
	tooltip: string;
}

export interface DropdownValues {
    fieldName: string
    values: string[] | number[]
}

export interface CasesBoxCase {
	type: string
	number: number | string
	icon: boolean
}

export interface Notification {
	id: number
	name: string
	enabled: boolean
}

export interface selectedInterval {
	startDate: Date 
	endDate: Date 
}

export interface SingleDate {
	date: Date
}

export type DatePickerMode = 'single' | 'range'

export interface Phone {
    prefix: string
    number: string
}

export enum SnackbarTypes {
    INFO = 'info',
    SUCCESS = 'success',
    ERROR = 'error'
}

export type SnackbarType = SnackbarTypes.SUCCESS | SnackbarTypes.ERROR | SnackbarTypes.INFO

export interface Sorting{
	by: string
	order: string
}

export enum UserRoles {
    PARTNER = 'partner',
    ADVISOR = 'advisor',
    COMPANY_ADMIN = 'company_admin',
    COMPANY_USER = 'company_user',
}

export enum ViewTypes {
    PARTNER = 'partner',
    COMPANY = 'company',
    REPORT = 'report',
}

export type subscriptionTypes = 'MONTHLY' | 'YEARLY' | 'BIANNUAL'

export interface Subscriptions {
	subscriptionKey: string
    subscriptionType: string
	price: number
    lastPaymentDate: string
    nextPaymentDate: string
    cancelled: boolean
	paymentType: string
}

export interface CardDetails {
	cardHolderName: string
	cardNumberLast4: string
	expMonth: number
	expYear: number
}
export interface CategoryType {
    categoryKey: string
	value: string
	isActive: boolean
    default: boolean
}

export interface CategoryFormValues {
	value: string
  isActive: boolean
}

export interface UserChangePassword {
	oldPassword: string,
	password: string,
	confirmPassword: string,
	encodedMasterPassword: string,
}

export interface DepartmentType {
    departmentKey: string
    name: string
    categories: CategoryType[]
    users: CompanyUser[]
}

export interface DepartmentFormValues {
    name: string
    categoriesKeys: string[]
    usersKeys: string[]
}

export interface SnackbarInitialState {
    isVisible: boolean
	message: string
	type?: SnackbarType
}

export interface CaseTypeList {
	type: string
	number: number
	icon: boolean
}

export enum StatusCaseType {
    NEW = 'NEW',
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
    REJECTED = 'REJECTED'
}

export interface CaseStatistic {
	created: string
	case: number
}

export interface Permission {
    permissionKey: string
    action: string
    subject: string
    description: string
	default: boolean
}

export interface PermissionList {
	text: string
	name: string
	tooltip: string
	permissionKey: string
	childs: PermissionList[] 
	default: boolean
}
export interface ReportFormValues {
    subject: string
	description: string
	category: string
	firstName: string
    lastName: string
	prefix: string
	number: string
	email: string 
}

export const initialReportFormValues: ReportFormValues = {
	subject: '',
	description: '',
	category: '',
	firstName: '',
	lastName: '',
	prefix: phonePrefixes[0],
	number: '',
	email: '', 
}


export enum ReportTypes {
    CONFIDENTIALLY = 'confidentially',
    ANONYMOUSLY = 'anonymously'
}

export enum CaseSources {
    SYSTEM = 'SYSTEM',
    PHONE = 'PHONE',
    EMAIL = 'EMAIL',
    PHYSICAL_MAIL = 'PHYSICAL_MAIL',
    PHYSICAL_MEETING = 'PHYSICAL_MEETING',
    OTHER = 'OTHER'
}

export interface CaseDetails {
	subject: string,
	description: string, 
	status: string,
    firstName?: string,
    lastName?: string,
    email?: string,
    phoneNumber?: string,
    companyName: string,
    category: string,
    tag: string,
    handlers?: Array<{
        id: string,
        email: string
    }>
    deadline: string
}

export interface UserNotification {
	notificationType: string,
	communicationType: string
}
export interface companyList {
	companyKey: string
	name: string
}

export interface StatisticsItem {
	name: string
	frequency: number
	color?: string
	legendFontSize?: number
}
export interface StatisticsData {
	creationDate: CaseStatistic[],
	category: StatisticsItem[],
	source: StatisticsItem[],
	severity: StatisticsItem[],
	state: StatisticsItem[],
	deadline: StatisticsItem[],
	type: StatisticsItem[],
	lastActivity: StatisticsItem[]
}
export interface PageTemplate {
    pageTemplateKey?: string
    content: string
    language: string
}

export interface Language {
    label: string
    value: string
}

export interface MasterPassowdFormValues {
    password: string
}
export interface VerificationCodeMPFormValues {
	verificationCode: string
}

export interface PasswordFormValues {
    password: string
}

export interface CaseLogs {
	action: string
	authorEmail: string
	date: string
	newValue: string
	oldValue: string
	target: string
}

export enum LogEventTypes {
    LOGOUT = 'LOGOUT_SUCCEEDED',
    LOGIN_SUCCESS = 'LOGIN_SUCCEEDED',
    LOGIN_ERROR = 'LOGIN_FAILED'
}

export type SecurityLogEvent = LogEventTypes.LOGOUT | LogEventTypes.LOGIN_SUCCESS | LogEventTypes.LOGIN_ERROR

export interface SecurityLog {
	companyKey: string,
    userEmail: string,
    userAgent: string,
    ip: string,
    time: string,
    event: SecurityLogEvent,
    failureReason: string,
}

export interface SecurityLogs {
	currentPage: number,
	pageResults: SecurityLog[],
	totalDisplayResults: number,
	totalResults: number
}

export interface LoginClientFormValues {
    clientId: string
    clientSecret: string
}

export interface CreateLegalPartnerFormValues {
	firstName: string
	lastName: string
	country: string
	name: string
    email: string
    prefix: string
    number: string
    street: string
    city: string
    zipCode: string
    vatNr: string
	language: string
	nrOfEmployees?: string
    isStateOwned: boolean
}

export const initialCreateLegalPartnerFormValues: CreateLegalPartnerFormValues = {
	firstName: '',
	lastName: '',
	country: '',
	name: '',
	email: '',
	prefix: phonePrefixes[0],
	number: '',
	street: '',
	city: '',
	zipCode: '',
	vatNr: '',
	language: '',
	nrOfEmployees: '',
	isStateOwned: false,
}

export interface Notes {
	authorEmail: '',
	authorName: '',
	caseKey: '',
	createdAt: '',
	noteKey: '',
	updatedAt: '',
	text: ''
}

export interface CaseStateType {
	caseStateKey: '',
state: '',
status: ''
}

export interface uploadedDocument {
	documentKey: string,
	fileName: string,
	documentPath: string,
	urlExpiryDate: Date
}

export interface MessageText {
	message: string
}

export const initialMessage: MessageText = {
	message: ''
}

export interface Message {
	key: string
	text: string
	author: string
	createdAt: string
	edited: boolean
	documents: uploadedDocument[]
}

export interface documentsDisplay {
	documentKey: string
	fileName: string
	documentPath: string
	urlExpiryDate: Date
	fileURL: string
}

export interface Category {
    departmentKey: string
	categoryKey: string
    label: string
    value: string
    isActive: boolean
    default: boolean
}

export interface User {
    departmentKey: string
    users: CompanyUser[]
}

export interface Department {
	categories: Category[], 
	users: CompanyUser[]
}

export interface WhistleBlowerCompany {
    companyKey: string
    advisors: Advisor[]
    categories: Category[],
	departments: Department[],
    users: CompanyUser[],
	publicKey: string,
	encodedPrivateKey: string,
	companyAdminName: string,
	companyAdminKey: string
}

export interface ReducedCategory {
	categoryKey: string
    label: string
    value: string
    isActive: boolean
	users?: CompanyUser[]
}
export interface subscriptionPlans {
	subscriptionType: string
	price: number
}

export interface FilterItems {
		creationDateStart: string,
		creationDateEnd: string,
		companyKeys?: string[], 
		states?: string[],
		userEmails?: string[],
		categoryKeys?: string[],
		tag?: string,
		sort?: {
			by: string,
			order: string
		},
}

export interface Address {
	number: string,
	building: string, 
	staircase: string, 
	floor: string,
	apartment: string
}

export interface Notification {
	notificationHistoryKey: string,
	content: string,
	notificationType: string,
	isRead: boolean,
	invoiceKey: string,
	timestamp: string
}