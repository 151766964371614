import { colors } from '@styles/constants'
import React from 'react'
import { View, Text, TextInput, TouchableOpacity } from 'react-native'
import { useTranslation } from 'react-i18next'
import FontAwesome from 'react-native-vector-icons/FontAwesome'
import { Formik } from 'formik'
import { masterPasswordSchema } from '@utils/formValidation'
import { styles } from './styles'

interface ChangePasswordProps {
  onChangeMasterPasswordSubmit: (values: { accountPassword: string }) => void;
  onClose: () => void;
}

const ChangeMasterPassword: React.FC<ChangePasswordProps> = ({
	onChangeMasterPasswordSubmit,
	onClose,
}) => {
	const { t } = useTranslation()

	return (
		<View style={styles.changePassContainer}>
			<Formik
				initialValues={{
					accountPassword: '',
				}}
				validationSchema={masterPasswordSchema}
				onSubmit={values => onChangeMasterPasswordSubmit(values)}
			>
				{({ handleChange, handleBlur, handleSubmit, values, touched, errors }) => (
					<View style={styles.container}>
						<TouchableOpacity style={styles.closeButton} onPress={onClose}>
							<FontAwesome name="times" color={colors.darkShades} size={20} />
						</TouchableOpacity>
						<Text style={styles.title}>{t('changeMasterPassword')}</Text>
						<View style={styles.sendForm}>
							<View style={styles.form}>
								<View>
									<Text style={styles.label}>{t('accountPassword')}</Text>
									<TextInput
										style={styles.input}
										placeholder={t('accountPassword')}
										secureTextEntry={values.accountPassword.length > 0}
										onChangeText={handleChange('accountPassword')}
										onBlur={handleBlur('accountPassword')}
										value={values.accountPassword}
									/>
									{touched.accountPassword && errors.accountPassword && (
										<Text style={styles.validationMessage}>{`*${errors.accountPassword}`}</Text>
									)}
								</View>
								
							
								<TouchableOpacity
									style={styles.button}
									onPress={() => handleSubmit()}
								>
									<FontAwesome name="lock" color={colors.pureWhite} size={14} />
									<Text style={styles.btnText}>{t('changeMasterPassword')}</Text>
								</TouchableOpacity>
								
							</View>
						</View>
					</View>
				)}
			</Formik>
		</View>
	)
}

export default ChangeMasterPassword