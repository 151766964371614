import CryptoJS from 'crypto-js'
import forge from 'node-forge'

export const getHash = (secret, data) => CryptoJS.SHA256(secret + data).toString(CryptoJS.enc.Hex)

export const generateRSAKeyPair = () => {
	const keypair = forge.pki.rsa.generateKeyPair({ bits: 2048 })
	const publicKeyPem = forge.pki.publicKeyToPem(keypair.publicKey)
	const privateKeyPem = forge.pki.privateKeyToPem(keypair.privateKey)

	return { publicKey: publicKeyPem, privateKey: privateKeyPem }
}

export const generateMasterPassword = (secretKey) => {
	const salt = forge.random.getBytesSync(16)
	const password = forge.pkcs5.pbkdf2(secretKey, salt, 100000, 32, 'sha256')

	return forge.util.bytesToHex(password)
}

export const generateKeyFromAccountPassword = (password) => {
	const md = forge.md.md5.create()
	md.update(password)

	return md.digest().toHex()
}

export const encryptValueWithAES = (secretKey, iv, value) => {
	const cipher = forge.cipher.createCipher('AES-CBC', forge.util.hexToBytes(secretKey))
	cipher.start({ iv })

	if (typeof value === 'string') {
		// if value is string encrypt it
		cipher.update(forge.util.createBuffer(value))
		cipher.finish() 
		const encryptedBytes = cipher.output.getBytes()
		const encryptedHex = forge.util.bytesToHex(encryptedBytes)
    
		return encryptedHex
	}

	if (Array.isArray(value)) {
		// if the value is an array encrypt each element recursively
		return value.map((element) =>
			encryptValueWithAES(secretKey, iv, element)
		)
	}

	if (typeof value === 'object' && !(value instanceof Blob)) {
		// if the value is an object recursively encrypt its properties
		const encryptedObject = {}
		for (const key in value) {
			encryptedObject[key] = encryptValueWithAES(secretKey, iv, value[key])
		}

		return encryptedObject
	}

	return value
}

export const encryptFilesWithAES = async (secretKey, iv, value) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(value)
		reader.onload = function () {
			const encryptedHex = encryptValueWithAES(secretKey, iv, reader.result)
			const fileToReturn = new global.File([encryptedHex], value.name, {type: value.type})
			resolve(fileToReturn)
		}
		reader.onerror = function (error) {
			reject(error)
		}
	})
}

export const decryptValueWithAES = (secretKey, iv, encryptedValue) => {
	const decipher = forge.cipher.createDecipher('AES-CBC', forge.util.hexToBytes(secretKey))

	decipher.start({ iv })

	if (typeof encryptedValue === 'string') {
		// if encrypted value is string decrypt it
		const encryptedBytes = forge.util.hexToBytes(encryptedValue)

		decipher.update(forge.util.createBuffer(encryptedBytes))
		decipher.finish() 
		const decryptedBytes = decipher.output.getBytes()
		const decryptedString = forge.util.encodeUtf8(decryptedBytes)
    
		return decryptedString
	}

	if (Array.isArray(encryptedValue)) {
		// if the encrypted value is an array encrypt each element recursively
		return encryptedValue.map((element) =>
			decryptValueWithAES(secretKey, iv, element)
		)
	}

	if (typeof encryptedValue === 'object' && !(encryptedValue instanceof Blob) && encryptedValue !== null) {
		// if the encrypted value is an object recursively encrypt its properties
		const encryptedObject = {}
		for (const key in encryptedValue) {
			encryptedObject[key] = decryptValueWithAES(secretKey, iv, encryptedValue[key])
		}

		return encryptedObject
	}

	return encryptedValue
}

export const decryptFilesWithAES = async (secretKey, iv, encryptedFile, fileName) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(encryptedFile)
		reader.onload = () => {
			const encryptedFileStringBase64 = reader.result.split(',')[1]
			const encryptedFileString = decodeURIComponent(global.atob( encryptedFileStringBase64 ))
			const decryptedFile = decryptValueWithAES(secretKey, iv, encryptedFileString)
			const contentType = decryptedFile.split(':')[1].split(';')[0]
			resolve({
				data: decryptedFile,
				contentType,
				fileName
			})
		}
		reader.onerror = function (error) {
			reject(error)
		}
	})
}
