import { Advisor } from '@utils/interfaces'
import { reduxActionType } from '@utils/reduxTypes'

export const setAdvisorKey = (payload: string): reduxActionType => {
	return {
		type: 'SET_ADVISOR_KEY',
		payload,
	}
}

export const setEditAdvisorKey = (payload: string): reduxActionType => {
	return {
		type: 'SET_EDIT_ADVISOR_KEY',
		payload,
	}
}

export const setAdvisors = (payload: Advisor[]): reduxActionType => {
	return {
		type: 'SET_ADVISORS',
		payload,
	}
}

export const setEditAdvisorEmail = (payload: string): reduxActionType => {
	return {
		type: 'SET_EDIT_ADVISOR_EMAIL',
		payload,
	}
}

