import { reduxActionType } from '@utils/reduxTypes'

const initialState = {
	activeIndex: '1',
	expanded: true
}

export const currentPageReducer = (
	state = initialState,
	action: reduxActionType
): (typeof initialState) => {
	const newState = JSON.parse(JSON.stringify(state))
	switch (action.type) {
	case 'SET_CURRENT_PAGE': {
		newState.activeIndex = action.payload
		return newState
	}
	case 'SET_EXPANDED': {
		newState.expanded = action.payload
		return newState
	}
	default: return state
	}
}