import { colors, theme } from '@styles/constants'
import { button, buttonText } from '@styles/mixins/button'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
	container: {
		flex: 1,
		padding: 20,
		gap: 8,
	},
	header: {
		alignItems: 'flex-start',
		padding: 0
	},
	form: {
		backgroundColor: theme.light.colors.mainContainer,
		padding: 20,
		borderRadius: 30,
		gap: 10,
		shadowColor: theme.light.colors.mainColor,
		shadowOffset: { width: 0, height: 1 },
		shadowOpacity: 0.2,
		shadowRadius: 2,
		elevation: 3,
	},
	header_form:{
		justifyContent: 'space-between',
		flexDirection: 'row'
	},
	header_form_mobile:{
		flexDirection: 'column'
	},
	backButton: {
		paddingVertical: 10,
		paddingHorizontal: 5,
		textAlign: 'left',
		flexDirection: 'row',
		alignItems: 'center',
		gap: 6,
	},
	buttonText: {
		...({
			...buttonText().buttonText,
		} as object),
		fontWeight: '600',
		fontSize: 16
	},
	backButtonText: {
		color: theme.light.colors.titleColor,
		fontWeight: 'bold'
	},
	title: {
		fontSize: 20,
		color: theme.light.colors.titleColor,
		fontWeight: 'bold'
	},
	icon: {
		color: theme.light.colors.titleColor,
	},
	buttonIcon: {
		...({
			...buttonText().buttonText,
		} as object),
	},
	buttonIcon_mobile: {
		...({
			...buttonText().buttonText,
		} as object),
		justifyContent:'center',
		gap: 0
	},
	iconDark: {
		color: colors.darkAccent
	},
	formFieldWrapper: {
		width: '100%'
	},
	labelText: {
		fontSize: 16,
		marginBottom: 6,
		paddingLeft: 6,
		paddingTop: 10
	},
	labelText_mobile: {
		fontSize: 13,
		marginBottom: 6,
		paddingLeft: 6,
		paddingTop: 10
	},
	radioButtonContainer: {
		flexDirection: 'column'
	},
	dropdownsRow: {
		flexDirection: 'row', 
		width: '100%', 
		paddingHorizontal: 8
	},
	dropdownBtn: {
		flex: 1,
		width: '95%',
		backgroundColor: colors.white,
		borderRadius: 4,
		borderWidth: 1,
		borderColor: colors.darkShades,
		padding: 8
	},
	dropdownBtnText: {
		color: colors.darkShades, 
		textAlign: 'left'
	},
	dropdownStyle: {
		backgroundColor: colors.white
	},
	SelectRow: {
		backgroundColor: colors.white, 
		borderBottomColor: colors.darkShades
	},
	dropdownRowTxt: {
		color: colors.darkShades, 
		textAlign: 'left'
	},
	iconTextContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		gap: 6,
		marginLeft: 12
	},
	button: {
		...({
			...button(theme.light.colors.primaryDark).button,
		} as object),
		borderRadius: 6,
		paddingVertical: 8,
		paddingHorizontal: 16,
		width: 180,
		flexDirection: 'row',
		gap: 8,
		justifyContent: 'center',
		alignItems: 'center',
	},
	reportOrallyButton: {
		backgroundColor: theme.light.colors.dangerDark,
		width: 360,
	},
	reportOrallyButton_mobile: {
		backgroundColor: theme.light.colors.dangerDark,
		marginTop: 10,
		width: 'auto',
		alignSelf:'flex-end',
		gap: 2,
		padding: 2
	},
	reportOrallyBttn: {
		backgroundColor: theme.light.colors.dangerDark,
		width: 'auto'
	},
	uploadFileButton: {
		backgroundColor: theme.light.colors.successDark,
	},
	uploadFileButton_mobile: {
		backgroundColor: theme.light.colors.successDark,
		width: 40,
		marginBottom: 20,
		alignSelf: 'flex-start'
	},
	fileContainer: {
		width: '60%'
	},
	fileContainer_mobile: {
		width: '100%'
	},
	voiceContainer: {
		justifyContent: 'flex-start',
		alignItems: 'flex-start'
	},
	voiceContainer_mobile:{ 
		width: 'auto'
	},
	footer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	footer_mobile: {
		flexDirection: 'column',
		alignItems: 'center'
	},
	errorText: {
		color: colors.error,
		fontSize: 12,
	},
	usersContainer: {
		gap: 5,
	},
	gap: {
		gap: 0
	},
})