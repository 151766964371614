import React from 'react'
import Layout from '@containers/Layout'
import { NotificationContainer } from '@containers/Notifications/Notifications'

export const NotificationsPage = () => {
	return (
		<Layout>
			<NotificationContainer />
		</Layout>
	)
}
