import { colors, theme } from '@styles/constants'
import { button, buttonText } from '@styles/mixins/button'
import React, { FC } from 'react'
import { Modal, StyleSheet, TouchableOpacity, View } from 'react-native'
import { Text } from 'react-native-paper'

interface PasswordChangedModalProps {
    title?: string
    text: string
    buttonText: string
    isVisible: boolean
    onClose: () => void
}

export const PasswordChangedModal: FC<PasswordChangedModalProps> = ({
	title,
	text,
	buttonText,
	isVisible,
	onClose,
}) => {
	return (
		<Modal
			animationType='slide'
			transparent={true}
			visible={isVisible}
		>
			<View style={styles.modalContainer}>
				<View style={styles.contentContainer}>
					{ title && <Text style={styles.title}>{title}</Text> }
					<Text style={styles.text}>{text}</Text>
					<TouchableOpacity style={styles.button} onPress={onClose}>
						<Text style={styles.buttonText}>{buttonText}</Text>
					</TouchableOpacity>
				</View>
			</View>
		</Modal>
	)
}

export const styles = StyleSheet.create({
	modalContainer: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: colors.shadeBackground,
	},
	contentContainer: {
		width: '40%',
		maxHeight: '40%',
		minWidth: 440,
		padding: 30,
		borderRadius: 16,
		backgroundColor: theme.light.colors.mainContainer,
		overflowY: 'auto',
		gap: 30,
	},
	title: {
		fontSize: 24,
		fontWeight: 'bold',
		textAlign: 'center',
	},
	text: {
		fontSize: 16,
		textAlign: 'justify',
	},
	button: {
		...({
			...button(theme.light.colors.primaryDark).button,
		} as object),
		borderRadius: 6,
		justifyContent: 'center',
		flexGrow: 1,
		shadowColor: theme.light.colors.mainColor,
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.2,
		shadowRadius: 2,
		elevation: 2,
		maxWidth: 260,
		alignSelf: 'center',
	},
	buttonText: {
		...({
			...buttonText().buttonText,
		} as object),
		fontSize: 18,
		fontWeight: 'bold',
	},
})
