import { colors, theme } from '@styles/constants'
import { button, buttonText } from '@styles/mixins/button'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		paddingHorizontal: 20,
	},
	forgotPass: {
		justifyContent: 'center',
		alignItems: 'center',
		padding: 30,
		backgroundColor: theme.light.colors.mainContainer,
		width: '100%',
		maxWidth: 500,
		borderRadius: 30,
		gap: 20,
		shadowColor: theme.light.colors.mainColor,
		shadowOffset: { width: 0, height: 1 },
		shadowOpacity: 0.2,
		shadowRadius: 2,
		elevation: 3,
	},
	title: {
		fontSize: 24,
		fontWeight: 'bold',
		marginBottom: 10,
	},
	subtitle: {
		fontSize: 16,
		marginBottom: 20,
	},
	label: {
		fontSize: 16,
		marginBottom: 8,
	},
	input: {
		fontSize: 16,
		borderRadius: 4,
		borderWidth: 1,
		padding: 6,
		backgroundColor: theme.light.colors.linkColorActive,
		width: '100%',
		outlineStyle: 'none',
		maxHeight: 50,
		color: theme.light.colors.mainColor,
	},
	confirmationText: {
		fontSize: 24,
		textAlign: 'center',
	},
	button: {
		...({
			...button(theme.light.colors.primaryDark).button,
		} as object),
		borderRadius: 6,
		paddingVertical: 12,
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		shadowColor: theme.light.colors.mainColor,
		shadowOffset: { width: 0, height: 1 },
		shadowOpacity: 0.2,
		shadowRadius: 2,
		elevation: 3,
	},
	btnText: {
		...({
			...buttonText().buttonText,
		} as object),
		fontSize: 16,
		color: colors.pureWhite,
	},
	sendForm: {
		width: '100%',
		justifyContent: 'center',
	},
	form: {
		padding: 10,
		display: 'flex',
		justifyContent: 'space-between',
		height: '100%',
		gap: 30,
	},
	closeButton: {
		position: 'absolute',
		top: 15,
		right: 15,
		padding: 5,
		zIndex: 1,
	}
})