import {Notification} from '@utils/interfaces'
import { reduxActionType } from '@utils/reduxTypes'

export const setNotifications = (payload: Notification[]): reduxActionType => {
	return {
		type: 'SET_NOTIFICATIONS',
		payload,
	}
}

export const readNotificationHistory = (payload: string): reduxActionType => {
	return {
		type: 'READ_NOTIFICATION',
		payload,
	}
}

export const addNotification = (payload: Notification): reduxActionType => {
	return {
		type: 'ADD_NOTIFICATION',
		payload,
	}
}