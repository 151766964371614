
export const iv = '1d98f2eb76ff5e03c1c503dfe56cf997'

export const casesTypes = {
	myCases: 'my cases',
	newCases: 'new cases',
	openCases: 'open cases',
	closedCases: 'closed cases',
	rejectedCases: 'rejected cases'
}

export const screenType = {
	underSmallScreen: 1242,
	smallLaptop: 1025,
	underSmallLaptop: 1024,
	tablet: 769,
	phone: 768
}

export const DOTS = '...'
export const COMPANY_ADMIN = 'COMPANY_ADMIN'
export const ADVISOR = 'ADVISOR'

export const phonePrefixes = ['+40']
export const resultsPerPage = ['10','20','30']

export const subscriptionType = ['MONTHLY', 'YEARLY', 'BIANNUAL']

export const settingsTabs = ['myAccount']

// newly created category is active by default
export const initialCategoryFormValues = {
	value: '',
	isActive: true,
}

export const initialDepartmentFormValues = {
	name: '',
	categoriesKeys: [],
	usersKeys: []
}

export const snackbarInitialState = {
	isVisible: false,
	message: ''
}

export const passwordChangeModalInitialState = {
	isVisible: false,
	title: '',
	text: '',
	buttonText: '',
}

export const templateInitialState = {
	content: '',
	language: 'en'
}

export const reportingPageTitle = 'reporting-page'

export const languages = [ 'en', 'ro', 'hu', 'de' ]
export const createCaseFields = ['subject', 'description', 'firstName', 'lastName', 'email', 'phoneNumber']
export const createCaseManuallyProperties = ['subject', 'description', 'whistleblowerRequest', 'whistleblower']
export const companyPermissionsArray = ['companyUser', 'case', 'department', 'companySettings', 'userSettings', 'companyStatistics', 'securityLogs']
export const partnerPermissionsArray = ['company', 'advisor', 'category', 'partnerSettings']		
export const documentPropertiesToDecrypt = ['documentPath']
export const wbFields = ['firstName', 'lastName', 'email', 'phoneNumber']
export const subscriptionExpiredStatuses = ['EXPIRED', 'PAYMENT_DETAILS_REQUIRED']

export const casesSort = ['DEADLINE', 'SEVERITY', 'LAST_ACTIVITY']
export const companiesSort = ['ACTIVATED_AT', 'NAME']

export const caseDetailsInitialState = {
	tag: '',
	companyName: '',
	status: '',
	description: '',
	subject: '',
	category: '',
	deadline: '',
}

export const legalPartnerType = ['EXTERNAL', 'INTERNAL']

import { colors } from '@styles/constants'

export const companyTableColumns = [
	{ key: 'id', title: 'ID', hasSorting: false, width: 20 },
	{ key: 'name', title: 'Name', hasSorting: true },
	{ key: 'nrOfEmployees', title: 'Number of Employees', hasSorting: false },
	{ key: 'cases', title: 'New/Open Cases', hasSorting: false },
	{ key: 'activated_At', title: 'Activation Date', hasSorting: true, width: 140 },
	{ key: 'status', title: 'Status', hasSorting: false },
	{ key: 'actions', title: '', hasSorting: false },
]

export const caseLogsColumns = [
	{ key: 'action', title: 'Action', hasSorting: false },
	{ key: 'authorEmail', title: 'Author email', hasSorting: false },
	{ key: 'date', title: 'Date', hasSorting: false },
	{ key: 'newValue', title: 'New value', width: 140, hasSorting: false },
	{ key: 'oldValue', title: 'Old value', width: 140, hasSorting: false },
	{ key: 'target', title: 'Field', hasSorting: false }
]

export const CaseDocumentsColumns = [
	{ key: 'fileName', title: 'Documents' },
]

export const dashboardCasesTableColumns = [
	{ key: 'id', title: 'ID', hasSorting: false },
	{ key: 'status', title: 'Status', hasSorting: false},
	{ key: 'severity', title: 'Severity', hasSorting: true },
	{ key: 'createdAt', title: 'Created At', hasSorting: false },
	{ key: 'deadline', title: 'Deadline', hasSorting: true },
	{ key: 'last_activity', title: 'Last Activity', hasSorting: true },
]

export const companyCasesTableColumns = [
	...dashboardCasesTableColumns,
	{ key: 'view', title: ''},
	{ key: 'accept', title: ''},
]

export const partnerCasesTableColumns = [
	...companyCasesTableColumns.slice(0,2),
	{ key: 'companyName', title: 'Company', hasSorting: false },
	...companyCasesTableColumns.slice(2),
]

export const countries = ['Romania']

export const status = [
	{
		name: 'NEW'
	},
	{
		name: 'OPEN'
	},
	{
		name: 'CLOSED'
	},
	{
		name: 'REJECTED'
	}
]

export const statisticsColors = [ colors.main, colors.blueChart, colors.mediumBlueChart, colors.secondBlueChart, colors.lightBlueChart, colors.lightGray, colors.gray, colors.darkGray ]

export const allData = { page: 1, size: 2147483647 }