import { colors, theme } from '@styles/constants'
import React, {useEffect, useState} from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import { styles } from './styles'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import Icon from 'react-native-vector-icons/FontAwesome'
import { Notification, ViewTypes } from '@utils/interfaces'
import { useSelector } from 'react-redux'
import { rootReducerType } from '@reducers/combineReducers'
import { formatTimestamp, isCompanyAdminOrUser } from '@utils/utils'
import { getInvoiceFile, readNotification } from '@services/apiService'
import { Loader } from '@components/Loader/Loader'
import { useDispatch } from 'react-redux'
import { readNotificationHistory } from '@actions/notificationActions'
import FontAwesome from 'react-native-vector-icons/FontAwesome'

export const NotificationDetails = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { roles } = useSelector((state: rootReducerType) => state.userReducer)
	const { notifications } = useSelector((state: rootReducerType) => state.notificationReducer)
	const isCompany = isCompanyAdminOrUser(roles)
	const location = useLocation()
	const userKey = useSelector((state: rootReducerType) => isCompany ? state.companyUserKeyReducer.key : state.legalPartnerKeyReducer.key)
	const [notification, setNotification] = useState<Notification | null>(null)
	const dispatch = useDispatch()
	const notificationHistoryKey = location.pathname.split('/')[3]

	const readSelectedNotification = async () => {
		const selectedNotification: Notification = notifications.filter((notification: Notification) => notification.notificationHistoryKey === notificationHistoryKey)[0]

		if (!selectedNotification.isRead) {
			try {
				const notification = await readNotification(notificationHistoryKey, userKey)
				dispatch(readNotificationHistory(notification.data.notificationHistoryKey))
			} catch (error) {
				console.error(error)
			}
		}
		setNotification(selectedNotification)
	}

	useEffect(() => {
		readSelectedNotification()
	}, [notification, notificationHistoryKey])

	const downloadInvoice = async () => {
		try {
			if (notification?.invoiceKey) {
				const invoiceData = await getInvoiceFile(notification?.invoiceKey)
				const blob = new Blob([invoiceData.data], { type: 'application/pdf' })
				const url = URL.createObjectURL(blob)
				const element = global.document.createElement('a')
				element.setAttribute('href', url)
				element.setAttribute('download', 'invoice.pdf')
				element.style.display = 'none'
				global.document.body.appendChild(element)
				element.click()
				global.document.body.removeChild(element)
			}
		} catch (error) {
			console.error(error)
		}
	}

	return (
		notification ?
			<View>
				<TouchableOpacity 
					style={styles.backButton}
					onPress={() => navigate(isCompany ? `/${ViewTypes.COMPANY}/notifications` : `/${ViewTypes.PARTNER}/notifications`)}>
					<Icon color={theme.light.colors.mainColor} name="arrow-left" size={18} />
					<Text style={styles.backButtonText}>{t('back')}</Text>
				</TouchableOpacity>
				<View style={styles.container}>
					<View style={styles.headerContainer}>
						<View style={styles.notificationIcon}>
							<FontAwesome 
								name={'bell-o'} 
								color={colors.gray} 
								size={30} />  
						</View>
						<View style={styles.detailsContent}>
							<View style={styles.titleContainer}>
								<View style={{flex: 1, justifyContent: 'space-between', flexDirection: 'row'}}>
									<Text style={styles.headerText}>{t(`notificationHistoryTypes.${notification.notificationType}`)}</Text>
									<Text style={styles.timestamp}>{formatTimestamp(new Date(notification.timestamp))}</Text>
								</View>
							</View>
							<View style={styles.mainContainer}>
								<Text>{notification.content}</Text>
							</View>
							{ notification.invoiceKey &&
								<TouchableOpacity style={styles.download} onPress={() => downloadInvoice()}>
									<Icon color={colors.white} name="download" size={14} />
									<Text style={styles.downloadText}>{t('download')}</Text>
								</TouchableOpacity>
							}
						</View>
					</View>
				</View> 
			</View> : <Loader />
	)
}