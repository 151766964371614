import Layout from '@containers/Layout'
import React from 'react'
import { Advisors } from '@containers/Advisors'

export const AdvisorsPage = () => {
	return (
		<Layout>
			<Advisors />
		</Layout>
	)
}