import { colors, theme } from '@styles/constants'
import { button, buttonText } from '@styles/mixins/button'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
	myAccount: {
		flexDirection: 'column',
		padding: 50,
		backgroundColor: theme.light.colors.mainContainer,
		borderRadius: 30
	},
	left: {
		flex: 1,
		padding: 10,
		justifyContent: 'space-between',
		gap: 10,
	},
	right: {
		flex: 2,
		padding: 10,
		justifyContent: 'space-between',
		gap: 10,
	},
	title: {
		fontSize: 24,
		fontWeight: 'bold',
		color: theme.light.colors.titleColor,
	},
	description: {
		marginBottom: 20,
		color: theme.light.colors.titleColor,
	},
	button: {
		...({
			...button(theme.light.colors.primaryDark).button,
			...buttonText().buttonText,
		} as object),
		width: 200,
		fontSize: 16,
		fontWeight: 'bold',
		paddingVertical: 10,
		paddingHorizontal: 20,
		textAlign: 'center',
		borderRadius: 5,
		overflow: 'hidden',
		justifyContent: 'center',
	},
	buttonText: {
		...({
			...buttonText().buttonText
		} as object),
	},
	separator: {
		width: '100%',
		borderColor: colors.dimmedMain,
		borderWidth: 1,
		marginVertical: 20,
	},
	row: {
		flexDirection: 'row',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
	},
	modalContainer: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		backdropFilter: 'blur(10px)',
	},
	iconMasterPass: {
		alignSelf: 'center'
	}
})