import { reduxActionType } from '@utils/reduxTypes'

export const setCaseKey = (payload: string): reduxActionType => {
	return {
		type: 'SET_CASE_KEY',
		payload,
	}
}
export const setCaseViewKey = (payload: string): reduxActionType => {
	return {
		type: 'SET_CASE_VIEW_KEY',
		payload,
	}
}
export const setIsAdvisorNewCase = (payload: boolean) : reduxActionType => {
	return {
		type: 'SET_IS_ADVISOR_NEW_CASE',
		payload,
	}
}

export const setCasePass = (payload: string) : reduxActionType => {
	return {
		type: 'SET_CASE_PASS',
		payload,
	}
}

export const setCaseAccepted = (payload: string) : reduxActionType => {
	return {
		type: 'SET_CASE_ACCEPTED',
		payload,
	}
}
