import { CompanyStatus, Subscriptions } from '@utils/interfaces'
import { reduxActionType } from '@utils/reduxTypes'

const initialState = {
	key: '',
	currentCompany: {
		id: '',
		companyKey: '',
		name: '',
		nrOfEmployees: '',
		cases: '',
		activatedAt: new Date(),
		status: '' as CompanyStatus,
		users: [],
		notes: '',
		city: '',
		country: '',
		advisors: [],
		departments: [],
		publicKey: '',
		encodedPrivateKey: '',
		subscription: {} as Subscriptions || '',
		vatNumber: '',
		zipCode: '',
		phoneNumber: '',
		reportLink: '',
		streetAddress: '',
		encodedMasterPassword: '',
		isStateOwned: false,
	},
	companies: [],
	settings: {
		language: '',
	},
}

export const companyReducer = (
	state = initialState,
	action: reduxActionType
): (typeof initialState) => {
	const newState = JSON.parse(JSON.stringify(state))
	switch (action.type) {
	case 'SET_COMPANY_KEY': {
		newState.key = action.payload
		return newState
	}
	case 'SET_CURRENT_COMPANY': {
		newState.currentCompany = action.payload
		return newState
	}
	case 'SET_COMPANIES': {
		newState.companies = action.payload
		return newState
	}
	case 'PATCH_COMPANY': {
		return {
			...state,
			currentCompany: {
				...state.currentCompany,
				...action.payload,
			},
		}
	}
	case 'SET_COMPANY_SETTINGS': {
		return {
			...state,
			settings: {
				...action.payload,
			}
		}
	}
	default: return state
	}
}
