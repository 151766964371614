import { StyleSheet } from 'react-native'
import { colors, theme } from '@styles/constants'

export const styles = StyleSheet.create({
	container: {
		gap: 20,
		paddingVertical: 20,
		paddingHorizontal: 20,
	},
	sectionContainer: {
		borderWidth: 1,
		borderColor: theme.light.colors.boxBorder,
		backgroundColor: theme.light.colors.mainContainer,
		borderRadius: 30,
		paddingVertical: 20,
		paddingHorizontal: 20,
		marginBottom: 20,
		gap: 10,
		shadowColor: theme.light.colors.mainColor,
		shadowOffset: { width: 0, height: 1 },
		shadowOpacity: 0.2,
		shadowRadius: 2,
		elevation: 3,
	},
	subtitle: {
		fontSize: 24,
		fontWeight: '500',
		marginBottom: 30,
		color: theme.light.colors.mainColor
	},
	text: {
		fontSize: 18,
		color: theme.light.colors.secondaryColor,
		marginTop: 20
	},
	tableCellText: {
		fontSize: 16
	},
	tableCellTextMobile: {
		fontSize: 14
	},
	itemText: {
		backgroundColor: colors.themedGray,
		borderRadius: 4,
		alignContent: 'center',
		alignItems: 'center',
		paddingVertical: 5,
		paddingHorizontal: 10,
		borderWidth: 0.2,
		borderColor: colors.gray
	},
	grayText: {
		color: colors.gray,
		fontWeight: '500'
	},
	elementsContainer: {
		flexDirection: 'row',
		justifyContent: 'space-around'
	},
	elementsContainerMobile: {
		flexDirection: 'column',
		gap: 20
	},
	barChart: {
		borderRadius: 4
	},
	lineChart: {
		width: '50%',
		borderRadius: 4
	},
	sectionHeader: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		zIndex: 999,
	}
})
