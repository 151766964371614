import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import { Modal, StyleProp, Text, TouchableOpacity, View, ViewStyle } from 'react-native'
import Icon from 'react-native-vector-icons/FontAwesome'
import * as DocumentPicker from 'expo-document-picker'
import RadioButtons from '@components/RadioButton/RadioButton'
import UserCard from '@components/UserCard/UserCard'
import FileCard from '@components/FileCard/FileCard'
import VocalRecording from '@components/VocalRecording/VocalRecording'
import { Input } from '@components/Input'
import { SelectDropDown } from '@components/SelectDropDown/SelectDropdown'
import { Snackbar } from '@components/Snackbar/Snackbar'
import { PhoneInput } from '@components/PhoneInput/PhoneInput'
import { axiosBFFServer, axiosBFFServerFormData } from '@services/connectionServer'
import { createReportSchema } from '@utils/formValidation'
import { formatDate, getLinkAndDocumentsForm, removeDuplicatesByCriteria } from '@utils/utils'
import { SnackbarTypes, audioFile, ViewTypes, ReportFormValues, ReportTypes, SnackbarInitialState, Advisor, CompanyUser, initialReportFormValues, SeverityType, File, CaseSources, WhistleBlowerCompany, ReducedCategory } from '@utils/interfaces'
import { createCaseFields, iv, screenType, snackbarInitialState } from '@utils/constants'
import { colors } from '@styles/constants'
import {useDispatch} from 'react-redux'
import { setCasePass} from '@actions/caseKeyActions'
import forge from 'node-forge'
import { encryptValueWithAES, generateMasterPassword, generateRSAKeyPair } from '@utils/encryption'
import { setPasswordToSave } from '@actions/masterPasswordActions'
import { styles } from './styles'
import {useSelector} from 'react-redux'
import {rootReducerType} from '@reducers/combineReducers'
import { setCurrentCompany } from '@actions/companyActions'
import useScreenDimensions from '@components/Common/UseScreenDimensions'
import ReCAPTCHA from 'react-google-recaptcha'
import { verifyRecaptcha } from '@services/apiService'
import { Loader } from '@components/Loader/Loader'
import { UseErrorMessages } from '@components/Common/UseErrorMessage'

export const NewReport = () => {
	const { t } = useTranslation()
	const { pathname } = useLocation()
	const reportLink = pathname.split('/')[2]
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const screenDimensions = useScreenDimensions()
	const { getErrorMessage } = UseErrorMessages()
	const isMobileScreen = screenDimensions.width < screenType.phone

	const [ company, setCompany ] = useState<WhistleBlowerCompany>({
		companyKey: '',
		advisors: [],
		categories: [],
		users: [],
		departments: [],
		publicKey: '',
		companyAdminName: '',
		encodedPrivateKey: '',
		companyAdminKey: '',
	})
	const [ selectedUsers, setSelectedUsers ] = useState<CompanyUser[]>([])
	const [ snackbar, setSnackbar ] = useState<SnackbarInitialState>(snackbarInitialState)
	const [ isLoading, setIsLoading ] = useState(false)
	const [ checkedValue, setCheckedValue ] = useState(ReportTypes.CONFIDENTIALLY)
	const [ reportOrally,setReportOrally ] = useState(false)
	const [ vocalRecord, setVocalRecord ] = useState<audioFile[]>([])
	const [ uploadedFiles, setUploadedFiles ] = useState<File[]>([])
	const [ legalPartnerKey, setLegalPartnerKey ] = useState<string>('')
	const [ categories, setCategories ] = useState<ReducedCategory[]>([])
	const [captchaValue, setCaptchaValue] = useState<string | null>(null) 
	const userEmail = useSelector((state: rootReducerType) => state.userReducer.email)
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const captchaRef = useRef<any>(null)
	const token = captchaRef.current && captchaRef.current.getValue()

	const currentDate = new Date()
	const deadline = new Date(currentDate)
	deadline.setDate(currentDate.getDate() + 7)
	const radioButtonValues = useMemo(() => ([
		{
			id: 1,
			label: t('reportConfidentially'),
			value: ReportTypes.CONFIDENTIALLY,
			description: t('reportConfidentiallyDescription'),
			descriptionLong: t('reportConfidentiallyDescriptionLong')
		},
		{
			id: 2,
			label: t('reportAnonymously'),
			value: ReportTypes.ANONYMOUSLY,
			description: t('reportAnonymouslyDescription'),
			descriptionLong: t('reportAnonymouslyDescriptionLong')
		}
	]), [])

	const encryptPublicKey = (keyToEncrypt : string, secretKey : string) => {
		return forge.util.bytesToHex(forge.pki.publicKeyFromPem(keyToEncrypt).encrypt(secretKey))
	}
	
	const handleSelectCategory = (categoryValue: string) => {
		const selectedDepartment = company.departments.filter((department) => {
			return department.categories.some((category) => category.categoryKey === categoryValue)
		})
		const adminFullName = company.companyAdminName.split(' ')
		const adminUser = {
			firstName: adminFullName[0],
			lastName: adminFullName[1],
			email: '',
			companyUserKey: company.companyAdminKey
		}

		if(selectedDepartment.length > 0) {
			setSelectedUsers(
                removeDuplicatesByCriteria([...selectedDepartment[0].users, adminUser], 'companyUserKey') as CompanyUser[]
			)
		} else {
			setSelectedUsers([adminUser])
		}
	}

	const handleCaptchaChange = (value: string | null) => {
		if (value !== null) {
			setCaptchaValue(value) 
		}
	} 
	
	const onSubmit = async (values: ReportFormValues) => {
		setIsLoading(true)
		const {
			subject,
			description,
			category,
			firstName,
			lastName,
			prefix,
			number,
			email, 
		} = values
		const { companyKey, advisors } = company

		const caseToSubmitCall = async () => {
			const secretKey = forge.random.getBytesSync(32)
			const keyPair = await generateRSAKeyPair()
			const masterPassword = generateMasterPassword(secretKey)
			const createCaseData = {
				companyKey,
				subject: reportOrally ? '' : subject,
				description: reportOrally ? '' : description,
				severity: SeverityType.MINOR,
				source: CaseSources.SYSTEM, 
				deadline: formatDate(deadline),
				advisors: advisors.map(advisor => advisor.advisorKey),
				companyUsers: selectedUsers?.length ? selectedUsers.map(user => user.companyUserKey) : [],
				categoryKey: categories.find(entry => entry.value === category)?.categoryKey,
				whistleblowerRequest: {
					...(
						(checkedValue !== ReportTypes.ANONYMOUSLY && !reportOrally) && {
							firstName,
							lastName,
							email,
							phoneNumber: number ? prefix + number : '',
						}
					),
					publicKey: '',
					encodedPrivateKey: '',
				},
				partnerEncryptedKey: encryptPublicKey(legalPartnerKey, secretKey),
				companyEncryptedKey: encryptPublicKey(company.publicKey, secretKey),
				whistleblowerEncryptedKey: encryptPublicKey(keyPair.publicKey, secretKey),
			}

			const allUploadedFiles = [...uploadedFiles, ...vocalRecord.map(audio => audio.blob)]
			const areDocuments = !!allUploadedFiles.length
		

			const encryptedCaseData = {
				whistleblowerRequest: {
					publicKey: keyPair.publicKey,
					encodedPrivateKey: encryptValueWithAES(masterPassword, iv, keyPair.privateKey),
				}
			}
			for (const property of createCaseFields) {
				if (typeof createCaseData[property] === 'string') {
					encryptedCaseData[property] = encryptValueWithAES(secretKey, iv, createCaseData[property])
				} else {
					encryptedCaseData.whistleblowerRequest[property] = encryptValueWithAES(secretKey, iv, createCaseData.whistleblowerRequest[property])
				}
			}

			const caseToSubmit = {
				...createCaseData, 
				...encryptedCaseData
			}

			axiosBFFServer
				.post('/case', caseToSubmit	)
				.then((response) => {
					if (response.status === 201) {
						return response.data.caseKey
					} else throw new Error()
				})
				.then(async (caseKey) => {
					dispatch(setCasePass(caseKey))
					dispatch(setPasswordToSave(`${caseKey}:${masterPassword}`))
					let hasUploadFilesError = false
					let errorMessage = t('errors.uploadFilesWB')

					if (areDocuments) {
						const { uploadDocUrl, fileDataToSend } = await getLinkAndDocumentsForm(allUploadedFiles, secretKey, iv)
						const uploadUrl = userEmail ? `${uploadDocUrl}/${caseKey}?authorEmail=${userEmail}` : `${uploadDocUrl}/${caseKey}`
						await axiosBFFServerFormData
							.post(uploadUrl, fileDataToSend)
							.then(response => {
								if (response.status !== 201) {
									hasUploadFilesError = true
								}
							})
							.catch(error => {
								hasUploadFilesError = true
								errorMessage = getErrorMessage(error, t('errors.uploadFilesWB'))
								if (error.response.status === 422 && error.response.data.message.includes('size')) {
									errorMessage = t('errors.fileSize')
								}
								if (error.response.status === 422 && error.response.data.message.includes('type')) {
									errorMessage = t('errors.fileType')
								}
							})
					}

					navigate(`/${ViewTypes.REPORT}/submit`, { state: { errorMessage: hasUploadFilesError ?  errorMessage : ''} })
				})
				.catch(error => {
					const	errorMessage = getErrorMessage(error, t('errors.createReport'))
					setIsLoading(false)
					setSnackbar({
						isVisible: true,
						type: SnackbarTypes.ERROR,
						message: errorMessage
					})
				})
		}

		if (process.env.DEPLOY_ENV?.toLowerCase() !== 'staging') {
			if (token !== null ) {
				const responseRecaptcha = await verifyRecaptcha(token)

				if (responseRecaptcha?.data.success === true) {
					await caseToSubmitCall()
				} else {
					setIsLoading(false)
					setSnackbar({
						isVisible: true,
						type: SnackbarTypes.ERROR,
						message: t('errors.captchaChecked'),
					})
				}
			} else {
				setIsLoading(false)
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: t('errors.captchaVerification'),
				})
			}
		} else {
			await caseToSubmitCall()
		}
	}

	const pickDocument = async () => {
		const result = await DocumentPicker.getDocumentAsync({
			type: '*/*',
			multiple: true,
			copyToCacheDirectory: true
		})
	
		if (result.output && result.output.length > 0){
			const files = Object.values(result.output) as File[]
			setUploadedFiles([ ...uploadedFiles, ...files ])
		}
	}

	const showError = (error) => {
		setSnackbar({
			isVisible: true,
			type: SnackbarTypes.ERROR,
			message: getErrorMessage(error, t('getCompanyError'))
		})
	}

	const getCompany = async () => {
		axiosBFFServer
			.get(`/company/link/${reportLink}`)
			.then(response => {
				if(response.status === 200) {
					dispatch(setCurrentCompany(response.data))
					const { companyKey, departments, advisors, publicKey, encodedPrivateKey, companyAdminName, companyAdminKey } = response.data
					const categories = departments
						.flatMap(department => department.categories
							.map(category => ({
								departmentKey: department.departmentKey,
								label: category.value,
								value: category.categoryKey,
							})))
					const users = departments
						.map(department => ({
							departmentKey: department.departmentKey,
							users: department.users,
						}))
					if (advisors.length) {
						const legalPartner = advisors.find(advisor => !!advisor.legalPartnerLink)
						axiosBFFServer
							.get(`/partner/link/${legalPartner?.legalPartnerLink}`) 
							.then(response => {
								setLegalPartnerKey(response.data.publicKey)
							})
							.catch(error =>{
								showError(error)
							
							})
					} else {
						setLegalPartnerKey(publicKey)
					}
					setCompany({	
						companyKey,
						advisors,
						categories,
						departments,
						users,
						publicKey,
						encodedPrivateKey,
						companyAdminName,
						companyAdminKey
					})
				}
			})
			.catch(error => showError(error))
	}

	const getCategories = async () => {
		const response = await axiosBFFServer.get(`category/all/${company.companyKey}`).catch(showError)
		const categories : ReducedCategory[] = []
		const reducedCategories = company.departments.reduce((accumulator, department) => {
			department.categories.forEach(category => accumulator.push({
				...category,
				label: category.value,
				value: category.categoryKey,
				users: department.users
			}))
			return accumulator
		}, categories)
		response?.data.forEach(existingCategory => {
			if(!reducedCategories.find(category => category?.categoryKey === existingCategory.categoryKey)) {
				categories.push({
					...existingCategory, 
					label: existingCategory.value,
					value: existingCategory.categoryKey,
				})
			}
		})
		setCategories(categories.filter(category => category.isActive))
	}

	useEffect(()=> {
		getCompany()
	}, [])

	useEffect(()=> {
		if (company.companyKey) getCategories()
	}, [company])

	return (
		<>
			<Modal
				visible={isLoading}
				transparent={true}
			>
				<Loader />
			</Modal>
			<View style={[
				styles.container,
				{...(isLoading && {
					opacity: 0,
				})}
			]}>
				<View style={styles.header}>
					<TouchableOpacity 
						style={styles.backButton}
						onPress={() => navigate(`/${ViewTypes.REPORT}/${reportLink}`)}>
						<Icon style={styles.icon} name="arrow-left" size={18} />
						<Text style={styles.backButtonText}>{t('back')}</Text>
					</TouchableOpacity>
				</View>
				<Formik
					initialValues={initialReportFormValues}
					validationSchema={createReportSchema}
					onSubmit={onSubmit}
				>
					{formikProps => (
						<View style={styles.form}>
							<View style={isMobileScreen ? styles.header_form_mobile : styles.header_form}>
								<Text style={styles.title}>{t('NewReportTitle')}</Text>
								<TouchableOpacity 
									style={isMobileScreen && !reportOrally ? [styles.button, styles.reportOrallyButton_mobile] : isMobileScreen || reportOrally ? [styles.button,styles.reportOrallyBttn] : [styles.button,styles.reportOrallyButton]}
									onPress={() => setReportOrally(!reportOrally)}>
								
									<Icon name={!reportOrally ? 'microphone' : 'file-text'} size={18} color={colors.white} style={isMobileScreen ? {alignSelf: 'center'} : undefined}/>
									<Text style={styles.buttonText}>{!isMobileScreen && !reportOrally ? t('reportOrally') : isMobileScreen && !reportOrally ? t('record') : t('reportText')}</Text>
								</TouchableOpacity>
							</View>
						
							{!reportOrally && (
								<>
									<View style={styles.formFieldWrapper}>
										<Text style={styles.labelText}>{t('subject')}</Text>
										<Input
											placeholder={t('subject')}
											name={'subject'}
											onChange={formikProps.setFieldValue} 
											value={formikProps.values['subject']}
										/>
									</View>

									<View style={styles.radioButtonContainer}>
										<Text style={styles.labelText}>{t('reportLabel')}</Text>
										{
											radioButtonValues.map((item,i)=>
												<RadioButtons 
													key={i} 
													item={item} 
													checked={checkedValue} 
													setChecked={setCheckedValue}
												/>
                                    
											)
										}
									</View>

									<View style={styles.formFieldWrapper}>
										<Text style={styles.labelText}>{t('description')}</Text>
										<Input
											placeholder={t('description')}
											name='description'
											multiline={true}
											onChange={formikProps.setFieldValue} 
											value={formikProps.values['description']}
										/>
									</View>

									{checkedValue !== ReportTypes.ANONYMOUSLY && (
										<>
											<View style={styles.formFieldWrapper}>
												<Text style={styles.labelText}>{t('firstNameOptional')}</Text>
												<Input
													placeholder={t('firstNameOptional')}
													name='firstName'
													onChange={formikProps.setFieldValue} 
													value={formikProps.values['firstName']}
												/>
											</View>
											<View style={styles.formFieldWrapper}>
												<Text style={styles.labelText}>{t('lastNameOptional')}</Text>
												<Input
													placeholder={t('lastNameOptional')}
													name='lastName'
													onChange={formikProps.setFieldValue} 
													value={formikProps.values['lastName']}
												/>
											</View>
											<View style={styles.formFieldWrapper}>
												<Text style={styles.labelText}>{t('emailOptional')}</Text>
												<Input
													placeholder={t('emailOptional')}
													name='email'
													onChange={formikProps.setFieldValue} 
													value={formikProps.values['email']}
												/>
												{formikProps.touched['email'] && formikProps.errors['email'] && (
													<Text style={styles.errorText}>{`*${formikProps.errors['email']}`}</Text>
												)}
											</View>
											<View style={styles.formFieldWrapper}>
												<Text style={styles.labelText}>{t('phoneNo')}</Text>
												<PhoneInput
													name={{
														prefix: 'prefix',
														number: 'number',
													}}
													onChange={formikProps.setFieldValue}
													phone={{
														prefix: formikProps.values['prefix'],
														number: formikProps.values['number']
													}}
													phoneNumberPlaceholder={t('phoneNo')}
												/>
												{formikProps.touched['number'] && formikProps.errors['number'] && (
													<Text style={styles.errorText}>{`*${formikProps.errors['number']}`}</Text>
												)}
											</View>
										</>
									)}
								</>
							)}
							{reportOrally && <View style={isMobileScreen ? styles.voiceContainer_mobile : styles.voiceContainer}>
								<VocalRecording
									setAudioRecords={setVocalRecord}
									setSnackbar={setSnackbar}
								/>
							</View>}

							<View style={styles.formFieldWrapper}>
								<Text style={styles.labelText}>{t('category')}</Text>
								<SelectDropDown 
									dropDownItems={categories}
									defaultButtonText={t('category')}
									fieldName='category'
									handleFormValueChange={(fieldName, value) => {
										formikProps.setFieldValue(fieldName, value)
										handleSelectCategory(value)
									}}
									areItemsObjects={true}
								/>
								{formikProps.touched['category'] && formikProps.errors['category'] && (
									<Text style={styles.errorText}>{`*${formikProps.errors['category']}`}</Text>
								)}
							</View>

							<View style={styles.formFieldWrapper}>
								<View style={styles.iconTextContainer}>
									<Icon style={styles.iconDark} name='users' size={18} />
									<Text style={isMobileScreen? styles.labelText_mobile : styles.labelText}>{t('caseHandlersText')}</Text>
								</View>
								<View style={styles.usersContainer}>
									{selectedUsers?.length ? selectedUsers.map((user, index) => 
										<UserCard
											key={index}
											name={`${user.firstName} ${user.lastName}`}
											isAdvisor={false}
										/>) : company && (
										<UserCard
											key={1}
											name={company.companyAdminName}
											isAdvisor={false}
										/>
									)}
								</View>
							</View>

							<View>
								<Text style={styles.labelText}>{t('files')}</Text>  
								{!!uploadedFiles.length && <View style={isMobileScreen? styles.fileContainer_mobile : styles.fileContainer}>
									{uploadedFiles.map((file, index) => 
										<FileCard
											key={index}
											name={file.name}
											index={index}
											uploadFiles={uploadedFiles}
											setUploadFiles={setUploadedFiles}
										/>
									)}
								</View>}
							</View>
							<TouchableOpacity 
								style={isMobileScreen ? [styles.button, styles.gap, styles.uploadFileButton_mobile] : [styles.button, styles.uploadFileButton]}
								onPress={pickDocument}>
								<Icon style={isMobileScreen ? styles.buttonIcon_mobile : styles.buttonIcon} name='upload' size={18} />
								<Text style={styles.buttonText}>{isMobileScreen ? '' : t('uploadFile')}</Text>
							</TouchableOpacity>

							{process.env.DEPLOY_ENV?.toLowerCase() !== 'staging' ? 
								<View  style={
									isMobileScreen
										? [{ transform: [{ scale: 0.65 }], transformOrigin: '0 0' }] as unknown as StyleProp<ViewStyle>[]
										: { alignItems: 'flex-start', overflow: 'hidden' }
								}>
									<ReCAPTCHA
										sitekey={process.env.RECAPTCHA_API_KEY}
										onChange={handleCaptchaChange}
										ref={captchaRef}
									/>
								</View> : <></>
							}
							<View style={isMobileScreen ? {alignItems: 'center'} : {alignItems: 'flex-end'}}>
								<TouchableOpacity 
									style={styles.button}
									onPress={() => {
										formikProps.handleSubmit()
									}}>
									<Icon name='check' size={18} color={colors.white} />
									<Text style={styles.buttonText}>{t('submit')}</Text>
								</TouchableOpacity>
							</View>
						</View>
					)}
				</Formik>
				<Snackbar
					visible={snackbar.isVisible}
					onDismiss={() => setSnackbar(snackbarInitialState)}
					type={snackbar.type}
					message={snackbar.message}
				/>
			</View>
		</>
	)
}

