import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dimensions, Text, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'
import { rootReducerType } from '@reducers/combineReducers'
import useScreenDimensions from '@components/Common/UseScreenDimensions'
import { CasesBox } from '@components/CasesBox/CasesBox'
import { CaseStatistic, CaseTypeList, CompanyCase, ReportTypes, SnackbarInitialState, SnackbarTypes, StatisticsData, StatisticsItem, StatusCaseType, companyList } from '@utils/interfaces'
import { colors } from '@styles/constants'
import { BarChart, LineChart, PieChart } from 'react-native-chart-kit'
import { DatePickerRange } from '@components/DatePickerRange/DatePickerRange'
import { screenType, snackbarInitialState } from '@utils/constants'
import { axiosBFFServer } from '@services/connectionServer'
import { Loader } from '@components/Loader/Loader'
import { capitalizeFirstLetter, downloadStatistics, formatDate, getChartData, getDeadline, getPreviousMonth, getStatisticsData, isPartnerOrAdvisor, replaceDotsWithUnderscores } from '@utils/utils'
import { Snackbar } from '@components/Snackbar/Snackbar'
import { MultiSelectDropdown } from '@components/MultiSelect'
import { status } from '@utils/constants'
import { styles } from './styles'
import { UseErrorMessages } from '@components/Common/UseErrorMessage'
import ViewShot, { captureRef } from 'react-native-view-shot'

export const Statistics = () => {
	const { t } = useTranslation()
	const screenDimensions = useScreenDimensions()
	const { getErrorMessage } = UseErrorMessages()
	const viewShotRefFirstPage = useRef(null)
	const viewShotRefSecondPage = useRef(null)
	const viewShotRefThirdPage = useRef(null)
	const viewShotRefFourthPage = useRef(null)
	const isSmallScreen = screenDimensions.width < screenType.underSmallScreen
	const companyKey = useSelector((state: rootReducerType) => state.companyReducer.key)
	const language = useSelector((state: rootReducerType) => state.userReducer.language)

	const advisorKey = useSelector((state: rootReducerType) => state.setAdvisorKeyReducer.key)
	const smallChartWidth = isSmallScreen ? Dimensions.get('window').width/1.3 : Dimensions.get('window').width/3

	const [filters, setFilters] = useState({
		company: [companyKey],
		category: [],
		status: []
	})
	const [ snackbar, setSnackbar ] = useState<SnackbarInitialState>(snackbarInitialState)
	const [categories,setCategories] = useState([])

	const [statisticsData,setStatisticsData] = useState<StatisticsData>({
		category: [],
		creationDate: [{
			created: '',
			case: 0
		}],
		source: [],
		severity: [],
		state: [],
		deadline: [],
		type: [],
		lastActivity: []
	})

	const pieChartWidth = isSmallScreen ? smallChartWidth - 140 : smallChartWidth - 30
	const [range, setRange] = useState({ startDate: getPreviousMonth() , endDate: new Date() })
	const [totalCases,setTotalCases] = useState(10)
	const [isLoading, setIsLoading] = useState(true)
	const [hasSelectedFilters,setHasSelectedFilters] = useState(false)
	const [hasStatistics,setHasStatistics] = useState(true)
	const [casesList,setCasesList] = useState<CompanyCase[]>([{
		id: '1',
		subject: '',
		category: '',
		severity: '',
		deadline: '',
		status: '',
		createdAt: ''
	}])
	const [caseTypesList,setCaseTypesList] = useState<CaseTypeList[]>([{
		type: 'new cases',
		number: 0,
		icon: false
	}])
	const [isGenerating, setIsGenerating] = useState(false)
	
	const { name } = useSelector((state: rootReducerType) => state.companyReducer.currentCompany)

	const chartConfig = {
		backgroundColor: colors.white,
		backgroundGradientFrom: colors.white,
		backgroundGradientTo: colors.pureWhite,
		decimalPlaces: 0, 
		color: (opacity = 1) => `${colors.darkShades}, ${opacity})`,
		labelColor: (opacity = 1) => `${colors.darkShades}, ${opacity})`,
		style: {
			borderRadius: 4,
		},
		propsForDots: {
			r: '6',
			strokeWidth: '2',
			stroke: colors.black
		}
	}

	const getCases = () => {
		const page = 1
		const size = 999
		let filterItems = {
			creationDateStart: formatDate(range.startDate),
			creationDateEnd: formatDate(range.endDate),
			companyKeys: [companyKey]
		}
		filters.status.length ? filterItems = Object.assign(filterItems, {statuses: filters.status}) : filterItems
		filters.category.length ? filterItems = Object.assign(filterItems, {categoryKeys:filters.category}) : filterItems
		advisorKey ? filterItems = Object.assign(filterItems, {advisorKeys: [advisorKey]}) : filterItems
		
		axiosBFFServer
			.post(`/case/search?page=${page}&size=${size}`, filterItems)
			.then(response => {
				const cases: CompanyCase[] = [] 
				response.data.pageResults.forEach((item,index) => {
					cases.push({
						id: index + 1,
						source: item.source,
						companyName: item.companyName,
						subject: item.subject,
						category: item.category ? item.category.value : '',
						severity: item.severity,
						deadline: getDeadline(item.deadline,language),
						status: item.caseState.status,
						createdAt: item.createdAt,
						lastActivity: getDeadline(item.lastActivity,language),
						whistleblower: item.whistleblower
					})})
				response.data.totalResults === 0 ? setHasStatistics(false) : setHasStatistics(true)
				setCasesList(cases)
				setTotalCases(response.data.totalResults)
				setIsLoading(false)
			})
			.catch(error => {
				const errorMessage = getErrorMessage(error, t('errorCases')) 
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
				setIsLoading(false)
			})
	}

	const getCategories = async () => {
		axiosBFFServer
			.get(`/category/all/${companyKey}`)
			.then(response => {
				setCategories(response.data)
			})
			.catch(error => {
				const errorMessage = getErrorMessage(error, t('errors.listCategories')) 
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}

	const onSelect = (selected,field) => {
		setFilters({...filters, [field]: selected})
	}

	useEffect(() => {
		getCategories()
		getCases()
	},[])

	const generateReport = async () => {
		setIsGenerating(true)
		const imageStatisticsFirstPage = viewShotRefFirstPage.current ? await captureRef(viewShotRefFirstPage.current, {
			format: 'png',
			quality: 0.9
		}) : ''

		const imageStatisticsSecondPage = viewShotRefSecondPage.current ? await captureRef(viewShotRefSecondPage.current, {
			format: 'png',
			quality: 0.9
		}) : ''

		const imageStatisticsThirdPage = viewShotRefThirdPage.current ? await captureRef(viewShotRefThirdPage.current, {
			format: 'png',
			quality: 0.9
		}) : ''

		const imageStatisticsFourthPage = viewShotRefFourthPage.current ? await captureRef(viewShotRefFourthPage.current, {
			format: 'png',
			quality: 0.9
		}) : ''

		const openCases = caseTypesList.find(item => item.type === 'open cases')?.number || 0
		const closedCases = caseTypesList.find(item => item.type === 'closed cases')?.number || 0

		if (imageStatisticsFirstPage && imageStatisticsSecondPage && imageStatisticsThirdPage && imageStatisticsFourthPage && openCases != null && closedCases != null) {
			downloadStatistics(imageStatisticsFirstPage, imageStatisticsSecondPage, imageStatisticsThirdPage, imageStatisticsFourthPage, name, totalCases, openCases, closedCases, formatDate(range.startDate), formatDate(range.endDate), setIsGenerating)
		} else {
			setSnackbar(
				{
					isVisible: true,
					type: SnackbarTypes.INFO,
					message: t('downloadReport.downloadReportError'),
				}
			)
			setIsGenerating(false)
		}

	}

	useEffect(() => {
		getCases()
		filters.status.length || filters.category.length ? setHasSelectedFilters(true) : setHasSelectedFilters(false) 
	},[filters,range])

	useEffect(() => {
		let openCases = 0 
		let closesCases = 0
		
		casesList.forEach((item) => item.status === StatusCaseType.OPEN ? openCases++
			: item.status === StatusCaseType.CLOSED ? closesCases++
				: null)
		
		casesList && setCaseTypesList([
			{
				type: t('amountOfCases'),
				number: totalCases,
				icon: false
			},
			{
				type: `${StatusCaseType.OPEN.toLowerCase()} cases`,
				number: openCases,
				icon: false
			},
			{
				type: `${StatusCaseType.CLOSED.toLowerCase()} cases`,
				number: closesCases,
				icon: false
			}
		])

		let anonReports = 0
		let confidentialReports = 0

		casesList.forEach((item) => item.whistleblower?.email ? confidentialReports++ : anonReports++)
		const statisticsDataReportType: StatisticsItem[] = [{
			name: capitalizeFirstLetter(ReportTypes.CONFIDENTIALLY),
			frequency: confidentialReports,
			color: colors.main,
			legendFontSize: 14
		},{
			name: capitalizeFirstLetter(ReportTypes.ANONYMOUSLY),
			frequency: anonReports,
			color: colors.secondBlueChart,
			legendFontSize: 14
		}]

		const statisticsDataCaseCreation: CaseStatistic[] = []
		const statisticsDataCategory: StatisticsItem[] = []
		const statisticsDataSource: StatisticsItem[] = []
		const statisticsDataSeverity: StatisticsItem[] = []
		const statisticsDataState: StatisticsItem[] = []
		const statisticsDataDeadline: StatisticsItem[] = []
		const statisticsDataLastActivity: StatisticsItem[] = []

		const caseCreationStatistics = getStatisticsData(casesList,'createdAt',statisticsDataCaseCreation,'created','case',false)
		const caseCategoryStatistics = getStatisticsData(casesList,'category',statisticsDataCategory,'name','frequency',true) 
		const caseSourceStatistics = getStatisticsData(casesList,'source',statisticsDataSource,'name','frequency',true)
		const caseSeverityStatistics = getStatisticsData(casesList,'severity',statisticsDataSeverity,'name','frequency',true)
		const caseStateStatistics = getStatisticsData(casesList,'status',statisticsDataState,'state','case',false)
		const caseDeadlineStatistics = getStatisticsData(casesList,'deadline',statisticsDataDeadline,'deadline','case',false)
		const caseLastActivityStatistics = getStatisticsData(casesList,'lastActivity',statisticsDataLastActivity,'lastActivity','case',false)

		setStatisticsData({
			category: caseCategoryStatistics,
			creationDate: caseCreationStatistics,
			source: caseSourceStatistics,
			severity: caseSeverityStatistics,
			state: caseStateStatistics,
			deadline: caseDeadlineStatistics,
			type: statisticsDataReportType,
			lastActivity: caseLastActivityStatistics
		})
	},[casesList])

	return (
		<View style={styles.container}>
			<Snackbar
				visible={snackbar.isVisible}
				onDismiss={() => setSnackbar(snackbarInitialState)}
				type={snackbar.type}
				message={snackbar.message}
			/>
			<View style={styles.header}>
				<Text style={[styles.subtitle, styles.title]}>{t('companyStatistics')}</Text>
				<View style={styles.generateReportContainer}>
					<DatePickerRange
						range={range}
						setRange={setRange} />
					<TouchableOpacity 
						disabled={(hasStatistics || !isGenerating) ? false : true}  
						onPress={() => {
							generateReport()  
						}} 
						style={[styles.generateReportButton, (!hasStatistics || isGenerating) && { opacity: 0.5, cursor: 'arrow' }]}>
						<View style={{ flexDirection: 'row', alignItems: 'center', gap: 10 }}>
							<Text style={styles.buttonText}>{t('downloadReport.generateReport')}</Text>
							{isGenerating && <Loader size={20} color={colors.white} />}
						</View>
					</TouchableOpacity>
				</View>
			</View>
			<View style={styles.sectionContainer}>
				<Text style={styles.subtitle}>{t('filters')}</Text>
				<View style={styles.filterSection}>
					<View style={styles.selectContainer}>
						<MultiSelectDropdown
							items={categories}
							selectedItems={filters.category}
							onSelectedItemsChange={(value) => onSelect(value,'category')}
							placeholder={t('selectCategories')}
							displayKey="value"
							uniqueKey="categoryKey"
							searchText={t('selectCategories')}
						/>
					</View>
					<View style={styles.selectContainer}>
						<MultiSelectDropdown
							items={status}
							selectedItems={filters.status}
							onSelectedItemsChange={(value) => onSelect(value,'status')}
							placeholder={t('selectStatus')}
							displayKey="name"
							uniqueKey="name"
							searchText={t('selectStatus')}
						/>
					</View>
						
				</View>
			</View>

			{isLoading ?
				<Loader
					color={colors.white} /> 
				: hasStatistics ?
					<>
						<View style={styles.sectionContainer}>
							<CasesBox cases={caseTypesList} />
						</View>
						<ViewShot ref={viewShotRefFirstPage} options={{ format: 'png', quality: 0.9 }}>
							<View style={styles.sectionContainer}>
								<View style={isSmallScreen ? styles.elementsContainerMobile : styles.elementsContainer}>
									<View style={styles.chartItem}>
										<Text style={styles.subtitle}>{t('categoryBreakdown')}</Text>
										<PieChart
											data={statisticsData.category}
											width={pieChartWidth}
											height={370}
											chartConfig={chartConfig}
											accessor={'frequency'}
											backgroundColor={colors.white}
											paddingLeft={'15'}
											center={[20, 10]}
											absolute
											style={styles.chart}
										/>
									</View>
									<View style={styles.chartItem}>
										<Text style={styles.subtitle}>{t('timeOfReporting')}</Text>
										<LineChart
											data={getChartData(statisticsData.creationDate,'created','case')}
											width={pieChartWidth}
											height={370}
											yAxisSuffix={t('cases')}
											yAxisInterval={1} 
											chartConfig={chartConfig}
											style={styles.lineChart}
											bezier
											fromZero
										/>
									</View>
								</View>
							</View>
						</ViewShot>
						<ViewShot ref={viewShotRefSecondPage} options={{ format: 'png', quality: 0.9 }}>
							<View style={styles.sectionContainer}>
								<View style={isSmallScreen ? styles.elementsContainerMobile : styles.elementsContainer}>
									<View style={styles.chartItem}>
										<Text style={styles.subtitle}>{t('severityBreakdown')}</Text>
										<PieChart
											data={statisticsData.severity}
											width={pieChartWidth}
											height={370}
											chartConfig={chartConfig}
											accessor={'frequency'}
											backgroundColor={colors.white}
											paddingLeft={'15'}
											center={[20, 10]}
											absolute
											style={styles.chart}
										/>
									</View>
									<View style={styles.chartItem}>
										<Text style={styles.subtitle}>{t('sourceBreakdown')}</Text>
										<PieChart
											data={statisticsData.source}
											width={pieChartWidth}
											height={370}
											chartConfig={chartConfig}
											accessor={'frequency'}
											backgroundColor={colors.white}
											paddingLeft={'15'}
											center={[20, 10]}
											absolute
											style={styles.chart}
										/>
									</View>
								</View>
							</View>
						</ViewShot>
						<ViewShot ref={viewShotRefThirdPage} options={{ format: 'png', quality: 0.9 }}>
							<View style={styles.sectionContainer}>
								<View style={isSmallScreen ? styles.elementsContainerMobile : styles.elementsContainer}>
									<View style={styles.chartItem}>
										<Text style={styles.subtitle}>{t('reportType')}</Text>
										<PieChart
											data={statisticsData.type}
											width={pieChartWidth}
											height={370}
											chartConfig={chartConfig}
											accessor={'frequency'}
											backgroundColor={colors.white}
											paddingLeft={'15'}
											center={[20, 10]}
											absolute
											style={styles.chart}
										/>
									</View>
									<View style={styles.chartItem}>
										<Text style={styles.subtitle}>{t('statusBreakdown')}</Text>
										<BarChart
											style={styles.barChart}
											data={getChartData(statisticsData.state,'state','case')}
											width={pieChartWidth}
											height={370}
											yAxisSuffix={t('cases')}
											yAxisLabel=''
											chartConfig={chartConfig}
											verticalLabelRotation={0}
											fromZero
										/>
									</View>
								</View>
							</View>
						</ViewShot>
						<ViewShot ref={viewShotRefFourthPage} options={{ format: 'png', quality: 0.9 }}>
							<View style={styles.sectionContainer}>
								<View style={isSmallScreen ? styles.elementsContainerMobile : styles.elementsContainer}>
									<View style={styles.chartItem}>
										<Text style={styles.subtitle}>{t('lastActivity')}</Text>
										<LineChart
											data={getChartData(statisticsData.lastActivity,'lastActivity','case')}
											width={pieChartWidth}
											height={370}
											yAxisSuffix={t('cases')}
											yAxisInterval={1} 
											chartConfig={chartConfig}
											style={styles.lineChart}
											bezier
											fromZero
										/>
									</View>
									<View style={styles.chartItem}>
										<Text style={styles.subtitle}>{t('deadlineBreakdown')}</Text>
										<BarChart
											style={styles.barChart}
											data={getChartData(statisticsData.deadline,'deadline','case')}
											width={pieChartWidth}
											height={370}
											yAxisSuffix={t('cases')}
											yAxisLabel=''
											chartConfig={chartConfig}
											verticalLabelRotation={0}
											fromZero
										/>
									</View>
								</View>
							</View>
						</ViewShot>
					</>	: <Text style={styles.text}>
						{hasSelectedFilters ?
							t('noStatisticsFilters')
							: t('noCasesStatistics')}
					</Text>
			}
		</View>
	)
}

