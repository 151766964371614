import Layout from '@containers/Layout'
import Companies from '@containers/Companies/Companies'
import React from 'react'

export const CompanyManagement = () => {
	return (
		<Layout>
			<Companies />
		</Layout>
	)
}
