import { reduxActionType } from '@utils/reduxTypes'

export const setMasterPassword = (payload: string): reduxActionType => {
	return {
		type: 'SET_MASTER_PASSWORD',
		payload,
	}
}

export const setPasswordToSave = (payload: string): reduxActionType => {
	return {
		type: 'SET_PASSWORD_TO_SAVE',
		payload,
	}
}
