import { colors, theme } from '@styles/constants'
import { button } from '@styles/mixins/button'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
	container: {
		width: '90%',
		margin: 'auto',
		maxWidth: 500,
		paddingHorizontal: 20,
		paddingVertical: 40,
		borderRadius: 8,
		backgroundColor: theme.light.colors.mainContainer,
		shadowColor: colors.black,
		shadowOffset: { width: 0, height: 4 },
		shadowOpacity: 0.1,
		shadowRadius: 4,
		elevation: 4,
		alignItems: 'center',
		gap: 20,
	},
	title: {
		fontSize: 20,
		fontWeight: 'bold',
		color: theme.light.colors.titleColor,
		textAlign: 'center'
	},
	subtitle: {
		fontSize: 18,
		color: theme.light.colors.titleColor,
		textAlign: 'justify'
	},
	formContainer: {
		alignSelf: 'stretch',
		padding: 16,
		gap: 16,
	},
	errorText: {
		fontSize: 12,
		color: colors.error,
	},
	button: {
		width: 200,
		alignSelf: 'center',
		...({
			...button(theme.light.colors.buttonLg).button,
		} as object),
		justifyContent: 'center',
		borderRadius: 6,
		paddingVertical: 12,
	},
	buttonText: {
		fontSize: 18,
		fontWeight: 'bold',
		color: colors.pureWhite,
		textAlign: 'center',
	},
})