import { reduxActionType } from '@utils/reduxTypes'

const initialState = {
	key: '',
	email: '',
	firstName: '',
	lastName: '',
	roles: [],
	permissions: [],
	phone: '',
	title: '',
	settings: [],
	language: 'ro'
}

export const userReducer = (
	state = initialState,
	action: reduxActionType
): (typeof initialState) => {
	switch (action.type) {
	case 'SET_USER_INFO': {
		return {
			...state,
			key: action.payload.accountKey,
			email: action.payload.email,
			firstName: action.payload.firstName,
			lastName: action.payload.lastName,
			roles: action.payload.roles,
			permissions: action.payload.accountPermissions
		}
	}
	case 'PATCH_USER_INFO': {
		return {
			...state,
			...action.payload
		}
	}
	case 'SET_USER_SETTINGS': {
		return {
			...state,
			settings: action.payload
		}
	}
	case 'SET_USER_LANGUAGE': {
		return {
			...state,
			language: action.payload
		}
	}
	default: return state
	}
}
