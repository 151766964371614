import { StyleSheet } from 'react-native'
import { colors, theme } from '@styles/constants'

export const styles = StyleSheet.create({
	container: {
		flex: 1,
		gap: 20,
		paddingVertical: 20,
		paddingHorizontal: 20,
	},
	masterPasswordContainer: {
		height: '100%',
		alignItems: 'center',
		justifyContent: 'center',
	},
	headerContainer: {
		backgroundColor: theme.light.colors.mainContainer,
		borderRadius: 30,
		marginBottom: 20,
		padding: 20,
		paddingTop: 0,
		shadowColor: theme.light.colors.mainColor,
		shadowOffset: { width: 0, height: 1 },
		shadowOpacity: 0.2,
		shadowRadius: 2,
		elevation: 3,
	},
	headerContainer_mobile: {
		marginBottom: 30,
		gap: 12
	},
	headerText: {
		fontSize: 26,
		fontWeight: '500',
		color: theme.light.colors.mainColor
	},
	dataText: {
		fontSize: 16,
	},
	smallScreenDataText: {
		fontSize: 14,
	},
	statusText: {
		textAlign: 'center',
		borderRadius: 20,
		paddingHorizontal: 10,
		width: '70%',
		fontWeight: '600',
	},
	tableItemsContainer: {
		flexDirection: 'row',
		gap: 8
	},
	tableItemID: {
		backgroundColor: colors.lightGray,
		borderRadius: 4,
		alignItems: 'center',
		padding: 10
	},
	severityItem: {
		backgroundColor: colors.dimmedRed,
		borderRadius: 4,
		alignContent: 'center',
		width: 60,
		alignItems: 'center',
		paddingVertical: 5,
		borderWidth: 0.2,
		borderColor: colors.danger
	},
	lowSeverity: {
		backgroundColor: colors.dimmedGreen,
		borderColor: colors.secondary
	},
	mediumSeverity: {
		backgroundColor: colors.dimmedYellow,
		borderColor: colors.yellow
	},
	boxContainer: {
		paddingTop: 40,
		paddingBottom: 20
	},
	itemText: {
		backgroundColor: colors.themedGray,
		borderRadius: 4,
		alignContent: 'center',
		alignItems: 'center',
		paddingHorizontal: 10,
		paddingVertical: 5,
		borderWidth: 0.2,
		borderColor: colors.gray
	},
	redText: {
		color: colors.danger,
		fontWeight: '500'
	},
	greenText: {
		color: colors.secondary,
		fontWeight: '500'
	},
	grayText: {
		color: colors.gray,
		fontWeight: '500'
	},
	link: {
		color: colors.darkShades,
		fontSize: 16,
		fontWeight: '500'
	},
	linkDisabled: {
		color: colors.gray,
		fontSize: 16,
		fontWeight: '500'
	},
	listCasesContainer: {
		marginBottom: 20,
		backgroundColor: theme.light.colors.mainContainer,
		borderRadius: 30,
		zIndex: 999,
		shadowColor: theme.light.colors.mainColor,
		shadowOffset: { width: 0, height: 1 },
		shadowOpacity: 0.2,
		shadowRadius: 2,
		elevation: 3,
	},
	textBox: {
		minWidth: 50,
		paddingVertical: 2,
		paddingHorizontal: 6,
		textAlign: 'center',
		borderWidth: 1,
		borderRadius: 4
	},
	button: {
		backgroundColor: colors.main,
		borderRadius: 6,
		paddingVertical: 10,
		paddingHorizontal: 20,
		width: 180,
		marginTop: 20,
		flexDirection: 'row',
		gap: 8,
		justifyContent: 'center',
		alignItems: 'center'
	},
	footer: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	buttonText: {
		color: colors.white,
		fontWeight: 'bold',
		fontSize: 16
	},
})
