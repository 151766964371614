import { StyleSheet } from 'react-native'
import { theme } from '@styles/constants'

export const styles = StyleSheet.create({
	tableContainer: {
		width: '100%',
		borderWidth: 1,
		borderColor: theme.light.colors.boxBorder,
		borderRadius: 30,
		overflow: 'hidden',
		marginBottom: 6,
		backgroundColor: theme.light.colors.mainContainer,
		shadowColor: theme.light.colors.mainColor,
		shadowOffset: { width: 0, height: 1 },
		shadowOpacity: 0.2,
		shadowRadius: 2,
		elevation: 3,
	},
	fullScreenContainer: {
		flex: 1,
	},
	smallScreenContainer: {
		justifyContent: 'center',
		marginTop: 20
	},
	headerRow: {
		flexDirection: 'row',
		backgroundColor: theme.light.colors.mainContainer,
		paddingHorizontal: 24,
		paddingTop: 10,
		paddingBottom: 10,
		borderBottomColor: theme.light.colors.boxBorder,
		borderBottomWidth: 1
	},
	headerCell: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
	},
	headerText: {
		fontWeight: '600',
		color: theme.light.colors.mainColor,
		fontSize: 16,
		textAlign: 'center',
	},
	headerTextIcon: {
		fontWeight: '600',
		color: theme.light.colors.titleColor
	},
	smallScreenHeaderText: {
		fontSize: 14,
	},
	dataRow: {
		flexDirection: 'row',
		paddingHorizontal: 20,
		paddingVertical: 5,
		borderBottomWidth: 1,
		borderBottomColor: theme.light.colors.boxBorder,
		backgroundColor: theme.light.colors.moreListBgHover,
		wordBreak: 'break-word'
	},
	dataCell: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
		paddingHorizontal: 10,
	},
	whiteRow: {
		backgroundColor: theme.light.colors.mainContainer,
	},

	footer: {
		display: 'flex',
		justifyContent: 'space-between',
		marginRight: '15px',
		marginLeft: '15px',
		backgroundColor: theme.light.colors.mainContainer
	},
	sortingHead: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center'
	},
	headerArrow: {
		fontSize: 16,
		marginLeft: 15,
		fontWeight: 'bold'
	},
	caseItem: {
		backgroundColor: theme.light.colors.secondary,
		flexDirection: 'row',
		gap: 10,
		alignItems: 'center',
		padding: 10,
		borderRadius: 4
	},
	noDataText: {
		padding: 20,
		textAlign: 'center'
	}
})
