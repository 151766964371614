import { StyleSheet } from 'react-native'
import { colors, theme } from '@styles/constants'

export const styles = StyleSheet.create({
	modalContainer: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: colors.shadeBackground,
	},
	contentContainer: {
		width: '40%',
		maxHeight: '80%',
		minWidth: 440,
		padding: 16,
		borderRadius: 30,
		backgroundColor: theme.light.colors.mainContainer,
		overflowY: 'auto'
	},
	contentContainer_mobile: {
		width: '80%',
		minWidth: 280,
		overflowY: 'auto'
	},
	title: {
		fontSize: 18,
		fontWeight: 'bold',
	},
	formContainer: {
		paddingVertical: 32,
		gap: 16,
		overflowY: 'auto'
	},
	label: {
		fontSize: 16,
	},
	field: {
		gap: 8,
	},
	buttonsContainer: {
		flexDirection: 'row',
		justifyContent: 'flex-end',
		gap: 16,
	},
	button: {
		fontWeight: 'bold',
		paddingVertical: 10,
		paddingHorizontal: 20,
		textAlign: 'center',
		borderRadius: 6,
		color: theme.light.colors.mainColor
	},
	saveButton: {
		backgroundColor: theme.light.colors.primaryDark,
		color: theme.light.colors.linkColorActive
	},
	errorText: {
		color: colors.error,
		fontSize: 12,
	},
})
