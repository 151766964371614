import Layout from '@containers/Layout'
import React from 'react'
import { AdvisorEditContainer } from '@containers/AdvisorEdit/AdvisorEdit'

export const AdvisorEdit = () => {
	return (
		<Layout>
			<AdvisorEditContainer />
		</Layout>
	)
}