import { colors, theme } from '@styles/constants'
import { button, buttonText } from '@styles/mixins/button'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
	container: {
		flex: 1,
		padding: 20,
	},
	headerContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: 20,
	},
	headerText: {
		fontSize: 26,
		fontWeight: '500',
		color: theme.light.colors.titleColor
	},
	textContainer: {
		width: 'auto',
		marginBottom: 20
	},
	explanatoryText: {
		margin: 16,
		fontSize: 16,
	},
	content: {
		backgroundColor: colors.dimmedMain,
		gap: 16,
	},
	section: {
		padding: 16,
		gap: 16,
		backgroundColor: theme.light.colors.mainContainer,
		borderRadius: 30,
		shadowColor: theme.light.colors.mainColor,
		shadowOffset: { width: 0, height: 1 },
		shadowOpacity: 0.2,
		shadowRadius: 2,
		elevation: 3,
	},
	sectionTitle: {
		fontSize: 20,
		fontWeight: '600',
	},
	sectionContent: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: 8,
		borderWidth: 1,
		borderColor: colors.gray,
		borderRadius: 4,
	},
	linkButtonsContainer: {
		flexDirection: 'row',
		gap: 8,
	},
	linkButton: {
		paddingLeft: 8,
		borderLeftWidth: 1,
		borderLeftColor: colors.gray,
	},
	buttonContainer: {
		alignItems: 'flex-end',
	},
	openButton: {
		...({
			...button(theme.light.colors.successDark).button,
		} as object),
		width: 160,
		paddingVertical: 8,
		paddingHorizontal: 16,
		textAlign: 'center',
		justifyContent: 'center', 
		borderRadius: 6,
	},
	buttonText: {
		...({
			...buttonText().buttonText,
		} as object),
		fontWeight: 'bold',
		textDecorationLine: 'none',
	},
})