import React, { useEffect, useState } from 'react'
import { Modal, StyleSheet, View } from 'react-native'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { NewLegalPartner } from '@components/LegalPartner/NewLegalPartner'
import { Loader } from '@components/Loader/Loader'
import { Snackbar } from '@components/Snackbar/Snackbar'
import { axiosBFFServer } from '@services/connectionServer'
import { CreateLegalPartnerFormValues, SnackbarInitialState, SnackbarTypes } from '@utils/interfaces'
import { countries, legalPartnerType, snackbarInitialState } from '@utils/constants'
import { theme } from '@styles/constants'
import { GeneralModal } from '@components/Modal/Modal'
import { verifyRecaptcha } from '@services/apiService'
import { UseErrorMessages } from '@components/Common/UseErrorMessage'

interface Props{
	type: string
}

export const LegalPartnerContainer = (props: Props) => {
	const navigate = useNavigate()
	const location = useLocation()
	const { t } = useTranslation()

	const [ isLoading, setIsLoading ] = useState(false)
	const [ snackbar, setSnackbar ] = useState<SnackbarInitialState>(snackbarInitialState)
	const [disabledButton, setDisabledButton] = useState(false)
	const [ isVisible, setIsVisible ] = useState(false)
	const [ showSuccessModal, setShowSuccessModal ] = useState(false)
	const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null)
	const { getErrorMessage } = UseErrorMessages()

	const onSubmit = async (values: CreateLegalPartnerFormValues) => {
		setDisabledButton(true)
		const {
			firstName,
			lastName,
			country,
			name,
			email,
			prefix,
			number,
			street,
			city,
			zipCode,
			vatNr,
			language,
			nrOfEmployees,
			isStateOwned,
		} = values
		setIsLoading(true)

		const reqBody = {
			firstName,
			lastName,
			country: countries[0],
			name,
			email,
			phoneNumber: prefix + number,
			streetAddress: street,
			city,
			zipCode: zipCode,
			vatNumber: vatNr, 
			type: props.type,
			defaultLanguage: language,
			...(legalPartnerType[1] && nrOfEmployees && { nrOfEmployees: nrOfEmployees }),
			isStateOwned,
		}

		if (process.env.DEPLOY_ENV?.toLowerCase() !== 'staging') {

			if (recaptchaToken !== null ) {
				const responseRecaptcha = await verifyRecaptcha(recaptchaToken).then(resp =>{ return resp})

				responseRecaptcha?.status === 201 && await axiosBFFServer
					.post('/partner', reqBody)
					.then(response => {
						if(response.status === 201) {
							setShowSuccessModal(true)
						} else {
							setDisabledButton(false)
							setIsLoading(false)  
							setSnackbar({
								isVisible: true,
								type: SnackbarTypes.ERROR,
								message: t('errors.createLP'),
							})
						}
					})
					.catch(error => {
						let errorMessage = ''
						if(error.response.data.customStatus === '609' ) {
							errorMessage = t('emailVAT') + ' ' + getErrorMessage(error, '')
							setIsLoading(false)
							setDisabledButton(false)
							setIsVisible(true)
						} else {
							errorMessage = getErrorMessage(error, '')
							setIsLoading(false)
							setDisabledButton(false)
							setIsVisible(true)
						}
						
						setSnackbar({
							isVisible: true,
							type: SnackbarTypes.ERROR,
							message: errorMessage,
						})
    
						setIsLoading(false)
						setDisabledButton(false)
						setSnackbar({
							isVisible: true,
							type: SnackbarTypes.ERROR,
							message: props.type === legalPartnerType[0]
								? t('errors.createLP')
								: t('errors.createLPIntern'),
						})
					})
			} 
			else {
				setIsLoading(false)
				setDisabledButton(false)

				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: t('errors.captchaVerification'),
				})
			}
		} else {
			await axiosBFFServer
				.post('/partner', reqBody)
				.then(response => {
					if(response.status === 201) {
						setShowSuccessModal(true)
					} else {
						setDisabledButton(false)
						setIsLoading(false)
						setSnackbar({
							isVisible: true,
							type: SnackbarTypes.ERROR,
							message: props.type === legalPartnerType[0]
								? t('errors.createLP')
								: t('errors.createLPIntern'),
						})
					}
				})
				.catch(error => {
					if (error.response.status === 409) {
						setIsLoading(false)
						setDisabledButton(false)
						setIsVisible(true)
						return
					}

					setIsLoading(false)
					setDisabledButton(false)
					setSnackbar({
						isVisible: true,
						type: SnackbarTypes.ERROR,
						message: props.type === legalPartnerType[0]
							? t('errors.createLP')
							: t('errors.createLPIntern'),
					})
				})
		}

	}


	const redirectWebsite = () => {
		global.location.replace(process.env.WEBSITE_URL)
	}

	useEffect(() => {
		if (props.type === legalPartnerType[0]) {
			if(!location.state?.success) {
				navigate('/login-client')
			}
		} 
	},[])


	return (
		<View style={styles.container}>
			<Modal
				visible={isLoading}
				transparent={true}
			>
				<Loader />
			</Modal>
			<View style={[
				styles.formContainer,
				{...(isLoading && {
					opacity: 0,
				})}
			]}>
				<NewLegalPartner
					type={props.type}
					onSubmit={onSubmit}
					setSnackbar={setSnackbar}
					disabledButton={disabledButton}
					onRecaptchaVerify={setRecaptchaToken}
				/>
			</View>
			{showSuccessModal && <GeneralModal
				visible={showSuccessModal}
				infoModal={true}
				onClose={redirectWebsite}
				onSubmit={redirectWebsite}
				messageText={t('LPRegistrationMessage')}
			/>}
			{isVisible && <GeneralModal
				visible={isVisible}
				onClose={() => setIsVisible(false)}
				onSubmit={redirectWebsite}
				messageText={t('accountAlreadyExists')}
				closeButtonText={t('close')}
				submitButtonText={t('goToWebsite')}
			/>}
			<Snackbar
				visible={snackbar.isVisible}
				onDismiss={() => setSnackbar(snackbarInitialState)}
				type={snackbar.type}
				message={snackbar.message}
			/>
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		padding: 20,
		backgroundColor: theme.light.colors.appContainer,
	},
	formContainer: {
		width: '100%',
		alignItems: 'center',
	}
})
