import { Snackbar } from '@components/Snackbar/Snackbar'
import Table from '@components/Table/Table'
import { allData, snackbarInitialState } from '@utils/constants'
import { SecurityLogs, SnackbarInitialState } from '@utils/interfaces'
import { checkIfPermissionExist, exportSecurityLogsToPDF, formatDateToCustomString, fromCamelCaseToTitle, sanitizeUserAgent } from '@utils/utils'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text, TouchableOpacity } from 'react-native'
import { styles } from './styles'
import { rootReducerType } from '@reducers/combineReducers'
import { useSelector } from 'react-redux'
import { getSecurityLogs } from '@services/apiService'

export const Security: FC = () => {
	const { t } = useTranslation()

	const initialLogState = {
		currentPage: 0,
		pageResults: [],
		totalDisplayResults: 0,
		totalResults: 0
	}
	const [securityLogs, setSecurityLogs] = useState<SecurityLogs>(initialLogState)
	const [ snackbar, setSnackbar ] = useState<SnackbarInitialState>(snackbarInitialState)
	const [page, setPage] = useState(1)
	const size = useSelector((state: rootReducerType) => state.paginationReducer.pageSize)
	const { permissions: userPermissions } = useSelector((state: rootReducerType) => state.userReducer)
	const hasViewSecurityLogsPermission = checkIfPermissionExist(userPermissions, 'securityLogs', 'view')
	const companyKey = useSelector((state:rootReducerType) => state.companyReducer.key)

	const generateColumns = () => {

		if(securityLogs.pageResults.length === 0) {
			return []
		}

		const firstObject = securityLogs.pageResults[0]

		return Object.keys(firstObject).filter(key => key !== 'companyKey' && key !== 'failureReason').map(key => ({
			key: key,
			title: fromCamelCaseToTitle(t(key)),
		}))
	}

	const renderTableCell = (column, item) => {
		switch(column.key){
		case 'time':
			return <Text style={{textAlign: 'center'}}>{formatDateToCustomString(item[column.key])}</Text>
		case 'userAgent': 
			return <Text style={{textAlign: 'center'}}>{sanitizeUserAgent(item[column.key])}</Text>
		default: 
			return <Text>{item[column.key]}</Text>
		}
	}

	const columns = generateColumns()

	const getLogs = async () => {
		const securityLogs = await getSecurityLogs(companyKey, page, size)

		if(securityLogs?.data) {
			setSecurityLogs(securityLogs.data)
		}
	}

	const exportLogs = async () => {
		const logs = await getSecurityLogs(companyKey, allData.page, allData.size)
		exportSecurityLogsToPDF(logs?.data.pageResults)
	}

	useEffect(() => {
		if (hasViewSecurityLogsPermission) {
			getLogs()
		}
	}, [page, size])

	return (
		hasViewSecurityLogsPermission ?
			<View style={styles.securityContainer}>
				<View style={styles.row}>
					<View style={styles.left}>
						<Text style={styles.title}>{ t('securityLogs') }</Text>
						<Text style={styles.description}>{ t('securityLogsDescription') }</Text>
					</View>
					<View style={styles.right}>
						<View style={styles.logs}>
							<TouchableOpacity style={styles.exportLogs} onPress={() => exportLogs()}>
								<Text style={styles.buttonText}>{ t('exportLogs') }</Text>
							</TouchableOpacity>
							<Table
								data={securityLogs.pageResults}
								columns={columns}
								isSmallScreen={false}
								totalRecords={securityLogs.totalResults}
								renderTableCell={renderTableCell}
								hasPagination
								page={page}
								setPage={setPage}
							/>
						</View>
					</View>
				</View>
				<Snackbar
					visible={snackbar.isVisible}
					onDismiss={() => setSnackbar(snackbarInitialState)}
					type={snackbar.type}
					message={snackbar.message}
				/>
			</View> : <></>
	)
}

