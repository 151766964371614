import React from 'react'
import { Text, View } from 'react-native'
import useScreenDimensions from '@components/Common/UseScreenDimensions'
import { AnimatedCircularProgress } from 'react-native-circular-progress'
import { casesTypes, screenType } from '@utils/constants'
import { colors } from '@styles/constants'
import { CasesBoxCase } from '@utils/interfaces'
import { capitalizeFirstLetter } from '@utils/utils'
import { useTranslation } from 'react-i18next'
import { ItemWithTooltip } from '@components/ItemWithTooltip'
import { boxCaseContainer, styles } from './styles'

interface CasesBoxProps {
    cases: CasesBoxCase[]
}

export const CasesBox = (props: CasesBoxProps) => {
	const { t } = useTranslation()
	const { cases } = props
	const screenDimensions = useScreenDimensions()
	const isSmallScreen = screenDimensions.width < screenType.underSmallLaptop
	let totalCases = 0
	let myCases = 0
	cases.forEach((item) => {
		if(item.type === casesTypes.newCases || item.type === casesTypes.openCases ){
			myCases += item.number as number
		}
		myCases += 0
		totalCases += item.number as number
	})

	const renderCases = (boxCase: CasesBoxCase, index: number) => {
		switch (boxCase.type) {
		case casesTypes.myCases:
			return (
				<View style={isSmallScreen ? boxCaseContainer(boxCase.type as keyof typeof casesTypes) : [boxCaseContainer(boxCase.type as keyof typeof casesTypes), styles.boxCaseContainerCustom]} key={index}>
					<View style={styles.containerText}>
						<View style={styles.boxCaseTypeContainer}>
							<Text style={styles.boxCaseTypeText}>{capitalizeFirstLetter(t(boxCase.type))}</Text>
							{boxCase.icon && 
							<ItemWithTooltip
								tooltip={t('myCasesTooltip')}
								iconName='lightbulb-o'
								transparentBackground={true}/>}
						</View>
						<Text style={styles.boxCaseNumberText}>{myCases} / {totalCases}</Text>
					</View>
					
					
					<AnimatedCircularProgress
						size={80}
						width={12}
						fill={myCases/totalCases * 100}
						tintColor={colors.main}
						backgroundColor={colors.lightGray} />
					
					
				</View>
			)
		default: 
			return (
				<View style={boxCaseContainer(boxCase.type as keyof typeof casesTypes)} key={index}>
					<View style={styles.boxCaseTypeContainer}>
						<Text style={styles.boxCaseTypeText}>{capitalizeFirstLetter(t(boxCase.type))}</Text>
						{boxCase.icon && boxCase.type === casesTypes.newCases ? 
							<ItemWithTooltip
								tooltip={t('newCasesTooltip')}
								iconName='lightbulb-o'
								transparentBackground={true}/>
							: boxCase.icon && boxCase.type === casesTypes.openCases ?
								<ItemWithTooltip
									tooltip={t('openCasesTooltip')}
									iconName='lightbulb-o'
									transparentBackground={true}/>
								: boxCase && boxCase.type === casesTypes.closedCases ?
									<ItemWithTooltip
										tooltip={t('closesCasesTooltip')}
										iconName='lightbulb-o'
										transparentBackground={true}/>
									: null
						}
					</View>
					<Text style={styles.boxCaseNumberText}>{boxCase.number}</Text>
				</View>
			)
		}
	}

	return (
		<View style={styles.container}>
			{cases.map((boxCase, index) => renderCases(boxCase, index))}
		</View>
	)
}
