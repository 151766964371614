import Layout from '@containers/Layout'
import React from 'react'
import { AdvisorSettings } from '@containers/AdvisorSettings'

export const AdvisorSettingsPage = () => {
	return (
		<Layout>
			<AdvisorSettings />
		</Layout>
	)
}