import React, { useEffect } from 'react'
import AppRouter from './src/containers/AppRouter'
import { I18nextProvider } from 'react-i18next'
import i18n from './assets/translation/i18n'
import { Provider } from 'react-redux'
import { persistor, store } from './src/redux/store'
import { PersistGate } from 'redux-persist/integration/react'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { ToastContainer } from 'react-toastify'
import { Crisp } from 'crisp-sdk-web'
import 'react-toastify/dist/ReactToastify.css'

export default function App() {
	
	Crisp.configure(process.env.APP_CRISP_ID as string, {
		autoload: false
	})

	return (
		<PersistGate persistor={persistor}>
			<SafeAreaProvider>
				<Provider store={store}>
					<I18nextProvider i18n={i18n}>
						<AppRouter />
						<ToastContainer />
					</I18nextProvider>
				</Provider>
			</SafeAreaProvider>
		</PersistGate>
	)
}
