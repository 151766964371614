import React from 'react'
import Layout from '@containers/Layout'
import { CompanyDashboard } from '@containers/CompanyDashboard/CompanyDashboard'

export const CompanyDashboardPage = () => {
	return (
		<Layout>
			<CompanyDashboard />
		</Layout>
	)
}
