import React, {FC} from 'react'
import { View, Text, ViewStyle, TextStyle, TouchableOpacity } from 'react-native'
import { Formik, FormikProps } from 'formik'
import { Input } from '@components/Input'
import { SelectDropDown } from '@components/SelectDropDown/SelectDropdown'
import { PhoneInput } from '@components/PhoneInput/PhoneInput'
import { DropdownValues, FormField, FormType, FormValues } from '@utils/interfaces'
import { loginSchema, registerCompanySchema } from '@utils/formValidation'
import { useTranslation } from 'react-i18next'
import { styles } from './styles'
import Checkbox from 'expo-checkbox'

interface FormProps {
	formType: FormType
	initialValues: FormValues
	onSubmit: (values: FormValues) => void
	submitButtonText: string;
	fields: FormField[];
	containerStyle?: ViewStyle
	labelStyle?: TextStyle
	inputStyle?: TextStyle
	onCancel?: () => void
    dropdownsValues?: DropdownValues[]
}

const Form: FC<FormProps> = ({ formType, initialValues, onSubmit, submitButtonText, fields, containerStyle, labelStyle, inputStyle, onCancel, dropdownsValues }) => {
	const validationSchema = formType === 'login' ? loginSchema : registerCompanySchema
	const { t } = useTranslation()
	
	const renderFields = (formikProps: FormikProps<FormValues>) => {
		return fields.map((field) => {
			const isSecureEntry = field.secureTextEntry || false
			const dropdownData = dropdownsValues?.find(dropdownValue => dropdownValue.fieldName === field.name)?.values ?? []
			const isLoginForm = field.name === 'email' || field.name === 'password'

			if (field.inputType === 'checkbox') {
				return (
					<View style={styles.checkboxField}>
						<Checkbox
							value={formikProps.values['isStateOwned']}
							onValueChange={(value) => formikProps.setFieldValue('isStateOwned',value)}
						/>
						<Text style={styles.label}>{t('fieldLabels.isStateOwned')}</Text>
					</View>
				)
			}

			return (
				<View key={field.name} style={formType === 'companyRegister' ? styles.fieldContainerRegister : styles.fieldContainer}>
					<Text style={[styles.label, labelStyle]}>{t(`fieldLabels.${field.label}`)}</Text>
					{field.inputType === 'textInput' && <Input
						name={field.name}
						onChange={formikProps.setFieldValue}
						onBlur={() => formikProps.setFieldTouched(field.name, true)}
						value={formikProps.values[field.name]}
						isSecureEntry={isSecureEntry}
						type={isLoginForm && field.name}
						editable
					/>}
					{field.inputType === 'selectDropdown' && <SelectDropDown
						data={dropdownData}
						fieldName={field.name}
						handleFormValueChange={formikProps.setFieldValue}
						onBlur={() => formikProps.setFieldTouched(field.name, true)}
						displayTranslatedValue={field.name === 'language'}
					/>}
					{field.inputType === 'phoneInput' && <PhoneInput
						name={{
							prefix: 'phonePrefix',
							number: 'phoneNumber',
						}}
						phone={{
							prefix: formikProps.values['phonePrefix'],
							number: formikProps.values['phoneNumber'],
						}}
						onChange={formikProps.setFieldValue}
						onBlur={formikProps.setFieldTouched}
					/>}
					{formikProps.touched[field.name] && formikProps.errors[field.name] && (
						<Text style={styles.errorText}>{`*${formikProps.errors[field.name]}`}</Text>
					)}
				</View>
			)
		})
	}

	return (
		<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
			{(formikProps) => (
				<>
					<View style={formType === 'companyRegister' ? styles.containerRegister : [styles.container, containerStyle]}>
						{renderFields(formikProps)}
					</View>
					<View style={formType === 'companyRegister' ? styles.buttonsContainer : [styles.buttonsContainer, styles.buttonsContainerLogin]}>
						{onCancel && <TouchableOpacity style={styles.cancelButton} onPress={() => onCancel()}>
							<Text style={styles.submitButtonText}>{t('cancel')}</Text>
						</TouchableOpacity>}
						<TouchableOpacity style={styles.submitButton} onPress={() => formikProps.handleSubmit()}>
							<Text style={styles.submitButtonText}>{submitButtonText}</Text>
						</TouchableOpacity>
					</View>
				</>
			)}
		</Formik>
	)
}



export default Form