import React, { useState } from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import { Input } from '@components/Input'
import { Loader } from '@components/Loader/Loader'
import { Snackbar } from '@components/Snackbar/Snackbar'
import { axiosBFFServer } from '@services/connectionServer'
import { loginClientSchema } from '@utils/formValidation'
import { LoginClientFormValues, SnackbarInitialState, SnackbarTypes } from '@utils/interfaces'
import { snackbarInitialState } from '@utils/constants'
import { colors } from '@styles/constants'
import { styles } from './styles'

export const LoginClientContainer = () => {
	const [ snackbar, setSnackbar ] = useState<SnackbarInitialState>(snackbarInitialState)
	const [ isLoading, setIsLoading ] = useState(false)
	const navigate = useNavigate()
	const { t } = useTranslation()

	const onSubmit = async (values: LoginClientFormValues) => {
		const { clientId, clientSecret } = values
		setIsLoading(true)

		await axiosBFFServer
			.post('/auth/client/login', {
				clientId,
				clientSecret
			})
			.then(response => {
				if(response.status === 201) {
					navigate('/legal-partner', { state: { success: true } })
				} else throw new Error()
			})
			.catch(error => {
				setIsLoading(false)
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: t('errors.loginClient'),
				})
			})
	}

	const initialValues: LoginClientFormValues = {
		clientId: '',
		clientSecret: ''
	}

	return (
		<View style={styles.container}>
			{isLoading && <View style={{ position:'absolute' }}>
				<Loader color={colors.white} />
			</View>}
			<View style={[
				styles.contentContainer,
				{...(isLoading && {
					opacity: 0,
				})}
			]}>
				<Text style={styles.title}>{t('loginClient')}</Text>
				<Formik
					initialValues={initialValues}
					validationSchema={loginClientSchema}
					onSubmit={onSubmit}
				>
					{(formikProps) => (
						<View style={styles.formContainer}>
							<View style={styles.field}>
								<Text style={styles.label}>{t('fieldLabels.clientId')}</Text>
								<Input
									name='clientId'
									value={formikProps.values['clientId']}
									onChange={formikProps.setFieldValue}
									onBlur={() => formikProps.setFieldTouched('clientId', true)}
								/>
								{formikProps.touched['clientId'] && formikProps.errors['clientId'] && (
									<Text style={styles.errorText}>{`*${formikProps.errors['clientId']}`}</Text>
								)}
							</View>
							<View style={styles.field}>
								<Text style={styles.label}>{t('fieldLabels.clientSecret')}</Text>
								<Input
									name='clientSecret'
									value={formikProps.values['clientSecret']}
									onChange={formikProps.setFieldValue}
									onBlur={() => formikProps.setFieldTouched('clientSecret', true)}
								/>
								{formikProps.touched['clientSecret'] && formikProps.errors['clientSecret'] && (
									<Text style={styles.errorText}>{`*${formikProps.errors['clientSecret']}`}</Text>
								)}
							</View>
							<TouchableOpacity style={styles.submitButton} onPress={() => formikProps.handleSubmit()}>
								<Text style={styles.submitButtonText}>{t('submit')}</Text>
							</TouchableOpacity>
						</View>
					)}
				</Formik>
			</View>
			<Snackbar
				visible={snackbar.isVisible}
				onDismiss={() => setSnackbar(snackbarInitialState)}
				type={snackbar.type}
				message={snackbar.message}
			/>
		</View>
	)
}