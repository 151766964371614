import React, { useEffect, useState } from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import Icon from 'react-native-vector-icons/FontAwesome'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import useScreenDimensions from '@components/Common/UseScreenDimensions'
import { Category } from '@components/Category/Category'
import { CategoryModal } from '@components/CategoryModal/CategoryModal'
import { Snackbar } from '@components/Snackbar/Snackbar'
import { axiosBFFServer } from '@services/connectionServer'
import { rootReducerType } from '@reducers/combineReducers'
import { CategoryFormValues, CategoryType, SnackbarInitialState, SnackbarTypes } from '@utils/interfaces'
import { screenType, snackbarInitialState } from '@utils/constants'
import { colors, theme } from '@styles/constants'
import { styles } from './style'
import { Loader } from '@components/Loader/Loader'
import { checkIfPermissionExist } from '@utils/utils'
import { UseErrorMessages } from '@components/Common/UseErrorMessage'

export const Categories = () => {
	const [ categories, setCategories ] = useState<CategoryType[]>([])
	const [ isLoading, setIsLoading ] = useState(true)
	const [ snackbar, setSnackbar ] = useState<SnackbarInitialState>(snackbarInitialState)
	const [ showInactive, setShowInactive ] = useState(false)
	const [ categoryModal, setCategoryModal ] = useState<{
        isVisible: boolean
        category?: CategoryType
    }>({ isVisible: false })
	const { t } = useTranslation()
	const screenDimensions = useScreenDimensions()
	const isMobileScreen = screenDimensions.width < screenType.phone
	const { getErrorMessage } = UseErrorMessages()
	
	const companyKey = useSelector((state: rootReducerType) => state.companyReducer.key)
	const { language: companyLanguage } = useSelector((state: rootReducerType) => state.companyReducer.settings)
	const userPermissions = useSelector((state: rootReducerType) => state.userReducer.permissions)
	const { language } = useSelector((state: rootReducerType) => state.userReducer) 

	const activeCategories = categories.filter(category => category.isActive)
	const inactiveCategories = categories.filter(category => !category.isActive)

	const getCategories = async () => {
		axiosBFFServer
			.get(`/category/all/${companyKey}`)
			.then(response => {
				setCategories(response.data)
				setIsLoading(false)
			})
			.catch(error => {
				const errorMessage = getErrorMessage(error, t('errors.listCategories'))
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
        
		setShowInactive(false)
	}

	const createCategory = async (reqBody) => {
		axiosBFFServer
			.post('/category', {
				...reqBody,
				companyLanguage,
			})
			.then(response => {
				if(response.status === 201){
					setSnackbar({
						isVisible: true,
						type: SnackbarTypes.SUCCESS,
						message: t('success.createCategory')
					})
					getCategories()
				}
			})
			.catch(error => {
				const errorMessage =  error.response.data.customStatus === '609' ? t('categAlreadyUsed') + ' ' +  getErrorMessage(error, '') : getErrorMessage(error, t('errors.createCategory'))
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}

	const editCategory = async (reqBody, categoryKey) => {
		axiosBFFServer
			.patch(`/category/${categoryKey}`, reqBody)
			.then(response => {
				if(response.status === 200) {
					setSnackbar({
						isVisible: true,
						type: SnackbarTypes.SUCCESS,
						message: t('success.editCategory')
					})
					getCategories()  
				}
			})
			.catch(error => {
				const errorMessage = getErrorMessage(error, t('errors.editCategory'))
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}

	const handleSave = async (values: CategoryFormValues) => {
		if(categoryModal.category) {
			const { categoryKey, value } = categoryModal.category
			const body = {
				companyKey,
				...( value !== values.value && {value: values.value}),
				isActive: values.isActive,
			}

			await editCategory(body, categoryKey)
		} else {
			const body = {
				companyKey,
				...values,
			}
			await createCategory(body)
		}

		setCategoryModal({ isVisible: false })
	}

	useEffect(() => {
		getCategories()
	}, [language])

	return (
		<View style={styles.container}>
			<View style={[styles.headerContainer, isMobileScreen && styles.headerContainer_mobile]}>
				<Text style={styles.headerText}>{t('categories')}</Text>
				{
					checkIfPermissionExist(userPermissions,'category','add') &&
					<TouchableOpacity
						onPress={() => setCategoryModal({ isVisible: true })}
						style={styles.button}
					>
						<Icon color={colors.white} name='plus' size={18} />
						<Text style={styles.buttonText}>{t('createCategory')}</Text>
					</TouchableOpacity>
				}
			</View>
			{isLoading
				? <Loader color={colors.white} />
				: !categories.length
					? <Text style={styles.noDataText}>{t('noCategoriesAvailable')}</Text>
					: <>
						<View>
							{activeCategories.map((category, index) => (
								<Category
									key={`a-${index}`}
									category={category}
									openModal={() => setCategoryModal({ isVisible: true, category })}
									editCategory={checkIfPermissionExist(userPermissions,'category','update')}
								/>
							))}
						</View>
						{!!inactiveCategories.length && <View style={styles.showButtonContainer}>
							<TouchableOpacity
								onPress={() => setShowInactive(showInactive => !showInactive)}
								style={[styles.button, { maxWidth: 260 }]}
							>
								<Text style={styles.buttonText}>
									{showInactive ? t('hideInactiveCategories') : t('showInactiveCategories')}
								</Text>
							</TouchableOpacity>
						</View>}
						{showInactive && <View>
							{inactiveCategories.map((category, index) => (
								<Category
									key={`i-${index}`}
									category={category}
									openModal={() => setCategoryModal({ isVisible: true, category })}
									editCategory={checkIfPermissionExist(userPermissions,'category','update')}
								/>
							))}
						</View>}
					</>
			}

			{categoryModal.isVisible && <CategoryModal
				isVisible={categoryModal.isVisible}
				onClose={() => setCategoryModal({ isVisible: false })}
				onSave={handleSave}
				category={categoryModal.category}
			/>}
            
			<Snackbar
				visible={snackbar.isVisible}
				onDismiss={() => setSnackbar(snackbarInitialState)}
				type={snackbar.type}
				message={snackbar.message}
			/>
		</View>
	)
}


