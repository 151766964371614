import { UserChangePassword, VerificationCodeMPFormValues } from '@utils/interfaces'
import { axiosBFFServer, axiosBFFServerFormData } from './connectionServer'
import localStorage from 'redux-persist/es/storage'
import axios from 'axios'

export const userForgotPassword = async (email: string) => {
	try {
		return await axiosBFFServer.post(`/auth/forgotPassword?email=${email}`)
	} catch(error) {
		console.error(error)
	}
}

export const userChangePassword = async (newPasswordBody: UserChangePassword) => {
	return await axiosBFFServer.put('/auth/changePassword', newPasswordBody)
}

export const verifyRecaptcha = async (recaptchaToken: string) => {
	try {
		return await axiosBFFServer.post('/auth/verifyRecaptcha', {recaptchaToken: recaptchaToken})
	} catch(error) {
		console.log(error)
	}
}

export const getCurrentCompany = async () => {
	try {
		return await axiosBFFServer.get('/company/current')
	} catch(error) {
		console.error(error)
	}
}

export const updateCompanyUser = async (companyUser, companyUserKey, companyKey) => {
	try {
		return await axiosBFFServer.patch(`/company-user/${companyUserKey}?companyKey=${companyKey}`, companyUser)
	} catch(error) {
		console.error(error)
	}
}

export const updatePartner = async (legalPartner, legalPartnerKey) => {
	try {
		return await axiosBFFServer.patch(`/partner/${legalPartnerKey}`, legalPartner)
	} catch(error) {
		console.error(error)
	}
}

export const updateAdvisor = async (advisor, advisorKey) => {
	try {
		return await axiosBFFServer.patch(`/advisor/${advisorKey}`, advisor)
	} catch(error) {
		console.error(error)
	}
}

export const getCaseByKey = async (key: string) => {
	try {
		return await axiosBFFServer.get(`/case/${key}`)
	} catch(error) {
		console.error(error)
	}
}

export const getCompanyByKey = async (key: string) => {
	try {
		return await axiosBFFServer.get(`/company/${key}`)
	} catch(error) {
		console.error(error)
	}
}

export const getUserNotificationSettings = async () => {
	try {
		return await axiosBFFServer.get('/settings/notifications/all')
	} catch(error) {
		console.error(error)
	}
}

export const getUserSettings = async (key: string, language?: string) => {
	try {
		const headers = {
			'Accept-Language': language || await localStorage.getItem('i18nextLng')
		}
		return await axiosBFFServer.get(`/settings/user/${key}`, { headers })
	} catch(error) {
		return error	
	}
}

export const updateUserSettings = async (settings, userKey) => {
	try {
		return await axiosBFFServer.put('/settings/user', { userKey, notificationSettingsRequest: { notificationSettings: settings }})
	} catch(error) {
		console.error(error)
	}
}

export const getCompanyUserByEmail = async (email,companyKey) => {
	try {
		return await axiosBFFServer.get(`/company-user/email/${email}?companyKey=${companyKey}`)
	} catch(error) {
		console.error(error)
	}
}

export const getLegalPartner = async (key) => {
	try {
		return await axiosBFFServer.get(`/partner/${key}`)
	} catch(error) {
		console.error(error)
	}
}

export const getLegalPartnerByLink = async (link) => {
	try {
		return await axiosBFFServer.get(`/partner/link/${link}`)
	} catch(error) {
		console.error(error)
	}
}

export const updateCompany = async (key, updatedCompany) => {
	try {
		return await axiosBFFServer.patch(`/company/${key}`, updatedCompany)
	} catch(error) {
		console.error(error)
	}
}

export const createLog = async (securityLog, shouldDeleteCookie) => {
	try {
		return await axiosBFFServer.post(`/settings/security-log?shouldDeleteCookie=${shouldDeleteCookie}`, securityLog)
	} catch(error) {
		console.error(error)
	}
}

export const getStripeSession = async () => {
	try {
		return await axiosBFFServer.post('subscription/session',{
			returnUrl: `${process.env.REACT_NATIVE_APP_BASE_URL}/company/settings/subscription`
		})
	} catch(error) {
		console.error(error)
	}
}

export const retrievesStripeSession = async (sessionId: string) => {
	try {
		return await axiosBFFServer.post(`subscription/session/${sessionId}`)
	} catch(error) {
		console.error(error)
	}
}

export const cancelStripeSessionLink = async (sessionId: string) => {
	try {
		return await axiosBFFServer.post(`subscription/expire-session/${sessionId}`)
	} catch(error) {
		console.error(error)
	}
}

export const getSecurityLogs = async (companyKey: string, page: number, size: number) => {
	try {
		return await axiosBFFServer.get(`settings/security-log/${companyKey}?page=${page}&size=${size}`)
	} catch(error) {
		console.error(error)
	}
}

export const getAllCompaniesForPartner = async () => {
	try {
		return await axiosBFFServer.post('/company/search?page=1&size=100')
	} catch(error) {
		console.error(error)
	}
}

export const uploadFile = async (uploadUrl, fileDataToSend) => {
	return await axiosBFFServerFormData.post(uploadUrl, fileDataToSend)
}

export const sendMsg = async (text, documentKeys, caseKey) => {
	return axiosBFFServer
		.post(`/chat/${caseKey}`, {
			text,
			documentKeys
		})
}

export const getCompanySettings = async (key: string) => {
	try {
		return await axiosBFFServer.get(`/settings/firm/company/${key}`)
	} catch(error) {
		console.error(error)
	}
}

export const getPartnerSettings = async (key: string) => {
	try {
		return await axiosBFFServer.get(`/settings/firm/partner/${key}`)
	} catch(error) {
		console.error(error)
	}
}

export const updateFirmSettings = async (firmKey, language, isPartner) => {
	try {
		return await axiosBFFServer.put('/settings/firm', { firmKey, language, isPartner })
	} catch(error) {
		console.error(error)
	}
}

export const getCompanyDetails = async (vatNumber: string) => {
	return await axiosBFFServer.get(`/company/search-company-details/${vatNumber}`)

}

export const userForgotMPSendCode = async (email: string) => {
	try {
		return await axiosBFFServer.post(`/auth/sendVerificationCodeMP?email=${email}`)
	} catch(error) {
		console.error(error)
	}
}

export const verifyCodeMP = async (verifyCodeMPBody: VerificationCodeMPFormValues) => {
	try {
		return await axiosBFFServer.post('/auth/verifyCodeMP', verifyCodeMPBody)
	} catch(error) {
		console.error(error)
	}
}

export const getNotificationHistory = async (userKey: string) => {
	return await axiosBFFServer.get(`/account/notifications/${userKey}`)
}

export const readNotification = async (notificationKey: string, userKey: string) => {
	return await axiosBFFServer.patch(`/account/notifications/read/${userKey}/${notificationKey}`)
}

export const getInvoiceFile = async (invoiceKey: string) => {
	const bffUrl = process.env.BFF_URL
	return await axios.post(`${bffUrl}/subscription/payment/${invoiceKey}`, {}, {
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/pdf',
		},
		withCredentials: true,
		responseType: 'arraybuffer'
	})
}