import { TextInput } from 'react-native'
import { CreateCompanyValues } from '@utils/interfaces'

interface Props {
	placeholder?: string;
	value?: string;
	name: string;
	type?: 'email' | 'password';
	multiline?: boolean;
	error?: boolean;
	onChange: (name: string |  keyof CreateCompanyValues, value: string | string[]) => void;
    onBlur?: () => void;
    isSecureEntry?: boolean;
	editable?: boolean;
	noBorder?: boolean;
}

export const Input = (props : Props) => {
	return (
		<TextInput
			placeholder={props.placeholder}
			style={[props.error ? styles.formFieldText_error : props.multiline ? styles.formFieldText_large : styles.formFieldText, props.noBorder && styles.noBorder, props.editable === false && styles.disabled]}
			multiline={props.multiline ? props.multiline : false}
			onChange={(event) => props.onChange(props.name,event.nativeEvent.text)}
			onBlur={props.onBlur}
			value={props.value}
			secureTextEntry={props.isSecureEntry}
			autoComplete={props.type}
			editable={props.editable !== undefined ? props.editable : true}
		/>
	)
}

import { StyleSheet } from 'react-native'
import { theme } from '@styles/constants'

export const styles = StyleSheet.create({
	formFieldText: {
		fontSize: 16,
		borderRadius: 4,
		borderWidth: 1,
		padding: 6,
		backgroundColor: theme.light.colors.linkColorActive,
		width: '100%',
		outlineStyle: 'none',
		maxHeight: 50,
		color: theme.light.colors.mainColor,
	},
	formFieldText_large: {
		fontSize: 16,
		borderRadius: 4,
		borderWidth: 1,
		height: 170,
		padding: 12,
		backgroundColor: theme.light.colors.linkColorActive,
		width: '100%',
		outlineStyle: 'none'
	},
	formFieldText_error: {
		fontSize: 16,
		borderRadius: 4,
		borderWidth: 1,
		borderColor: theme.light.colors.danger,
		padding: 12,
		backgroundColor: theme.light.colors.linkColorActive,
		width: '100%'
	},
	noBorder: {
		borderWidth: 0	
	},
	disabled: {
		opacity: 0.7,
		borderWidth: 0.5,
		cursor: 'arrow'
	}
})
