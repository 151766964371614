import { colors, theme } from '@styles/constants'
import { button } from '@styles/mixins/button'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
	container: {
		gap: 16,
	},
	labelText: {
		fontSize: 16,
		paddingLeft: 6,
	},
	inputContainer: {
		width: 160,
	},
	buttonContainer: {
		alignItems: 'flex-end',
	},
	button: {
		...({
			...button(theme.light.colors.primaryDark).button,
		} as object),
		width: 180,
		paddingVertical: 10,
		paddingHorizontal: 20,
		justifyContent: 'center',
		borderRadius: 6,
	},
	buttonText: {
		color: colors.pureWhite,
		fontWeight: 'bold',
	},
})
