
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'react-native'
import { useSelector } from 'react-redux'
import { CompanyInfoForm } from '@components/Settings/companyInfo/CompanyInformationForm/CompanyInfoForm'
import { FirmSettingForm } from '@components/Settings/companyInfo/FirmSettingsForm.tsx/FirmSettingsForm'
import { Snackbar } from '@components/Snackbar/Snackbar'
import { rootReducerType } from '@reducers/combineReducers'
import { SnackbarInitialState } from '@utils/interfaces'
import { checkIfPermissionExist } from '@utils/utils'
import { snackbarInitialState } from '@utils/constants'
import { styles } from './styles'

export const CompanyInformation: React.FC<{ isCompany?: boolean }> = ({ isCompany }) => {
	const [ snackbar, setSnackbar ] = useState<SnackbarInitialState>(snackbarInitialState)
	const { t } = useTranslation()
	const { permissions } = useSelector((state: rootReducerType) => state.userReducer)
	const hasViewFirmSettingsPermission = checkIfPermissionExist(permissions, 'firmSettings', 'view')

	return (
		<View style={styles.companyInfo}>
			<View style={styles.row}>
				<View style={styles.left}>
					<Text style={styles.title}>{ t('companyInfo') }</Text>
					<Text style={styles.description}>{ t('companyInfoDescription') }</Text>
				</View>
				<View style={styles.right}>
					<CompanyInfoForm 
						isCompany={isCompany || false}
						setSnackbar={setSnackbar}
					/>
				</View>
			</View>

			{hasViewFirmSettingsPermission && <>
				<View style={styles.separator}></View>
				<View style={styles.row}>
					<View style={styles.left}>
						<Text style={styles.title}>{t('companyOptions')}</Text>
						<Text style={styles.description}>{t('companyOptionsDescription')}</Text>
					</View>
					<View style={styles.right}>
						<FirmSettingForm 
							isCompany={isCompany || false}
							setSnackbar={setSnackbar}
						/>
					</View>
				</View>
			</>}

			<Snackbar
				visible={snackbar.isVisible}
				onDismiss={() => setSnackbar(snackbarInitialState)}
				type={snackbar.type}
				message={snackbar.message}
			/>
		</View>
	)
}

