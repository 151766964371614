import { NotificationComponent } from '@components/Notifications/NotificationsComponent'
import { isCompanyAdminOrUser } from '@utils/utils'
import React from 'react'
import { Text, View } from 'react-native'
import { styles } from './styles'
import { useNavigate } from 'react-router-dom'
import { Notification, ViewTypes } from '@utils/interfaces'
import { rootReducerType } from '@reducers/combineReducers'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

export const NotificationContainer = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const { roles } = useSelector((state: rootReducerType) => state.userReducer)
	const isCompany = isCompanyAdminOrUser(roles)
	const notifications = useSelector((state: rootReducerType) => state.notificationReducer.notifications).sort((a, b) => {
		const timestampA = new Date(a.timestamp).getTime()
		const timestampB = new Date(b.timestamp).getTime()
		return timestampB - timestampA
	})

	const handleNotificationPress = (notification: Notification) => {
		navigate(isCompany ? `/${ViewTypes.COMPANY}/notifications/${notification.notificationHistoryKey}` : `/${ViewTypes.PARTNER}/notifications/${notification.notificationHistoryKey}`)
	}

	return (
		<View style={styles.container}>
			{
				notifications.length > 0 ?
					notifications.map(notification => (
						<NotificationComponent
							key={notification.notificationHistoryKey}
							notification={notification}
							onPress={handleNotificationPress}
						/>
					)) : 
					<View style={styles.noNotificationsContainer}>
						<Text style={styles.noNotificationsText}>{t('noNotifications')}</Text>
					</View>
			}
		</View>
	)
}
