import React, { ReactNode, FC } from 'react'
import { View, Text, Image  } from 'react-native'
import { useTranslation } from 'react-i18next'
import { styles } from './styles'

interface LayoutProps {
	children: ReactNode
}

export const ReportLayout: FC<LayoutProps> = ({ children }) => {
	const { t } = useTranslation()

	return (
		<View style={styles.container}>
			<View style={styles.header}>
				<Image
					source={require('@assets/Logo.webp')}
					style={styles.logo}
				/>
				<Text style={styles.headerText}>{t('appName')}</Text>
			</View>
			<View style={styles.content}>{children}</View>
		</View>
	)
}
