import { rootReducerType } from '@reducers/combineReducers'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { isPartner } from './utils'
import { Crisp } from 'crisp-sdk-web'

const CrispChat: React.FC = () => {
	const user = useSelector((state: rootReducerType) => state.userReducer)
	const companyName = isPartner(user.roles) ? useSelector((state:rootReducerType) => state.legalPartnerKeyReducer.name) : useSelector((state:rootReducerType) => state.companyReducer.currentCompany.name)
	const fullNAme = `${user.firstName} ${user.lastName}` 
	const sessionId = useSelector((state: rootReducerType) => state.userReducer.key)
	const { status } = useSelector((state: rootReducerType) => state.companyReducer.currentCompany)

	useEffect(() => {
		if (user.email) {
			Crisp.setTokenId(sessionId)
			Crisp.user.setEmail(user.email)
			Crisp.user.setNickname(fullNAme)
			Crisp.user.setPhone(user.phone)
			Crisp.user.setCompany(companyName, {})
			Crisp.load()
			Crisp.chat.show()	
			if (status === 'PAYMENT_DETAILS_REQUIRED') Crisp.chat.close()
		}
	
		return () => {
			Crisp.chat.hide()
		}
	}, [sessionId])

	return null
}
export default CrispChat