import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Text, TouchableOpacity, View, TextInput } from 'react-native'
import { Snackbar } from '@components/Snackbar/Snackbar'
import { colors } from '@styles/constants'
import { useTranslation } from 'react-i18next'
import * as Clipboard from 'expo-clipboard'
import Icon from 'react-native-vector-icons/FontAwesome'
import Checkbox from 'expo-checkbox'
import { useSelector } from 'react-redux'
import { rootReducerType } from '@reducers/combineReducers'
import { SnackbarInitialState, SnackbarTypes, ViewTypes } from '@utils/interfaces'
import { screenType, snackbarInitialState } from '@utils/constants'
import { downloadMasterPasswordPDF } from '@utils/utils'
import { styles } from './style'
import useScreenDimensions from '@components/Common/UseScreenDimensions'

export const SubmittedReport = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const location = useLocation()
	const screenDimensions = useScreenDimensions()
	const isMobileScreen = screenDimensions.width < screenType.phone
	const [ snackbar, setSnackbar ] = useState<SnackbarInitialState>(snackbarInitialState)
	const [isChecked, setIsChecked] = useState(false)
	const [errorPasswordConfirmation, setErrorPasswordConfirmation] = useState(false)
    
	const passwordCase = useSelector((state: rootReducerType) => state.masterPasswordReducer.passwordToSave)
	const isAdvisorNewCase = useSelector((state: rootReducerType) => state.setCaseKeyReducer.isAdvisorNewCase)
	const { reportLink, name } = useSelector((state: rootReducerType) => state.companyReducer.currentCompany)

	const copyToClipboard = async () => {
		await Clipboard.setStringAsync(inputValues.password)
	}

	const [inputValues, setInputValues] = useState({
		password: passwordCase,
		email: ''
	})

	const submitForm = () => {
		if (isChecked){
			isAdvisorNewCase ? navigate(`/${ViewTypes.COMPANY}/cases`) : navigate(`/${ViewTypes.REPORT}/view`)
		} else {
			setErrorPasswordConfirmation(true)
		}
	}

	useEffect(() => {
		isChecked && setErrorPasswordConfirmation(false)
	}, [isChecked])

	useEffect(() => {
		!!location.state?.errorMessage && setSnackbar({
			isVisible: true,
			type: SnackbarTypes.ERROR,
			message: location.state.errorMessage
		})					
	}, [])

	const downloadMasterPassword = () => {
		downloadMasterPasswordPDF(passwordCase.replace(':', ': '), name, `${process.env.REACT_NATIVE_APP_BASE_URL}/report/${reportLink}`)
	}
    
	return (
		<View style={styles.container}>
			<View style={isMobileScreen ? [styles.contentContainer, styles.contentContainer_mobile] : styles.contentContainer}>
				<Text style={isMobileScreen ? styles.title_mobile : styles.title}>{t('submittedReportTitle')}</Text>
				<View style={isMobileScreen ? styles.textContainer_mobile : styles.textContainer}>
					<Text style={styles.errorText}>{t('important')}</Text>
					<Text style={styles.labelText}>{t('submittedReportImportantMessage')}</Text>
				</View>
				<View style={styles.sectionContainer}>
					<Text style={styles.labelTextBold}>{t('savePasswordMessage')}</Text>
					<View style={isMobileScreen ? styles.inputContainer_mobile : styles.inputContainer}>
						<TextInput
							style={styles.formFieldText}
							value={inputValues.password}
							editable={false}
						/>
						<View style={isMobileScreen ? styles.bttnsContainer_mobile : styles.bttnsContainer}>
							<TouchableOpacity 
								style={styles.button}
								onPress={copyToClipboard}>
								<Icon name='clipboard' size={18} color={colors.white} />
							</TouchableOpacity>			
							<TouchableOpacity 
								style={[styles.button, styles.downloadButton]}
								onPress={() => downloadMasterPassword()}>
								<Icon style={styles.icon} name='download' size={18} />
							</TouchableOpacity>
						</View>
					</View>
				</View>
				<View style={styles.sectionContainer}>
					<View style={styles.checkBoxContainer}>
						<Checkbox 
							value={isChecked} 
							onValueChange={setIsChecked}
							color={isChecked ? colors.main : errorPasswordConfirmation ? colors.error : colors.darkAccent} />
						<Text style={styles.labelText}>{t('storedPassword')}</Text>	
					</View>
					{errorPasswordConfirmation && (
						<Text style={styles.errorText}>{t('storedPasswordError')}</Text>
					)}
				</View>
				<TouchableOpacity 
					style={styles.buttonSubmitt}
					onPress={submitForm}>
					<Icon style={styles.icon} name='check' size={18} />
					<Text style={styles.buttonText}>{t('continue')}</Text>
				</TouchableOpacity>
			</View>
			<Snackbar
				visible={snackbar.isVisible}
				onDismiss={() => setSnackbar(snackbarInitialState)}
				type={snackbar.type}
				message={snackbar.message}
			/>
		</View>
	)
}

