import { colors, theme } from '@styles/constants'
import { button } from '@styles/mixins/button'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
	modalContainer: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: theme.light.colors.appContainer,
	},
	contentContainer: {
		width: '90%',
		maxWidth: 600,
		padding: 32,
		borderRadius: 30,
		backgroundColor: theme.light.colors.mainContainer,
		gap: 24,
	},
	title: {
		fontSize: 24,
		fontWeight: 'bold',
		color: theme.light.colors.titleColor,
		marginBottom: 8,
	},
	textContainer: {
		gap: 12,
		textAlign: 'justify',
	},
	sectionConteiner: {
		gap: 8,
	},
	errorText: {
		color: colors.error,
	},
	errorBoldText: {
		fontWeight: '500',
		color: colors.error,
		textTransform: 'uppercase',
		fontSize: 16,
	},
	boldText: {
		fontSize: 16,
		fontWeight: '500',
	},
	inputContainer: {
		flexDirection: 'row',
		gap: 16,
		justifyContent: 'space-between'
	},
	formFieldText: {
		fontSize: 16,
		borderRadius: 4,
		borderWidth: 1,
		borderColor: colors.darkAccent,
		padding: 6,
		backgroundColor: colors.white,
		width: '100%',
		outlineStyle: 'none',
		color: theme.light.colors.mainColor,
	},
	copyButton: {
		...({
			...button(theme.light.colors.successDark).button,
		} as object),
		borderRadius: 6,
		paddingVertical: 10,
		paddingHorizontal: 20,
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
	},
	checkBoxContainer: {
		flexDirection: 'row',
		gap: 16,
		alignItems: 'center',
	},
	button: {
		width: 160,
		alignSelf: 'center',
		...({
			...button(theme.light.colors.primaryDark).button,
		} as object),
		borderRadius: 6,
		justifyContent: 'center',
		paddingVertical: 10,
	},
	buttonText: {
		fontSize: 16,
		fontWeight: 'bold',
		color: colors.pureWhite,
		textAlign: 'center',
	},
	icon: {
		color: colors.white
	},
	download: {
		backgroundColor: theme.light.colors.dangerDark,
	}
}) 