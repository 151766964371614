import React, {useEffect, useState} from 'react'
import { View, Text, TouchableOpacity, Linking } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { colors } from '@styles/constants'
import { axiosBFFServer } from '@services/connectionServer'
import { Loader } from '@components/Loader/Loader'
import { Snackbar } from '@components/Snackbar/Snackbar'
import { CardDetails, SnackbarInitialState, SnackbarTypes, Subscriptions, details, subscriptionPlans } from '@utils/interfaces'
import { snackbarInitialState } from '@utils/constants'
import { GeneralModal } from '@components/Modal/Modal'
import { rootReducerType } from '@reducers/combineReducers'
import { useLocation } from 'react-router-dom'
import { cancelStripeSessionLink, getStripeSession, retrievesStripeSession } from '@services/apiService'
import { useSearchParams } from 'react-router-dom'
import { setIsPaymentNeeded, setPaymentType, setSubscriptionToActivate } from '@actions/paymentActions'
import { patchCompany, setCurrentCompany } from '@actions/companyActions'
import { SubscriptionPlans } from '../SubscriptionPlanItem/SubscriptionPlanItem'
import { styles } from './styles'
import { checkIfPermissionExist, isTodayBeforeDate } from '@utils/utils'
import { setLoader } from '@actions/loaderActions'
import { PaymentOptionsModal } from '@components/PaymentOptionsModal/PaymentOptionsModal'
import { UseErrorMessages } from '@components/Common/UseErrorMessage'

export const Subscription = () => {
	const { t } = useTranslation()
	const location = useLocation()
	const sessionID = location.search
	const dispatch = useDispatch()
	const { getErrorMessage } = UseErrorMessages()

	const { companyKey, status: companyStatus, subscription: currentSubscription } = useSelector((state: rootReducerType) => state.companyReducer.currentCompany)
	const freeTrial = useSelector((state: rootReducerType) => state.companyReducer.currentCompany.status) === 'FREE_TRIAL' ? true : false
	const userPermissions = useSelector((state: rootReducerType) => state.userReducer.permissions)
	const subscriptionToActivate = useSelector((state: rootReducerType) => state.paymentReducer.subscriptionToActivate)
	const { isPaymentNeeded, paymentType } = useSelector((state: rootReducerType) => state.paymentReducer)
	const hasSubscriptionEditPermission = checkIfPermissionExist(userPermissions,'subscription','update')
	const language = useSelector((state: rootReducerType) => state.userReducer.language)
	const isStateOwned = useSelector((state: rootReducerType) => state.companyReducer.currentCompany.isStateOwned)

	const [searchParams, setSearchParams] = useSearchParams()
	const [isLoading, setIsLoading] = useState(true)
	const [subscriptionData, setSubscriptionData] = useState<Subscriptions>({
		subscriptionKey: '',
		subscriptionType: '',
		price: 0,
		lastPaymentDate: '',
		nextPaymentDate: '',
		cancelled: true,
		paymentType: ''
	})
	const [cardDetails, setCardDetails] = useState<CardDetails | null>(null)
	const [subscriptionDetails, setSubscriptionDetails] = useState<details[]>([
		{key: '', title: ''}
	])
	const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] = useState(false)
	const [showInfoModal, setShowInfoModal] = useState(false)

	const [subscriptionDoesNotExist, setSubscriptionDoesNotExist] = useState(false)
	const [showModalEditPlan, setShowModalEditPlan] = useState({visible: false, paymentType: ''})
	const [snackbar, setSnackbar] = useState<SnackbarInitialState>(snackbarInitialState)
	const [subscriptions, setSubscriptions] = useState<subscriptionPlans[]>([{
		subscriptionType: '',
		price: 0
	}])
	const [subscriptionPlan, setSubscriptionPlan] = useState<subscriptionPlans>({subscriptionType: '', price: 0})
	const [freeTrialActive, setFreeTrialActive] = useState(false)
	const [isOpen, setIsOpen] = useState(false)

	const confirmPaymentOption = (paymentOption: string) => {
		handlePayment(paymentOption)
	} 

	const getSessionLink = async () => {
		dispatch(setLoader(true))
		const session = await getStripeSession()
		const sessionLink = session?.data.sessionUrl
		if (sessionLink){
			global.location.replace(sessionLink)
		} 
	}

	const handleNavigation = () => {
		Linking.openURL(`${process.env.WEBSITE_URL}/en/contact`)
	}

	const checkForCustomPlan = (subscriptions) => {
		if (subscriptions && subscriptions.length > 0) {
			const middleYearly = [...subscriptions.slice(0, subscriptions.length / 2), subscriptions[subscriptions.length - 1], ...subscriptions.slice(subscriptions.length / 2, subscriptions.length - 1)]
			setSubscriptions(middleYearly)
		}
		else {
			setSubscriptions([{ subscriptionType: 'unavailable', price: 0 }])
		}
	}

	const getSubscriptionDetails = () => {
		axiosBFFServer
			.get(`/company/${companyKey}`)
			.then(response => {
				getCardDetails()
				if (response && response.data.subscription){
					setSubscriptionDoesNotExist(false)
					setSubscriptionData(response.data.subscription)
					dispatch(setCurrentCompany(response.data))
					setIsPaymentNeeded(false)
					if (response.data.subscription.lastPaymentDate === null){
						setFreeTrialActive(true)
					}
				} else setSubscriptionDoesNotExist(true)
					
			})
			.catch(error => {
				const errorMessage = getErrorMessage(error, t('errorSubscriptionData'))
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}

	const getCardDetails = () => {
		axiosBFFServer
			.get('/subscription/payment/card')
			.then(response => {
				setIsLoading(false)
				if (response.status === 200){
					setCardDetails(response.data)
				} else setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: t('errors.cardDetails')
				})
			})
			.catch(error => {
				const errorMessage = getErrorMessage(error, t('errors.cardDetails'))
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
				setIsLoading(false)
			})
	}

	const getSubscriptionPrices = () => {
		axiosBFFServer
			.get(`/subscription/payment/prices/${companyKey}`)
			.then(response => {
				if (response.status === 200){
					const subscriptions = response.data
					checkForCustomPlan(subscriptions)
				} else setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: t('errors.subscriptionTypes')
				})
			})
			.catch(error => {
				const errorMessage = getErrorMessage(error, t('errors.subscriptionTypes'))
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}

	const activateSubscription = ({ subscriptionType, price }, paymentType: string) => {
		const handleActivate = () => {
			axiosBFFServer
				.post('subscription/payment/activate', {
					subscriptionType,
					companyKey,
					price,
					paymentType
				})
				.then((response) => {
					if (response.status === 201) {
						dispatch(setIsPaymentNeeded(false))
						setSnackbar({
							isVisible: true,
							type: SnackbarTypes.SUCCESS,
							message: isTodayBeforeDate(response.data?.nextPaymentDate) ? t('activeSubscription') : t('paymentOrderActivation')
						})
						getSubscriptionDetails()
					} else {
						getSubscriptionDetails()
						setSnackbar({
							isVisible: true,
							type: SnackbarTypes.ERROR,
							message: t('errors.activateSubscription')
						})
					}
				})
				.catch((error) => {
					const errorMessage = getErrorMessage(error, t('errors.activateSubscription'))
					setSnackbar({
						isVisible: true,
						type: SnackbarTypes.ERROR,
						message: errorMessage
					})
				})
		}
	
		if (currentSubscription && ((currentSubscription?.paymentType && currentSubscription?.paymentType !== paymentType) || currentSubscription?.subscriptionType !== subscriptionType)) {
			editSubscription({ subscriptionType, price }, paymentType, handleActivate)
		} else {
			handleActivate()
		}
	}
	
	const editSubscription = (
		{ subscriptionType, price },
		paymentType: string,
		onSuccess?: () => void,
	) => {
		setShowModalEditPlan({ visible: false, paymentType: '' })
		setIsLoading(true)
	
		axiosBFFServer
			.patch('subscription/update', {
				subscriptionType,
				companyKey,
				price,
				paymentType
			})
			.then((response) => {
				setIsLoading(false)
				if (response.status === 200) {
					setSnackbar({
						isVisible: true,
						type: SnackbarTypes.SUCCESS,
						message: t('updateSubscription')
					})
					onSuccess && onSuccess()
					getSubscriptionDetails()
				} else {
					setSnackbar({
						isVisible: true,
						type: SnackbarTypes.ERROR,
						message: t('errors.updateSubscription')
					})
				}
			})
			.catch((error) => {
				setIsLoading(false)
				const errorMessage = getErrorMessage(error, t('errors.updateSubscription'))
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}

	const addCardDetailsFromStripe = async (sessionIdValue) => {
		setIsLoading(true)
		const sessionResponse = await retrievesStripeSession(sessionIdValue)
		if (sessionResponse?.status === 201){
			removeQueryParams()
			setCardDetails(sessionResponse.data)
		}
	}
	
	const cancelStripeSession = async (sessionIdValue) => {
		const utilsResponse = await cancelStripeSessionLink(sessionIdValue)
		if (utilsResponse?.status === 201){
			removeQueryParams()
		}
	}

	const removeQueryParams = () => {
		searchParams.delete('session_id')
		searchParams.delete('cancelled')
		setSearchParams(searchParams)
	}

	const checkSession = () => {
		const sessionIdValue = new URLSearchParams(sessionID).get('session_id')
		const cancelled = new URLSearchParams(sessionID).get('cancelled')
		if (sessionIdValue && cancelled === 'false'){
			addCardDetailsFromStripe(sessionIdValue)
		} else if (cancelled === 'true') cancelStripeSession(sessionIdValue)
	}

	const addCardDetails = () => {
		setIsLoading(true)
		getSessionLink()
	}

	const cancelSubscription = () => {
		setShowCancelSubscriptionModal(false)
		setIsLoading(true)
		axiosBFFServer
			.patch(`subscription/cancel/${companyKey}`)
			.then((response) => {
				if (response.status === 200){
					dispatch(setIsPaymentNeeded(true))
					setSnackbar({
						isVisible: true,
						type: SnackbarTypes.SUCCESS,
						message: t('cancelSubscriptionSuccess')
					})
					getSubscriptionDetails()
				} else setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: t('errors.cancelSubscription')
				})
			})
			.catch((error) => {
				const errorMessage = getErrorMessage(error, t('errors.cancelSubscription'))

				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}

	const firstPayment = () => {
		setShowInfoModal(false)
		addCardDetails()
	}

	const checkRemainingDays = () => {
		if (subscriptionData.nextPaymentDate) { 
			return isTodayBeforeDate(subscriptionData.nextPaymentDate)
		} return false
	}

	const handlePayment = (paymentType: string) => {
		const hasRemainingDays = checkRemainingDays()
		if (subscriptionPlan.subscriptionType){
			dispatch(setSubscriptionToActivate(subscriptionPlan))
			if (cardDetails || paymentType === 'PAYMENT_ORDER'){
				const hasNoActivePlan = subscriptionData.cancelled || subscriptionDoesNotExist ? true : false
				hasNoActivePlan && activateSubscription(subscriptionPlan, paymentType);
				(!hasNoActivePlan && subscriptionData.subscriptionKey && subscriptionData.subscriptionType !== subscriptionPlan.subscriptionType) &&
					setShowModalEditPlan({visible: true, paymentType: paymentType})
			} else if (paymentType === 'CARD' && ((companyStatus === 'FREE_TRIAL' && !subscriptionData.cancelled) || companyStatus === 'PAYMENT_DETAILS_REQUIRED' || (companyStatus === 'CANCELLED' && hasRemainingDays))){
				setShowInfoModal(true)
			} else paymentType === 'CARD' && addCardDetails()
		}
	}

	useEffect(() => {
		const subscription = ([
			{key: t('lastPaymentDate'), title: subscriptionData.lastPaymentDate},
			{key: freeTrial || freeTrialActive ? t('firstPaymentDate') : t('nextPaymentDate'), title: subscriptionData.nextPaymentDate || ''},
			...(currentSubscription && currentSubscription?.paymentType === 'CARD' ? [{key: t('cardDetails'), title: cardDetails?.cardNumberLast4 ? `*** ${cardDetails?.cardNumberLast4}` : ''}] : []),
		])
		subscriptionData && subscriptionData.lastPaymentDate === null && subscription.shift()
		setSubscriptionDetails(subscription)
	}, [subscriptionData, cardDetails, freeTrial, freeTrialActive, companyStatus])

	useEffect(() => {
		if(subscriptions[0].subscriptionType !== 'unavailable') {
			const hasNoActivePlan = subscriptionData.cancelled || subscriptionDoesNotExist ? true : false
			if (cardDetails?.cardHolderName && subscriptionToActivate.subscriptionType) {
				if ((hasNoActivePlan && isPaymentNeeded)) {
					activateSubscription(subscriptionToActivate, 'CARD')
				} else if (paymentType && paymentType !== currentSubscription.paymentType) {
					editSubscription(subscriptionToActivate, paymentType)
				}
			}
		}
	}, [cardDetails])

	useEffect(() => {
		getSubscriptionPrices()
		if (!isStateOwned) {
			getSubscriptionDetails()
			checkSession()
		}
	}, [])

	const renderCustomPlan500 = () => {
		return (
			<View style={styles.content}>
				<Text style={styles.title}>
					{t('customPlanTitle')}
				</Text>
				<Text style={styles.subtitle}>
					<View>
						<View>
							<Text style={styles.stepText}>
								{t('step1')}
							</Text>
							<Text style={styles.additionalInfo}>
								{t('additionalInfo')}
							</Text>
						</View>

						<View style={{ marginTop: 16 }}>
							<Text style={styles.stepText}>
								{t('step2')}
							</Text>
							<Text style={styles.additionalInfo}>
								{t('customPlanMessageStart')}{' '}
								<TouchableOpacity onPress={() => handleNavigation()}>
									<Text style={styles.contactUrl}>
										{t('contactPageLinkText')}
									</Text>
								</TouchableOpacity>
								{' '}{t('customPlanMessageEnd')}
							</Text>
						</View>
					</View>
				</Text>
				<View style={styles.footer}>
					{
						cardDetails && (subscriptionDoesNotExist || subscriptionData.cancelled) && <Text style={{...styles.textDark, alignSelf: 'center'}}>
							{`${t('savedCardDetails')} *** ${cardDetails.cardNumberLast4}`}
						</Text>
					}
					{
						cardDetails && hasSubscriptionEditPermission && <TouchableOpacity onPress={() =>  addCardDetails()}>
							<Text style={{...styles.button, margin: 0}}>{ t('updateCard') }</Text>
						</TouchableOpacity>
					}
					{	
						!isLoading && !cardDetails && <TouchableOpacity style={styles.addCardButton} onPress={() =>  addCardDetails()}>
							<Text style={styles.buttonText}>{ t('addCard') }</Text>
						</TouchableOpacity>
					}
				</View>
			</View>
		)
	}

	const renderCustomPlanStateComp = () => {
		if (companyStatus === 'ACTIVE') {
			return (
				<View style={[styles.content, { alignItems: 'center' }]}>
					<Text style={styles.stepText}>
						{t('stateCompanySubscriptionActivated')}
					</Text>
				</View>
			)
		} else {
			return (
				<View style={styles.content}>
					<Text style={styles.title}>
						{t('customPlanTitle')}
					</Text>
					<Text style={styles.additionalInfo}>
						{t('customPlanStateCompany')}{' '}
						<TouchableOpacity onPress={() => handleNavigation()}>
							<Text style={styles.contactUrl}>
								{t('contactPageLinkText')}
							</Text>
						</TouchableOpacity>
						{'.'}
					</Text>
				</View>
			) 
		}
	}

	if (isStateOwned) {
		return <View style={styles.container}>
			{renderCustomPlanStateComp()}
		</View>
	}

	return (
		<View style={styles.container}>
			{subscriptions[0].subscriptionType === 'unavailable' ? 
				renderCustomPlan500()
				:
				<>
					<Snackbar
						visible={snackbar.isVisible}
						onDismiss={() => setSnackbar(snackbarInitialState)}
						type={snackbar.type}
						message={snackbar.message}
					/>
					{
						showInfoModal && <GeneralModal
							visible={showInfoModal}
							infoModal={true}
							onClose={firstPayment}
							onSubmit={firstPayment}
							messageText={companyStatus === 'CANCELLED' ? t('remainingPlanMessage') : t('firstPaymentInfo')}
						/>
					}
					{
						showCancelSubscriptionModal && <GeneralModal
							visible={showCancelSubscriptionModal}
							onClose={()=> setShowCancelSubscriptionModal(false)}
							onSubmit={cancelSubscription}
							itemKey={companyKey}
							messageText={t('cancelSubscriptionMessage')}
						/>
					}
					{
						showModalEditPlan && <GeneralModal
							visible={showModalEditPlan.visible}
							onClose={()=> setShowModalEditPlan({visible: false, paymentType: ''})}
							onSubmit={() => editSubscription(subscriptionPlan, showModalEditPlan.paymentType)}
							itemKey={subscriptionPlan.subscriptionType}
							messageText={t('changeSubscriptionType')}
						/>
					}
					{isLoading ?
						<Loader
							color={colors.white} /> 
						: <View style={styles.content}>
							<Text style={styles.title}>
								{subscriptionData.cancelled || subscriptionDoesNotExist ?
									t('choosePlan')
									: t('subscriptionDetails') 
								}
							</Text>
							<SubscriptionPlans
								subscriptions={subscriptions}
								setSubscriptionPlan={setSubscriptionPlan}
								subscriptionDetails={subscriptionData}
								activeSubscriptionData={subscriptionDetails}
								hasSavedCard={cardDetails ? true : false}
								hasSubscriptionEditPermission={hasSubscriptionEditPermission}
								openPaymentOptionsModal={setIsOpen}
							/>
					
							{
								cardDetails && (subscriptionDoesNotExist || subscriptionData.cancelled) && <Text style={styles.textDark}>
									{`${t('savedCardDetails')} *** ${cardDetails.cardNumberLast4}`}
								</Text>
							}
							<View style={styles.footer}>
								{
									cardDetails && hasSubscriptionEditPermission && <TouchableOpacity onPress={() =>  addCardDetails()}>
										<Text style={styles.button}>{ t('updateCard') }</Text>
									</TouchableOpacity>
								}
					
								{
									!subscriptionData.cancelled && hasSubscriptionEditPermission && <TouchableOpacity onPress={() => setShowCancelSubscriptionModal(true)}>
										<Text style={[styles.button,styles.cancelButton]}>
											{ t('cancelSubscription') }
										</Text>
									</TouchableOpacity>
								}
							</View>
						</View>
					}
					<Snackbar
						visible={snackbar.isVisible}
						onDismiss={() => setSnackbar(snackbarInitialState)}
						type={snackbar.type}
						message={snackbar.message}
					/>
				</>
			}
			{
				isOpen && 
			<PaymentOptionsModal visible={isOpen} onClose={() => setIsOpen(false)} onPaymentSelect={confirmPaymentOption} />
			}
		</View>
	)
}
