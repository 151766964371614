import React, { FC, useEffect, useRef, useState } from 'react'
import { Image, Text, TouchableOpacity, View } from 'react-native'
import Checkbox from 'expo-checkbox'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import { CreateLegalPartnerFormValues, SnackbarInitialState, SnackbarTypes, initialCreateLegalPartnerFormValues } from '@utils/interfaces'
import { Input } from '@components/Input'
import { PhoneInput } from '@components/PhoneInput/PhoneInput'
import { createLegalPartnerSchema } from '@utils/formValidation'
import { SelectDropDown } from '@components/SelectDropDown/SelectDropdown'
import { axiosBFFServer } from '@services/connectionServer'
import { legalPartnerType, languages, screenType } from '@utils/constants'
import { styles } from './styles'
import { ItemWithTooltip } from '@components/ItemWithTooltip'
import { useNavigate } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import useScreenDimensions from '@components/Common/UseScreenDimensions'
import { InputCUI } from '@components/InputCUI/InputCUI'
import { UseErrorMessages } from '@components/Common/UseErrorMessage'

interface NewLegalPartnerProps {
  onSubmit: (values: CreateLegalPartnerFormValues) => void
	setSnackbar: (value: SnackbarInitialState) => void
	disabledButton: boolean
	type: string
	onRecaptchaVerify: (token: string | null) => void
}

export const NewLegalPartner: FC<NewLegalPartnerProps> = ({ onSubmit, setSnackbar, disabledButton, type, onRecaptchaVerify}) => {
	const { t } = useTranslation()
	const { getErrorMessage } = UseErrorMessages()
	const navigate = useNavigate()
	const [companySizes, setCompanySizes] = useState([]) 
	const [captchaValue, setCaptchaValue] = useState<string | null>(null)
	const [isCUIValid, setIsCUIValid] = useState(false)
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const captchaRef = useRef<any>(null)
	const token = captchaRef.current && captchaRef.current.getValue()
	const screenDimensions = useScreenDimensions()
	const isMobileScreen = screenDimensions.width < screenType.phone

	const handleCaptchaChange = (value: string | null) => {
		setCaptchaValue(value) 
		if (value !== null) {
			onRecaptchaVerify(value)
		}
	}

	const getSizes = () => {
		axiosBFFServer
			.get('/company/sizes')
			.then((response) => {
				setCompanySizes(response.data)
			})
			.catch((error) => {
				const errorMessage = getErrorMessage(error, t('getCompanySizesError'))
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage,
				})
			})
	}

	useEffect(() => {
		getSizes()
	},[])

	return (
		<View style={styles.contentContainer}>
			<Image
				source={require('@assets/Logo.webp')}
				style={styles.logo}
			/>
			<Text style={styles.appName}>{t('appName')}</Text>
			<Text style={styles.title}>
				{type === legalPartnerType[0] ?
					t('createLegalPartner')
					: t('createAccountHeader')}
			</Text>
			{ isMobileScreen && 
			<Text style={styles.recommendation}>
				{t('appUseRecommendation')}
			</Text> 
			}
			<Formik
				initialValues={initialCreateLegalPartnerFormValues}
				validationSchema={createLegalPartnerSchema}
				onSubmit={onSubmit}
			>
				{(formikProps) => (
					<View style={styles.formContainer}>
						<Text style={styles.sectionHeader}>
							{t('personalInformation')}
						</Text>
						<View style={styles.infoSection}>	
							<View style={styles.field}>
								<View style={styles.fieldHeader}>
									<Text style={styles.label}>
										{type === legalPartnerType[0]
											? t('fieldLabels.firstNameLP')
											: t('fieldLabels.firstNameLPIntern')}
									</Text>
									<ItemWithTooltip iconName='info-circle' tooltip={t('inputPlaceholders.firstNameLPIntern')} style={styles.tooltip} size={15} />
								</View>
								<Input
									name='firstName'
									value={formikProps.values['firstName']}
									onChange={formikProps.setFieldValue}
									onBlur={() => formikProps.setFieldTouched('firstName', true)}
									editable
								/>
								{formikProps.touched['firstName'] && formikProps.errors['firstName'] && (
									<Text style={styles.errorText}>{`*${formikProps.errors['firstName']}`}</Text>
								)}
							</View>
							<View style={styles.field}>
								<View style={styles.fieldHeader}>
									<Text style={styles.label}>
										{type === legalPartnerType[0]
											? t('fieldLabels.lastNameLP')
											: t('fieldLabels.lastNameLPIntern')}
									</Text>
									<ItemWithTooltip iconName='info-circle' tooltip={t('inputPlaceholders.lastNameLPIntern')} style={styles.tooltip} size={15} />
								</View>
								<Input
									name='lastName'
									value={formikProps.values['lastName']}
									onChange={formikProps.setFieldValue}
									onBlur={() => formikProps.setFieldTouched('lastName', true)}
									editable
								/>
								{formikProps.touched['lastName'] && formikProps.errors['lastName'] && (
									<Text style={styles.errorText}>{`*${formikProps.errors['lastName']}`}</Text>
								)}
							</View>
							<View style={styles.field}>
								<View style={styles.fieldHeader}>
									<Text style={styles.label}>{t('fieldLabels.email')}</Text>
									<ItemWithTooltip iconName='info-circle' tooltip={t('inputPlaceholders.emailTooltip')} style={styles.tooltip} size={15} />
								</View>
								<Input
									name='email'
									value={formikProps.values['email']}
									onChange={formikProps.setFieldValue}
									onBlur={() => formikProps.setFieldTouched('email', true)}
									editable
								/>
								{formikProps.touched['email'] && formikProps.errors['email'] && (
									<Text style={styles.errorText}>{`*${formikProps.errors['email']}`}</Text>
								)}
							</View>
							<View style={styles.field}>
								<View style={styles.fieldHeader}>
									<Text style={styles.label}>{t('fieldLabels.phoneNumber')}</Text>
									<ItemWithTooltip iconName='info-circle' tooltip={t('inputPlaceholders.phoneNumberTooltip')} style={styles.tooltip} size={15} />
								</View>
								<PhoneInput
									name={{
										prefix: 'prefix',
										number: 'number',
									}}
									onChange={formikProps.setFieldValue}
									phone={{
										prefix: formikProps.values['prefix'],
										number: formikProps.values['number']
									}}
								/>
								{formikProps.touched['number'] && formikProps.errors['number'] && (
									<Text style={styles.errorText}>{`*${formikProps.errors['number']}`}</Text>
								)}
							</View>
						</View>
						<Text style={styles.sectionHeader}>
							{t('companyInfo')}
						</Text>
						<View style={styles.infoSection}>
							
							<InputCUI 
								companySizes={companySizes}
								isLegalPartnerIntern={type === legalPartnerType[1]}
								formikProps={formikProps}
								setIsCUIValid={setIsCUIValid}
							/>
							
							<View style={styles.field}>
								<View style={styles.fieldHeader}>
									<Text style={styles.label}>{t('fieldLabels.language')}</Text>
									<ItemWithTooltip iconName='info-circle' tooltip={t('inputPlaceholders.languageTooltip')} style={styles.tooltip} size={15} />
								</View>
								<SelectDropDown 
									data={languages}
									defaultButtonText={t('fieldLabels.language')}
									fieldName={'language'}
									handleFormValueChange={formikProps.setFieldValue}
									onBlur={() => formikProps.setFieldTouched('language', true)}
									displayTranslatedValue={true}
								/>
								{formikProps.touched['language'] && formikProps.errors['language'] && (
									<Text style={styles.errorText}>{`*${formikProps.errors['language']}`}</Text>
								)}
							</View>
							{type === legalPartnerType[1] &&
								<View style={styles.checkboxField}>
									<Checkbox
										value={formikProps.values['isStateOwned']}
										onValueChange={(value) => formikProps.setFieldValue('isStateOwned',value)}
									/>
									<Text style={styles.label}>{t('fieldLabels.isStateOwned')}</Text>
								</View>
							}
						</View>
						{process.env.DEPLOY_ENV?.toLowerCase() !== 'staging' ? 
							<View style={{ alignItems: 'center' }}>
								<ReCAPTCHA
									sitekey={process.env.RECAPTCHA_API_KEY}
									onChange={handleCaptchaChange}
									ref={captchaRef}
								/>
							</View> : <></>
						}
						
						<TouchableOpacity 
							style={[styles.submitButton, (disabledButton || !isCUIValid) && styles.disabled]} 
							disabled={disabledButton || !isCUIValid}
							onPress={() => formikProps.handleSubmit()}>
							<Text style={styles.submitButtonText}>{t('createAccount')}</Text>
						</TouchableOpacity>
						{ !isCUIValid &&
							<Text style={styles.loginText}>{t('cuiInvalidated')}</Text>
						}
					</View>
				)}
			</Formik>
			<View style={styles.login}>
				<Text style={styles.loginText}>{t('alreadyHaveAccount')}&nbsp;</Text>
				<TouchableOpacity onPress={() => navigate('/login')}>
					<Text style={[styles.loginText, styles.loginLink]}>{t('loginHere')}</Text>
				</TouchableOpacity>
			</View>
		</View>
	)
}
