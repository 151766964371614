import { StyleSheet } from 'react-native'
import { colors, theme } from '@styles/constants'

export const styles = StyleSheet.create({
	container: {
		padding: 20,
		paddingTop: 30,
		backgroundColor: theme.light.colors.mainContainer,
		borderRadius: 30
	},
	content: {
		gap: 10
	},
	title: {
		fontSize: 24,
		fontWeight: 'bold',
		color: theme.light.colors.mainColor,
	},
	button: {
		marginTop: 20,
		width: 220,
		backgroundColor: colors.black,
		color: colors.black,
		fontSize: 16,
		fontWeight: 'bold',
		paddingVertical: 10,
		paddingHorizontal: 20,
		textAlign: 'center',
		borderRadius: 5,
		overflow: 'hidden'
	},
	cancelButton: {
		backgroundColor: colors.dimmedRed,
		color: colors.danger,
		shadowColor: theme.light.colors.mainColor,
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.2,
		shadowRadius: 2,
		elevation: 3,
	},
	updateCardButton: {
		borderBottomWidth: 1,
		borderBottomColor: theme.light.colors.primaryDark
	},
	dataText: {
		fontSize: 16,
	},
	smallScreenDataText: {
		fontSize: 14,
	},
	footer: {
		flexDirection: 'row',
		gap: 20
	},
	text: {
		fontSize: 18,
		color: colors.white
	},
	textDark: {
		fontSize: 16,
		color: colors.black,
	},
	subtitle: {
		fontSize: 16,
		color: colors.black,
		padding: 10
	},
	contactUrl: {
		fontWeight: 'bold', 
		color: colors.blueChart, 
		textDecorationLine: 'underline' 
	},
	addCardButton: {
		flexDirection: 'row',
		backgroundColor: theme.light.colors.primaryDark,
		borderRadius: 6,
		paddingVertical: 10,
		paddingHorizontal: 20,
		justifyContent: 'center',
		alignItems: 'center',
		shadowColor: theme.light.colors.mainColor,
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.2,
		shadowRadius: 2,
		elevation: 3,
	},
	buttonText: {
		textAlign: 'center',
		color: colors.white,
		fontWeight: 'bold',
		fontSize: 16
	},
	stepText: {
		color: colors.black,
		marginBottom: 8,
		fontWeight: '500'
	},
	additionalInfo: {
		padding: 10
	}
})
