import React from 'react'
import Layout from '@containers/Layout'
import { Marketing } from '@containers/Marketing/Marketing'

export const MarketingPage = () => {

	return (
		<Layout>
			<Marketing/>
		</Layout>
	)
}
