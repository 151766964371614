import Table from '@components/Table/Table'
import React, { useEffect, useState } from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import { companyTableColumns } from '@utils/constants'
import { colors, theme } from '@styles/constants'
import { Company, LogEventTypes, SnackbarInitialState, SnackbarTypes, TableColumn, ViewTypes } from '@utils/interfaces'
import useScreenDimensions from '@components/Common/UseScreenDimensions'
import { useTranslation } from 'react-i18next'
import Icon from 'react-native-vector-icons/FontAwesome'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setCompanyKey, setCompanySettings, setCurrentCompany } from '@actions/companyActions'
import CompanyModal from '@components/Company/CompanyModal'
import { Input } from '@components/Input'
import { screenType, snackbarInitialState } from '@utils/constants'
import { axiosBFFServer } from '@services/connectionServer'
import { rootReducerType } from '@reducers/combineReducers'
import { Loader } from '@components/Loader/Loader'
import { Snackbar } from '@components/Snackbar/Snackbar'
import { formatDateRo, getISOStringFromDate, getUserAgent, textTransform } from '@utils/utils'
import {createLog, getCompanySettings} from '@services/apiService'
import { styles } from './styles'
import { UseErrorMessages } from '@components/Common/UseErrorMessage'

const Companies = () => {
	const [companies,setCompanies] = useState<Company[]>([])
	const [isLoading, setIsLoading] = useState(true)
	const [searchValue, setSearchValue] = useState('')
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [editCompany, setEditCompany] = useState(false)
	const [totalCompanies,setTotalCompanies] = useState(10)
	const [ page, setPage ] = useState(1)
	const [snackbar, setSnackbar] = useState<SnackbarInitialState>(snackbarInitialState)
	const [activatingCompany, setActivatingCompany] = useState('')
	
	const screenDimensions = useScreenDimensions()
	const isSmallScreen = screenDimensions.width < screenType.phone

	const { t } = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { getErrorMessage } = UseErrorMessages()

	const size = useSelector((state: rootReducerType) => state.paginationReducer.pageSize)
	const sorting = useSelector((state: rootReducerType) => state.sortReducer.companies)
	const { email, language } = useSelector((state: rootReducerType) => state.userReducer)
	
	const onClickEdit = (companyKey: string) => {
		dispatch(setCompanyKey(companyKey))
		setIsModalVisible(true)
		setEditCompany(true)
	}

	const onClickView = async (companyKey: string) => {
		const company = companies.find(company => company.companyKey === companyKey)
		const userAgent = getUserAgent()
		const securityLogInfo = {
			companyKey,
			userEmail: email,
			userAgent,
			time: new Date().toISOString(),
			event: LogEventTypes.LOGIN_SUCCESS,
			failureReason: ''
		}
		const companySettings = await getCompanySettings(companyKey)
		await createLog(securityLogInfo, false)
		dispatch(setCompanyKey(companyKey))
		dispatch(setCurrentCompany(company as Company))
		dispatch(setCompanySettings({
			language: companySettings?.data.language,
		}))
		navigate(`/${ViewTypes.COMPANY}/dashboard`)
	}

	const updateCompanyList = (companyKey: string, activatedCompany) => {
		const companyIndex = companies.findIndex(company => company.companyKey === companyKey)

		if (companyIndex !== -1) {
			const updatedCompanies = [...companies]
	
			updatedCompanies[companyIndex] = {
				...updatedCompanies[companyIndex],
				...activatedCompany,
			}
	
			setCompanies(updatedCompanies)
		}
	}
	
	const onClickActivate = (companyKey: string) => {
		setActivatingCompany(companyKey)
		axiosBFFServer
			.put(`company/${companyKey}/activate`)
			.then((response) => {
				updateCompanyList(companyKey, response.data)
				setActivatingCompany('')
			})
			.catch((error) => {
				setActivatingCompany('')
				const errorMessage = getErrorMessage(error, t('errors.activateCompany'))
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}

	const renderTableCell = (column: TableColumn, item: Company) => {
		switch (column.key) {
		case 'actions':
			return (
				<View style={styles.actionsCell}>
					{activatingCompany === item.companyKey ?
						<Loader />
						:
						<>
							<TouchableOpacity onPress={() => onClickEdit(item.companyKey)}>
								<Text style={styles.linkText}>{t('edit')}</Text>
							</TouchableOpacity>
							{item.activatedAt
								? <TouchableOpacity onPress={() => onClickView(item.companyKey)}>
									<Text style={styles.linkText}>{t('view')}</Text>
								</TouchableOpacity>
								:  <TouchableOpacity onPress={() => onClickActivate(item.companyKey)}>
									<Text style={styles.linkText}>{t('activate')}</Text>
								</TouchableOpacity>}
						</>
					}
				</View>
			)
		case 'status':
			return (
				<Text
					style={[
						styles.statusText,
						{
							color: item[column.key] === 'EXPIRED' ? theme.light.colors.dangerDark : item[column.key] === 'ACTIVE' ? theme.light.colors.successDark : theme.light.colors.warningDark,
							backgroundColor: item[column.key] === 'EXPIRED' ? theme.light.colors.danger : item[column.key] === 'ACTIVE'? theme.light.colors.success : theme.light.colors.warning,
						},
						isSmallScreen && styles.smallScreenStatusText,
						activatingCompany === item.companyKey && styles.activating
					]}
				>
					{item[column.key]}
				</Text>
			)
		case 'activated_At': 
			return (
				<Text style={[styles.dataText, isSmallScreen && styles.smallScreenDataText, activatingCompany === item.companyKey && styles.activating]}>{item.activatedAt ? formatDateRo(item.activatedAt) : '-'}</Text>
			)
		default:
			return (
				<Text style={[styles.dataText, isSmallScreen && styles.smallScreenDataText, activatingCompany === item.companyKey && styles.activating]}>{item[column.key]}</Text>
			)
		}
	}
	
	const openCompanyModal = () => {
		setIsModalVisible(true)
	}

	const closeCompanyModal = () => {
		setIsModalVisible(false)
		setEditCompany(false)
		getCompanies()
	}
	
	const handleSearch = (key, value) => {
		setSearchValue(value)
	} 

	const getCompanies = () => {
		let filterItems = sorting.by ? {
			sort: sorting
		} : {}
		
		searchValue.length ? filterItems = {...filterItems, ...{name : searchValue}} : filterItems
		axiosBFFServer
			.post(`/company/search?page=${page}&size=${size}`, filterItems)
			.then(response => {
				const companiesWithIndex = response.data.pageResults.map((item,index) => ({
					...item, 
					id: index + 1,
					cases: item.newCasesNumber + item.openCasesNumber,
					status: textTransform(item.status)
				}))
				setCompanies(companiesWithIndex)
				setTotalCompanies(response.data.totalResults)
				setIsLoading(false)
			})
			.catch(error => {
				setIsLoading(false)
				const errorMessage = getErrorMessage(error, t('errorCompanies'))
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}

	useEffect(() => {
		getCompanies()
	},[page, size, sorting, searchValue, language])
	
	return (
		<View style={styles.container}>
			<View style={isSmallScreen ? styles.headerContainer_mobile : styles.headerContainer}>
				<Text style={styles.headerText}>{t('companies')}</Text>
				<TouchableOpacity style={styles.createButton} onPress={openCompanyModal}>
					<Icon color={theme.light.colors.linkColorActive} name="plus" size={18} />
					<Text style={styles.createButtonText}>{t('createCompany')}</Text>
				</TouchableOpacity>
			</View>
	
			<View style={isSmallScreen ? styles.filterContainer_mobile :styles.filterContainer}>
				<View style={styles.searchContainer}>
					<Input
						placeholder={t('searchCompanies')} 
						name={'searchCompany'}
						noBorder
						onChange={handleSearch}/>
					<View style={styles.searchIcon}>
						<Icon name="search" size={18} color={theme.light.colors.mainColor} />
					</View>
				</View>
			</View>
			{
				isLoading ?
					<Loader
						color={colors.white} /> 
					: <Table
						data={companies}
						columns={companyTableColumns}
						isSmallScreen={isSmallScreen}
						renderTableCell={renderTableCell}
						totalRecords={totalCompanies}
						hasPagination={true}
						page={page}
						setPage={setPage}
					/>
			}

			<Snackbar
				visible={snackbar.isVisible}
				onDismiss={() => setSnackbar(snackbarInitialState)}
				type={snackbar.type}
				message={snackbar.message}
			/>
			
			{isModalVisible &&
				<CompanyModal 
					onClose={closeCompanyModal} 
					visible={isModalVisible} 
					editCompany={editCompany}/>}
	
		</View>
	)
}
	
export default Companies