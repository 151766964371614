import React, { FC, useState } from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import Icon from 'react-native-vector-icons/FontAwesome'
import { useTranslation } from 'react-i18next'
import useScreenDimensions from '@components/Common/UseScreenDimensions'
import { DepartmentType } from '@utils/interfaces'
import { screenType } from '@utils/constants'
import { colors } from '@styles/constants'
import { checkIfPermissionExist } from '@utils/utils'
import { useSelector } from 'react-redux'
import { rootReducerType } from '@reducers/combineReducers'
import { styles } from './styles'

interface DepartmentProps {
    department: DepartmentType
    openModal: () => void
}

export const Department: FC<DepartmentProps> = ({ department, openModal }) => {
	const [ openDetails, setOpenDetails ] = useState(false)
	const { t } = useTranslation()
	const screenDimensions = useScreenDimensions()
	const isMobileScreen = screenDimensions.width < screenType.phone
	const userPermissions = useSelector((state: rootReducerType) => state.userReducer.permissions)
    
	const renderDetail = (title: string, array: string[]) => {
		return (
			<View style={[styles.detailContainer, isMobileScreen && styles.detailContainer_mobile]}>
				<Text style={{ fontWeight: '500'}}>{`${title}:`}</Text>
				<View>
					{array.map(item => (<Text>{item}</Text>))}
				</View>
			</View>
		)
	}

	return (      
		<View style={styles.departmentContainer}>
			<View style={[styles.headerContainer, isMobileScreen && styles.headerContainer_mobile]}>
				<View style={[styles.departmentColumn, styles.departmentColumnName]}>
					<TouchableOpacity onPress={() => setOpenDetails(openDetails => !openDetails)}>
						<Icon
							name={openDetails ? 'chevron-up' : 'chevron-down'} 
							color={colors.gray}
							size={12}
						/>
					</TouchableOpacity>
					<Text>{department.name}</Text>
				</View>
				<View style={[styles.departmentColumn, styles.departmentColumnAction, isMobileScreen && styles.departmentColumnAction_mobile]}>
					{
						checkIfPermissionExist(userPermissions,'department','update') &&
						<View style={styles.departmentColumnSmall}>
							<TouchableOpacity onPress={openModal}>
								<Icon name={'cog'} color={colors.gray} size={16} />
							</TouchableOpacity>
						</View>
					}
				</View>
			</View>
			{openDetails && <View style={[styles.detailsContainer, isMobileScreen && styles.detailsContainer_mobile]}>
				{renderDetail(t('categories'), department.categories.map(category => category.value))}
				{renderDetail(t('users'), department.users.map(user => `${user.firstName} ${user.lastName}`))}
			</View>}
		</View>
	)
}


