import React, { FC, useEffect, useState } from 'react'
import { View,Text, FlatList, ScrollView, TouchableOpacity, TouchableWithoutFeedback } from 'react-native'
import { Case, CaseTable, Company, TableColumn, details } from '@utils/interfaces'
import { theme } from '@styles/constants'
import Pagination from '@components/Pagination/Pagination'
import { useDispatch } from 'react-redux'
import { setPageSize } from '@actions/paginationActions'
import FontAwesome from 'react-native-vector-icons/FontAwesome'
import { setCasesSort, setCompaniesSort } from '@actions/sortingActions'
import { useSelector } from 'react-redux'
import { rootReducerType } from '@reducers/combineReducers'
import { useTranslation } from 'react-i18next'
import { casesSort, companiesSort, screenType } from '@utils/constants'
import { styles } from './styles'
import useScreenDimensions from '@components/Common/UseScreenDimensions'

interface TableProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: Array<Company | Case | details > | any[]
	columns: Array<TableColumn>
	isSmallScreen: boolean
	caseType?: string
	totalRecords?: number
	hasPagination: boolean
	renderTableCell: ((column: TableColumn, item: Company) => React.ReactNode)
	| ((column: TableColumn, item: Case) => React.ReactNode)
	| ((column: TableColumn, item: CaseTable) => React.ReactNode)
	isHeaderless?: boolean
	onClick?: (key: string) => void
    page?: number
    setPage?: (number: number) => void
}

const Table: FC<TableProps> = ({ data, columns, isSmallScreen, caseType, totalRecords, hasPagination, renderTableCell, isHeaderless, onClick, page, setPage }) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const screenDimensions = useScreenDimensions()
	const isMobileScreen = screenDimensions.width < screenType.phone
	const size = useSelector((state: rootReducerType) => state.paginationReducer.pageSize)

	const initialSorting: Record<string, 'asc' | 'desc' | 'none'> = {}
	columns.forEach((column, index) => {
		initialSorting[column.key] = 'none'
	})

	const [sorting, setSorting] = useState(initialSorting)

	const handleSort = (column: TableColumn) => {
		let currentSortValue = sorting[column.key]
		currentSortValue = currentSortValue === 'asc' ? 'desc' : 'asc'
		
		setSorting({...initialSorting, [column.key]: currentSortValue})
	}

	const displaySortingIcons = (order: string) => {
		switch(order) {
		case 'asc':
			return <FontAwesome
				name='caret-up'
				color={theme.light.colors.mainColor}
				size={15} />
		case 'desc': 
			return <FontAwesome
				name='caret-down'
				color={theme.light.colors.mainColor}
				size={15} />
		default: 
			return <FontAwesome
				name='sort'
				color={theme.light.colors.mainColor}
				size={15} />
		}
	}

	useEffect(() => {
		const key = Object.keys(sorting).find(key => sorting[key] !== 'none')
		if (key) {
			const sortingObj = {
				by: key.toUpperCase(),
				order: sorting[key].toUpperCase()
			}
			companiesSort.includes(sortingObj.by) && dispatch(setCompaniesSort(sortingObj))
			casesSort.includes(sortingObj.by) && dispatch(setCasesSort(sortingObj))
		}
	},[sorting])

	const renderHeader = () => (
		<View style={styles.headerRow}>
			{columns.map((column, index) => (
				column.title && column.hasSorting ?
					<TouchableOpacity key={index} style={[styles.headerCell, styles.sortingHead, { ...(column.width && {minWidth: column.width, maxWidth: column.width})}]} onPress={() => handleSort(column)}>
						<Text style={[styles.headerText, isSmallScreen && styles.smallScreenHeaderText]}>
							{t(column.title)}
						</Text>
						{sorting[column.key] && (
							<Text style={styles.headerArrow}>{displaySortingIcons(sorting[column.key])}</Text>
						)}
					</TouchableOpacity>
					:
					<View key={index} style={[styles.headerCell, { ...(column.width && {minWidth: column.width, maxWidth: column.width})}]}>
						<Text style={[styles.headerText, isSmallScreen && styles.smallScreenHeaderText]}>
							{t(column.title)}
						</Text>
					</View>
			))}
		</View>
	)
	
	const renderItem = ({ item }) => (
		<View>
			<TouchableWithoutFeedback onPress={() => {onClick && onClick(item.documentKey)}}>
				<View style={[styles.dataRow, item.index % 2 === 0 && styles.whiteRow, ]}>
					{columns.map((column, index) => (
						<View key={index} style={[styles.dataCell, { ...(column.width && {minWidth: column.width, maxWidth: column.width})}]}>
							{renderTableCell(column, item)}
						</View>
					))}
				</View>
			</TouchableWithoutFeedback>
		</View>
	)

	return (
		<ScrollView horizontal={isSmallScreen} contentContainerStyle={(isSmallScreen ? { width: 788 } :undefined) && (isMobileScreen ? {width: '100%'} : undefined)}>
			<View style={[styles.tableContainer, isSmallScreen && styles.smallScreenContainer]}>
				{!isHeaderless && renderHeader()}
				<FlatList
					data={data.map((item, index) => ({ ...item, index }))}
					renderItem={renderItem}
					keyExtractor={(item, index) => index.toString()}
					ListFooterComponent={
						<View style={styles.footer}>
							{
								data.length
									? hasPagination && <Pagination 
										resultsPerPage={size} 
										totalRecords={totalRecords ? totalRecords : 0} 
										page={page || 1}
										onPageChange={(number) => hasPagination && setPage ? setPage(number) : null} 
										onResultsPerPageChange={(fieldName, pageSize) => dispatch(setPageSize(pageSize))} 
									/>
									: <Text style={styles.noDataText}>{t('noDataAvailable')}</Text>
							}
							
						</View>
					}
				/>
			</View>
		</ScrollView>
	)
}

export default Table