import React, { useEffect, useMemo, useState } from 'react'
import { Modal, Text, TouchableOpacity, View } from 'react-native'
import { colors } from '@styles/constants'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Icon from 'react-native-vector-icons/FontAwesome'
import RadioButtons from '@components/RadioButton/RadioButton'
import { SnackbarTypes, formValues, ViewTypes, CompanyUser, ReportTypes, File, CaseSources, Company, SeverityType, SnackbarInitialState, Advisor, CategoryType } from '@utils/interfaces'
import UserCard from '@components/UserCard/UserCard'
import FileCard from '@components/FileCard/FileCard'
import * as DocumentPicker from 'expo-document-picker'
import { SelectDropDown } from '@components/SelectDropDown/SelectDropdown'
import { Input } from '@components/Input'
import { axiosBFFServer, axiosBFFServerFormData } from '@services/connectionServer'
import { Snackbar } from '@components/Snackbar/Snackbar'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { rootReducerType } from '@reducers/combineReducers'
import { PhoneInput } from '@components/PhoneInput/PhoneInput'
import { setCasePass,setCaseKey } from '@actions/caseKeyActions'
import { createCaseManuallyProperties, iv, phonePrefixes, snackbarInitialState } from '@utils/constants'
import { CaseSubmitted } from '../CaseSubmitted'
import { Formik } from 'formik'
import { createCaseSchema } from '@utils/formValidation'
import { formatDate, getCompanyAdmin, getLinkAndDocumentsForm, isCompanyUserAdmin, isPartnerOrAdvisor, removeDuplicatesByCriteria } from '@utils/utils'
import forge from 'node-forge'
import { encryptValueWithAES } from '@utils/encryption'
import { styles } from './styles'
import { Loader } from '@components/Loader/Loader'
import { UseErrorMessages } from '@components/Common/UseErrorMessage'

export const CaseNew = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { getErrorMessage } = UseErrorMessages()
	const userRoles = useSelector((state: rootReducerType) => state.userReducer.roles)
	const { firstName, lastName, email } = useSelector((state: rootReducerType) => state.userReducer)
	const advisorKey = useSelector((state: rootReducerType) => state.setAdvisorKeyReducer.key)
	const companyUserKey = isPartnerOrAdvisor(userRoles) ? '' : useSelector((state: rootReducerType) => state.companyUserKeyReducer.key)
	const { publicKey: partnerPublicKey } = useSelector((state: rootReducerType) => state.legalPartnerKeyReducer)
	const { companyKey, publicKey: companyPublicKey, advisors, departments, users } = useSelector((state: rootReducerType) => state.companyReducer.currentCompany)
	const companyKeyforLP = useSelector((state: rootReducerType) => state.companyReducer.key)

	const [checkedValue, setCheckedValue] = useState(ReportTypes.CONFIDENTIALLY)
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [companyUsersFromDepart, setCompanyUsersFromDepart] = useState<CompanyUser[]>([])
	const [categoryKey, setCategoryKey] = useState('')
	const [uploadedFiles, setUploadedFiles] = useState<File[]>([])
	const [ categoryByCompany, setCategoryByCompany ] = useState<CategoryType[]>([])

	const severity = SeverityType.MINOR
	const currentDate = new Date()
	const deadline = new Date(currentDate)
	deadline.setDate(currentDate.getDate() + 7)
	const companiesData = useSelector((state: rootReducerType) => state.companyReducer.companies)

	const [ snackbar, setSnackbar ] = useState<SnackbarInitialState>(snackbarInitialState)
	const [ isLoading, setIsLoading ] = useState(false)
	const departmentsLP = companiesData.map((company: Company) => company.departments)

	const subDepart = departmentsLP.filter(item => Array.isArray(item) && item.length > 0)

	const allDepartments = subDepart.flatMap(innerArray => {
		return innerArray.map(item => {
			const { categories, users, departmentKey, name } = item
			return { categories, users, departmentKey, name }
		})
	})

	const initialFormValues = {
		subject: '',
		description: '',
		category: '',
		source: '',
		firstName: '',
		lastName: '',
		phonePrefix: phonePrefixes[0],
		phoneNo: '',
		email: '', 
	}

	const adminUser: CompanyUser = getCompanyAdmin(users)
	const defaultUserNames: CompanyUser[] = []
	if (isPartnerOrAdvisor(userRoles) || isCompanyUserAdmin(userRoles)) {
		defaultUserNames.push(adminUser)
	} else {
		defaultUserNames.push(adminUser, { firstName, lastName, companyUserKey, email } )
	}

	const handleFormValueChange = (key, value) => {
		categoryByCompany.map((category) => {
			if (category.categoryKey == value) {
				setCategoryKey(category.categoryKey)
				setCompanyUsersFromDepartment(category.categoryKey)
			}
		})
	}

	const setCompanyUsersFromDepartment = (categoryKey: string) => {
		const departmentsByRole = isPartnerOrAdvisor(userRoles) ? allDepartments : departments
		const selectedDepartments = departmentsByRole.filter((department) => {
			return department.categories.some((category) => category.categoryKey === categoryKey)
		})
		const usersFromSelectedDepartments = selectedDepartments.map((department) => department.users)
		setCompanyUsersFromDepart(
            removeDuplicatesByCriteria([...usersFromSelectedDepartments.flat(), adminUser], 'companyUserKey') as CompanyUser[]
		)
	}

	const radioButtonValues = useMemo(() => ([
		{
			id: 1,
			label: t('reportConfidentially'),
			value: ReportTypes.CONFIDENTIALLY,
			description: '',
			descriptionLong: ''
		},
		{
			id: 2,
			label: t('reportAnonymously'),
			value: ReportTypes.ANONYMOUSLY,
			description: '',
			descriptionLong: ''
		}
	]), [])

	const submitForm = async (values: formValues) => {
		setIsLoading(true)
		const keys = companyUsersFromDepart.map((user) => user.companyUserKey)
		const adminAndUserKeys = defaultUserNames.map(user => user.companyUserKey)
		const combinedKeys = [...keys, ...adminAndUserKeys]
		const uniqueKeys = Array.from(new Set(combinedKeys))
		const companyUsersKey = isPartnerOrAdvisor(userRoles) ? keys : uniqueKeys

		const firstName = values.firstName
		const lastName = values.lastName	
		const phoneNumber = values.phoneNo
			? values.phonePrefix + values.phoneNo
			: ''
		const email = values.email
		const { source, subject, description } = values


		const createCaseData = {
			subject,
			description,
			source,
			severity,
			deadline: formatDate(deadline),
			advisors: isPartnerOrAdvisor(userRoles) ? [advisorKey] : (advisors as Advisor[]).map(advisor => advisor?.advisorKey),
			companyKey: isPartnerOrAdvisor(userRoles) ? companyKeyforLP : companyKey, 
			categoryKey: categoryKey,
			companyUsers: companyUsersKey,
			...(checkedValue !== ReportTypes.ANONYMOUSLY && {
				whistleblowerRequest: {
					email: email,
					phoneNumber: phoneNumber,
					firstName: firstName,
					lastName: lastName,
				}
			}),
			whistleblowerEncryptedKey: ''
			// wbEcncryptedKey needs to be removed once be changes are done
		}

		const secretKey = forge.util.bytesToHex(forge.random.getBytesSync(32))

		const encryptedCaseData = {}
		for (const property of createCaseManuallyProperties) {
			encryptedCaseData[property] = encryptValueWithAES(secretKey, iv, createCaseData[property])
		}

		// encrypt secretKey with legal partner public key
		const partnerPublicKeyObj = forge.pki.publicKeyFromPem(partnerPublicKey)
		const encryptedSecretKeyByPartner = partnerPublicKeyObj.encrypt(secretKey)
		const encryptedSecretKeyByPartnerHex = forge.util.bytesToHex(encryptedSecretKeyByPartner)


		// encrypt secretKey with company public key
		const companyPublicKeyObj = forge.pki.publicKeyFromPem(companyPublicKey)
		const encryptedSecretKeyByCompany = companyPublicKeyObj.encrypt(secretKey)
		const encryptedSecretKeyByCompanyHex = forge.util.bytesToHex(encryptedSecretKeyByCompany)

		const dataToSendCreateCase = {
			...createCaseData,
			...encryptedCaseData,
			partnerEncryptedKey: encryptedSecretKeyByPartnerHex,
			companyEncryptedKey: encryptedSecretKeyByCompanyHex,
		}

		const documentsUploaded = !!uploadedFiles.length

		await axiosBFFServer
			.post('/case', dataToSendCreateCase)
			.then(async (formResponse) => {
				let hasUploadFilesError = false
				let errorMessage = t('errors.uploadFiles')

				if (formResponse.status === 201) {
					const caseKey = formResponse.data.caseKey
					const passw = formResponse.data.encodedPassword
					dispatch(setCasePass(passw))
					dispatch(setCaseKey(caseKey))
    
					if (documentsUploaded) {
						const { uploadDocUrl, fileDataToSend } = await getLinkAndDocumentsForm(uploadedFiles, secretKey, iv)
						await axiosBFFServerFormData
							.post(`${uploadDocUrl}/${caseKey}?authorEmail=${email}`, fileDataToSend)
							.then((documentResponse) => {
								if (documentResponse.status !== 201) {
									hasUploadFilesError = true
								}
							})
							.catch((err) => {
								errorMessage = getErrorMessage(err, t('errors.uploadFiles'))
								if (err.response.status === 422 && err.response.data.message.includes('size')) {
									errorMessage = t('errors.fileSize')
								}
								if (err.response.status === 422 && err.response.data.message.includes('type')) {
									errorMessage = t('errors.fileType')
								}
								hasUploadFilesError = true
							})
					}

					navigate(`/${ViewTypes.COMPANY}/cases`, { state: { errorMessage: hasUploadFilesError ?  errorMessage : '' } })
				} else throw new Error()
			})
			.catch((error) => {
				setIsLoading(false) 
				const errorMessage = getErrorMessage(error, t('errors.createCase'))
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
				
			})
	}

	const closeModal = () => {
		setIsModalVisible(false)
		navigate(`/${ViewTypes.COMPANY}/cases`)
	}

	const pickDocument = async () => {
		const result = await DocumentPicker.getDocumentAsync({
			type: '*/*',
			multiple: true,
			copyToCacheDirectory: true
		})
	
		if (result.output && result.output.length > 0){
			const files = Object.values(result.output) as File[]
			setUploadedFiles([ ...uploadedFiles, ...files ])
		}
	}

	const getCategories = () => {
		axiosBFFServer
			.get(`category/all/${companyKey}`)
			.then((response) => {
				setCategoryByCompany(response.data
					.filter(category => category.isActive)
					.map(category => ({
						...category,
						label: category.value,
						value: category.categoryKey,
					}))
				)
			})
			.catch((error) => { 
				const errorMessage = getErrorMessage(error, t('caseErrors'))
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}

	useEffect(()=> {
		getCategories()
	}, [])

	return (
		<>
			<Modal
				visible={isLoading}
				transparent={true}
			>
				<Loader />
			</Modal>
			<View style={{
				...(isLoading && {
					opacity: 0,
				})
			}}>
				<Formik initialValues={initialFormValues} validationSchema={createCaseSchema} onSubmit={submitForm}>
					{(formikProps) => (
						<View style={styles.container}>
							<View style={styles.header}>
								<TouchableOpacity 
									style={styles.backButton}
									onPress={() => navigate(`/${ViewTypes.COMPANY}/cases`)}>
									<Icon style={styles.backIcon} name="arrow-left" size={18} />
									<Text style={styles.backButtonText}>{t('back')}</Text>
								</TouchableOpacity>
							</View>
							<View style={styles.form}>
								<View style={styles.header_form}>
									<Text style={styles.title}>{t('NewReportTitle')}</Text>
								</View>
                
								<>
									<View style={styles.formFieldWrapper}>
										<Text style={styles.labelText}>{t('fieldLabels.subject')}</Text>
										<Input
											placeholder={t('subject')}
											name={'subject'}
											onChange={(key, value) => {
												formikProps.setFieldValue(key, value)
											}} 
											value={formikProps.values['subject']}
											onBlur={() => formikProps.setFieldTouched('subject', true)} 
										/>
										{formikProps.touched['subject'] && formikProps.errors['subject'] && (
											<Text style={styles.errorText}>{`*${formikProps.errors['subject']}`}</Text>
										)}
									</View>

									<View style={styles.formFieldWrapper}>
										<Text style={styles.labelText}>{t('fieldLabels.source')}</Text>
										<SelectDropDown 
											data={Object.values(CaseSources)}
											defaultButtonText={t('source')}
											fieldName={'source'}
											handleFormValueChange={(key, value) => {
												formikProps.setFieldValue(key, value)
											}}
											onBlur={() => formikProps.setFieldTouched('source', true)}
										/>
										{formikProps.touched['source'] && formikProps.errors['source'] && (
											<Text style={styles.errorText}>{`*${formikProps.errors['source']}`}</Text>
										)}
									</View>

									<View style={styles.radioButtonContainer}>
										<Text style={styles.labelText}>{t('reportLabel')}</Text>
										{
											radioButtonValues.map((item,i)=>
												<RadioButtons 
													key={i} 
													item={item} 
													checked={checkedValue} 
													setChecked={setCheckedValue}
													isCaseRelated={true}
												/>
                            
											)
										}
									</View>

									<View style={styles.formFieldWrapper}>
										<Text style={styles.labelText}>{t('fieldLabels.description')}</Text>
										<Input
											placeholder={t('description')}
											name='description'
											multiline={true}
											onChange={(key, value) => {
												formikProps.setFieldValue(key, value)
											}} 
											value={formikProps.values['description']}
											onBlur={() => formikProps.setFieldTouched('description', true)} 
										/>
										{formikProps.touched['description'] && formikProps.errors['description'] && (
											<Text style={styles.errorText}>{`*${formikProps.errors['description']}`}</Text>
										)}
									</View>

									<View style={styles.formFieldWrapper}>
										<Text style={styles.labelText}>{t('fieldLabels.category')}</Text>
										<SelectDropDown 
											dropDownItems={categoryByCompany}
											defaultButtonText={t('category')}
											fieldName={'category'}
											handleFormValueChange={(key, value) => {
												formikProps.setFieldValue(key, value)
												handleFormValueChange(key, value)}}
											onBlur={() => formikProps.setFieldTouched('category', true)}
											areItemsObjects={true}
										/>
										{formikProps.touched['category'] && formikProps.errors['category'] && (
											<Text style={styles.errorText}>{`*${formikProps.errors['category']}`}</Text>
										)}
									</View>

									<View style={styles.formFieldWrapper}>
										<View style={styles.iconTextContainer}>
											<Icon style={styles.iconDark} name='users' size={18} />
											<Text style={styles.labelText}>{t('caseHandlersText')}</Text>
										</View>
										<View style={styles.usersContainer}>
											{companyUsersFromDepart.length > 0 ? companyUsersFromDepart.map((user, index) => {
												const fullname =  `${user.firstName} ${user.lastName}`
												return <UserCard
													key={index}
													name={fullname}
													isAdvisor={false}
												/> }
											) : defaultUserNames.length > 0 ? defaultUserNames.map((user, index) => {
												const fullname =  `${user.firstName} ${user.lastName}`
												return <UserCard
													key={index}
													name={fullname}
													isAdvisor={false}
												/>}) : <></>}
										</View>
									</View> 

									{checkedValue !== ReportTypes.ANONYMOUSLY && (
										<>
											<View style={styles.formFieldWrapper}>
												<Text style={styles.labelText}>{t('fieldLabels.firstName')}</Text>
												<Input
													placeholder={t('firstName')}
													name='firstName'
													onChange={(key, value) => {
														formikProps.setFieldValue(key, value)
													}}
													value={formikProps.values['firstName']}
													onBlur={() => formikProps.setFieldTouched('firstName', true)} 
												/>
												{formikProps.touched['firstName'] && formikProps.errors['firstName'] && (
													<Text style={styles.errorText}>{`*${formikProps.errors['firstName']}`}</Text>
												)}
											</View>

											<View style={styles.formFieldWrapper}>
												<Text style={styles.labelText}>{t('fieldLabels.lastName')}</Text>
												<Input
													placeholder={t('lastName')}
													name='lastName'
													onChange={(key, value) => {
														formikProps.setFieldValue(key, value)
													}} 
													value={formikProps.values['lastName']}
													onBlur={() => formikProps.setFieldTouched('lastName', true)} 
												/>
												{formikProps.touched['lastName'] && formikProps.errors['lastName'] && (
													<Text style={styles.errorText}>{`*${formikProps.errors['lastName']}`}</Text>
												)}
											</View>

											<View style={styles.formFieldWrapper}>
												<Text style={styles.labelText}>{t('fieldLabels.emailOptional')}</Text>
												<Input
													placeholder={t('emailOptional')}
													name='email'
													onChange={(key, value) => {
														formikProps.setFieldValue(key, value)
													}} 
													value={formikProps.values['email']}/>
											</View>
											<View style={styles.formFieldWrapper}>
												<Text style={styles.labelText}>{t('fieldLabels.phoneNo')}</Text>
												<PhoneInput
													name={{
														prefix: 'phonePrefix',
														number: 'phoneNo',
													}}
													onChange={(key, value) => {
														formikProps.setFieldValue(key, value)
													}}
													phone={{
														prefix: formikProps.values['phonePrefix'],
														number: formikProps.values['phoneNo']
													}}
													phoneNumberPlaceholder={t('phoneNo')}
													onBlur={formikProps.setFieldTouched}
												/>
												{formikProps.touched['phonePrefix'] && formikProps.errors['phonePrefix'] && (
													<Text style={styles.errorText}>{`*${formikProps.errors['phonePrefix']}`}</Text>
												)}
												{formikProps.touched['phoneNo'] && formikProps.errors['phoneNo'] && (
													<Text style={styles.errorText}>{`*${formikProps.errors['phoneNo']}`}</Text>
												)}
											</View>
										</>
									)}
								</>

								<View>
									<Text style={styles.labelText}>{t('files')}</Text>
									{
										!!uploadedFiles.length && (
											<View style={styles.fileContainer}>
												{uploadedFiles.map((file, index) => 
													<FileCard
														key={index}
														name={file.name}
														index={index}
														uploadFiles={uploadedFiles}
														setUploadFiles={setUploadedFiles}
													/>
												)}
											</View>
										)
									}
								</View>

								<View style={styles.footer}>
									<TouchableOpacity 
										style={styles.buttonDark}
										onPress={pickDocument}>
										<Icon style={styles.icon} name="upload" size={18} />
										<Text style={styles.buttonTextDark}>{t('uploadFile')}</Text>
									</TouchableOpacity>
									<TouchableOpacity 
										style={styles.button}
										onPress={() => formikProps.handleSubmit()}
									>
										<Icon name="check" size={18} color={colors.white} />
										<Text style={styles.buttonText}>{t('submit')}</Text>
									</TouchableOpacity>
								</View>
								{isModalVisible && <CaseSubmitted isModalVisible={isModalVisible} closeModal={closeModal}/>}
							</View> 

							<Snackbar
								visible={snackbar.isVisible}
								onDismiss={() => setSnackbar(snackbarInitialState)}
								type={snackbar.type}
								message={snackbar.message}
							/>
						</View>
					)}
				</Formik>
			</View>
		</>
	)
}
