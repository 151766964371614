import useScreenDimensions from '@components/Common/UseScreenDimensions'
import React, { FC, RefObject, useEffect, useState } from 'react'
import { View, Text, TouchableOpacity, Animated } from 'react-native'
import Icon from 'react-native-vector-icons/Feather'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { rootReducerType } from '@reducers/combineReducers'
import { setCurrentPage } from '@actions/menuActions'
import { screenType } from '@utils/constants'
import { buildRoutes } from '@utils/router'
import { RouteType } from '@utils/interfaces'
import { styles } from './styles'

interface MenuProps {
	isOpen: boolean,
}

const Menu: FC<MenuProps> = ({ isOpen }) => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const activeItem = useSelector((state: rootReducerType) => state.currentPageReducer.activeIndex)
	const userPermissions = useSelector((state: rootReducerType) => state.userReducer.permissions)
	const [userRoutes, setUserRoutes] = useState<RouteType[]>([])
	const [logoutIndex, setLogoutIndex] = useState(-1)

	const screenDimensions = useScreenDimensions()
	const isSmallScreen = screenDimensions.width < screenType.phone
	const { t } = useTranslation()
	const { pathname } = useLocation()
	const parentRoute = pathname.split('/')[1]
	const menuRoutes = userRoutes.filter(route => route.iconName)

	useEffect(() => {
		if (userPermissions) {
			const routes = buildRoutes(parentRoute, userPermissions)
			setUserRoutes(routes)
			const logoutIdx = routes.filter(route => route.iconName).findIndex(route => route.name === 'logout')
			setLogoutIndex(logoutIdx)
			const activeIndex = routes.findIndex(route => pathname.includes(`/${parentRoute}/${route.path.includes('settings') ? route.path.split('/')[0] : route.path}`))
			if (activeIndex !== -1) {
				dispatch(setCurrentPage((activeIndex + 1).toString()))
			}
		}	
	}, [userPermissions, pathname])

	const renderMenuItem = (route, index) => {
		const routeIndex = (index + 1).toString()
		const navigateTo = `/${parentRoute}/${route.path}`

		return (
			<TouchableOpacity
				onPress={() => {
					navigate(navigateTo)
				}
				}
				key={index}
			>
				<View style={activeItem === routeIndex ? styles.menuItem_active : styles.menuItem}>
					<View style={styles.iconContainer}>
						<Icon
							style={styles.menuIcon}
							name={route.iconName as string}
							size={22}
						/>
					</View>
					{isOpen && <Text style={styles.menuText}>
						{t(`menu.${route.name}`)}
					</Text>}
				</View>
			</TouchableOpacity>
		)
	}

	return (
		<View style={[
			isSmallScreen ? styles.menuContainer_phone : styles.menuContainer,
			isOpen && isSmallScreen ? styles.menuOpen_phone : isOpen ? styles.menuOpen : styles.menuClosed]}>
			{menuRoutes && menuRoutes.length > 0 &&
				<>
					<View>
						{menuRoutes.map((route, index) => { 
							if(index !== logoutIndex) {
								return renderMenuItem(route, index)
							}
						})}
					</View>
					{renderMenuItem(menuRoutes[logoutIndex], logoutIndex)}
				</>
			}
		</View>

	)
}

export default Menu