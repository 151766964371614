import React, { useState } from 'react'
import ForgotPassword from '@components/ForgotPassword/ForgotPassword'
import { Loader } from '@components/Loader/Loader'
import { Snackbar } from '@components/Snackbar/Snackbar'
import {userForgotPassword} from '@services/apiService'
import { Platform } from 'react-native'
import { useNavigate } from 'react-router-dom'
import { SnackbarInitialState, SnackbarTypes } from '@utils/interfaces'
import { useTranslation } from 'react-i18next'
import { snackbarInitialState } from '@utils/constants'

const ForgotPasswordContainer: React.FC<{
    onClose: () => void
    setIsPasswordForgotten: (value: boolean) => void
}> = ({
	onClose,
	setIsPasswordForgotten,
}) => {
	const [email, setEmail] = useState('')
	const [isEmailSent, setIsEmailSent] = useState(false)
	const navigate = useNavigate()
	const { t } = useTranslation()
	const [ isLoading, setIsLoading ] = useState(false)
	const [ snackbar, setSnackbar ] = useState<SnackbarInitialState>(snackbarInitialState)

	const handleEmailChange = (text: string) => {
		setEmail(text)
	}

	const handleEmailSubmit = async () => {
		setIsLoading(true)
		const response = await userForgotPassword(email)

		if (!response) {
			setIsLoading(false)
			setIsPasswordForgotten(true)
			setSnackbar({
				isVisible: true,
				type: SnackbarTypes.ERROR,
				message: t('errors.nonexistentAccount')
			})
		}

		if (response?.data.status === 'Redirect' && Platform.OS === 'web') {
			setIsLoading(false)
			setIsEmailSent(true)
			onClose()
			navigate(response.data.redirectUrl)
		}
	}

	return (
		<>
			{isLoading
				? <Loader />
				: <>
					<ForgotPassword
						email={email}
						onEmailChange={handleEmailChange}
						onEmailSubmit={handleEmailSubmit}
						isEmailSent={isEmailSent}
						onClose={onClose}
					/>
					<Snackbar
						visible={snackbar.isVisible}
						onDismiss={() => setSnackbar(snackbarInitialState)}
						type={snackbar.type}
						message={snackbar.message}
					/>
				</>
			}
		</>
	)
}

export default ForgotPasswordContainer