import React, { useEffect, useState } from 'react'
import { Modal, Text, TouchableOpacity, View } from 'react-native'
import { theme } from '@styles/constants'
import { useTranslation } from 'react-i18next'
import Icon from 'react-native-vector-icons/FontAwesome'
import { CompanyUserValues, FormValues, SnackbarTypes, Permission, PermissionList, SnackbarInitialState } from '@utils/interfaces'
import useScreenDimensions from '@components/Common/UseScreenDimensions'
import { CheckBoxCustom } from '@components/CheckBox/CheckBox'
import { Input } from '@components/Input'
import { phonePrefixes, screenType, snackbarInitialState } from '@utils/constants'
import { axiosBFFServer } from '@services/connectionServer'
import { useSelector } from 'react-redux'
import { rootReducerType } from '@reducers/combineReducers'
import { PhoneInput } from '@components/PhoneInput/PhoneInput'
import { Formik } from 'formik'
import { editCompanyUserSchema } from '@utils/formValidation'
import { Snackbar } from '@components/Snackbar/Snackbar'
import { Loader } from '@components/Loader/Loader'
import { createUserPermissionsList } from '@utils/utils'
import { styles } from './styles'
import { UseErrorMessages } from '@components/Common/UseErrorMessage'

interface Props {
	visible: boolean
	onClose: () => void
	onSubmit: () => void
	setNewCreated: (value: boolean) => void
}

export const NewCompanyUserModal = (props: Props) => {
	const { t } = useTranslation()
	const screenDimensions = useScreenDimensions()
	const { getErrorMessage } = UseErrorMessages()

	const isSmallLaptop = screenDimensions.width < screenType.smallLaptop
	const isSmallScreen = screenDimensions.width < screenType.tablet

	const companyKey = useSelector((state: rootReducerType) => state.companyReducer.key)

	const [ snackbar, setSnackbar ] = useState<SnackbarInitialState>(snackbarInitialState)
	const [ isLoading, setIsLoading ] = useState(false)
	
	const [permissionsList, setPermissionsList] = useState<PermissionList[]>()
	const [permissionsKeys,setPermissionsKeys] = useState<string[]>([])

	const [newCompanyUser, setNewCompanyUser] = useState({
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
		prefix: phonePrefixes[0],
		title: ''
	})

	const onSubmit = async (values: FormValues) => {
		const { firstName, lastName, email, prefix, title, phone } = values as CompanyUserValues

		setIsLoading(true)
		try {
			const response = await axiosBFFServer.post('/company-user', { 
				firstName,
				lastName, 
				email,
				phoneNumber: prefix + phone,
				companyKey,
				title,
				permissionKeys: permissionsKeys
			})

			setIsLoading(false)
			if (response && response.status === 201) {
				props.setNewCreated(true)
				props.onClose()
			} else {
				throw new Error('Unexpected response status')
			}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			const errorMessage =  error.response.data.customStatus === '609' ? t('emailAlreadyUsed') + ' ' + getErrorMessage(error, '') : getErrorMessage(error, t('errors.createCompanyUser'))
			
			setIsLoading(false)
			setSnackbar({
				isVisible: true,
				type: SnackbarTypes.ERROR,
				message: errorMessage,
			})
		}
	}

	const getPermissions = async () => {
		let permissions: Permission[] = []
		const role = 'COMPANY_USER'

		await axiosBFFServer
			.get(`/permission/role/${role}`)
			.then((response) => {
				permissions = response.data
			})
			.catch((error) => {
				const errorMessage = getErrorMessage(error, t('getPermissions'))
				
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
		const permissionList = createUserPermissionsList(permissions)
		setPermissionsList(permissionList)
	}

	useEffect(() => {
		getPermissions()
	},[])
	
	return (
		<Modal
			animationType="slide"
			transparent={true}
			visible={props.visible}
			onRequestClose={props.onClose}>

			{isLoading ? 
				<View style={styles.container}>
					<Loader/> 
				</View>
				:<Formik 
					enableReinitialize={true} 
					initialValues={newCompanyUser} 
					validationSchema={editCompanyUserSchema} 
					onSubmit={onSubmit}>
					{(formikProps) => (
						<View style={styles.container}>
							<View style={isSmallLaptop ? styles.content_smallLaptop : isSmallScreen ? styles.content_smallDevice  : styles.content}>
								<Text style={styles.title}>{t('createUser')}</Text>
			
								<View style={styles.textFieldContainer}>
									<View style={styles.formFieldWrapperSmall}>
										<Text style={styles.labelText}>{t('firstName')}</Text>
										<Input
											placeholder={t('firstName')}
											name='firstName'
											value={formikProps.values['firstName']}
											onChange={formikProps.setFieldValue}
											onBlur={() => formikProps.setFieldTouched('firstName', true)} />
										{formikProps.touched['firstName'] && formikProps.errors['firstName'] && (
											<Text style={styles.errorText}>{`*${formikProps.errors['firstName']}`}</Text>
										)}
									</View>
									<View style={styles.formFieldWrapperSmall}>
										<Text style={styles.labelText}>{t('lastName')}</Text>
										<Input
											placeholder={t('fieldLabels.lastName')}
											name='lastName'
											value={formikProps.values['lastName']}
											onChange={formikProps.setFieldValue}
											onBlur={() => formikProps.setFieldTouched('lastName', true)} />
										{formikProps.touched['lastName'] && formikProps.errors['lastName'] && (
											<Text style={styles.errorText}>{`*${formikProps.errors['lastName']}`}</Text>
										)}
									</View>
								</View>
								<View style={styles.formFieldWrapper}>
									<Text style={styles.labelText}>{t('email')}</Text>
									<Input
										placeholder={t('fieldLabels.email')}
										name='email'
										value={formikProps.values['email']}
										onChange={formikProps.setFieldValue}
										onBlur={() => formikProps.setFieldTouched('email', true)} />
									{formikProps.touched['email'] && formikProps.errors['email'] && (
										<Text style={styles.errorText}>{`*${formikProps.errors['email']}`}</Text>
									)}
								</View>
								<View style={styles.formFieldWrapper}>
									<Text style={styles.labelText}>{t('title')}</Text>
									<Input
										placeholder={t('title')}
										name='title'
										value={formikProps.values['title']}
										onChange={formikProps.setFieldValue}
										onBlur={() => formikProps.setFieldTouched('title', true)} />
									{formikProps.touched['title'] && formikProps.errors['title'] && (
										<Text style={styles.errorText}>{`*${formikProps.errors['title']}`}</Text>
									)}
								</View>


								<View style={styles.formFieldWrapper}>
									<Text style={styles.labelText}>{t('phone')}</Text>
									<PhoneInput
										name={{
											prefix: 'prefix',
											number: 'phone',
										}}
										phone={{
											prefix: formikProps.values['prefix'],
											number: formikProps.values['phone']
										}}
										onChange={formikProps.setFieldValue}
										onBlur={formikProps.setFieldTouched}
									/>
									{formikProps.touched['phone'] && formikProps.errors['phone'] && (
										<Text style={styles.errorText}>{`*${formikProps.errors['phone']}`}</Text>
									)}
								</View>
								<Text style={styles.labelText}>{t('permissions')}</Text>
								<View style={isSmallScreen ? styles.checkBoxContanerSmall : styles.checkBoxContainer}>
									{
										permissionsList && permissionsList.map((item) => 
											<View style={isSmallScreen ? styles.checkElementSmall : styles.checkElement}>
												<CheckBoxCustom
													text={item.text}
													name={item.name}
													permissionKey={item.permissionKey}
													disabled={item.default}
													hasTooltip={item.tooltip.length ? true : false}
													tooltip={item.tooltip}
													hasDropDown={item.childs.length ? true : false}
													dropdownChecksList={item.childs}
													permissionKeys={permissionsKeys}
													setPermissionKeys={setPermissionsKeys}/>
											</View>
										)
									}
								</View> 
						
								<View style={styles.footer}>
									<TouchableOpacity 
										style={styles.cancelButton}
										onPress={props.onClose}>
										<Icon style={styles.icon} name="close" size={18} color={theme.light.colors.linkColor} />
										<Text style={styles.cancelButtonText}>{t('cancel')}</Text>
									</TouchableOpacity>
									<TouchableOpacity 
										onPress={() => formikProps.handleSubmit()}
										style={styles.button}>
										<Icon style={styles.icon} name="check" size={18} />
										<Text style={styles.buttonText}>{t('create')}</Text>
									</TouchableOpacity>
								</View>
							</View>
						</View>
					)}
				</Formik>
			}
			<Snackbar
				visible={snackbar.isVisible}
				onDismiss={() => setSnackbar(snackbarInitialState)}
				type={snackbar.type}
				message={snackbar.message}
			/>
		</Modal>
	)
}

