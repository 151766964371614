import React, { FC } from 'react'
import { StyleSheet, TextInput, View } from 'react-native'
import SelectDropdown from 'react-native-select-dropdown'
import FontAwesome from 'react-native-vector-icons/FontAwesome'
import { Phone } from '@utils/interfaces'
import { phonePrefixes, screenType } from '@utils/constants'
import { colors } from '@styles/constants'
import { styles } from './styles'
import useScreenDimensions from '@components/Common/UseScreenDimensions'

interface Props {
    name: Phone
    phone: Phone
	onChange: (field: string , value: string) => void
    onBlur?: (field: string, isTouched: boolean) => void
    phoneNumberPlaceholder?: string
	editable?: boolean
}

export const PhoneInput: FC<Props> = ({
	name: {
		prefix: prefixName,
		number: phoneName,
	},
	onChange,
	onBlur,
	phone: {
		prefix,
		number,
	},
	phoneNumberPlaceholder,
	editable
}) => {
	const screenDimensions = useScreenDimensions()
	const isMobileScreen = screenDimensions.width < screenType.phone

	const renderDropdownIcon = (isOpened: boolean) => {
		return <FontAwesome 
			name={isOpened ? 'caret-down' : 'caret-right'} 
			color={colors.lightGrayIcon} 
			size={20}
		/>
	}

	return (
		<View style={styles.formField}>
			<SelectDropdown
				data={phonePrefixes}
				defaultButtonText={prefix}
				onSelect={(selectedItem) => onChange(prefixName, selectedItem)}
				{...(onBlur ? {onBlur: () => onBlur(prefixName, true)} : {})}
				buttonStyle={isMobileScreen ? {...styles.dropdownBttn_mobile, width: '33%', paddingHorizontal: 0} : styles.dropdownButton}
				buttonTextStyle={styles.dropdownButtonText}
				dropdownStyle={styles.dropdown}
				rowTextStyle={styles.dropdownRowText}
				renderDropdownIcon={isOpened => renderDropdownIcon(isOpened)}
				disabled={editable !== undefined ? !editable : false}
			/>
			<TextInput
				value={number}
				placeholder={phoneNumberPlaceholder ?? ''}
				onChange={(event) => onChange(phoneName, event.nativeEvent.text)}
				{...(onBlur ? {onBlur: () => onBlur(phoneName, true)} : {})}
				style={isMobileScreen ? { ...styles.textField, width: '67%' } : styles.textField}
				editable={editable !== undefined ? editable : true}
			/>
		</View>
	)
}
