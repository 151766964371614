import { StyleSheet } from 'react-native'
import { colors, theme } from '@styles/constants'
import MultiSelect from 'react-native-multiple-select'

interface Props {
	placeholder: string;
	displayKey: string;
	uniqueKey: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onSelectedItemsChange: (selected: any, field?: string) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	selectedItems: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	items: any;
	searchText: string;
}

export const MultiSelectDropdown = (props : Props) => {

	return (
		<MultiSelect
			hideTags
			items={props.items}
			uniqueKey={props.uniqueKey}
			onSelectedItemsChange={props.onSelectedItemsChange}
			selectedItems={props.selectedItems}
			selectText={props.searchText}
			searchInputPlaceholderText={props.placeholder}
			styleMainWrapper={styles.styleMainWrapper}
			styleDropdownMenu={styles.styleDropdownMenu}
			styleDropdownMenuSubsection={styles.styleDropdownMenuSubsection}
			styleIndicator={styles.styleIndicator}
			styleInputGroup={styles.styleInputGroup}
			searchInputStyle={styles.searchInputStyle}
			fontFamily="Arial, sans-serif"
			fontSize={16}
			selectedItemTextColor={theme.light.colors.mainColor}
			selectedItemIconColor={theme.light.colors.mainColor}
			itemTextColor={theme.light.colors.secondaryDark}
			itemFontSize={16}
			displayKey={props.displayKey}
			hideSubmitButton={true}
			tagRemoveIconColor={theme.light.colors.secondaryDark}
			tagBorderColor={theme.light.colors.secondaryDark}
			tagTextColor={theme.light.colors.secondaryDark}
			submitButtonColor={theme.light.colors.secondaryDark}
		/>
	)
}

const styles = StyleSheet.create({
	searchInputStyle: {
		fontSize: 16,
		borderRadius: 4,
		padding: 6,
		backgroundColor: theme.light.colors.mainContainer,
		width: '100%',
		outlineStyle: 'none',
		height: 30
	},
	styleMainWrapper: {
		backgroundColor: theme.light.colors.mainContainer,
		borderRadius: 4,
		paddingHorizontal: 0,
		borderWidth: 1,
		borderColor: theme.light.colors.mainColor,
	},
	styleDropdownMenu: {
		margin: 0,
		padding: 0,
		height: 26
	},
	styleDropdownMenuSubsection: {
		padding: 0,
		paddingLeft: 8,
		marginTop: 10,
		height: 20,
		borderColor: 'transparent',
		outlineStyle: 'none'
	},
	styleInputGroup: {
		borderRadius: 4
	},
	styleIndicator: {
		color: theme.light.colors.mainColor
	}
})