import React, { useEffect, useState } from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import { theme } from '@styles/constants'
import { useTranslation } from 'react-i18next'
import useScreenDimensions from '@components/Common/UseScreenDimensions'
import { useNavigate } from 'react-router-dom'
import FontAwesome from 'react-native-vector-icons/FontAwesome'
import { useSelector } from 'react-redux'
import { rootReducerType } from '@reducers/combineReducers'
import Icon from 'react-native-vector-icons/FontAwesome'
import { CheckBoxCustom } from '@components/CheckBox/CheckBox'
import { AdvisorValues, Company, Permission, PermissionList, SnackbarInitialState } from '@utils/interfaces'
import { Input } from '@components/Input'
import { MultiSelectDropdown } from '@components/MultiSelect'
import { PhoneInput } from '@components/PhoneInput/PhoneInput'
import { screenType, phonePrefixes, companyPermissionsArray, snackbarInitialState } from '@utils/constants'
import { axiosBFFServer } from '@services/connectionServer'
import { Snackbar } from '@components/Snackbar/Snackbar'
import { SnackbarTypes} from '@utils/interfaces'
import { checkIfPermissionExist, getDate, mapAdvisorCheckboxTree } from '@utils/utils'
import { Loader } from '@components/Loader/Loader'
import { createAdvisorSchema } from '@utils/formValidation'
import { Formik } from 'formik'
import { styles } from './styles'
import {useDispatch} from 'react-redux'
import {patchUserInfo} from '@actions/userActions'
import { UseErrorMessages } from '@components/Common/UseErrorMessage'

export const AdvisorEditContainer = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { getErrorMessage } = UseErrorMessages()

	const currentAdvisorKey = useSelector((state: rootReducerType) => state.setAdvisorKeyReducer.keyEdit)
	const advisorEmail = useSelector((state: rootReducerType) => state.setAdvisorKeyReducer.advisorEmail)
	const loggedUserEmail = useSelector((state: rootReducerType) => state.userReducer.email)
	const userPermissions = useSelector((state: rootReducerType) => state.userReducer.permissions)
	const editPermission = 	checkIfPermissionExist(userPermissions, 'advisor', 'update')
	const hasEditPermission = editPermission || loggedUserEmail === advisorEmail

	const screenDimensions = useScreenDimensions()
	const isSmallScreen = screenDimensions.width < screenType.phone
	
	const [selectedCompanies, setSelectedCompanies] = useState<string[]>([])
	const [companies, setCompanies] = useState<Company[]>([])

	const [companyPermissionList, setCompanyPermissionList] = useState<PermissionList[]>()
	const [partnerPermissionList, setPartnerPermissionList] = useState<PermissionList[]>()
	const [companyPermissionKeys, setCompanyPermissionKeys] = useState<string[]>([])
	const [initialCompanyPermissions, setInitialCompanyPermissions] = useState<string[]>([])
	const [snackbar, setSnackbar] = useState<SnackbarInitialState>(snackbarInitialState)
	const [isLoading, setIsLoading] = useState(true)

	const [disabledPermissions, setDisabledPermissions] = useState(false)

	const [currentAdvisor, setCurrentAdvisor] = useState({
		key: '1',
		firstName: '',
		lastName: '',
		email: '',
		title: '',
		phone: '',
		prefix: phonePrefixes[0],
		language: '',
		isEmailVerified: false,
		companyKeys: [''],
		permissionCategory: '',
		permissionKeys: [],
		active: false, 
		verifiedEmail: false,
		accountCreateDate: ''
	})
	const [initialAdvisor, setInitialAdvisor] = useState({
		key: '1',
		firstName: '',
		lastName: '',
		email: '',
		title: '',
		phone: '',
		prefix: phonePrefixes[0],
		language: '',
		isEmailVerified: false,
		companyKeys: [''],
		permissionCategory: '',
		permissionKeys: [],
		active: false, 
		verifiedEmail: false,
		accountCreateDate: ''
	})
	const initials = currentAdvisor.firstName || currentAdvisor.lastName ? 
		(currentAdvisor.firstName?.charAt(0) || '') + (currentAdvisor.lastName?.charAt(0) || '') : currentAdvisor.email?.charAt(0)

	const mapPermissionKeysToCheckboxes = (permissions) => {
		const newCompanyPermissions: string[] = []
		const newPartnerPermissions: string[] = []
		permissions.filter((item) => !item.default ).forEach((permission) => {
			if (companyPermissionsArray.find((entry) => entry === permission.subject)) {
				newCompanyPermissions.push(permission.permissionKey)
				return
			}
			newPartnerPermissions.push(permission.permissionKey)
		})
		return {
			companyPermissions: newCompanyPermissions, 
			partnerPermissions: newPartnerPermissions
		}
	}

	const getCompanies = () => {
		axiosBFFServer
			.post('/company/search?page=1&size=100')
			.then(response => {
				setCompanies(response.data.pageResults)
			})
			.catch((error) => 	{
				const errorMessage = getErrorMessage(error, t('errorCompanies'))
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}

	const getPermissions = () => {
		let permissions: Permission[] = []
		axiosBFFServer
			.get(`/permission/email/${advisorEmail}`)
			.then((response) => {
				permissions = response.data
				if (permissions) {
					const mappedTrees = mapAdvisorCheckboxTree(permissions)
					setCompanyPermissionList(mappedTrees.companyTree)
					setPartnerPermissionList(mappedTrees.partnerTree)
				}
			})
			.catch((error) => {
				const errorMessage = getErrorMessage(error, t('getPermissions'))
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}

	const getcurrentAdvisor = () => {
		axiosBFFServer
			.get(`/advisor/${currentAdvisorKey}`)
			.then(response => {
				setIsLoading(false)
				const { firstName, lastName, email, phoneNumber, permissions, verifiedEmail, disabledLogin, createdAt, title, companyKeys } = response.data
				const currentData = {
					...currentAdvisor, firstName, lastName, email, verifiedEmail, companyKeys,
					active: !disabledLogin,
					prefix: phoneNumber.slice(0,3),
					phone: phoneNumber.slice(3),
					accountCreateDate: getDate(createdAt),
					title: title || ''
				}
				setSelectedCompanies(companyKeys)
				setCurrentAdvisor(currentData)
				setInitialAdvisor(currentData)
				const mappedPermissions = mapPermissionKeysToCheckboxes(permissions)
				setInitialCompanyPermissions(mappedPermissions.companyPermissions.concat(mappedPermissions.partnerPermissions))
				setCompanyPermissionKeys(mappedPermissions.companyPermissions.concat(mappedPermissions.partnerPermissions))
			})
			.catch((error) =>{
				setIsLoading(false)
				const errorMessage = getErrorMessage(error, t('advisorsListError'))
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}

	const onSelectedCompaniesChange = (selected) => {
		setSelectedCompanies(selected)
	}

	const submitForm = (values: AdvisorValues) => {
		const { firstName, lastName, email, phone, prefix, companyKeys, title } =  values as AdvisorValues
		const initialPhone = initialAdvisor.prefix + initialAdvisor.phone
	
		const fields = {
			firstName,
			lastName, 
			email,
			phoneNumber: prefix + phone,
			title,
			companyKeys
		}
		let changedFields = {}

		for (const item in fields){
			if (item === 'phoneNumber'){
				fields[item] != initialPhone ? changedFields = {...changedFields, ...{phoneNumber: fields[item]}} : changedFields
			} else {
				fields[item] != initialAdvisor[item] ? changedFields = {...changedFields, ...{[item]: fields[item]}} : changedFields
			}
		}

		JSON.stringify(initialCompanyPermissions) !== JSON.stringify(companyPermissionKeys) ?  changedFields = {...changedFields, ...{permissionKeys: companyPermissionKeys}} : changedFields

		if (Object.keys(changedFields).length){
			axiosBFFServer
				.patch(`/advisor/${currentAdvisorKey}`, changedFields)
				.then((response) => {
					if(response.status === 200) {
						dispatch(patchUserInfo(changedFields))
						setSnackbar({
							isVisible: true,
							type: SnackbarTypes.SUCCESS,
							message: t('success.editAdvisor'),
						})
					} else throw new Error()
				})
				.catch((error) => {
					const errorMessage = error.response.data.customStatus === '609' ? t('emailAlreadyUsed') + ' ' + getErrorMessage(error, '') : getErrorMessage(error, t('errors.editAdvisor') )
					
					setSnackbar({
						isVisible: true,
						type: SnackbarTypes.ERROR,
						message: errorMessage,
					})
				})
		}
	}

	useEffect(() => {
		if (currentAdvisorKey) getcurrentAdvisor()
	}, [currentAdvisorKey])

	useEffect(() => {
		getCompanies()
		advisorEmail && getPermissions()
	}, [])

	useEffect(() => {
		selectedCompanies.length &&
		setCurrentAdvisor(
			{
				...currentAdvisor,
				companyKeys: selectedCompanies
			}
		)
	}, [selectedCompanies])

	useEffect(() => {
		advisorEmail === loggedUserEmail && setDisabledPermissions(true)
	},[advisorEmail])

	useEffect(() => {
		setCompanyPermissionKeys(initialCompanyPermissions)
	},[initialCompanyPermissions])


	return (
		<View style={styles.container}>
			<View style={styles.header}>
				<TouchableOpacity 
					style={styles.backButton}
					onPress={() => navigate(-1)}>
					<Icon style={styles.backIcon} name="arrow-left" size={18} />
					<Text style={styles.backButtonText}>{t('back')}</Text>
				</TouchableOpacity>
				<Text style={styles.title}>{`${currentAdvisor.firstName || ''} ${currentAdvisor.lastName || ''}`}</Text>
			</View>
			{isLoading ?
				<Loader
					color={theme.light.colors.mainColor} /> 
				:
				<Formik 
					enableReinitialize={true} 
					initialValues={currentAdvisor} 
					validationSchema={createAdvisorSchema} 
					onSubmit={submitForm}>
					{(formikProps) => (
						<View style={styles.form}>
							<View style={isSmallScreen ? styles.header_form_phone : styles.header_form}>
								<View style={styles.profileAvatar}>
									<Text style={styles.avatarText}>{initials?.toUpperCase() || ''}</Text>
								</View>
								<View style={styles.infoContainer}>
									<View style={styles.textIconContainer}>
										<FontAwesome 
											name={'calendar'} 
											color={theme.light.colors.mainColor} 
											size={18} /> 
										<Text style={styles.text}> {currentAdvisor.accountCreateDate ? `${t('accuntCreated')} ${currentAdvisor.accountCreateDate}` : ''}</Text>
									</View>
									<View style={styles.textIconContainer}>
										<FontAwesome 
											name={currentAdvisor.active ? 'check' : 'warning'} 
											color={currentAdvisor.active ? theme.light.colors.mainColor : theme.light.colors.warningDark} 
											size={18} /> 
										<Text style={styles.text}>{currentAdvisor.active ? t('enableLogin') : t('disableLogin')}</Text>
									</View>
									<View style={styles.textIconContainer}>
										<FontAwesome 
											name={currentAdvisor.verifiedEmail ? 'check' : 'warning'} 
											color={currentAdvisor.verifiedEmail ? theme.light.colors.mainColor : theme.light.colors.warningDark} 
											size={18} /> 
										<Text style={styles.text}>{currentAdvisor.verifiedEmail ? t('emailVerified') : t('emailNotVerified')}</Text>
									</View>
								</View>				
							</View>
							<View style={styles.formInputsContainer}>
								<View style={isSmallScreen ? styles.formFieldsPhone : styles.formFields}>
									<View style={isSmallScreen ? styles.formFieldsColumnPhone : styles.formFieldsColumn}>
										<View style={styles.formFieldWrapper}>
											<Text style={styles.labelText}>{t('firstName')}</Text>
											<Input
												placeholder={t('firstName')}
												name='firstName'
												value={formikProps.values['firstName']}
												editable={hasEditPermission}
												onChange={formikProps.setFieldValue}
												onBlur={() => formikProps.setFieldTouched('firstName', true)} />
											{formikProps.touched['firstName'] && formikProps.errors['firstName'] && (
												<Text style={styles.errorText}>{`*${formikProps.errors['firstName']}`}</Text>
											)}
										</View>
										<View style={styles.formFieldWrapper}>
											<Text style={styles.labelText}>{t('email')}</Text>
											<Input
												placeholder={t('email')}
												name='email'
												value={formikProps.values['email']}
												editable={hasEditPermission}
												onChange={formikProps.setFieldValue}
												onBlur={() => formikProps.setFieldTouched('email', true)} />
											{formikProps.touched['email'] && formikProps.errors['email'] && (
												<Text style={styles.errorText}>{`*${formikProps.errors['email']}`}</Text>
											)}
										</View>

										<View style={styles.formFieldWrapper}>
											<Text style={styles.labelText}>{t('companies')}</Text>
											<MultiSelectDropdown
												items={companies}
												selectedItems={selectedCompanies}
												onSelectedItemsChange={onSelectedCompaniesChange}
												placeholder={t('selectCompany')}
												displayKey="name"
												uniqueKey="companyKey"
												searchText={t('selectCompany')}
											/>
										</View>
									</View>
									<View style={isSmallScreen ? styles.formFieldsColumnPhone : styles.formFieldsColumn}>
										<View style={styles.formFieldWrapper}>
											<Text style={styles.labelText}>{t('lastName')}</Text>
											<Input
												placeholder={t('lastName')}
												name='lastName'
												value={formikProps.values['lastName']}
												editable={hasEditPermission}
												onChange={formikProps.setFieldValue}
												onBlur={() => formikProps.setFieldTouched('lastName', true)} />
											{formikProps.touched['lastName'] && formikProps.errors['lastName'] && (
												<Text style={styles.errorText}>{`*${formikProps.errors['lastName']}`}</Text>
											)}
										</View>
										<View style={styles.formFieldWrapper}>
											<Text style={styles.labelText}>{t('title')}</Text>
											<Input
												placeholder={t('title')}
												name='title'
												value={formikProps.values['title']}
												editable={hasEditPermission}
												onChange={formikProps.setFieldValue}
												onBlur={() => formikProps.setFieldTouched('title', true)} />
											{formikProps.touched['title'] && formikProps.errors['title'] && (
												<Text style={styles.errorText}>{`*${formikProps.errors['title']}`}</Text>
											)}
										</View>
										<View style={styles.formFieldWrapper}>
											<Text style={styles.labelText}>{t('phone')}</Text>
											<PhoneInput
												name={{
													prefix: 'prefix',
													number: 'phone',
												}}
												phone={{
													prefix: formikProps.values['prefix'],
													number: formikProps.values['phone']
												}}
												editable={hasEditPermission}
												onChange={formikProps.setFieldValue}
												onBlur={formikProps.setFieldTouched}
											/>
											{formikProps.touched['phone'] && formikProps.errors['phone'] && (
												<Text style={styles.errorText}>{`*${formikProps.errors['phone']}`}</Text>
											)}
										</View>
									</View>
								</View>
								<View style={isSmallScreen ? styles.checkBoxContainerSmallScreen : styles.checkBoxContainer}>
									<View style={isSmallScreen ? styles.columnCheckItemsSmallScreen : styles.columnCheckItems}>
										<Text style={styles.labelText}>{t('companyPermissions')}</Text>
										{
											companyPermissionList && companyPermissionList.map((item) => 
												<CheckBoxCustom
													text={item.text}
													name={item.name}
													permissionKey={item.permissionKey}
													disabled={disabledPermissions ? disabledPermissions : !hasEditPermission}
													hasTooltip={item.tooltip.length ? true : false}
													tooltip={item.tooltip}
													hasDropDown={item.childs.length ? true : false}
													dropdownChecksList={item.childs}
													permissionKeys={companyPermissionKeys}
													setPermissionKeys={setCompanyPermissionKeys}
													initialPermissions={initialCompanyPermissions}/>
											)
										}
			
									</View>
									<View style={isSmallScreen ? styles.columnCheckItemsSmallScreen : styles.columnCheckItems}>
										<Text style={styles.labelText}>{t('partnerPermissions')}</Text>
										{
											partnerPermissionList && partnerPermissionList.map((item) => 
												<CheckBoxCustom
													text={item.text}
													name={item.name}
													permissionKey={item.permissionKey}
													disabled={disabledPermissions ? disabledPermissions : !hasEditPermission}
													hasTooltip={item.tooltip.length ? true : false}
													tooltip={item.tooltip}
													hasDropDown={item.childs.length ? true : false}
													dropdownChecksList={item.childs}
													permissionKeys={companyPermissionKeys}
													setPermissionKeys={setCompanyPermissionKeys}
													initialPermissions={initialCompanyPermissions}/>
											)
										}
									</View>
								</View>
							</View>
							{hasEditPermission && <View style={styles.footer}>
								<TouchableOpacity 
									style={styles.button}
									onPress={() => formikProps.handleSubmit()}>
									<Icon name="check" size={18} color={theme.light.colors.linkColorActive} />
									<Text style={styles.buttonText}>{t('submit')}</Text>
								</TouchableOpacity>
							</View>}
						</View>
					)}
				</Formik>
			}
			<Snackbar
				visible={snackbar.isVisible}
				onDismiss={() => setSnackbar(snackbarInitialState)}
				type={snackbar.type}
				message={snackbar.message}
			/>
		</View>
	)
}

