import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dimensions, Text, View } from 'react-native'
import { useSelector } from 'react-redux'
import { rootReducerType } from '@reducers/combineReducers'
import useScreenDimensions from '@components/Common/UseScreenDimensions'
import Table from '@components/Table/Table'
import { CasesBox } from '@components/CasesBox/CasesBox'
import {
	Case,
	CaseStatistic,
	CaseTypeList,
	CompanyCase,
	SeverityType,
	SnackbarInitialState,
	SnackbarTypes,
	StatusCaseType,
	TableColumn,
} from '@utils/interfaces'
import { dashboardCasesTableColumns } from '@utils/constants'
import { colors } from '@styles/constants'
import { BarChart, LineChart } from 'react-native-chart-kit'
import { DatePickerRange } from '@components/DatePickerRange/DatePickerRange'
import { screenType, snackbarInitialState } from '@utils/constants'
import { axiosBFFServer } from '@services/connectionServer'
import { Loader } from '@components/Loader/Loader'
import {
	formatDate,
	formatDateRo,
	getDeadline,
	getPreviousMonth,
} from '@utils/utils'
import { Snackbar } from '@components/Snackbar/Snackbar'
import { styles } from './styles'
import { UseErrorMessages } from '@components/Common/UseErrorMessage'

export const CompanyDashboard = () => {
	const { t } = useTranslation()
	const { getErrorMessage } = UseErrorMessages()

	const language = useSelector(
		(state: rootReducerType) => state.userReducer.language
	)
	const screenDimensions = useScreenDimensions()
	const isSmallScreen = screenDimensions.width < screenType.tablet
	const companyKey = useSelector(
		(state: rootReducerType) => state.companyReducer.key
	)
	const size = useSelector(
		(state: rootReducerType) => state.paginationReducer.pageSize
	)
	const sorting = useSelector(
		(state: rootReducerType) => state.sortReducer.cases
	)
	const advisorKey = useSelector(
		(state: rootReducerType) => state.setAdvisorKeyReducer.key
	)

	const [casesStatistic, setCasesStatistic] = useState<CaseStatistic[]>([
		{
			created: '',
			case: 0,
		},
	])
	const [range, setRange] = useState({
		startDate: getPreviousMonth(),
		endDate: new Date(),
	})
	const [totalCases, setTotalCases] = useState(10)
	const [page, setPage] = useState(1)
	const [isLoading, setIsLoading] = useState(true)
	const [snackbar, setSnackbar] =
		useState<SnackbarInitialState>(snackbarInitialState)
	const [hasStatistics, setHasStatistics] = useState(false)
	const [casesList, setCasesList] = useState<CompanyCase[]>([
		{
			id: '',
			subject: '',
			category: '',
			severity: '',
			deadline: '',
			status: '',
			createdAt: '',
		},
	])
	const [caseTypesList, setCaseTypesList] = useState<CaseTypeList[]>([
		{
			type: 'new cases',
			number: 0,
			icon: false,
		},
	])

	const chartData = {
		labels: casesStatistic.map((item) => item.created),
		datasets: [
			{
				data: casesStatistic.map((item) => item.case),
			},
		],
	}
	const chartConfig = {
		backgroundColor: colors.white,
		backgroundGradientFrom: colors.white,
		backgroundGradientTo: colors.pureWhite,
		decimalPlaces: 0,
		color: (opacity = 1) => `${colors.darkShades}, ${opacity})`,
		labelColor: (opacity = 1) => `${colors.darkShades}, ${opacity})`,
		style: {
			borderRadius: 4,
		},
		propsForDots: {
			r: '6',
			strokeWidth: '2',
			stroke: colors.black,
		},
	}

	const renderTableCell = (column: TableColumn, item: Case) => {
		switch (column.key) {
		case 'status':
			return (
				<View style={styles.itemText}>
					<Text style={styles.grayText}>{item[column.key]}</Text>
				</View>
			)
		case 'severity':
			return (
				<View
					style={[
						styles.itemText,
						{
							borderColor:
									item[column.key] === SeverityType.URGENT ||
									item[column.key] === SeverityType.SEVERE
										? colors.danger
										: item[column.key] === SeverityType.MINOR
											? colors.secondary
											: colors.yellow,
							backgroundColor:
									item[column.key] === SeverityType.URGENT ||
									item[column.key] === SeverityType.SEVERE
										? colors.dimmedRed
										: item[column.key] === SeverityType.MINOR
											? colors.dimmedGreen
											: colors.dimmedYellow,
						},
					]}
				>
					<Text style={styles.grayText}>{item[column.key]}</Text>
				</View>
			)
		case 'deadline':
			return (
				<View style={styles.itemText}>
					<Text
						style={{
							color: item.deadline.includes(t('ago'))
								? colors.danger
								: colors.secondary,
							borderColor: colors.darkGray,
							fontWeight: '500',
						}}
					>
						{item[column.key]}
					</Text>
				</View>
			)
		case 'createdAt':
			return (
				<Text
					style={
						isSmallScreen ? styles.tableCellTextMobile : styles.tableCellText
					}
				>
					{formatDateRo(item[column.key])}
				</Text>
			)
		case 'last_activity':
			return (
				<View style={styles.itemText}>
					<Text style={styles.grayText}>{item.lastActivity}</Text>
				</View>
			)
		default:
			return (
				<Text
					style={[
						isSmallScreen ? styles.tableCellTextMobile : styles.tableCellText,
					]}
				>
					{item[column.key]}
				</Text>
			)
		}
	}

	const getCases = () => {
		let filterItems = {
			creationDateStart: formatDate(range.startDate),
			creationDateEnd: formatDate(range.endDate),
			companyKeys: [companyKey],
		}
		sorting.by
			? (filterItems = Object.assign(filterItems, { sort: sorting }))
			: filterItems
		advisorKey
			? (filterItems = Object.assign(filterItems, {
				advisorKeys: [advisorKey],
			}))
			: filterItems

		axiosBFFServer
			.post(`/case/search?page=${page}&size=${size}`, filterItems)
			.then((response) => {
				const cases: CompanyCase[] = []
				response.data.pageResults.forEach((item) => {
					cases.push({
						id: item.tag,
						subject: item.subject,
						category: item.category ? item.category.value : '',
						severity: item.severity,
						deadline: getDeadline(item.deadline, language),
						status: item.caseState.status,
						createdAt: item.createdAt,
						lastActivity: getDeadline(item.lastActivity.slice(0, 10), language),
					})
				})
				setCasesList(cases)
				setTotalCases(response.data.totalResults)
				setIsLoading(false)
			})
			.catch((error) => {
				const errorMessage = getErrorMessage(error, t('errorCases'))
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage,
				})
				setIsLoading(false)
			})
	}

	useMemo(() => {
		getCases()
	}, [sorting, page, size, range, language])

	useEffect(() => {
		let newCases = 0
		let openCases = 0
		let closesCases = 0
		let rejectedCases = 0

		casesList.forEach((item) =>
			item.status === StatusCaseType.NEW
				? (newCases += 1)
				: item.status === StatusCaseType.OPEN
					? openCases++
					: item.status === StatusCaseType.CLOSED
						? closesCases++
						: item.status === StatusCaseType.REJECTED
							? rejectedCases++
							: null
		)

		casesList &&
			setCaseTypesList([
				{
					type: `${StatusCaseType.NEW.toLowerCase()} cases`,
					number: newCases,
					icon: false,
				},
				{
					type: `${StatusCaseType.OPEN.toLowerCase()} cases`,
					number: openCases,
					icon: false,
				},
				{
					type: `${StatusCaseType.CLOSED.toLowerCase()} cases`,
					number: closesCases,
					icon: false,
				},
				{
					type: `${StatusCaseType.REJECTED.toLowerCase()} cases`,
					number: rejectedCases,
					icon: false,
				},
			])

		const statisticsData: CaseStatistic[] = []
		const caseDates = casesList.map((item) => item.createdAt)
		const frequency = new Map()

		for (const date of caseDates) {
			let currentDateCount = frequency.get(date)
			if (currentDateCount != undefined) {
				frequency.delete(date)
				frequency.set(date, ++currentDateCount)
			} else frequency.set(date, 1)
		}

		const uniqueDates = caseDates.filter(
			(value, index, array) => array.indexOf(value) === index
		)
		uniqueDates.length ? setHasStatistics(true) : setHasStatistics(false)
		uniqueDates.forEach((value) =>
			statisticsData.push({
				created: formatDateRo(value),
				case: frequency.get(value),
			})
		)

		setCasesStatistic(statisticsData)
	}, [casesList, language])

	return (
		<View style={styles.container}>
			<Snackbar
				visible={snackbar.isVisible}
				onDismiss={() => setSnackbar(snackbarInitialState)}
				type={snackbar.type}
				message={snackbar.message}
			/>
			<View>
				<Text style={styles.subtitle}>{t('companyCases')}</Text>
				{isLoading ? (
					<Loader color={colors.white} />
				) : (
					<Table
						data={casesList}
						columns={dashboardCasesTableColumns}
						isSmallScreen={isSmallScreen}
						renderTableCell={renderTableCell}
						hasPagination={true}
						totalRecords={totalCases}
						page={page}
						setPage={setPage}
					/>
				)}
			</View>
			<View>
				<Text style={styles.subtitle}>{t('allCases')}</Text>
				<View style={styles.sectionContainer}>
					<CasesBox cases={caseTypesList} />
				</View>
				<Text style={styles.subtitle}>{t('statistics')}</Text>
				<View style={styles.sectionContainer}>
					<View style={styles.sectionHeader}>
						<DatePickerRange range={range} setRange={setRange} />
					</View>

					<View
						style={
							isSmallScreen
								? styles.elementsContainerMobile
								: styles.elementsContainer
						}
					>
						{hasStatistics ? (
							<>
								<LineChart
									data={chartData}
									width={
										isSmallScreen
											? Dimensions.get('window').width / 1.3
											: Dimensions.get('window').width / 4
									}
									height={300}
									yAxisSuffix={t('cases')}
									yAxisInterval={1}
									chartConfig={chartConfig}
									bezier
									style={styles.lineChart}
								/>
								<BarChart
									style={styles.barChart}
									data={chartData}
									width={
										isSmallScreen
											? Dimensions.get('window').width / 1.3
											: Dimensions.get('window').width / 4
									}
									height={300}
									yAxisSuffix={t('cases')}
									yAxisLabel=''
									chartConfig={chartConfig}
									verticalLabelRotation={0}
								/>
							</>
						) : (
							<Text style={styles.text}>{t('noStatistics')}</Text>
						)}
					</View>
				</View>
			</View>
		</View>
	)
}
