import React, { useEffect, useState } from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import { theme } from '@styles/constants'
import useScreenDimensions from '@components/Common/UseScreenDimensions'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { rootReducerType } from '@reducers/combineReducers'
import {  CaseDocumentsColumns, caseLogsColumns } from '@utils/constants'
import { CaseLogs, Documents, MasterPassowdFormValues, SnackbarInitialState, SnackbarTypes, TableColumn, ViewTypes, caseDetails } from '@utils/interfaces'
import Table from '@components/Table/Table'
import { ItemWithTooltip } from '@components/ItemWithTooltip'
import Icon from 'react-native-vector-icons/FontAwesome'
import { createCaseManuallyProperties, iv, screenType, snackbarInitialState } from '@utils/constants'
import { axiosBFFServer } from '@services/connectionServer'
import { Snackbar } from '@components/Snackbar/Snackbar'
import { decryptDocs, downloadFile, formatDateRo, getCaseType, getDateAndTime, isPartnerOrAdvisor, textTransform } from '@utils/utils'
import { decryptValueWithAES } from '@utils/encryption'
import forge from 'node-forge'
import { Messages } from '@components/Messages/Messages'
import { NotesBox } from '@components/Notes/NotesBox'
import { styles } from './styles'
import {MasterPasswordForm} from '@components/MasterPasswordForm/MasterPasswordForm'
import {useDispatch} from 'react-redux'
import {setCaseKey, setCaseViewKey} from '@actions/caseKeyActions'
import { UseErrorMessages } from '@components/Common/UseErrorMessage'

export const CaseDetails = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const [ logsPage, setLogsPage ] = useState(1)
	const size = useSelector((state: rootReducerType) => state.paginationReducer.pageSize)
	const location = useLocation()
	const dispatch = useDispatch()

	const screenDimensions = useScreenDimensions()
	const isSmallScreen = screenDimensions.width < screenType.phone
	const { getErrorMessage } = UseErrorMessages()
	
	const caseKeyView = location.pathname.split('/')[4]
	const companiesData = useSelector((state: rootReducerType) => state.companyReducer.companies)
	const [ caseDetail, setCaseDetail ] = useState<caseDetails | null>(null)
	const [ snackbar, setSnackbar ] = useState<SnackbarInitialState>(snackbarInitialState)
	const [ activityLogs, setActivityLog ] = useState<CaseLogs[]>([])
	const [ files, setFiles ] = useState<Documents[]>([])
	const [ totalLogs, setTotalLogs ] = useState(0)
	const [ companyKey, setCompanyKey ] = useState('')
	const [ secretKey, setSecretKey ] = useState('')
	const [ caseType, setCaseType ] = useState('')

	useEffect(() => {
		companiesData.map((company: {name, companyKey}) => caseDetail && company.name === caseDetail.companyName ? setCompanyKey(company.companyKey) : '')
	}, [caseDetail])

	const { roles, language } = useSelector((state: rootReducerType) => state.userReducer)
	const masterPassword = useSelector((state: rootReducerType) => state.masterPasswordReducer.password)
	const { publicKey, encodedPrivateKey } = useSelector((state: rootReducerType) => isPartnerOrAdvisor(roles)
		? state.legalPartnerKeyReducer
		: state.companyReducer.currentCompany
	)

	const decryptedPrivateKey = masterPassword && decryptValueWithAES(masterPassword, iv, encodedPrivateKey)
	const privateKeyObj = decryptedPrivateKey && forge.pki.privateKeyFromPem(decryptedPrivateKey)
	

	const handleClickEdit = () => {
		navigate(`/${ViewTypes.COMPANY}/cases/case-edit`, {state: {
			caseCreatedDetails: caseDetail,
			companyKeyLP: companyKey,
			secretKey
		}})
	}
	
	const getCaseDetails = () => {
		axiosBFFServer 
			.get(`/case/${caseKeyView}`)
			.then(async (response) => {
				const dataToDecrypt = createCaseManuallyProperties.reduce((acc, key) => {
					if (Object.prototype.hasOwnProperty.call(response.data, key)) {
						acc[key] = response.data[key]
					}
					return acc
				}, {})
				const caseEncryptedKey = response.data.caseEncryptionKey[isPartnerOrAdvisor(roles) ? 'partnerEncryptedKey' : 'companyEncryptedKey']
				
				const decryptedSecretKey = privateKeyObj.decrypt(forge.util.hexToBytes(caseEncryptedKey))
				const decryptedResponse = decryptValueWithAES(decryptedSecretKey, iv, dataToDecrypt)
				const decryptedDocs = await decryptDocs(response.data.documents, decryptedSecretKey, iv)
				const decryptedFiles = decryptedDocs.map((item, index) => ({
					...item,
					id: (index + 1).toString(),
					fileName: item.fileName,
				}))

				setFiles(decryptedFiles)
				setCaseDetail({ ...response.data, ...decryptedResponse })
				setCaseType(getCaseType(decryptedResponse.whistleblower))
				setSecretKey(decryptedSecretKey)
				dispatch(setCaseViewKey(caseKeyView))
				dispatch(setCaseKey(caseKeyView))
				getCaseLogs(decryptedSecretKey)
			})
			.catch((error) => {
				const errorMessage = getErrorMessage(error, t('caseErrors') )
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})	
	}
	
	const getCaseLogs = (secretKey: string) => {
		axiosBFFServer 
			.post(`/case/logs/search/${caseKeyView}?page=${logsPage}&size=${size}`,{})
			.then(response => {
				const logs: CaseLogs[] = [] 
				response.data.pageResults.forEach((item,index) => {
					logs.push({
						...item,
						id: (index + 1).toString(),
						action: textTransform(item.action),
						date: getDateAndTime(item.date),
						target: textTransform(item.target),
						...(createCaseManuallyProperties.includes(item.target.toLowerCase()) && {
							oldValue: decryptValueWithAES(secretKey, iv, item.oldValue),
							newValue: decryptValueWithAES(secretKey, iv, item.newValue)
						})
					})})
				setActivityLog(logs)
				setTotalLogs(response.data.totalResults)
			})
			.catch((error) => {
				const errorMessage = getErrorMessage(error, t('errors.caseLogs') )
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})	
	}

	useEffect(()=> {
		getCaseDetails()
	}, [language])

	useEffect(() => {
		secretKey && getCaseLogs(secretKey)
	}, [logsPage, size])

	const handleSubmit = (values: MasterPassowdFormValues) => {
		const navigateRoute = location.pathname
		navigate(`${navigateRoute}/check`, { state: {
			masterPassword: values.password.trim(),
			publicKey,
			encodedPrivateKey,
			route: navigateRoute,
			from: navigateRoute
		}})
	}
	
	if (!masterPassword) {
		return (
			<View style={styles.container}>
				<View style={styles.masterPasswordContainer}>
					<MasterPasswordForm onSubmit={handleSubmit} />
				</View>
				<Snackbar
					visible={snackbar.isVisible}
					onDismiss={() => setSnackbar(snackbarInitialState)}
					type={snackbar.type}
					message={snackbar.message}
				/>
			</View>
		)
	}

	const advForCase = caseDetail && caseDetail.advisors
	const companyUsersForCase = caseDetail && caseDetail.companyUsers
	const handlerAssignedToCase = advForCase &&  companyUsersForCase  && [...advForCase.map((item: {advisorKey, email}) => ({ id: item.advisorKey, email: item.email })), ...companyUsersForCase.map((item: {companyUserKey, email}) => ({id: item.companyUserKey, email: item.email}))]


	const renderAssignedUsers = () => {
		return(
			handlerAssignedToCase && handlerAssignedToCase.length > 0 ? handlerAssignedToCase.map((users => {
				return (
					<View>
						<Text>{users.email}</Text>
					</View>
				)
			})) : (
				<Text>{t('noAssignedhandlers')}</Text>
			)
		)
	}

	const dataRender = 	[
		{caseDetails: 'ID', caseDetailsValues: caseDetail && caseDetail.tag },
		{caseDetails: t('companyName'), caseDetailsValues: caseDetail && caseDetail.companyName },
		{caseDetails: t('firstName'), caseDetailsValues: caseDetail && caseDetail.whistleblower?.firstName || '' },
		{caseDetails: t('lastName'), caseDetailsValues: caseDetail && caseDetail.whistleblower?.lastName || '' },
		{caseDetails: t('email'), caseDetailsValues: caseDetail && caseDetail.whistleblower?.email || '' },
		{caseDetails: t('fieldLabels.phoneNumber'), caseDetailsValues: caseDetail && caseDetail.whistleblower?.phoneNumber || '' },
		{caseDetails: t('subject'), caseDetailsValues: caseDetail && caseDetail.subject },
		
	]

	const dataRenderRight = [
		{caseDetails: t('source'), caseDetailsValues: caseDetail && caseDetail.source},
		{caseDetails: t('fieldLabels.status'), caseDetailsValues: caseDetail && caseDetail.caseState.state },
		{caseDetails: t('fieldLabels.severity'), caseDetailsValues: caseDetail && caseDetail.severity },
		{caseDetails: t('fieldLabels.caseType'), caseDetailsValues: caseDetail && caseType },
		{caseDetails: t('deadline'), caseDetailsValues: caseDetail && formatDateRo(caseDetail.deadline) },
		{caseDetails: t('handlers'), caseDetailsValues: renderAssignedUsers()}
	]

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const renderTableCell = (column: TableColumn, cases) => {
		switch (column.title) {
		case 'Documents':
			return(<Text onPress={() => downloadFile(cases.file)}>
				{cases.fileName}
			</Text>)
		case 'New value':
		case 'Old value':
			return (
				<View style={styles.logValue}>
					<Text style={[styles.dataText, isSmallScreen && styles.smallScreenDataText, styles.ellipsis]}>{cases[column.key] && cases[column.key].length ? cases[column.key].toString() : ''}</Text>
					{cases[column.key] && cases[column.key].length && <ItemWithTooltip
						tooltip={cases[column.key].toString()}
						iconName='info'
						transparentBackground={true} />}
				</View>	
			)		
		default:
			return(
				<Text style={[styles.dataText, isSmallScreen && styles.smallScreenDataText]}>{cases[column.key] && cases[column.key].length ? cases[column.key].toString() : ''}</Text>
			)
		}
	}
	return (
		<View>
			<View style={styles.container}>
				<View style={styles.headerContainer}>
					<TouchableOpacity 
						style={styles.backButton}
						onPress={() => navigate(`/${ViewTypes.COMPANY}/cases`)}>
						<Icon color={theme.light.colors.mainColor} name="arrow-left" size={18} />
						<Text style={styles.backButtonText}>{t('back')}</Text>
					</TouchableOpacity>
					<View style={styles.titleContainer}>
						<Text style={styles.headerText}>{t('caseDetails')}</Text>
						<TouchableOpacity disabled={caseDetail === null} style={styles.button} onPress={handleClickEdit}>
							<Text style={styles.buttonText}>{t('edit')}</Text>
						</TouchableOpacity>
					</View>
				</View>
				<View style={styles.mainContainer}>
					<View style={styles.containerLeft}>
						<View style={styles.detailsContainer}>
							<View style={styles.detailsColumns}>
								<View style={styles.column}>
									{dataRender.map(entry => (
										<View style={styles.row}>
											<Text style={styles.caseLabel}>{entry.caseDetails}:</Text>
											<Text style={styles.caseValue}>{entry.caseDetailsValues}</Text>
										</View>
									))}
							
								</View>
								<View style={styles.column}>
									{dataRenderRight.map(entry => (
										<View style={styles.row}>
											<Text style={styles.caseLabel}>{entry.caseDetails}:</Text>
											<Text style={styles.caseValue}>{entry.caseDetailsValues}</Text>
										</View>
									))}
								</View>
							</View>
							<View style={styles.row}>
								<Text style={styles.caseLabel}>{t('description')}:</Text>
								<Text style={[styles.caseValue, styles.descriptionValue]}>{caseDetail?.description}</Text>
							</View>
						</View>
						{secretKey && caseKeyView && <Messages	
							isLargeComponent={true}
							caseKey={caseKeyView}
							setSnackbar={setSnackbar}
							secretKey={secretKey}
							getCaseDocs={getCaseDetails}
						/>}
						<Snackbar
							visible={snackbar.isVisible}
							onDismiss={() => setSnackbar(snackbarInitialState)}
							type={snackbar.type}
							message={snackbar.message}
						/>
					</View>
					<View style={styles.containerRight}>
						<NotesBox caseKey={caseKeyView} />
						<Table
							data={files}
							columns={CaseDocumentsColumns}
							isSmallScreen={isSmallScreen}
							renderTableCell={renderTableCell}
							hasPagination={false}
							totalRecords={files.length}
						/>
					</View>
				</View>
				<View>
					<Text style={styles.headerTextLogs}>{t('caseLogs')}</Text>
					<Table
						data={activityLogs}
						columns={caseLogsColumns}
						isSmallScreen={isSmallScreen}
						renderTableCell={renderTableCell}
						hasPagination={true}
						totalRecords={totalLogs}
						page={logsPage}
						setPage={setLogsPage}
					/>
				</View>
			</View>
		</View>

	)
}
	
