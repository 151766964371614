import React from 'react'
import { ViewReport } from '@containers/ViewReport/ViewReport'
import { ReportLayout } from '@containers/ReportLayout/ReportLayout'

export const ViewReportPage = () => {
	return (
		<ReportLayout>
			<ViewReport />
		</ReportLayout>
	)
}