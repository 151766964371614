import { StyleSheet } from 'react-native'
import { colors, theme } from '@styles/constants'

export const styles = StyleSheet.create({
	container: {
		flex: 1,
		padding: 20,
	},
	headerContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: 20,
	},
	headerContainer_mobile: {
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: 30,
		gap: 12
	},
	headerText: {
		fontSize: 26,
		fontWeight: '500',
		color: theme.light.colors.mainColor
	},
	searchInput: {
		flex: 1,
		height: 40,
		borderWidth: 1,
		borderColor: theme.light.colors.boxBorder,
		borderRadius: 4,
		paddingHorizontal: 12,
		backgroundColor: 'red'
	},
	searchContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		borderWidth: 1,
		borderColor: theme.light.colors.mainColor,
		borderRadius: 6,
		width: 305
	},
	searchIcon: {
		padding: 5,
		marginLeft: 8,
	},
	filterContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: 16,
		backgroundColor: theme.light.colors.mainContainer,
		borderRadius: 30,
		padding: 20
	},
	filterContainer_mobile: {
		flexDirection: 'column',
		justifyContent: 'space-around',
		alignItems: 'flex-start',
		gap: 12,
		backgroundColor: theme.light.colors.mainContainer,
		borderRadius: 30,
		padding: 20
	},
	createButton: {
		flexDirection: 'row',
		gap: 8,
		backgroundColor: theme.light.colors.primaryDark,
		borderRadius: 6,
		paddingVertical: 10,
		paddingHorizontal: 20,
		justifyContent: 'center',
		alignItems: 'center'
	},
	createButtonText: {
		color: theme.light.colors.linkColorActive,
		fontWeight: 'bold',
		fontSize: 16
	},
	smallScreenHeaderTextsmallScreenHeaderText: {
		fontSize: 14,
	},
	dataText: {
		fontSize: 16,
	},
	smallScreenDataText: {
		fontSize: 14,
	},
	grayRow: {
		backgroundColor: colors.gray,
	},
	linkText: {
		color: colors.darkShades,
		fontWeight: '600',
	},
	statusText: {
		textAlign: 'center',
		borderRadius: 8,
		paddingHorizontal: 10,
		paddingVertical: 1,
		width: '70%',
		fontWeight: '600',
	},
	smallScreenStatusText: {
		fontSize: 12,
		paddingVertical: 4,
	},
	footerEmptySpace: {
		height: 40,
	},
	selectContainer: {
		display: 'flex',
		flexDirection: 'row',
	},
	selectContainer_mobile: {
		width: '100%'
	},
	actionsCell: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center'
	},
	companiesCheckboxLabel: {
		color: colors.white,
		fontWeight: 'bold',
	},
	companiesCheckbox: {
		marginHorizontal: 20
	},
	deleteButton: {
		justifyContent: 'center'
	},
	activating: {
		opacity: 0.5
	}
})
