import { colors, theme } from '@styles/constants'
import { button, buttonText } from '@styles/mixins/button'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: colors.shadeBackground,
	},
	content: {
		backgroundColor: colors.white,
		height: 'auto',
		width: '50%',
		maxWidth: 600,
		padding: 20,
		borderRadius: 30,
		gap: 16
	},
	content_mobile: {
		backgroundColor: colors.white,
		height: 'auto',
		width: '90%',
		maxWidth: 600,
		padding: 20,
		borderRadius: 30,
		gap: 16
	},
	title: {
		fontSize: 20,
		fontWeight: 'bold',
		marginBottom: 6
	},
	formFieldWrapper: {
		width: '100%',
		gap: 6
	},
	formFieldText: {
		fontSize: 16,
		borderRadius: 4,
		borderWidth: 1,
		borderColor: colors.darkAccent,
		color: theme.light.colors.mainColor,
		padding: 6,
		backgroundColor: colors.white,
		width: '100%',
		outlineStyle: 'none',
	},
	formFieldText_error: {
		fontSize: 16,
		borderRadius: 4,
		borderWidth: 1,
		borderColor: colors.error,
		padding: 12,
		backgroundColor: colors.white,
		width: '100%'
	},
	labelText: {
		fontSize: 16,
		paddingLeft: 6
	},
	errorText: {
		fontSize: 16,
		paddingLeft: 6,
		color: colors.error
	},
	contentText: {
		fontSize: 14
	},
	icon: {
		color: colors.white
	},
	textContainer: {
		paddingHorizontal: 20,
		paddingVertical: 30
	},
	button: {
		...({
			...button(theme.light.colors.successDark).button,
		} as object),
		borderRadius: 6,
		paddingVertical: 8,
		paddingHorizontal: 16,
		textAlign: 'center',
		justifyContent: 'center',
		flexDirection: 'row',
		gap: 8,
		width: 160,
	},
	cancelButton: {
		backgroundColor: theme.light.colors.dangerDark,
	},
	buttonText: {
		...({
			...buttonText().buttonText,
		} as object),
		fontWeight: '600',
	},
	buttonWidth_mobile: {
		width: '100%'
	},
	footer: {
		flexDirection: 'row',
		gap: 16,
		justifyContent: 'flex-end',
	},
	footer_mobile: {
		flexDirection: 'column',
		justifyContent: 'center',
		gap: 14,
		marginTop: 6
	}
})