import React, { FC, useState } from 'react'
import { Image, Text, View } from 'react-native'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import forge from 'node-forge'
import { Loader } from '@components/Loader/Loader'
import { PasswordChangedModal } from '@components/PasswordChangedModal'
import { PasswordVerificationCodeForm } from '@components/PasswordVerificationCodeForm/PasswordVerificationCodeForm'
import { decryptValueWithAES, encryptValueWithAES, generateKeyFromAccountPassword } from '@utils/encryption'
import { axiosBFFServer } from '@services/connectionServer'
import { getCurrentCompany, getLegalPartner } from '@services/apiService'
import { isPartner } from '@utils/utils'
import { iv, passwordChangeModalInitialState } from '@utils/constants'
import { styles } from './styles'

const PasswordVerificationCode: FC = () => {
	const navigate = useNavigate()
	const { search, pathname } = useLocation()
	const { t } = useTranslation()
	const [ isLoading, setIsLoading ] = useState(false)
	const [ passwordChangeModal, setPasswordChangeModal ] = useState<{
        isVisible: boolean
        title?: string
        text: string
        buttonText: string
    }>(passwordChangeModalInitialState)
	const isAdminOrPartnerAccount = pathname.includes('wbAdmin')
	const userEmail = new URLSearchParams(search).get('email')

	if(!userEmail) {
		return <Navigate to='/login' />
	}

	const sendEmailNewPassword = async (email, newPassword) => {
		await axiosBFFServer
			.post('/auth/sendPasswordEmail', { email, newPassword })
			.then(response => {
				if (response.status === 201) {
					setIsLoading(false)
					setPasswordChangeModal({
						isVisible: true,
						title: t('passwordChangedSuccess'),
						text: t('passwordChangedText'),
						buttonText: t('goLogin')
					})
				} else throw new Error()
			})
			.catch(error => {
				setIsLoading(false)
				setPasswordChangeModal({
					isVisible: true,
					text: t('errors.changeAccountPassword'),
					buttonText: t('ok')
				})
			})
	}

	const changePassword = async (verificationCode, email, masterPassword) => {
		await axiosBFFServer
			.post('/auth/verifyCode', {
				verificationCode,
				email,
			})
			.then(async (response) => {
				if (response.status === 201) {
					return response.data.password
				} else throw new Error()
			})
			.then(async (newPassword) => {
				// if company admin/ LP update mp
				if (isAdminOrPartnerAccount) {
					await updateMP(email, newPassword, masterPassword)
				} else {
					await sendEmailNewPassword(email, newPassword)
				}
			})
			.catch(error => {
				setIsLoading(false)
				setPasswordChangeModal({
					isVisible: true,
					text: `${t('errors.wrongVerifCode')} ${t('errors.repeatForgetPass')}`,
					buttonText: t('ok')
				})
			})
	}

	const updateMP = async(email, newPassword, masterPassword) => {
		await axiosBFFServer.post('/auth/advisor/login', {
			email,
			password: newPassword,
		})
			.then(async (response) => {
				if(response.status === 201) {
					const accountResponse = await axiosBFFServer.get('/account')
					const { roles } = accountResponse.data
					let encodedPrivateKey = ''

					// get company/LP encodedPrivateKey
					if (isPartner(roles)) {
						const advisorResponse = await axiosBFFServer.get(`/advisor/email/${email}`)
						const legalPartnerInfo = await getLegalPartner(advisorResponse.data.legalPartnerKey)
						encodedPrivateKey = legalPartnerInfo?.data.encodedPrivateKey
					} else {
						const currentCompany = await getCurrentCompany()
						encodedPrivateKey = currentCompany?.data.encodedPrivateKey
					}

					// check MP is correct
					try {
						const decryptedPrivateKey = decryptValueWithAES(masterPassword, iv, encodedPrivateKey)
						forge.pki.privateKeyFromPem(decryptedPrivateKey)
						const encodedMasterPassword = encryptValueWithAES(generateKeyFromAccountPassword(newPassword), iv, masterPassword)

						// update MP
						await axiosBFFServer.patch('/company/master-password', { encodedMasterPassword })
							.then(async (response) => {
								if (response.status === 200) {
									await sendEmailNewPassword(email, newPassword)
								} else throw new Error()
							})
							.catch(error => {
								setIsLoading(false)
								setPasswordChangeModal({
									isVisible: true,
									text: `${t('errors.updateMP')} ${t('errors.repeatForgetPass')}`,
									buttonText: t('ok')
								})    
							})
					} catch (error) {
						setIsLoading(false)
						setPasswordChangeModal({
							isVisible: true,
							text: `${t('errors.wrongMP')} ${t('errors.repeatForgetPass')}`,
							buttonText: t('ok')
						})
					}
				} else throw new Error()
			})
			.catch(error => {
				setIsLoading(false)
				setPasswordChangeModal({
					isVisible: true,
					text: t('errors.changeAccountPassword'),
					buttonText: t('ok')
				})
			})
	}

	const onSubmit = async (values) => {
		setIsLoading(true)
		await changePassword(values.verificationCode, userEmail, values.masterPassword)
	}

	const onClose = () => {
		setPasswordChangeModal(passwordChangeModalInitialState)
		navigate('/login')
	}

	return (
		<View style={styles.container}>
			{<PasswordChangedModal
				isVisible={passwordChangeModal.isVisible}
				title={passwordChangeModal.title}
				text={passwordChangeModal.text}
				buttonText={passwordChangeModal.buttonText}
				onClose={onClose}
			/>}
			{isLoading
				? <View style={{ position: 'absolute' }}>
					<Loader />
				</View>
				: <View style={styles.formContainer}>
					<View>
						<Image
							source={require('@assets/Logo.webp')}
							style={styles.logo}
						/>
						<Text style={styles.appName}>{t('appName')}</Text>
					</View>
					<Text style={styles.text}>{isAdminOrPartnerAccount
						? t('verificationCodeAndMP')
						: t('verificationCode')}
					</Text>
					<PasswordVerificationCodeForm
						isAdminOrPartnerAccount={isAdminOrPartnerAccount}
						onSubmit={onSubmit}
					/>
				</View>
			}
		</View>
	)
}

export default PasswordVerificationCode
