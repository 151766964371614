import React, { useEffect, useState } from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import { colors, theme } from '@styles/constants'
import Icon from 'react-native-vector-icons/FontAwesome'
import { useTranslation } from 'react-i18next'
import useScreenDimensions from '@components/Common/UseScreenDimensions'
import { iv, screenType } from '@utils/constants'
import { Formik } from 'formik'
import { Input } from '@components/Input'
import { createMessageSchema } from '@utils/formValidation'
import { axiosBFFServer } from '@services/connectionServer'
import { Documents, MessageText, SnackbarInitialState, SnackbarTypes, uploadedDocument } from '@utils/interfaces'
import { encryptValueWithAES } from '@utils/encryption'
import { styles } from './styles'
import { decryptDocs, downloadFile } from '@utils/utils'
import { UseErrorMessages } from '@components/Common/UseErrorMessage'

interface Props {
	caseKey: string
	messageKey: string
	user: string
	message: string
	date: string
	documents: uploadedDocument[]
	edited: boolean
	secretKey
	setSnackbar: (value: SnackbarInitialState) => void
}

const MessageCard = (props: Props) => {
	const { t } = useTranslation()
	const { getErrorMessage } = UseErrorMessages()
	const screenDimensions = useScreenDimensions()
	const isSmallScreen = screenDimensions.width < screenType.tablet
	const isUnderSmallScreen = screenDimensions.width < screenType.underSmallLaptop
	const [editMode, setEditMode] = useState(false)
	const [messageItem, setMessageItem] = useState({
		message: props.message
	})

	const [uploadedDocuments, setUploadedDocuments] = useState<Documents[]>([])

	const decryptDocuments = async () => {
		const decryptedDocs = await decryptDocs(props.documents, props.secretKey, iv)
		const decryptedFiles = decryptedDocs.map((item, index) => ({
			...item,
			id: (index + 1).toString(),
			fileName: item.fileName,
		}))

		setUploadedDocuments(decryptedFiles)
	}

	const updateMessage = (values: MessageText) => {
		const {message} = values as MessageText
		axiosBFFServer
			.patch(`/chat/${props.caseKey}/${props.messageKey}`,{
				text: encryptValueWithAES(props.secretKey, iv, message)
			})
			.then((response) => {
				if (response.status === 200){
					setEditMode(false)
					setMessageItem({message: message})
				} else {
					props.setSnackbar({
						isVisible: true,
						type: SnackbarTypes.ERROR,
						message: t('errors.updateComment')
					})
				}
			})
			.catch((error) =>{
				const errorMessage = getErrorMessage(error, t('errors.updateComment'))
				props.setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}

	useEffect(() => {
		decryptDocuments()
	}, [props.documents])
	
	return (
		<View style={(isSmallScreen || isUnderSmallScreen) ? styles.contentContainer_smallScreen : styles.contentContainer}>
			{
				(isSmallScreen || isUnderSmallScreen) && !editMode ?
					<View style={styles.avatarArea}>
						<View style={styles.profileAvatar}>
							{props.user === 'Anonymous'
								? <Icon color={colors.pureWhite} name='user-secret' size={22} />
								: <Text style={styles.avatarText}>{props.user[0].toUpperCase()}</Text>
							}
						</View>
						<View style={styles.leftSide}>
							<View style={styles.buttonsArea}>
								<TouchableOpacity onPress={() => setEditMode(true)}>
									<Icon name='pencil' color={colors.darkGray} size={20} />
								</TouchableOpacity>
							</View>
							{props.edited &&
					<Text style={styles.info}>
						{t('edited')}
					</Text>
							}
						</View>
					</View>
					: <View style={styles.profileAvatar}>
						{props.user === 'Anonymous'
							? <Icon color={colors.pureWhite} name='user-secret' size={22} />
							: <Text style={styles.avatarText}>{props.user[0].toUpperCase()}</Text>
						}
					</View>
			}
			
			<View style={styles.textContainer}>
				<Text style={styles.mainText}>{props.user === 'Anonymous' ? t('whistleblower') : props.user}</Text>
				{
					editMode ?
						<Formik
							initialValues={messageItem}
							validationSchema={createMessageSchema}
							onSubmit={updateMessage}
							enableReninitialize
						>
							{formikProps => (
								<View style={styles.inputContainer}>
									<Input
										placeholder={t('writeMessage')}
										name='message'
										value={formikProps.values['message']}
										onChange={formikProps.setFieldValue}
										multiline={true}
										onBlur={() => formikProps.setFieldTouched('message', true)} />
									{formikProps.touched['message'] && formikProps.errors['message'] && (
										<Text style={styles.errorText}>{`*${formikProps.errors['message']}`}</Text>
									)}
									<TouchableOpacity 
										style={styles.button}
										onPress={() => formikProps.handleSubmit()}>
										<Text style={styles.buttonText}>{t('save')}</Text>
									</TouchableOpacity>
								</View>
					
							)}
						</Formik> :
						<Text style={styles.text}>{messageItem.message}</Text>
				}
				
				{
					uploadedDocuments && uploadedDocuments.length ? (
						<View style={styles.fileContainer}>
							{uploadedDocuments.map((document) => 
								<View style={isSmallScreen ? styles.fileItem_small :  isUnderSmallScreen ? styles.fileItem_small : styles.fileItem}>
									<View style={styles.fileNameContainer}>
										<Icon style={styles.iconDark} name='paperclip' size={20} />
										<Text 
											style={styles.fileLink}
											onPress={() => downloadFile(document.file)}
										>
											{document.fileName}
										</Text>
									</View> 
								</View>
							)}
						</View>
						
					) : null
				}
				<Text style={styles.info}> {props.date} </Text>
			</View>
			{
				!isSmallScreen &&
				<View style={styles.leftSide}>
					<View style={styles.buttonsArea}>
						<TouchableOpacity onPress={() => setEditMode(true)}>
							<Icon name='pencil' color={theme.light.colors.mainColor} size={20} />
						</TouchableOpacity>
					</View>
					{props.edited &&
					<Text style={styles.info}>
						{t('edited')}
					</Text>
					}
				</View>
			}
		</View>
	)
}

export default MessageCard