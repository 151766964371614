import React from 'react'
import Layout from '@containers/Layout'
import { Departments } from '@containers/Departments/Departments'

export const DepartmentsPage = () => {
	return (
		<Layout>
			<Departments />
		</Layout>
	)
}
