import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import forge from 'node-forge'
import { Loader } from '@components/Loader/Loader'
import { createLog, updateCompany, updatePartner } from '@services/apiService'
import { rootReducerType } from '@reducers/combineReducers'
import { setCurrentCompany } from '@actions/companyActions'
import { setMasterPassword } from '@actions/masterPasswordActions'
import { decryptValueWithAES, encryptValueWithAES } from '@utils/encryption'
import { Company, LogEventTypes, ViewTypes } from '@utils/interfaces'
import { iv } from '@utils/constants'
import { setLegalPartnerKeys } from '@actions/legalPartnerKeyActions'
import { clearStorage, getISOStringFromDate, getUserAgent } from '@utils/utils'
import { resetState } from '@actions/resetActions'

export const Check: FC = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()
	const { t } = useTranslation()
	const { currentCompany } = useSelector((state: rootReducerType) => state.companyReducer)
	const { companyKey, nrOfEmployees, advisors } = currentCompany
	const { key: legalPartnerKey } = useSelector((state: rootReducerType) => state.legalPartnerKeyReducer)
	const { email } = useSelector((state: rootReducerType) => state.userReducer)

	if(!location.state) {
		return <Navigate to='/' />
	}

	const { masterPassword, encodedMasterPassword, publicKey, privateKey, encodedPrivateKey, route } = location.state ?? {}

	const updateCompanyKeys = async (keyPair) => {
		const response = await updateCompany(companyKey, {
			...keyPair,
			nrOfEmployees,
			encodedMasterPassword,
		})

		if (response?.status === 200) {
			dispatch(setCurrentCompany({
				...currentCompany,
				publicKey,
				encodedPrivateKey: keyPair.encodedPrivateKey,
			} as Company))

			// if is LP intern set LP keys same as company
			if (!advisors.length) {
				dispatch(setLegalPartnerKeys({
					publicKey,
					encodedPrivateKey: keyPair.encodedPrivateKey,
				}))
			}

			dispatch(setMasterPassword(masterPassword))
			navigate(route)
		} else {
			await redirectToLogin(companyKey)
		}
	}

	const updateLPKeys = async (keyPair) => {
		const response = await updatePartner({
			...keyPair,
			encodedMasterPassword,
		}, legalPartnerKey)

		if (response?.status === 200) {
			dispatch(setLegalPartnerKeys({
				publicKey,
				encodedPrivateKey: keyPair.encodedPrivateKey,
			}))
			dispatch(setMasterPassword(masterPassword))
			navigate(route)
		} else {
			await redirectToLogin(legalPartnerKey)
		}
	}

	const redirectToLogin = async (key) => {
		const userAgent = getUserAgent()
		const securityLogInfo = {
			companyKey: key,
			userEmail: email,
			userAgent,
			time: new Date().toISOString,
			event: LogEventTypes.LOGOUT,
			failureReason: ''
		}
		clearStorage()
		dispatch(resetState())
		await createLog(securityLogInfo, true)
		navigate('/login', { state: { errorMessage: t('errors.updateCompanyKeys') } })
	}

	useEffect(() => {
		renderComp()
	},[])

	const renderComp = () => {
		if (encodedPrivateKey) {
			try {
				const decryptedPrivateKey = decryptValueWithAES(masterPassword, iv, encodedPrivateKey)
				forge.pki.privateKeyFromPem(decryptedPrivateKey)
				dispatch(setMasterPassword(masterPassword))
	
				navigate(route)
			} catch(error) {
				navigate(route, { state: { errorMessage: t('errors.wrongMP') } })
			}
		}

		if (privateKey) {
			const encryptedPrivateKey = encryptValueWithAES(masterPassword, iv, privateKey)
            
			// if is company admin
			if (route.split('/')[1] === ViewTypes.COMPANY) updateCompanyKeys({ publicKey, encodedPrivateKey: encryptedPrivateKey })

			// if is LP
			if (route.split('/')[1] === ViewTypes.PARTNER) updateLPKeys({ publicKey, encodedPrivateKey: encryptedPrivateKey })
		}
	}

	return <Loader />
}
