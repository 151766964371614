import React, { useEffect, useState } from 'react'
import { Modal, Text, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import Icon from 'react-native-vector-icons/FontAwesome'
import { AdvisorValues, FormValues, Permission, PermissionList, SnackbarInitialState, SnackbarTypes, newAdvisor } from '@utils/interfaces'
import useScreenDimensions from '@components/Common/UseScreenDimensions'
import { CheckBoxCustom } from '@components/CheckBox/CheckBox'
import { Input } from '@components/Input'
import { MultiSelectDropdown } from '@components/MultiSelect'
import { phonePrefixes, screenType, snackbarInitialState } from '@utils/constants'
import { axiosBFFServer } from '@services/connectionServer'
import { useDispatch, useSelector } from 'react-redux'
import { rootReducerType } from '@reducers/combineReducers'
import { PhoneInput } from '@components/PhoneInput/PhoneInput'
import { mapAdvisorCheckboxTree } from '@utils/utils'
import { Loader } from '@components/Loader/Loader'
import { setAdvisors } from '@actions/advisorActions'
import { styles } from './Style'
import { Formik } from 'formik'
import { createAdvisorSchema } from '@utils/formValidation'
import { Snackbar } from '@components/Snackbar/Snackbar'
import { UseErrorMessages } from '@components/Common/UseErrorMessage'

interface Props {
	visible: boolean;
	onClose: () => void;
	onSubmit: () => void;
}

export const NewAdvisorModal = (props: Props) => {
	const { t } = useTranslation()
	const screenDimensions = useScreenDimensions()
	const dispatch = useDispatch()
	const { getErrorMessage } = UseErrorMessages()

	const isSmallLaptop = screenDimensions.width < screenType.smallLaptop
	const isSmallScreen = screenDimensions.width < screenType.tablet
	
	const [ isLoading, setIsLoading ] = useState(false)
	const [companies, setCompanies] = useState([])
	const [selectedCompanies, setSelectedCompanies] = useState([])
	const legalPartnerKey = useSelector((state: rootReducerType) => state.legalPartnerKeyReducer.key)
	const advisorsList = useSelector((state: rootReducerType) => state.setAdvisorKeyReducer.advisors)

	const [companyPermissionList, setCompanyPermissionList] = useState<PermissionList[]>()
	const [partnerPermissionList, setPartnerPermissionList] = useState<PermissionList[]>()
	const [permissionKeys, setPermissionKeys] = useState<string[]>([])
	const [snackbar, setSnackbar] = useState<SnackbarInitialState>(snackbarInitialState)
	const initialPermissionsKeys = []

	const [newAdvisor, setNewAdvisor] = useState<newAdvisor>({
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
		prefix: phonePrefixes[0],
		companies: [''],
		permissionCategory: '',
		permissions: [],
		title: ''
	})

	const onSelectedItemsChange = (selected) => {
		setSelectedCompanies(selected)
	}

	const onSubmitForm = (values: FormValues) => {
		setIsLoading(true)
		const { firstName, lastName, email, prefix, phone, title} = values as AdvisorValues
		const { onSubmit } = props
		
		axiosBFFServer.post('/advisor/create', { 
			firstName,
			lastName, 
			email,
			phoneNumber: prefix+phone,
			legalPartnerKey,
			permissionKeys: permissionKeys,
			companyKeys: selectedCompanies[0] ? selectedCompanies : [],
			title: title
		})
			.then((response) => {
				if (response.status === 201){
					setSnackbar({
						isVisible: true,
						type: SnackbarTypes.SUCCESS,
						message: t('success.createNewAdvisor'),
					})
					dispatch(setAdvisors([
						...advisorsList,
						response.data,
					]))
					onSubmit()
				} else throw new Error()
			}).catch((error) => {
				const errorMessage = error.response.data.customStatus === 609 ? t('emailAlreadyUsed') + ' ' + getErrorMessage(error, '') : getErrorMessage(error, t('errors.createAdvisor'))
			
				setIsLoading(false)
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage,
				})
			})
	}

	const getCompanies = () => {
		axiosBFFServer
			.post('/company/search?page=1&size=100')
			.then(response => {
				setCompanies(response.data.pageResults)
			})
			.catch((error) => {
				const errorMessage = getErrorMessage(error, t('errorCompanies'))
			
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}

	const getPermissions = () => {
		let permissions: Permission[] = []
		const role = 'ADVISOR'

		axiosBFFServer
			.get(`/permission/role/${role}`)
			.then((response) => {
				permissions = response.data
				if (permissions) {
					const mappedTrees = mapAdvisorCheckboxTree(permissions)
					setCompanyPermissionList(mappedTrees.companyTree)
					setPartnerPermissionList(mappedTrees.partnerTree)
				}
			})
			.catch((error) => {
				const errorMessage = getErrorMessage(error, t('getPermissions'))
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}

	useEffect(() => {
		selectedCompanies.length &&
		setNewAdvisor(
			{
				...newAdvisor,
				companies: selectedCompanies
			}
		)
	}, [selectedCompanies])

	useEffect(() => {
		getCompanies()
		getPermissions()
	},[])
	
	return (
		<Modal
			animationType="slide"
			transparent={true}
			visible={props.visible}
			onRequestClose={props.onClose}>
			{
				isLoading
					? <View style={styles.container}>
						<Loader /> 
					</View>
					: <Formik 
						enableReinitialize={true} 
						initialValues={newAdvisor} 
						validationSchema={createAdvisorSchema} 
						onSubmit={onSubmitForm}>
						{(formikProps) => (
							<View style={styles.container}>
								<View style={isSmallLaptop ? styles.content_smallLaptop : isSmallScreen ? styles.content_smallDevice  : styles.content}>
									<Text style={styles.title}>{t('createAdvisor')}</Text>
									<View style={styles.textFieldContainer}>
										<View style={styles.formFieldWrapperSmall}>
											<Text style={styles.labelText}>{t('firstName')}</Text>
											<Input
												placeholder={t('firstName')}
												name='firstName'
												value={formikProps.values['firstName']}
												onChange={formikProps.setFieldValue}
												onBlur={() => formikProps.setFieldTouched('firstName', true)} />
											{formikProps.touched['firstName'] && formikProps.errors['firstName'] && (
												<Text style={styles.errorText}>{`*${formikProps.errors['firstName']}`}</Text>
											)}
										</View>
										<View style={styles.formFieldWrapperSmall}>
											<Text style={styles.labelText}>{t('lastName')}</Text>
											<Input
												placeholder={t('lastName')}
												name='lastName'
												value={formikProps.values['lastName']}
												onChange={formikProps.setFieldValue}
												onBlur={() => formikProps.setFieldTouched('lastName', true)} />
											{formikProps.touched['lastName'] && formikProps.errors['lastName'] && (
												<Text style={styles.errorText}>{`*${formikProps.errors['lastName']}`}</Text>
											)}
										</View>
									</View>
									<View style={styles.formFieldWrapper}>
										<Text style={styles.labelText}>{t('email')}</Text>
										<Input
											placeholder={t('email')}
											name='email'
											value={formikProps.values['email']}
											onChange={formikProps.setFieldValue}
											onBlur={() => formikProps.setFieldTouched('email', true)} />
										{formikProps.touched['email'] && formikProps.errors['email'] && (
											<Text style={styles.errorText}>{`*${formikProps.errors['email']}`}</Text>
										)}
									</View>
									<View style={styles.formFieldWrapper}>
										<Text style={styles.labelText}>{t('title')}</Text>
										<Input
											placeholder={t('title')}
											name='title'
											value={formikProps.values['title']}
											onChange={formikProps.setFieldValue}
											onBlur={() => formikProps.setFieldTouched('title', true)} />
										{formikProps.touched['title'] && formikProps.errors['title'] && (
											<Text style={styles.errorText}>{`*${formikProps.errors['title']}`}</Text>
										)}
									</View>
									<View style={styles.formFieldWrapper}>
										<Text style={styles.labelText}>{t('phone')}</Text>
										<PhoneInput
											name={{
												prefix: 'prefix',
												number: 'phone',
											}}
											phone={{
												prefix: formikProps.values['prefix'],
												number: formikProps.values['phone']
											}}
											onChange={formikProps.setFieldValue}
											onBlur={formikProps.setFieldTouched}
										/>
										{formikProps.touched['phone'] && formikProps.errors['phone'] && (
											<Text style={styles.errorText}>{`*${formikProps.errors['phone']}`}</Text>
										)}
									</View>

									<View style={styles.formFieldWrapper}>
										<Text style={styles.labelText}>{t('companies')}</Text>
										<MultiSelectDropdown
											items={companies}
											selectedItems={selectedCompanies}
											onSelectedItemsChange={onSelectedItemsChange}
											placeholder={t('searchCompany')}
											displayKey="name"
											uniqueKey="companyKey"
											searchText={t('searchCompany')}
										/>
									</View>
									<View style={isSmallScreen ? styles.checkBoxContainerSmallScreen : styles.checkBoxContainer}>
										<View style={isSmallScreen ? styles.columnCheckItemsSmallScreen : styles.columnCheckItems}>
											<Text style={styles.labelText}>{t('companyPermissions')}</Text>
											{
												companyPermissionList && companyPermissionList.map((item) => 
													<CheckBoxCustom
														text={item.text}
														name={item.name}
														permissionKey={item.permissionKey}
														disabled={false}
														hasTooltip={item.tooltip.length ? true : false}
														tooltip={item.tooltip}
														hasDropDown={item.childs.length ? true : false}
														dropdownChecksList={item.childs}
														permissionKeys={permissionKeys}
														setPermissionKeys={setPermissionKeys}
														initialPermissions={initialPermissionsKeys}/>
												)
											}
					
										</View>
										<View style={isSmallScreen ? styles.columnCheckItemsSmallScreen : styles.columnCheckItems}>
											<Text style={styles.labelText}>{t('partnerPermissions')}</Text>
											{
												partnerPermissionList && partnerPermissionList.map((item) => 
													<CheckBoxCustom
														text={item.text}
														name={item.name}
														permissionKey={item.permissionKey}
														disabled={false}
														hasTooltip={item.tooltip.length ? true : false}
														tooltip={item.tooltip}
														hasDropDown={item.childs.length ? true : false}
														dropdownChecksList={item.childs}
														permissionKeys={permissionKeys}
														setPermissionKeys={setPermissionKeys}
														initialPermissions={initialPermissionsKeys}/>
												)
											}
										</View>
									</View> 
									<View style={styles.footer}>
										<TouchableOpacity 
											style={styles.cancelButton}
											onPress={props.onClose}>
											<Icon style={styles.cancelIcon} name="close" size={18} />
											<Text style={styles.cancelButtonText}>{t('cancel')}</Text>
										</TouchableOpacity>
										<TouchableOpacity 
											onPress={() => formikProps.handleSubmit()}
											style={styles.button}
										>
											<Icon style={styles.icon} name="check" size={18} />
											<Text style={styles.buttonText}>{t('create')}</Text>
										</TouchableOpacity>
						
									</View>
								</View>
							</View>
						)}
					</Formik>
			}
			<Snackbar
				visible={snackbar.isVisible}
				onDismiss={() => setSnackbar(snackbarInitialState)}
				type={snackbar.type}
				message={snackbar.message}
			/>
		</Modal>
	)
}
