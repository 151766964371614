import { theme } from '@styles/constants'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		padding: 20,
		backgroundColor: theme.light.colors.appContainer,
	},
	formContainer: {
		width: '100%',
		maxWidth: 500,
		padding: 40,
		borderRadius: 30,
		backgroundColor: theme.light.colors.mainContainer,
		alignItems: 'center',
		shadowColor: theme.light.colors.mainColor,
		shadowOffset: { width: 0, height: 1 },
		shadowOpacity: 0.2,
		shadowRadius: 2,
		elevation: 3,
		gap: 20,
	},
	text: {
		fontSize: 16,
		textAlign: 'justify',
	},
	appName: {
		fontSize: 20,
		fontWeight: '500',
		color: theme.light.colors.titleColor,
	},
	logo: {
		width: 100,
		height: 100,
		borderRadius: 50,
		justifyContent: 'center',
		alignItems: 'center',
	},
})
