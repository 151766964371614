import React from 'react'
import { Modal, Text, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import Icon from 'react-native-vector-icons/FontAwesome'
import { styles } from './styles'

interface Props {
	visible: boolean;
	onClose: () => void;
	onSubmit: (key?: string) => void;
	itemKey?: string;
	messageText: string;
	opacity?: boolean;
	infoModal?: boolean;
    closeButtonText?: string;
    submitButtonText?: string;
}

export const GeneralModal = (props: Props) => {
	const { t } = useTranslation()
	
	return (
		<Modal
			animationType="slide"
			transparent={true}
			visible={props.visible}
			onRequestClose={props.onClose}>

			<View style={props.opacity ? [styles.container, {backgroundColor: '', backdropFilter: 'blur(10px)'}] : styles.container }>
				<View style={styles.content}>			
					<Text style={styles.text}>{props.messageText}</Text>
					<View style={styles.buttonsContainer}>
						{
							props.infoModal ?
								<TouchableOpacity 
									style={styles.button}
									onPress={props.onClose}>
									<Text style={styles.buttonText}>{props.submitButtonText || t('ok')}</Text>
								</TouchableOpacity>
								:
								<>
									<TouchableOpacity 
										style={[styles.button, styles.cancelButton]}
										onPress={props.onClose}>
										<Icon style={styles.icon} name="close" size={18} />
										<Text style={styles.buttonText}>{props.closeButtonText || t('cancel')}</Text>
									</TouchableOpacity>
									<TouchableOpacity 
										onPress={() =>props.itemKey ? props.onSubmit(props.itemKey) : props.onSubmit()}
										style={styles.button}>
										<Icon style={styles.icon} name="check" size={18} />
										<Text style={styles.buttonText}>{props.submitButtonText || t('yes')}</Text>
									</TouchableOpacity>
								</>
						}
						
					</View>
				</View>
			</View>
		</Modal>
	)
}

