import MyAccount from '@components/Settings/account/MyAccount/MyAccount'
import { CompanyInformation } from '@components/Settings/companyInfo/CompanyInformation/CompanyInformation'
import { Tab } from '@components/Tab'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { getUserNotificationSettings } from '@services/apiService'
import { SnackbarInitialState, UserNotification} from '@utils/interfaces'
import { settingsTabs, snackbarInitialState } from '@utils/constants'
import { useSelector } from 'react-redux'
import { rootReducerType } from '@reducers/combineReducers'
import { Security } from '@components/Settings/security/Security'
import { checkIfPermissionExist, isPartner } from '@utils/utils'
import { Snackbar } from '@components/Snackbar/Snackbar'
import {useLocation, useNavigate} from 'react-router-dom'

export const AdvisorSettings = () => {
	const { t } = useTranslation()
	const location = useLocation()
	const navigate = useNavigate()
	const [tabs, setTabs] = useState(settingsTabs)
	const [currentTab, setCurrentTab] = useState(tabs[0])
	const [userNotifications, setUserNotifications] = useState<Array<UserNotification>>([])
	const [snackbar, setSnackbar] = useState<SnackbarInitialState>(snackbarInitialState)

	let componentToRender: JSX.Element|null

	const { permissions: userPermissions, roles: userRoles, language } = useSelector((state: rootReducerType) => state.userReducer)
	const hasViewSecurityLogsPermission = checkIfPermissionExist(userPermissions, 'securityLogs', 'view')
	const hasViewCompanySettingsPermission = checkIfPermissionExist(userPermissions, 'companySettings', 'view')

	const changeTab = (tab: string) => {
		setCurrentTab(tab)
		navigate(tab.toLowerCase())
	}

	const checkTabsAccess = () => {
		const userTabs = [...tabs]
	
		if (isPartner(userRoles) || hasViewCompanySettingsPermission){
			!userTabs.includes('companyInfo') && userTabs.push('companyInfo')
		}

		if (hasViewSecurityLogsPermission){
			!userTabs.includes('security') && userTabs.push('security')
		}

		setTabs(userTabs)
	}

	useEffect(() => {
		getUserNotificationSettings().then(resp => setUserNotifications(resp?.data))
		checkTabsAccess()
	}, [language])

	useEffect(() => {
		if (location.pathname.includes('*') || tabs.every(tab => !location.pathname.includes(tab))) {
			navigate(currentTab.toLowerCase())
		}
	}, [])

	switch (currentTab) {
	case 'myAccount':
		componentToRender = <MyAccount setSnackbar={setSnackbar} userNotifications={userNotifications} />
		break
	case 'companyInfo':
		componentToRender = <CompanyInformation />
		break
	case 'security': 
		componentToRender = <Security />
		break
	default:
		componentToRender = null
		break
	}

	return (
		<View style={styles.container}>
			<View style={styles.header}>
				{tabs.map((tab, index) => {
					return <Tab
						key={index}
						label={t(tab)}
						active={currentTab === tab}
						onClick={() => changeTab(tab)}
					/>
				})}
			</View>
			{componentToRender}
			<Snackbar
				visible={snackbar.isVisible}
				onDismiss={() => setSnackbar(snackbarInitialState)}
				type={snackbar.type}
				message={snackbar.message}
				wrapperStyle={{ bottom: 430, height: 20 }}
			/>
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		display: 'flex',
		marginBottom: 20,
	},
	header: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		paddingVertical: 20,
	},
})
