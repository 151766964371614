import React from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import { styles } from './styles'
import { useTranslation } from 'react-i18next'
import { formatTimestamp } from '@utils/utils'

export const NotificationComponent = ({ notification, onPress }) => {
	const { t } = useTranslation()
	
	return (
		<TouchableOpacity onPress={() => onPress(notification)}>
			<View style={[styles.notification, notification.isRead && styles.readNotification]}>
				<View style={styles.content}>
					<View style={{flex: 1, gap: 20, alignItems: 'center', flexDirection: 'row'}}>
						<Text style={styles.title}>{t(`notificationHistoryTypes.${notification.notificationType}`)}</Text>
						<Text style={styles.timestamp}>{formatTimestamp(new Date(notification.timestamp))}</Text>
					</View>
					<Text style={styles.description} numberOfLines={1} ellipsizeMode="tail">
						{notification.content}
					</Text>
				</View>
				{!notification.isRead && (
					<View style={styles.unreadDot}></View>
				)}
			</View>
		</TouchableOpacity>
	)
}