import { StyleSheet } from 'react-native'
import { colors, theme } from '@styles/constants'
import { button } from '@styles/mixins/button'

export const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		justifyContent: 'space-around',
		backgroundColor: theme.light.colors.mainContainer,
		alignItems: 'center',
		paddingHorizontal: 8,
		elevation: 2,
		shadowColor: theme.light.colors.mainColor,
		shadowOffset: {
			width: 2,
			height: 2,
		},
		shadowOpacity: 0.2,
		shadowRadius: 2,
		borderRadius: 8,
		borderWidth: 1,
		borderColor: theme.light.colors.boxBorder, 
	},
	buttonText: {
		color: theme.light.colors.linkColor,
		fontWeight: '500',
	},
	footerButton: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		gap: 8,
		...({
			...button(theme.light.colors.primary).button,
		} as object),
		borderRadius: 4,
		paddingVertical: 8,
		paddingHorizontal: 16,
		elevation: 2,
		shadowColor: theme.light.colors.mainColor,
		shadowOffset: {
			width: 2,
			height: 2,
		},
		shadowOpacity: 0.2,
		shadowRadius: 2,
		borderWidth: 1,
		borderColor: theme.light.colors.boxBorder,
	},
	buttonIcon: {
		color: theme.light.colors.linkColor,
		textAlign: 'center'
	},
	footerInfoText: {
		fontSize: 16,
		letterSpacing: 1,
		fontWeight: '500',
		color: theme.light.colors.linkColor,
	}
})
