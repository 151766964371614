import { RecordingState } from '@utils/interfaces'
import { RecordingActionType } from '@utils/reduxTypes' 

const initialState: RecordingState = {
	recording: false,
}

export const recordingReducer = (state = initialState, action: RecordingActionType): RecordingState => {
	switch (action.type) {
	case 'START_RECORDING':
		return { recording: true }
	case 'STOP_RECORDING':
		return { recording: false }
	default:
		return state
	}
}