import React, { useState } from 'react'
import { Modal, Text, TextInput, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Icon from 'react-native-vector-icons/FontAwesome'
import { SnackbarInitialState, SnackbarTypes, ViewTypes } from '@utils/interfaces'
import {useDispatch} from 'react-redux'
import { setPasswordToSave } from '@actions/masterPasswordActions'
import { styles } from './styles'
import { iv, screenType, snackbarInitialState } from '@utils/constants'
import { Snackbar } from '@components/Snackbar/Snackbar'
import { axiosBFFServer } from '@services/connectionServer'
import { decryptValueWithAES } from '@utils/encryption'
import forge from 'node-forge'
import useScreenDimensions from '@components/Common/UseScreenDimensions'
import { UseErrorMessages } from '@components/Common/UseErrorMessage'

interface Props {
	visible: boolean;
	passwordError: boolean;
	onClose: () => void;
}

export const ReportModal = (props: Props) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { getErrorMessage } = UseErrorMessages()

	const [password, setPassword] = useState<string>('')
	const [ snackbar, setSnackbar ] = useState<SnackbarInitialState>(snackbarInitialState)
	const dispatch = useDispatch()
	const screenDimensions = useScreenDimensions()
	const isMobileScreen = screenDimensions.width < screenType.phone
	
	const viewReport = async () => {
		const masterPassword = password.replace(/ /g, '')
		const [caseKey, masterPass] = masterPassword.split(':')
		await axiosBFFServer
			.get(`/case/${caseKey}`)
			.then(async (response) => {
				try {
					const decryptedPrivateKey =decryptValueWithAES(masterPass, iv, response.data.whistleblower.encodedPrivateKey)
					forge.pki.privateKeyFromPem(decryptedPrivateKey)
					dispatch(setPasswordToSave(masterPassword))
					navigate(`/${ViewTypes.REPORT}/view`)
				} catch(error) {
					setSnackbar({
						isVisible: true,
						type: SnackbarTypes.ERROR,
						message: t('errors.wrongMP')
					})
				}
			})
			.catch(error => {
				const errorMessage = getErrorMessage(error, t('errors.wrongMP'))
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}

	return (
		<Modal
			animationType="slide"
			transparent={true}
			visible={props.visible}
			onRequestClose={props.onClose}>

			<View style={styles.container}>
				<View style={isMobileScreen ? styles.content_mobile : styles.content}>
					<Text style={styles.title}>{t('reportModalTitle')}</Text>
					<Text style={styles.contentText}>{t('reportModalText')}</Text>

					<View style={styles.formFieldWrapper}>
						<Text style={styles.labelText}>{t('secretKey')}</Text>
						<TextInput
							placeholder={t('passwordExemple')}
							style={props.passwordError ? styles.formFieldText_error : styles.formFieldText}
							onChange={(event) => setPassword(event.nativeEvent.text)}
							secureTextEntry={true}
						/>
						{
							props.passwordError && (
								<Text style={styles.errorText}>{t('passwordError')}</Text>
							)
						}
						
					</View>
				
					<View style={isMobileScreen ? styles.footer_mobile : styles.footer}>
						<TouchableOpacity 
							style={isMobileScreen ? [styles.button, styles.cancelButton, styles.buttonWidth_mobile] : [styles.button, styles.cancelButton]}
							onPress={() => props.onClose()}>
							<Icon style={styles.icon} name="close" size={18} />
							<Text style={styles.buttonText}>{t('cancel')}</Text>
						</TouchableOpacity>
						<TouchableOpacity 
							onPress={() => viewReport()}
							style={isMobileScreen ? [styles.button, styles.buttonWidth_mobile] : styles.button}>
							<Icon style={styles.icon} name="check" size={18} />
							<Text style={styles.buttonText}>{t('goToReport')}</Text>
						</TouchableOpacity>
					</View>
				</View>
			
				<Snackbar
					visible={snackbar.isVisible}
					onDismiss={() => setSnackbar(snackbarInitialState)}
					type={snackbar.type}
					message={snackbar.message}
				/>
			</View>
		</Modal>
	)
}