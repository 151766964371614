import { theme } from '@styles/constants'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
	container: {
		flex: 1,
		padding: 20
	},
	content: {
		height: '90%',
		gap: 30,
	},
	title: {
		fontSize: 26,
		color: theme.light.colors.titleColor,
		fontWeight: '500',
		textAlign: 'center'
	},
	button: {
		backgroundColor: theme.light.colors.primaryDark,
		borderRadius: 6,
		paddingVertical: 10,
		paddingHorizontal: 20,
		flexDirection: 'row',
		gap: 8,
		textAlign: 'center'
	},
	buttonText: {
		color: theme.light.colors.linkColorActive,
		fontWeight: 'bold',
	},
	header: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		borderColor: theme.light.colors.boxBorder
	},
	headerSmallDevice: {
		gap: 12,
		alignItems: 'flex-start'
	},
	cardsContainer: {
		width: '100%',
		backgroundColor: theme.light.colors.mainContainer,
		borderBottomRightRadius: 30,
		borderBottomLeftRadius: 30,
		borderRadius: 30,
	}
})