import React, { ReactNode, FC, useState, useRef, useEffect } from 'react'
import { View, Animated, Easing, ScrollView, StyleSheet, SafeAreaView } from 'react-native'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Menu from '@components/Layout/Menu/Menu'
import Header from '@components/Layout/Header/Header'
import useScreenDimensions from '@components/Common/UseScreenDimensions'
import { FooterBackButton } from '@components/FooterBackButton/FooterBackButton'
import { rootReducerType } from '@reducers/combineReducers'
import { isPartnerOrAdvisor } from '@utils/utils'
import { ViewTypes } from '@utils/interfaces'
import { screenType } from '@utils/constants'
import { colors, theme } from '@styles/constants'
import { useDispatch } from 'react-redux'
import { setIsExpanded } from '@actions/menuActions'
import CrispIntegration from '@utils/CrispIntegration'

interface LayoutProps {
	children: ReactNode
}

const Layout: FC<LayoutProps> = ({ children }) => {
	const menuAnimation = useRef(new Animated.Value(0)).current
	const screenDimensions = useScreenDimensions()
	const isSmallScreen = screenDimensions.width < screenType.phone
	const userRoles = useSelector((state: rootReducerType) => state.userReducer.roles)
	const isExpanded = useSelector((state: rootReducerType) => state.currentPageReducer.expanded)

	const menuWidth = menuAnimation.interpolate({
		inputRange: [0, 1],
		outputRange: [60, 200],
	})
	
	const menuWidth_phone = menuAnimation.interpolate({
		inputRange: [0, 1],
		outputRange: [60, 320],
	})

	const { pathname } = useLocation()
	const parentRoute = pathname.split('/')[1]
	const showFooter = parentRoute === ViewTypes.COMPANY && isPartnerOrAdvisor(userRoles)
	const footerHeight = 60
	const headerHeight = 80
	const dispatch = useDispatch()

	const toggleMenu = () => {
		dispatch(setIsExpanded(!isExpanded))
		Animated.timing(menuAnimation, {
			toValue: isExpanded ? 0 : 1,
			duration: 300,
			easing: isExpanded ? Easing.out(Easing.linear) : Easing.inOut(Easing.linear),
			useNativeDriver: false
		}).start()
	}

	useEffect(() => {
		Animated.timing(menuAnimation, {
			toValue: isExpanded ? 1 : 0,
			duration: 0,
			useNativeDriver: false,
		}).start()
	}, [])

	return (
		<View style={{ height: screenDimensions.height }}>
			<CrispIntegration />
			<Header isOpen={ isExpanded } toggleMenu={ toggleMenu } headerHeight={headerHeight} />
			<View style={styles.contentContainer}>
				<Animated.View style={[ styles.menu, { width: isSmallScreen ? menuWidth_phone : menuWidth } ]}>
					<Menu isOpen={isExpanded} />
				</Animated.View>
				<ScrollView>
					<View style={[
						{ minHeight: showFooter
							? screenDimensions.height - footerHeight - headerHeight
							: screenDimensions.height - headerHeight
						}
					]}>
						<View style={styles.content}>{children}</View>
					</View>
				</ScrollView>
			</View>
			{showFooter && <FooterBackButton height={footerHeight} />}
		</View>
	)
}


const styles = StyleSheet.create({
	menu: {
		backgroundColor: theme.light.colors.appContainer,
		zIndex: 2,
		overflow: 'hidden',
	},
	contentContainer: {
		flex: 1,
		flexDirection: 'row',
		backgroundColor: theme.light.colors.appContainer,
	},
	content: {
		flex: 1,
		height: 'auto',
		paddingHorizontal: '8%'
	},
})
	
export default Layout