import Layout from '@containers/Layout'
import React from 'react'
import { CaseEdit } from '@containers/CaseEdit/CaseEdit'

export const CaseEditPage = () => {
	return (
		<Layout>
			<CaseEdit />
		</Layout>
	)
}