import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text, Image, TouchableOpacity, Modal } from 'react-native'
import { useLocation, useNavigate } from 'react-router-dom'
import Form from '@components/Form/Form'
import { Snackbar } from '@components/Snackbar/Snackbar'
import { FormValues, initialCompanyRegisterFormValues, companyRegisterFields, CompanyRegisterFormValues, SnackbarTypes, SnackbarInitialState } from '@utils/interfaces'
import { countries } from '@utils/constants'
import { axiosBFFServer } from '@services/connectionServer'
import { snackbarInitialState, languages } from '@utils/constants'
import { Loader } from '@components/Loader/Loader'
import { styles } from './styles'
import { GeneralModal } from '@components/Modal/Modal'
import { UseErrorMessages } from '@components/Common/UseErrorMessage'

const RegisterContainer = () => {
	const { t } = useTranslation()
	const location = useLocation()
	const { getErrorMessage } = UseErrorMessages()
	const navigate = useNavigate()
	const [legalPartnerLinkKey,setLegalPartnerLinkKey] = useState('')
	const [ isLoading, setIsLoading ] = useState(false)
	const [ snackbar, setSnackbar ] = useState<SnackbarInitialState>(snackbarInitialState)
	const [companyNoOfEmployees, setCompanyNoOfEmployees] = useState([])
	const [showModal, setShowModal] = useState(false)
	const [ isVisible, setIsVisible ] = useState(false)

	const handleCompanyRegister = (values: FormValues) => {
		setIsLoading(true)
		const {
			firstName,
			lastName,
			email,
			phonePrefix: prefix,
			phoneNumber: number,
			companyName,
			country,
			language,
			numberOfEmployees,
			street,
			city,
			zipCode,
			vatNr,
			isStateOwned,
		} = values as CompanyRegisterFormValues

		const companyData = {
			firstName,
			lastName,
			email,
			name: companyName,
			country,
			phoneNumber: prefix + number,
			legalPartnerKey: legalPartnerLinkKey,
			nrOfEmployees: numberOfEmployees,
			defaultLanguage: language,
			city,
			zipCode,
			streetAddress: street,
			vatNumber: vatNr,
			isStateOwned,
		}

		axiosBFFServer
			.post('/company', companyData)
			.then(response => {
				if(response.status === 201) {
					setIsLoading(false)
					setShowModal(true)
					setSnackbar({
						isVisible: true,
						type: SnackbarTypes.SUCCESS,
						message: t('success.registerCompany')
					})
					
				}
			})
			.catch(error => {
				if (error.response.status === 409) {
					setIsLoading(false)
					setIsVisible(true)
					return
				}
				const errorMessage = getErrorMessage(error, t('errors.registerCompany'))                
				setIsLoading(false)
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}

	const dropdownsValues = [
		{
			fieldName: 'country',
			values: countries
		},
		{
			fieldName: 'language',
			values: languages
		},
		{
			fieldName: 'numberOfEmployees',
			values: companyNoOfEmployees
		},
	]

	const getLPKey = () => {
		const link = location.pathname.slice(location.pathname.lastIndexOf('/')+1)

		axiosBFFServer
			.get(`/partner/link/${link}`)
			.then(response => {
				setLegalPartnerLinkKey(response.data.legalPartnerKey)
			})
			.catch(error => {
				const errorMessage = getErrorMessage(error, t('errors.getLPKey')) 
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}

	const getCompanyNoOfEmployees = () => {
		axiosBFFServer
			.get('/company/sizes')
			.then((response) => {
				setCompanyNoOfEmployees(response.data)
			})
			.catch((error) => {
				const errorMessage = getErrorMessage(error, t('errors.getNoOfEmployees')) 
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}

	const redirectWebsite = () => {
		global.location.replace(process.env.WEBSITE_URL)
	}

	useEffect(() => {
		getLPKey()
		getCompanyNoOfEmployees()
	},[])

	return (
		<View style={styles.container}>
			{showModal &&
				<GeneralModal
					visible={showModal}
					infoModal={true}
					onClose={redirectWebsite}
					onSubmit={redirectWebsite}
					messageText={t('companyRegistrationMessage')}
				/>
			}
			{isVisible && <GeneralModal
				visible={isVisible}
				onClose={() => setIsVisible(false)}
				onSubmit={redirectWebsite}
				messageText={t('accountAlreadyExists')}
				closeButtonText={t('close')}
				submitButtonText={t('goToWebsite')}
			/>}
			<Modal
				visible={isLoading}
				transparent={true}
			>
				<Loader />
			</Modal>
			<View style={[
				styles.formContainer,
				{...(isLoading && {
					opacity: 0,
				})}
			]}>
				<Image
					source={require('@assets/Logo.webp')}
					style={styles.logo}
				/>
				<Text style={styles.appName}>{t('appName')}</Text>
				<Text style={styles.formTitle}>{t('registerCompany')}</Text>
				<Form
					formType='companyRegister'
					initialValues={initialCompanyRegisterFormValues}
					fields={companyRegisterFields}
					submitButtonText={t('registerCompany')}
					onSubmit={handleCompanyRegister}
					dropdownsValues={dropdownsValues}
				/>
				<View style={styles.login}>
					<Text style={styles.loginText}>{t('alreadyHaveAccount')}&nbsp;</Text>
					<TouchableOpacity onPress={() => navigate('/login')}>
						<Text style={[styles.loginText, styles.loginLink]}>{t('loginHere')}</Text>
					</TouchableOpacity>
				</View>
			</View>
			<Snackbar
				visible={snackbar.isVisible}
				onDismiss={() => setSnackbar(snackbarInitialState)}
				type={snackbar.type}
				message={snackbar.message}
			/>
		</View>
	)
}

export default RegisterContainer