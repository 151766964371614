import { StyleSheet } from 'react-native'
import { colors, theme } from '@styles/constants'


export const styles = StyleSheet.create({
	menuContainer: {
		position: 'absolute',
		top: 0,
		left: 0,
		bottom: 0,
		width: 260,
		backgroundColor: theme.light.colors.mainContainer,
		zIndex: 2,
		borderRightWidth: 1,
		borderRightColor: theme.light.colors.boxBorder,
		shadowColor: theme.light.colors.mainColor,
		shadowOffset: {
			width: 2,
			height: 0,
		},
		shadowOpacity: 0.2,
		shadowRadius: 2,
		elevation: 2,
		display: 'flex',
		justifyContent: 'space-between',
	},
	menuContainer_phone: {
		position: 'absolute',
		top: 0,
		left: 0,
		bottom: 0,
		width: 320,
		backgroundColor: theme.light.colors.mainContainer,
		zIndex: 2
	},
	menuOpen: {
		width: 200,
	},
	menuOpen_phone: {
		width: 320
	},
	menuClosed: {
		width: 60,
	},
	menuItem: {
		flexDirection: 'row',
		alignItems: 'center',
		paddingVertical: 8,
		paddingHorizontal: 8,
	},
	menuItem_active: {
		flexDirection: 'row',
		alignItems: 'center',
		marginVertical: 8,
		marginLeft: 8,
		marginRight: 8,
		backgroundColor: theme.light.colors.primary,
		borderLeftWidth: 3,
		borderLeftColor: theme.light.colors.primaryDark,
	},
	menuText: {
		marginLeft: 8,
		color: theme.light.colors.linkColor,
		fontWeight: 600
	},
	menuText_active: {
		marginLeft: 8,
	},
	menuIcon: {
		color: theme.light.colors.linkColor,
	},
	iconContainer: {
		width: 43,
		height: 43,
		textAlign: 'center',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center'
	},
})
