import { StyleSheet } from 'react-native'
import { theme } from '@styles/constants'

export const styles = StyleSheet.create({
	dropdownBtn: {
		flex: 1,
		width: '100%',
		backgroundColor: theme.light.colors.mainContainer,
		borderColor: theme.light.colors.mainColor,
		borderWidth: 1,
		borderRadius: 4,
		padding: 8,
		paddingTop: 6,
		paddingBottom: 10,
		maxHeight: 50
	},
	dropdownBtnText: {
		color: theme.light.colors.mainColor,
		textAlign: 'left',
		lineHeight: 20,
		fontSize: 16
	},
	dropdownStyle: {
		backgroundColor: theme.light.colors.mainContainer,
		borderRadius: 12,
		paddingVertical: 15,
		minHeight: 60
	},
	SelectRow: {
		borderBottomWidth: 0,
		borderTopWidth: 1,
		borderTopColor: theme.light.colors.boxBorder,
		paddingVertical: 4
	},
	dropdownRowTxt: {
		color: theme.light.colors.linkColor,
		textAlign: 'left',
		fontSize: 16
	}
})
