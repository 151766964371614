import React, { useState } from 'react'
import { Modal, View, Text, TouchableOpacity, StyleSheet } from 'react-native'
import { colors, theme } from '@styles/constants'
import { useTranslation } from 'react-i18next'
import { button, buttonText } from '@styles/mixins/button'

interface PopupProps {
  isVisible: boolean
  onClose: () => void
  onConfirm: () => void
  popupTitle: string
  textConfirm: string
  showCloseButton?: boolean
  children?: React.ReactNode
}

export const Popup: React.FC<PopupProps> = ({ isVisible, onClose, onConfirm, popupTitle, textConfirm, showCloseButton=true, children }) => {
	const { t } = useTranslation()
	return (
		<Modal
			animationType="slide"
			transparent={true}
			visible={isVisible}
			onRequestClose={onClose}
		>
			<View style={styles.modal}>
				<View style={styles.modalContent}>
					<Text style={styles.popupText}>{popupTitle}</Text>
					<View style={styles.actionButtons}>
						{showCloseButton && (
							<TouchableOpacity onPress={onClose}>
								<Text style={styles.button}>{t('close')}</Text>
							</TouchableOpacity>
						)}
						<TouchableOpacity onPress={onConfirm} style={styles.closeButton}>
							<Text style={styles.closeButtonText}>{textConfirm}</Text>
						</TouchableOpacity>
					</View>
				</View>
			</View>
		</Modal>
	)
}

const styles = StyleSheet.create({
	modal: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
		textAlign: 'center',
		flexDirection: 'column',
	},
	modalContent: {
		backgroundColor: 'white',
		fontWeight: '500',
		color: colors.main,
		padding: 20,
		borderRadius: 10,
		width: '30%',
		justifyContent: 'center',
	},
	actionButtons: {
		padding: 20,
		flexDirection: 'row',
		justifyContent: 'space-evenly'
	},
	closeButton: {
		...({
			...button(theme.light.colors.buttonLg).button,
		} as object),
		alignSelf: 'flex-end',
		marginBottom: 10,
	},
	popupText: {
		color: colors.main,
		fontWeight: '500',
		fontSize: 18
	},
	closeButtonText: {
		fontWeight: '500',
		fontSize: 16,
		...({
			...buttonText().buttonText,
		} as object),
		color: theme.light.colors.linkColorActive
	},
	button: {
		fontWeight: 'bold',
		paddingVertical: 10,
		paddingHorizontal: 20,
		textAlign: 'center',
		borderRadius: 6,
		color: theme.light.colors.mainColor
	},
	buttonText: {
		fontWeight: '500',
		color: theme.light.colors.mainColor,
	}
})