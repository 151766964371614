import React from 'react'
import { Report } from '@containers/Report/Report'
import { ReportLayout } from '@containers/ReportLayout/ReportLayout'

export const ReportPage = () => {
	return (
		<ReportLayout>
			<Report />
		</ReportLayout>
	)
}