import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, Text, TextInput, TouchableOpacity, View } from 'react-native'
import Icon from 'react-native-vector-icons/FontAwesome'
import * as Clipboard from 'expo-clipboard'
import Checkbox from 'expo-checkbox'
import { Snackbar } from '@components/Snackbar/Snackbar'
import { SnackbarInitialState, SnackbarTypes } from '@utils/interfaces'
import { snackbarInitialState } from '@utils/constants'
import { colors, theme } from '@styles/constants'
import { styles } from './styles'
import { downloadMasterPasswordPDF } from '@utils/utils'
import { useSelector } from 'react-redux'
import { rootReducerType } from '@reducers/combineReducers'

interface MasterPasswordModalProps {
    masterPassword: string
    isVisible: boolean
    onClose: () => void
}

export const MasterPasswordModal: FC<MasterPasswordModalProps> = ({
	masterPassword,
	isVisible,
	onClose,
}) => {
	const [ snackbar, setSnackbar ] = useState<SnackbarInitialState>(snackbarInitialState)
	const [ isChecked, setIsChecked ] = useState(false)
	const [ errorConfirmation, setErrorConfirmation ] = useState(false)
	const [ isCheckedSecond, setIsCheckedSecond ] = useState(false)
	const [ showSecondConfirmation, setShowSecondConfirmation ] = useState(false)
	const [ errorSecondConfirmation, setErrorSecondConfirmation ] = useState(false)
	const { t } = useTranslation()
	const { name } = useSelector((state: rootReducerType) => state.companyReducer.currentCompany)

	const copyLinkToClipboard = async () => {
		await Clipboard.setStringAsync(masterPassword)
		setSnackbar({
			isVisible: true,
			type: SnackbarTypes.INFO,
			message: t('copyMasterPassword'),
		})
	}

	const handleCheckConfirmation = (value: boolean) => {
		setIsChecked(value)
		value && setErrorConfirmation(false)
	}

	const handleCheckSecondConfirmation = (value: boolean) => {
		setIsCheckedSecond(value)
		value && setErrorSecondConfirmation(false)
	}

	const handleClose = () => {
		if (isChecked) {
			if (isCheckedSecond) {
				onClose()
			} else if (showSecondConfirmation) {
				setErrorSecondConfirmation(true)
			} else {
				setShowSecondConfirmation(true)
			}
		} else {
			setErrorConfirmation(true)
			if (showSecondConfirmation && !isCheckedSecond) {
				setErrorSecondConfirmation(true)
			}
		}
	}

	const downloadMasterPassword = () => {
		downloadMasterPasswordPDF(masterPassword, name)
	}

	return (
		<Modal
			animationType='slide'
			transparent={true}
			visible={isVisible}
			onRequestClose={handleClose}
		>
			<View style={styles.modalContainer}>
				<View style={styles.contentContainer}>
					<Text style={styles.title}>{t('companyMasterPassword')}</Text>
					<View style={styles.textContainer}>
						<Text style={styles.errorBoldText}>{t('important')} {t('masterPasswordImportantText')}</Text>
						<Text>{t('masterPasswordExplanatoryText')}</Text>
					</View>
					<View style={styles.sectionConteiner}>
						<Text style={styles.boldText}>{t('masterPasswordText')}</Text>
						<View style={styles.inputContainer}>
							<TextInput
								style={styles.formFieldText}
								value={masterPassword}
								editable={false}
							/>
							<TouchableOpacity style={styles.copyButton} onPress={copyLinkToClipboard}>
								<Icon
									name={'clipboard'} 
									color={colors.white}
									size={18}
								/>
							</TouchableOpacity>			
							<TouchableOpacity 
								style={[styles.copyButton, styles.download]}
								onPress={() => downloadMasterPassword()}>
								<Icon style={styles.icon} name='download' size={18} />
							</TouchableOpacity>
						</View>
					</View>
					<View style={styles.sectionConteiner}>                   
						<Text style={styles.boldText}>{t('masterPasswordStoreInfo')}</Text>
						<View style={styles.checkBoxContainer}>
							<Checkbox 
								value={isChecked} 
								onValueChange={handleCheckConfirmation}
								color={isChecked ? theme.light.colors.buttonLg : errorConfirmation ? colors.error : colors.darkAccent}
							/>
							<Text>{t('storedMasterPassword')}</Text>	
						</View>
						{errorConfirmation && (
							<Text style={styles.errorText}>{t('storedMasterPasswordError')}</Text>
						)}
						{showSecondConfirmation && <>
							<View style={styles.checkBoxContainer}>
								<Checkbox 
									value={isCheckedSecond} 
									onValueChange={handleCheckSecondConfirmation}
									color={isCheckedSecond ? theme.light.colors.buttonLg : errorSecondConfirmation ? colors.error : colors.darkAccent}
								/>
								<Text>{t('storedMasterPasswordSecond')}</Text>	
							</View>
							{errorSecondConfirmation && (
								<Text style={styles.errorText}>{t('storedMasterPasswordError')}</Text>
							)}
						</>}
					</View>
					<TouchableOpacity style={styles.button} onPress={handleClose}>
						<Text style={styles.buttonText}>{t('continue')}</Text>
					</TouchableOpacity>
				</View>
			</View>
			<Snackbar
				visible={snackbar.isVisible}
				onDismiss={() => setSnackbar(snackbarInitialState)}
				type={snackbar.type}
				message={snackbar.message}
			/>
		</Modal>
	)
}
