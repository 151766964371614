import { SnackbarInitialState, UserNotification } from '@utils/interfaces'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { useSelector, useDispatch } from 'react-redux'
import { setCompanySettings, setCurrentCompany } from '@actions/companyActions'
import { Subscription } from '@components/Settings/Subscription/Subscription'
import MyAccount from '@components/Settings/account/MyAccount/MyAccount'
import { CompanyInformation } from '@components/Settings/companyInfo/CompanyInformation/CompanyInformation'
import { Security } from '@components/Settings/security/Security'
import { Tab } from '@components/Tab'
import { rootReducerType } from '@reducers/combineReducers'
import { getCompanyByKey, getCurrentCompany, getCompanySettings, getUserNotificationSettings } from '@services/apiService'
import { settingsTabs, snackbarInitialState, subscriptionExpiredStatuses } from '@utils/constants'
import { checkIfPermissionExist, isCompanyUserAdmin, isPartnerOrAdvisor, isTodayBeforeDate } from '@utils/utils'
import { CaseStateTab } from '@components/Settings/CaseState/CaseState'
import { useLocation, useNavigate } from 'react-router-dom'
import { Loader } from '@components/Loader/Loader'
import { Snackbar } from '@components/Snackbar/Snackbar'

export const CompanySettings = () => {
	const { t } = useTranslation()
	const location = useLocation()
	const dispatch = useDispatch()
	const sessionID = location.search

	const [tabs, setTabs] = useState(settingsTabs)
	const [currentTab, setCurrentTab] = useState(tabs[0])
	const [userNotifications, setUserNotifications] = useState<Array<UserNotification>>([])
	const [isLoading, setIsLoading] = useState(true)
	const [snackbar, setSnackbar] = useState<SnackbarInitialState>(snackbarInitialState)

	let componentToRender: JSX.Element|null
	const { roles, language } = useSelector((state: rootReducerType) => state.userReducer)
	const isPaymentNeeded = useSelector((state: rootReducerType) => state.paymentReducer.isPaymentNeeded)
	const userPermissions = useSelector((state: rootReducerType) => state.userReducer.permissions)
	const { subscription, status } = useSelector((state: rootReducerType) => state.companyReducer.currentCompany)
	const viewSecurityLogs = checkIfPermissionExist(userPermissions, 'securityLogs', 'view')
	const hasCompanySettingsPermission = checkIfPermissionExist(userPermissions, 'companySettings', 'view')
	const hasSubscriptionPermission = checkIfPermissionExist(userPermissions, 'subscription', 'view')

	const companyKey = useSelector((state:rootReducerType) => state.companyReducer.key)
	const navigate = useNavigate()

	const changeTab = (tab: string) => {
		setCurrentTab(tab)
		navigate(tab.toLowerCase())
	}

	const getCompany = async () => {
		const company = (isPartnerOrAdvisor(roles) && companyKey) ? await getCompanyByKey(companyKey) : await getCurrentCompany()
		return company?.data
	}

	const checkTabsAccess = () => {
		const userTabs = [...tabs]
	
		if (isCompanyUserAdmin(roles) || hasCompanySettingsPermission){
			!userTabs.includes('companyInfo') && userTabs.push('companyInfo', 'caseManagement')
		}
		if (viewSecurityLogs){
			!userTabs.includes('security') && userTabs.push('security')
		}
		if (isCompanyUserAdmin(roles) || hasSubscriptionPermission){
			!userTabs.includes('subscription') && userTabs.push('subscription')
		}
		setTabs(userTabs)
	}

	const checkPayment = () => {
		const sessionID = location.search
		if ((isPaymentNeeded || sessionID) && hasSubscriptionPermission){
			setCurrentTab('subscription')
		}
		setIsLoading(false)
	}

	const hasRemainingDays = () => {
		if (subscription.nextPaymentDate) { 
			return isTodayBeforeDate(subscription.nextPaymentDate)
		} return false
	}

	const isCanceledSubscription = () => {
		if ((status === 'CANCELLED' && subscription.cancelled && !hasRemainingDays()) || subscriptionExpiredStatuses.includes(status)) {
			return true
		}
		return false
	}

	useEffect(() => {
		checkPayment()
		const fetchData = async () => {
			const [company, notificationSettings] = await Promise.all([
				getCompany(),
				getUserNotificationSettings()
			])

			dispatch(
				setCurrentCompany(company)
			)
		
			if (isPartnerOrAdvisor(roles)) {
				const companySettings = await getCompanySettings(company.companyKey)
				dispatch(setCompanySettings({
					language: companySettings?.data.language,
				}))
			}

			if (notificationSettings) {
				setUserNotifications(notificationSettings?.data)
			}
			checkTabsAccess()
		}

		fetchData()
	}, [language])

	useEffect(() => {
		if ((location.pathname.includes('*') || tabs.every(tab => !location.pathname.includes(tab))) && !sessionID) {
			navigate(currentTab.toLowerCase())
		}
	}, [currentTab, location.pathname])

	switch (currentTab) {
	case 'myAccount':
		componentToRender = <MyAccount setSnackbar={setSnackbar} isCompany userNotifications={userNotifications} />
		break
	case 'companyInfo':
		componentToRender = <CompanyInformation isCompany />
		break
	case 'caseManagement':
		componentToRender = <CaseStateTab />
		break
	case 'security': 
		componentToRender = <Security />
		break
	case 'subscription':
		componentToRender = <Subscription />
		break
	
	default:
		componentToRender = null	
		break
	}

	return (
		<View style={styles.container}>
			<View style={styles.header}>
				{tabs.map((tab, index) => {
					return <Tab 
						key={index} 
						label={t(tab)} 
						active={currentTab === tab} 
						onClick={() => changeTab(tab)}
						disabled={isPaymentNeeded && isCanceledSubscription() && tab !== 'subscription'} 
					/>
				})}
			</View>
			{isLoading ?
				<Loader/> 
				: componentToRender
			}
			<Snackbar
				visible={snackbar.isVisible}
				onDismiss={() => setSnackbar(snackbarInitialState)}
				type={snackbar.type}
				message={snackbar.message}
				wrapperStyle={{ bottom: 430, height: 20 }}
			/>
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		display: 'flex',
		marginBottom: 20,
	},
	header: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		paddingVertical: 20,
	},
})