import { colors, theme } from '@styles/constants'
import { button, buttonText } from '@styles/mixins/button'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
	container: {
		alignSelf: 'stretch',
		padding: 16,
	},
	containerRegister: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		marginBottom: 16,
	},
	fieldContainer: {
		marginBottom: 16,
	},
	fieldContainerRegister: {
		marginBottom: 16,
		width: '47%'
	},
	checkboxField: {
		flexDirection: 'row',
		alignItems: 'center',
		gap: 10,
		padding: 6,
	},
	label: {
		fontSize: 16,
		marginBottom: 8,
	},
	input: {
		fontSize: 16,
		borderWidth: 1,
		borderColor: colors.darkAccent,
		backgroundColor: colors.white,
		borderRadius: 4,
		padding: 12,
	},
	errorText: {
		color: colors.error,
		fontSize: 12,
	},
	buttonsContainer: {
		width: '100%',
	},
	buttonsContainerLogin: {
		padding: 16,
	},
	submitButton: {
		...({
			...button(theme.light.colors.primaryDark).button,
		} as object),
		borderRadius: 6,
		paddingVertical: 12,
		justifyContent: 'center',
		flexGrow: 1,
		shadowColor: theme.light.colors.mainColor,
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.2,
		shadowRadius: 2,
		elevation: 2,
	},
	submitButtonText: {
		...({
			...buttonText().buttonText,
		} as object),
		fontSize: 18,
		fontWeight: 'bold'
	},
	cancelButton: {
		backgroundColor: colors.lightAccent,
		borderRadius: 4,
		paddingVertical: 12,
		alignItems: 'center',
	},
	dropdownButton: {
		width: 'unset',
	},
	dropdownText: {
		fontSize: 16,
		textAlign: 'left',
	},
	dropdownRow: {
		padding: 6,
	},
})