import { useEffect, useState } from 'react'
import { Dimensions } from 'react-native'

const useScreenDimensions = () => {
	const [screenData, setScreenData] = useState(Dimensions.get('window'))

	useEffect(() => {
		const onChange = ({ window }) => setScreenData(window)
		Dimensions.addEventListener('change', onChange)
	}, [])

	return screenData
}

export default useScreenDimensions