import React from 'react'
import { SubmittedReport } from '@containers/SubmittedReport/SubmittedReport'
import { ReportLayout } from '@containers/ReportLayout/ReportLayout'

export const SubmittedReportPage = () => {
	return (
		<ReportLayout>
			<SubmittedReport />
		</ReportLayout>
	)
}