import React from 'react'
import { NewReport } from '@containers/NewReport/NewReport'
import { ReportLayout } from '@containers/ReportLayout/ReportLayout'

export const NewReportPage = () => {
	return (
		<ReportLayout>
			<NewReport />
		</ReportLayout>
	)
}