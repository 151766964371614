
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startRecording, stopRecording } from '@actions/voiceRecordingActions'
import { RecordingState, SnackbarInitialState, SnackbarTypes, audioFile } from '@utils/interfaces'
import { Audio } from 'expo-av'
import { View, Text, TouchableOpacity, Platform } from 'react-native'
import FontAwesome from 'react-native-vector-icons/FontAwesome'
import { colors } from '@styles/constants'
import RecordingPlayer from '@components/RecordingPlayer/RecordingPlayer'
import Icon from 'react-native-vector-icons/FontAwesome'
import { GeneralModal } from '@components/Modal/Modal'
import { useTranslation } from 'react-i18next'
import {generateFileName} from '@utils/utils'
import { styles } from './style'
import useScreenDimensions from '@components/Common/UseScreenDimensions'
import { screenType } from '@utils/constants'
import { Popup } from '@components/Popup'

interface State {
	recordingReducer: RecordingState;
}

interface Props {
	setAudioRecords: (value: audioFile[]) => void;
	setSnackbar: (value: SnackbarInitialState) => void
}

const VocalRecording: React.FC<Props> = ({ setAudioRecords, setSnackbar }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const screenDimensions = useScreenDimensions()
	const isMobileScreen = screenDimensions.width < screenType.phone
	const isRecording = useSelector((state: State) => state.recordingReducer.recording)
	const isUnderSmallScreen = screenDimensions.width < screenType.smallLaptop
	const [recording, setRecording] = useState<Audio.Recording | null>(null)
	const [recordings, setRecordings] = useState<audioFile[]>([])
	const [openPopUp, setOpenPopUp] = useState(false)
	const [recordToDiscard, setRecordToDiscard] = useState(0)
	const [openPopupTroubleshoot, setOpenPopupTroubleshoot] = useState(false)
 
	useEffect(() => {
		return () => {
			recordings.forEach((recording) => {
				if (recording.sound) {
					recording.sound.unloadAsync()
				}
			})
		}
	}, [])	

	useEffect(() => {
		recordings && setAudioRecords(recordings)
	}, [recordings])

	const handleStartRecording = async () => {
		const { status } = await Audio.requestPermissionsAsync()
		
		if (status !== Audio.PermissionStatus.GRANTED) {
			setSnackbar({ isVisible: true, message: t('microponePermission'), type: SnackbarTypes.ERROR })
			return
		}

		const recordingOptions: Audio.RecordingOptions = Audio.RecordingOptionsPresets.HIGH_QUALITY
		const recordingObject = new Audio.Recording()
		await recordingObject.prepareToRecordAsync(recordingOptions)
		await recordingObject.startAsync()
		setRecording(recordingObject)
		dispatch(startRecording())
		setSnackbar({ isVisible: true, message: t('voiceRecording'), type: SnackbarTypes.SUCCESS })
	}

	const handleStopRecording = async () => {
		if (recording) {
			await recording.stopAndUnloadAsync()
			const uri = recording.getURI()
			const fileName = generateFileName('recording', 'mp3')
			if (uri) {
				const response = await fetch(uri)
				const audioBlob = await response.blob()
				const audioFile = new global.File([audioBlob], fileName, { type: 'audio/mpeg' })
				const sound = new Audio.Sound()
				const audioBlobFromAudioFile = new Blob([audioFile], { type: 'audio/mpeg', lastModified: new Date().getMilliseconds() })
				const audioUrl = URL.createObjectURL(audioBlobFromAudioFile)
				await sound.loadAsync({ uri: audioUrl })
				setRecordings([...recordings, { blob: audioFile, sound: sound, isPlaying: false }])
			}
			dispatch(stopRecording())
		}
	}

	const discard = (index) => {
		setRecordToDiscard(index)
		setOpenPopUp(true)
	}

	const discardRecord = (vocalIndex) => {
		const newRecords = recordings
		newRecords.splice(vocalIndex,1)
		setRecordings(newRecords)
		setOpenPopUp(false)
	}

	const handleOpenPopupTroubleshoot = () => {
		setOpenPopupTroubleshoot(true)
	}

	const getInstructions = () => {
		return Platform.select({
			ios: t('settingsIos'),
			android: t('settingsAndroid'),
			web: t('settingsWeb'),
			default: t('settingsdefault'),
		})
	}

	return (
		<View style={styles.container}>
			<TouchableOpacity style={isMobileScreen ? [styles.button, styles.button_mobile] : styles.button} onPress={isRecording ? handleStopRecording : handleStartRecording}>
				<FontAwesome name="microphone" size={20} color={isRecording ? colors.lightGray : colors.white} />
				<Text style={[styles.buttonText, {color: isRecording ? colors.lightGray : colors.white }]}>{isRecording ? t('stopRecording') : t('startRecording')}</Text>
			</TouchableOpacity>
			<TouchableOpacity style={{alignSelf: 'flex-start'}} onPress={handleOpenPopupTroubleshoot}>
				<Text style={styles.troubleshoot}>{t('troubleshoot')}</Text>
			</TouchableOpacity>
			{recordings && recordings.length > 0 && recordings.map((recording, index) => (
				<View key={index} style={(isMobileScreen || isUnderSmallScreen) ? styles.recordingItem_mobile : styles.recordingItem}>
					<RecordingPlayer audioUrl={URL.createObjectURL(recording.blob)} />
					<TouchableOpacity onPress={() => discard(index)}>
						<Icon
							name="close"
							size={20}
							color={colors.gray}/>
					</TouchableOpacity>
					
				</View>
			))}
			<GeneralModal
				visible={openPopUp ? openPopUp : openPopupTroubleshoot}
				onClose={() => openPopUp ? setOpenPopUp(false) : setOpenPopupTroubleshoot(false)}
				onSubmit={() => openPopUp ? discardRecord(recordToDiscard) : setOpenPopupTroubleshoot(false)}
				messageText={openPopUp ? t('discardVocalRecord') : getInstructions()}
				infoModal={openPopUp ? false : true}
			/>
		</View>
	)
}



export default VocalRecording