import React, { useState } from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { colors } from '@styles/constants'
import Icon from 'react-native-vector-icons/FontAwesome'
import { GeneralModal } from '@components/Modal/Modal'
import { useTranslation } from 'react-i18next'
import { File } from '@utils/interfaces'
import useScreenDimensions from '@components/Common/UseScreenDimensions'
import { screenType } from '@utils/constants'

interface Props {
	name: string;
    index: number;
	uploadFiles: File[];
	setUploadFiles: (values: File[]) => void;
}


const FileCard = (props: Props) => {
	const { t } = useTranslation()
	const [openPopUp,setOpenPopUp] = useState(false)
	const screenDimensions = useScreenDimensions()
	const isMobileScreen = screenDimensions.width < screenType.phone

	const discardFile = (index: number) => {
		const files = [...props.uploadFiles]
		files.splice(index, 1)
		props.setUploadFiles(files)
		setOpenPopUp(false)
	}

	return (
		<View style={styles.contentContainer}>
			{openPopUp &&
			<GeneralModal
				visible={openPopUp}
				onClose={()=>setOpenPopUp(false)}
				onSubmit={() => discardFile(props.index)}
				itemKey={props.name}
				messageText={t('deleteFileMessageModal')}
			/>
			}
			<Text style={isMobileScreen? styles.mainText_mobile : styles.mainText}>{props.name}</Text>
			<TouchableOpacity 
				style={styles.createButton}
				onPress={() => setOpenPopUp(true)}>
				<Icon style={styles.icon} name="trash" size={20} />
			</TouchableOpacity>
		
		</View>
	)
}

const styles = StyleSheet.create({
	contentContainer: {
		flexDirection: 'row',
		marginBottom: 6,
		marginTop: 6,
		borderWidth: 1,
		borderColor: colors.gray,
		backgroundColor: colors.white,
		borderRadius: 4,
		paddingHorizontal: 12,
		paddingVertical: 4,
		gap: 12,
		width: '100%',
		justifyContent: 'space-between',
		alignContent: 'center'
	},
	textContainer: {
		flexDirection: 'column'
	},
	mainText: {
		fontWeight: '600',
		fontSize: 16,
		wordBreak: 'break-word'
	},
	mainText_mobile: {
		fontWeight: '600',
		fontSize: 13,
		wordBreak: 'break-word'
	},
	createButton: {
		backgroundColor: colors.gray,
		borderRadius: 50,
		width: 40,
		height: 40,
		justifyContent: 'center',
		alignItems: 'center'
	},
	icon: {
		color: colors.white
	}
})

export default FileCard