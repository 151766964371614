import React, { FC } from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import Icon from 'react-native-vector-icons/FontAwesome'
import { useTranslation } from 'react-i18next'
import useScreenDimensions from '@components/Common/UseScreenDimensions'
import { CategoryType } from '@utils/interfaces'
import { screenType } from '@utils/constants'
import { colors } from '@styles/constants'
import { styles } from './style'

interface CategoryProps {
    category: CategoryType
    openModal: () => void
	editCategory: boolean
}

export const Category: FC<CategoryProps> = ({ category, openModal, editCategory }) => {
	const { t } = useTranslation()
	const screenDimensions = useScreenDimensions()
	const isMobileScreen = screenDimensions.width < screenType.phone

	return (
		<View style={[styles.categoryContainer, isMobileScreen && styles.categoryContainer_mobile]}>
			<View style={styles.categoryColumn}>
				<Text>{category.value}</Text>
			</View>
			<View style={styles.categoryColumn}>
				<View style={styles.categoryColumnSmall}>
					<Icon
						name={category.isActive ? 'check' : 'remove'}
						color={category.isActive ? colors.secondary : colors.danger}
					/>
					<Text style={{ color: colors.gray }}>
						{category.isActive ? t('active') : t('inactive')}
					</Text>
				</View>
				<View style={[styles.categoryColumnSmall, { justifyContent: 'flex-end' }]}>
					{
						editCategory &&
						<TouchableOpacity onPress={openModal}>
							<Icon name='cog' color={colors.gray} size={16} />
						</TouchableOpacity>
					}
				</View>
			</View>
		</View>
	)
}


