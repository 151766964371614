import { RouteType } from '@utils/interfaces'
import { Login } from '@pages/Login'
import { CompanyManagement } from '@pages/CompanyManagement'
import { ReportPage } from '@pages/ReportPage'
import { NewReportPage } from '@pages/NewReportPage'
import { SubmittedReportPage } from '@pages/SubmittedReport'
import { ViewReportPage } from '@pages/ViewReport'
import { AdvisorsPage } from '@pages/Advisors'
import { Register } from '@pages/Register'
import { AdvisorEdit } from '@pages/AdvisorEdit'
import { CompanyDashboardPage } from '@pages/CompanyDashboard'
import { CasesPage } from '@pages/Cases'
import { CaseDetailsPage } from '@pages/CaseDetails'
import { Authorize } from '@pages/Authorize'
import { ReportingLinkPage } from '@pages/ReportingLinkPage'
import { MarketingPage } from '@pages/MarketingPage'
import { AdvisorSettingsPage } from '@pages/AdvisorSettingsPage'
import { CompanySettingsPage } from '@pages/CompanySetingsPage'
import { CategoriesPage } from '@pages/CategoriesPage'
import { DepartmentsPage } from '@pages/DepartmentsPage'
import { CompanyUsersPage } from '@pages/CompanyUsers'
import { CompanyUserEdit } from '@containers/CompanyUsersEdit/CompanyUserEdit'
import { ViewTypes } from '@utils/interfaces'
import { CaseEditPage } from '@pages/CaseEditPage'
import { CaseNewPage } from '@pages/CaseNewPage'
import { CaseImportPage } from '@pages/CaseImportPage'
import { Check } from '@pages/Check'
import { StatisticsPage } from '@pages/Statistics'
import LogoutContainer from '@containers/LogoutContainer'
import { LegalPartner } from '@pages/LegalPartner'
import { LoginClient } from '@pages/LoginClient'
import { LegalPartnerInternPage } from '@pages/LegalPartnerIntern'
import { VerifyCodePage } from '@pages/VerifyCode'
import { PasswordVerificationCodePage } from '@pages/PasswordVerificationCodePage'
import {NotificationsPage} from '@pages/Notifications'
import {NotificationDetails} from '@components/Notifications/NotificationDetails'
import {NotificationDetailsPage} from '@pages/NotificationDetailsPage'

export const routes: RouteType[] = [
	{
		path: '/',
		element: <Authorize />,
		protectedRoute: false,
	},
	{
		path: '/check',
		element: <Check />,
		protectedRoute: true,
	},
	{
		path: 'login/:lang?',
		element: <Login />,
		protectedRoute: false,
	},
	{
		path: 'register/:companyLink',
		element: <Register />,
		protectedRoute: false,
	},
	{
		path: 'legal-partner',
		element: <LegalPartner />,
		protectedRoute: false,
	},
	{
		path: 'login-client',
		element: <LoginClient />,
		protectedRoute: false,
	},
	{
		path: 'sign-up-representative/:lang?',
		element: <LegalPartnerInternPage />,
		protectedRoute: false,
	},
	{
		path: '/enterCode',
		element: <PasswordVerificationCodePage />,
		protectedRoute: false,
	},
	{
		path: '/wbAdmin/enterCode',
		element: <PasswordVerificationCodePage />,
		protectedRoute: false,
	}
]

export const partnerRoutes: RouteType[] = [
	{
		path: 'companies',
		name: 'company',
		iconName: 'home',
		element: <CompanyManagement />,
		protectedRoute: true,
		requiresPermission: true,
	},
	{
		path: 'cases',
		name: 'case',
		iconName: 'folder',
		element: <CasesPage />,
		protectedRoute: true,
		requiresPermission: true,
	},
	{
		path: 'advisor',
		name: 'advisor',
		iconName: 'users',
		element: <AdvisorsPage />,
		protectedRoute: true,
		requiresPermission: true,
	},
	{
		path: 'marketing',
		name: 'marketing',
		iconName: 'twitch',
		element: <MarketingPage />,
		protectedRoute: true,
	},
	{
		path: 'settings/*',
		name: 'userSettings',
		iconName: 'settings',
		element: <AdvisorSettingsPage />,
		protectedRoute: true,
		requiresPermission: true,
	},
	{
		path: 'notifications',
		name: 'notifications',
		iconName: 'bell',
		element: <NotificationsPage />,
		protectedRoute: true,
	},
	{
		path: 'notifications/:notificationId',
		element: <NotificationDetailsPage />,
		protectedRoute: true,
	},
	{
		path: 'verify-code',
		name: 'verifyCode',
		iconName: 'settings',
		element: <VerifyCodePage />,
		protectedRoute: true,
		requiresPermission: true,
	},
	{
		path: 'logout',
		name: 'logout',
		iconName: 'log-out',
		element: <LogoutContainer />,
		protectedRoute: false,
	},
	{
		path: 'advisor/edit-advisor',
		element: <AdvisorEdit/>,
		protectedRoute: true,
	},
	{
		path: 'cases/check',
		element: <Check />,
		protectedRoute: true,
	},
]

export const companyRoutes: RouteType[] = [
	{
		path: 'dashboard',
		name: 'dashboard',
		iconName: 'layout',
		element: <CompanyDashboardPage />,
		protectedRoute: true,
	},
	{
		path: 'cases',
		name: 'case',
		iconName: 'folder',
		element: <CasesPage />,
		protectedRoute: true,
		requiresPermission: true,
	},
	{
		path: 'statistics',
		name: 'companyStatistics',
		iconName: 'pie-chart',
		element: <StatisticsPage />,
		protectedRoute: true,
		requiresPermission: true,
	},
	{
		path: 'departments',
		name: 'department',
		iconName: 'globe',
		element: <DepartmentsPage />,
		protectedRoute: true,
		requiresPermission: true,
	},
	{
		path: 'users',
		name: 'users',
		iconName: 'users',
		element: <CompanyUsersPage />,
		protectedRoute: true,
	},
	{
		path: 'categories',
		name: 'category',
		iconName: 'briefcase',
		element: <CategoriesPage />,
		protectedRoute: true,
		requiresPermission: true,
	},
	{
		path: 'reporting-link',
		name: 'reportingLink',
		iconName: 'link',
		element: <ReportingLinkPage />,
		protectedRoute: true,
	},
	{
		path: 'settings/*',
		name: 'userSettings',
		iconName: 'settings',
		element: <CompanySettingsPage />,
		protectedRoute: true,
		requiresPermission: true,
	},
	{
		path: 'notifications',
		name: 'notifications',
		iconName: 'bell',
		element: <NotificationsPage />,
		protectedRoute: true,
	},
	{
		path: 'notifications/:notificationId',
		element: <NotificationDetailsPage />,
		protectedRoute: true,
	},
	{
		path: 'verify-code',
		name: 'verifyCode',
		iconName: 'settings',
		element: <VerifyCodePage />,
		protectedRoute: true,
		requiresPermission: true,
	},
	{
		path: 'cases/case-details/:caseKey',
		element: <CaseDetailsPage />,
		protectedRoute: true,
	},
	{
		path: 'edit-companyUser',
		element: <CompanyUserEdit/>,
		protectedRoute: true,
	},
	{
		path: 'cases/case-edit',
		element: <CaseEditPage />,
		protectedRoute: true,
	},
	{
		path: 'cases/case-new',
		element: <CaseNewPage />,
		protectedRoute: true,
	},
	{
		path: 'cases/case-import',
		element: <CaseImportPage />,
		protectedRoute: true,
	},
	{
		path: 'logout',
		name: 'logout',
		iconName: 'log-out',
		element: <LogoutContainer />,
		protectedRoute: false,
	},
	{
		path: 'cases/check',
		element: <Check />,
		protectedRoute: true,
	},
	{
		path: 'cases/case-details/:caseKey/check',
		element: <Check />,
		protectedRoute: true,
	},
]

export const reportRoutes: RouteType[] = [
	{
		path: ':reportLink',
		element: <ReportPage />,
		protectedRoute: false,
	},
	{
		path: ':reportinkLink/new',
		element: <NewReportPage />,
		protectedRoute: false,
	},
	{
		path: 'submit',
		element: <SubmittedReportPage />,
		protectedRoute: false,
	},
	{
		path: 'view',
		element: <ViewReportPage />,
		protectedRoute: false,
	},
]

export const buildRoutes = (role, permissions): RouteType[]=> {
	const baseRoutes = role === ViewTypes.PARTNER ? partnerRoutes : companyRoutes
	const shouldRouteShowInMenu = (permission, route) => {
		return !route.requiresPermission || ((permission.subject === route.name) && (permission.action === 'view'))
	}

	return baseRoutes.filter((route) => {
		return !!permissions.find(permission => shouldRouteShowInMenu(permission, route))
		// UNBLOCKER FOR LACKING PERMISSIONS
		// MUST REMOVE AFTER DEVELOPMENT
		// return true
	})
}
