import React, { useEffect, useState } from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import { colors, theme } from '@styles/constants'
import { useTranslation } from 'react-i18next'
import useScreenDimensions from '@components/Common/UseScreenDimensions'
import UserCard from '@components/UserCard/UserCard'
import FontAwesome from 'react-native-vector-icons/FontAwesome'
import { screenType, snackbarInitialState } from '@utils/constants'
import { axiosBFFServer } from '@services/connectionServer'
import { Snackbar } from '@components/Snackbar/Snackbar'
import { CompanyUser, SnackbarInitialState, SnackbarTypes} from '@utils/interfaces'
import { rootReducerType } from '@reducers/combineReducers'
import { useSelector } from 'react-redux'
import { Loader } from '@components/Loader/Loader'
import { NewCompanyUserModal } from '@components/NewCompanyUserModal/NewCompanyUserModal'
import { styles } from './styles'
import { checkIfPermissionExist } from '@utils/utils'
import { UseErrorMessages } from '@components/Common/UseErrorMessage'

export const CompanyUsers = () => {
	const { t } = useTranslation()
	const screenDimensions = useScreenDimensions()
	const { getErrorMessage } = UseErrorMessages()
	
	const isSmallScreen = screenDimensions.width < screenType.phone
	const userPermissions = useSelector((state: rootReducerType) => state.userReducer.permissions)
	
	const [snackbar, setSnackbar] = useState<SnackbarInitialState>(snackbarInitialState)
	const [isLoading, setIsLoading] = useState(true)
	const [oenCreateModal, setOpenCreateModal] = useState(false)
	const [users, setUsers] = useState<CompanyUser[]>([])
	const [newCreated, setNewCreated] = useState(false)
  
	const companyKey = useSelector((state: rootReducerType) => state.companyReducer.key)

	const getUsers = () => {
		setIsLoading(true)
		axiosBFFServer
			.get(`/company/${companyKey}`)
			.then(response => {
				setIsLoading(false)
				setUsers(response.data.users)
			})
			.catch(error => {
				const errorMessage = getErrorMessage(error, t('getCompanyError'))
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}

	const onSubmitNewCompanyUser = () => {
		newCreated && getUsers()
	}

	const onCloseModal = () => {
		setOpenCreateModal(false)
	}

	useEffect(() => {
		companyKey && getUsers()
	}, [])

	useEffect(() => {
		newCreated && getUsers()
	},[newCreated])


	return (
		<View style={styles.container}>
			<View style={styles.content}>
				<View style={isSmallScreen ? styles.headerSmallDevice : styles.header}>
					<Text style={styles.title}>{t('users')}</Text>
					{
						checkIfPermissionExist(userPermissions,'companyUser','create') &&
						<TouchableOpacity 
							style={styles.button}
							onPress={() => setOpenCreateModal(true)}>
							<FontAwesome 
								name={'user'} 
								color={colors.white} 
								size={18} /> 
							<Text style={styles.buttonText}>{t('createUser')}</Text>
						</TouchableOpacity>
					}
				</View>
				
				<View style={styles.cardsContainer}>
					{isLoading ?
						<Loader
							color={colors.white} /> 
						:
						users.map((user) =>
							<UserCard
								userKey={user.companyUserKey}
								name={user.firstName + ' ' + user.lastName}
								email={user.email}
								verifiedEmail={user.verifiedEmail}
								isAdvisor={true}
								description=''/>
						)
					}
				</View>
				
			</View>
			<Snackbar
				visible={snackbar.isVisible}
				onDismiss={() => setSnackbar(snackbarInitialState)}
				type={snackbar.type}
				message={snackbar.message}
			/>
			{
				oenCreateModal && 
					<NewCompanyUserModal
						visible={oenCreateModal}
						onClose={onCloseModal}
						onSubmit={onSubmitNewCompanyUser}
						setNewCreated={setNewCreated} />
			}
		</View>
	)
}

