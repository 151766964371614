import React, { FC, useEffect, useRef, useState } from 'react'
import { Modal, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import QRCode from 'react-qr-code'
import ViewShot, { captureRef } from 'react-native-view-shot'
import { useTranslation } from 'react-i18next'
import { colors } from '@styles/constants'

interface QRCodeModal {
    value: string
    isVisible: boolean
    onClose: () => void
		onImageCaptured: (uri: string) => void
		handleDownloadQR: () => void
}

export const QRCodeModal: FC<QRCodeModal> = ({ value, isVisible, onClose, onImageCaptured, handleDownloadQR }) => {
	const { t } = useTranslation()
	const viewShotRef = useRef(null)

	const captureQRCode = async () => {
		if (viewShotRef.current) {
			const uri = await captureRef(viewShotRef.current, {
				format: 'png',
				quality: 0.9,
			})
			if (uri) {
				onImageCaptured(uri) 
			}
		}
	}

	useEffect(() => {
		if (isVisible) {
			captureQRCode()
		}
	}, [isVisible])

	return (
		<Modal
			animationType='slide'
			transparent={true}
			visible={isVisible}
			onRequestClose={onClose}
		>
			<View style={styles.modalContainer}>
				<View style={styles.contentContainer}>
					<ViewShot ref={viewShotRef} options={{ format: 'png', quality: 0.9 }}>
						<View style={styles.qrCodeContainer}>
							<QRCode value={value} />
						</View>
					</ViewShot>
					<View style={styles.buttonsContainer}>
						<TouchableOpacity onPress={onClose}>
							<Text style={styles.button}>{t('cancel')}</Text>
						</TouchableOpacity>
						<TouchableOpacity onPress={handleDownloadQR}>
							<Text style={styles.button}>{t('download')}</Text>
						</TouchableOpacity>
					</View>
				</View>
			</View>
		</Modal>
	)
}

const styles = StyleSheet.create({
	modalContainer: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: colors.shadeBackground,
	},
	contentContainer: {
		padding: 16,
		borderRadius: 4,
		backgroundColor: colors.white,
	},
	qrCodeContainer: {
		padding: 32,
	},
	buttonsContainer: {
		flexDirection: 'row',
		justifyContent: 'flex-end',
		gap: 16,
	},
	button: {
		backgroundColor: colors.gray,
		color: colors.pureWhite,
		fontWeight: 'bold',
		paddingVertical: 10,
		paddingHorizontal: 20,
		textAlign: 'center',
		borderRadius: 6,
	},
})
