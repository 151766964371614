import React, { FC } from 'react'
import { Modal, Text, TouchableOpacity, View, Switch } from 'react-native'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import { Input } from '@components/Input'
import useScreenDimensions from '@components/Common/UseScreenDimensions'
import { CategoryFormValues, CategoryType } from '@utils/interfaces'
import { categorySchema } from '@utils/formValidation'
import { initialCategoryFormValues, screenType } from '@utils/constants'
import { theme } from '@styles/constants'
import { styles } from './styles'
import { useSelector } from 'react-redux'
import { rootReducerType } from '@reducers/combineReducers'

interface CategoryModalProps {
    isVisible: boolean
    onClose: () => void
    onSave: (values: CategoryFormValues) => void
    category?: CategoryType
}

export const CategoryModal: FC<CategoryModalProps> = ({
	isVisible,
	onClose,
	onSave,
	category,
}) => {
	const { t } = useTranslation()    
	const screenDimensions = useScreenDimensions()
	const isMobileScreen = screenDimensions.width < screenType.phone
	const { language: companyLanguage } = useSelector((state: rootReducerType) => state.companyReducer.settings)

	const initialValues = category
		? { 
			value: category.value,
			isActive: category.isActive,
		}
		: initialCategoryFormValues

	return (
		<Modal
			animationType='slide'
			transparent={true}
			visible={isVisible}
			onRequestClose={onClose}
		>
			<View style={styles.modalContainer}>
				<View style={[styles.contentContainer, isMobileScreen && styles.contentContainer_mobile]}>
					<Text style={styles.title}>{category ? t('editCategory') : t('createCategory')}</Text>
					<Formik
						initialValues={initialValues}
						validationSchema={categorySchema}
						onSubmit={onSave}
					>
						{formikProps => (
							<>
								<View style={styles.formContainer}>
									<View style={styles.field}>
										<View>
											<Text style={styles.label}>{t('fieldLabels.name')}</Text>
											<Text style={styles.nameInfo}>{t('createCategoryInfo', { language: t(companyLanguage) })}</Text>
										</View>
										<Input
											name='value'
											value={formikProps.values['value']}
											onChange={formikProps.setFieldValue}
											onBlur={() => formikProps.setFieldTouched('value', true)}
											editable={!category?.default}
										/>
										{formikProps.touched['value'] && formikProps.errors['value'] && (
											<Text style={styles.errorText}>{`*${formikProps.errors['value']}`}</Text>
										)}
									</View>
									{category && <View style={[styles.field, styles.activeField]}>
										<View style={{ flex: 8 }}>
											<Text style={styles.label}>{t('fieldLabels.active')}</Text>
											<Text style={styles.activateText}>{t('categoryActivateText')}</Text>
										</View>
										<View style={{ flex: 2, alignItems: 'flex-end' }}>
											<Switch
												value={formikProps.values['isActive']}
												onValueChange={value => { formikProps.setFieldValue('isActive', value) }}
												trackColor={{ false: theme.light.colors.moreListShadow, true: theme.light.colors.secondaryColor }}
												thumbColor={formikProps.values['isActive'] ? undefined : theme.light.colors.secondaryDark}
												activeThumbColor={theme.light.colors.buttonLg}
											/>
										</View>
										{formikProps.touched['isActive'] && formikProps.errors['isActive'] && (
											<Text style={styles.errorText}>{`*${formikProps.errors['isActive']}`}</Text>
										)}
									</View>}
								</View>
								<View style={styles.buttonsContainer}>
									<TouchableOpacity onPress={onClose}>
										<Text style={styles.button}>{t('cancel')}</Text>
									</TouchableOpacity>
									<TouchableOpacity onPress={() => formikProps.handleSubmit()}>
										<Text style={[styles.button, styles.saveButton]}>{t('save')}</Text>
									</TouchableOpacity>
								</View>
							</>
						)}
					</Formik>
				</View>
			</View>
		</Modal>
	)
}