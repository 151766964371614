import { colors, theme } from '@styles/constants'
import { button, buttonText } from '@styles/mixins/button'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
	securityContainer: {
		flexDirection: 'column',
		backgroundColor: theme.light.colors.mainContainer,
		borderRadius: 30,
		padding: 20
	},
	left: {
		flex: 1,
		padding: 10,
		justifyContent: 'space-between'
	},
	right: {
		flex: 2,
		padding: 10,
		justifyContent: 'space-between',
		gap: 50
	},
	title: {
		fontSize: 24,
		fontWeight: 'bold',
		color: theme.light.colors.titleColor,
		marginBottom: 10,
	},
	description: {
		marginBottom: 20,
		color: theme.light.colors.titleColor,
	},
	row: {
		flexDirection: 'row',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		marginBottom: 20,
	},
	detailsButton: {
		color: colors.main,
		fontWeight: 'bold'
	},
	exportLogs: {
		...({
			...button(theme.light.colors.primaryDark).button,
			...buttonText().buttonText,
		} as object),
		padding: 10,
		borderRadius: 4,
		width: 150,
		height: 40,
		textAlign: 'center',
		alignSelf: 'flex-end'
	},
	buttonText: {
		...({
			...buttonText().buttonText,
		} as object),
	},
	logs: {
		display: 'flex',
		gap: 10
	}
})