import React from 'react'
import { Statistics } from '@containers/Statistics/Statistics'
import Layout from '@containers/Layout'

export const StatisticsPage = () => {
	return (
		<Layout>
			<Statistics />
		</Layout>
	)
}