import React from 'react'
import Layout from '@containers/Layout'
import { CompanySettings } from '@containers/CompanySettings'


export const CompanySettingsPage = () => {
	return (
		<Layout>
			<CompanySettings />
		</Layout>
	)
}
