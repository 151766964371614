export const colors = {
	secondary: '#748BAA',
	danger: '#c0392b',
	lightGray: '#DCDCDC',
	dimmedRed: '#ffd6cc',
	dimmedGreen: '#e6ffe6',
	black: 'rgba(255, 255, 255)',
	darkGray: '#5A5A5A',
	gray: '#808080',
	themedGray: '#D2E1EE',
	white: '#F8F7F7',
	lightAccent: '#95968C',
	main: '#344966',
	darkAccent: '#0D1821',
	darkShades: '#344966',
	light: '#F9FAFBFF',
	error: '#ff6666',
	success: '#27ae60',
	pureWhite: '#FFFFFF',
	lightGrayIcon: 'rgb(169, 169, 169)',
	yellow: '#fdc500',
	dimmedYellow: '#ffffe6',
	modalBackdrop: 'rgba(0, 0, 0, 0.5)',
	dimmedMain: 'rgba(238, 247, 244, 0.2)',
	link: '#5779a8',
	shadeBackground: 'rgba(128, 128, 128, 0.4)',
	transparent: 'rgb(0, 0, 0, 0.5)',
	lightBlueChart: '#cdd7e5',
	secondBlueChart: '#abbcd4',
	mediumBlueChart: '#89a1c2',
	blueChart: '#4e6d97'
}

export const theme = {
	light: {
		colors: {
			appContainer: '#f3f6fd',
			mainColor: '#1f1c2e',
			secondaryColor: '#4A4A4A',
			linkColor: '#1f1c2e',
			linkColorsHover: '#c3cff4',
			linkColorActive: '#fff',
			linkColorActiveBg: '#1f1c2e',
			mainContainer: '#fff',
			boxHover: '#fafcff',
			boxBorder: '#e9ebf0',
			moreListBg: '#fff',
			moreListBgHover:  '#f6fbff',
			moreListShadow: 'rgba(209, 209, 209, 0.4)',
			buttonLg: '#1f1c24',
			star: '#1ff1c2e',
			titleColor: '#1f1c2e',
			primary: '#dbf6fd',
			primaryDarkDimmed: 'rgba(9, 108, 134, 0.5)',
			secondary: '#808080',
			success: '#c8f7dc',
			danger: '#ffd3e2',
			warning: '#fee4cb',
			info: '#d5deff',
			primaryDark: '#096c86',
			secondaryDark: '#4A4A4A',
			successDark: '#34c471',
			dangerDark: '#df3670',
			warningDark: '#ff942e',
			infoDark: '#4067f9',
		}
	}
}