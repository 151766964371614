import React, { useEffect, useState } from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import {CheckboxList} from '@components/CheckBoxList/CheckBoxList'
import { Input } from '@components/Input'
import useScreenDimensions from '@components/Common/UseScreenDimensions'
import { ScrollView } from 'react-native-gesture-handler'
import { DatePickerRange } from '@components/DatePickerRange/DatePickerRange'
import { screenType, snackbarInitialState } from '@utils/constants'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { CaseStateType, CategoryType, CompanyCase, CompanyUser, FilterItems, SnackbarInitialState, SnackbarTypes, ViewTypes } from '@utils/interfaces'
import { rootReducerType } from '@reducers/combineReducers'
import { axiosBFFServer } from '@services/connectionServer'
import { capitalizeFirstLetter, generateFileName, isCompanyUser, isPartnerOrAdvisor } from '@utils/utils'
import { styles } from './styles'
import { ExcelExport } from '@components/ExcelExport/ExcelExport'
import {Snackbar} from '@components/Snackbar/Snackbar'
import { UseErrorMessages } from '@components/Common/UseErrorMessage'

interface CheckboxItem {
	category: string;
  items: Array<{ id: string; text: string; name: string }>;
}

interface Props {
	setSearchValue: (searchValue) => void
	// eslint-disable-next-line no-empty-pattern
	onSelectedFiltersChange: ({}) => void,
	range: {
		startDate: Date,
		endDate: Date
	}
	setRange: (range) => void
	onDataChange: (data: {statuses: string[], categories: string[], assignedTo: string[]}) => void
	cases: CompanyCase[]
	getFilterItems: () => FilterItems
}

export const ListCasesFilter = ({ setSearchValue, onSelectedFiltersChange, range, setRange, onDataChange, cases, getFilterItems }: Props) => {
	const { t } = useTranslation()
	const [selectedItems, setSelectedItems] = useState({})
	const [isOpenCheckboxes, setIsOpenCheckboxes] = useState(false)
	const [showSnackbar, setShowSnackbar] = useState(false)
	const [errorMessage,setErrorMessage] = useState('')
	const [handlersForTheCase, setHandlersForTheCase ] = useState<CompanyUser[]>([])
	const [categoryByCompany, setCategoryByCompany] = useState<CategoryType[]>([])
	const [caseStates, setCaseStates] = useState<CaseStateType[]>([])
	const [ snackbar, setSnackbar ] = useState<SnackbarInitialState>(snackbarInitialState)
	
	const screenDimensions = useScreenDimensions()
	const navigate = useNavigate()
	const isSmallLaptop = screenDimensions.width < screenType.smallLaptop
	const tablet = screenDimensions.width < screenType.tablet
	const location = useLocation()
	const { getErrorMessage } = UseErrorMessages()
	const { pathname } = location

	const { roles: userRoles, language } = useSelector((state: rootReducerType) => state.userReducer) 
	const { key: companyKey, currentCompany, companies: companiesData } = useSelector((state: rootReducerType) => state.companyReducer)
	const { users } = useSelector((state:rootReducerType) => state.companyReducer.currentCompany)

	useEffect(() => {
		if(isPartnerOrAdvisor(userRoles)) {
			const allUsers = pathname.split('/')[1] === ViewTypes.COMPANY
				? currentCompany.users
				: companiesData && companiesData.length > 0 ? companiesData.map((company: {users:[]}) => company.users) : []
			const flattenArrUsers = allUsers.flat()
			const uniqueArrayOfCompUsers = [...new Set(flattenArrUsers)]

			setHandlersForTheCase(uniqueArrayOfCompUsers)
		} else {
			setHandlersForTheCase(users)
		}
	}, [])

	const getCaseStatus = () => {
		axiosBFFServer
			.post(`/caseState/search?companyKey=${companyKey}&page=1&size=100`)
			.then((response) => {
				setCaseStates(response.data.pageResults)
			})
			.catch(error => {
				const	errorMessage = getErrorMessage(error, '')

				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}

	const transformedDataStatuses: CheckboxItem = {
		category: t('statuses'),
		items: (caseStates || []).map((item: CaseStateType) => ({
			id: item.caseStateKey,
			text: item.state,
			name: item.status
		}))
	}

	const transformedDataCategories: CheckboxItem = {
		category: t('categories'),
		items: (categoryByCompany || []).map((item: CategoryType) => ({
			id: item.categoryKey,
			text: item.value,
			name: item.categoryKey 
		}))}

	const transformDataAssignedTo: CheckboxItem = {
		category: t('assignedTo'),
		items: (handlersForTheCase || []).map((users) => ({
			id: users.companyUserKey,
			text: users.firstName + ' ' + users.lastName,
			name: users.email
		}))
	}

	const filtersCheckboxesList = (pathname.split('/')[1] === ViewTypes.COMPANY)
		? [ transformedDataStatuses, transformedDataCategories, ...isCompanyUser(userRoles) ? [] : [ transformDataAssignedTo ] ]
		: [ transformDataAssignedTo ]

	const filterItemsByKeys = (selectedItems: Record<string, boolean>, keys: string[]): string[] => {
		return keys.filter(key => selectedItems[key])
	}

	const handleCheckboxChange = (itemId) => {
		setSelectedItems((prevState) => ({
			...prevState,
			[itemId]: !prevState[itemId],
		}))
		
	}
	const deselectAll = () => {
		setSelectedItems({})
	}

	const totalCount = Object.values(selectedItems).filter(Boolean).length


	const toggleCategorySelection = (categoryTitle) => {
		const updatedSelections = { ...selectedItems }

		filtersCheckboxesList.forEach((category) => {
			if (category.category === categoryTitle) {
				const allSelected = category.items.every((item) => updatedSelections[item.name])
				category.items.forEach((item) => {
					updatedSelections[item.name] = !allSelected
				})
			}
		})
		setSelectedItems(updatedSelections)
	}

	const handleSearch = (key, value) => {
		setSearchValue(value)
	} 

	const getCategories = () => {
		axiosBFFServer
			.get(`category/all/${companyKey}`)
			.then((response) => {
				setCategoryByCompany(response.data)
			})
			.catch(error => {
				const	errorMessage = getErrorMessage(error, t('caseErrors'))
				setErrorMessage(errorMessage)
				setShowSnackbar(true)
			})
	}
    
	useEffect(() => {
		if(pathname.split('/')[1] === ViewTypes.COMPANY) {
			getCaseStatus()
			getCategories()
		}
	}, [language])

	useEffect(() => {
		const statusKeys = caseStates.map((item: CaseStateType) => { return item.status })
		const categoriesKeys = categoryByCompany.map((item: CategoryType) => { return item.categoryKey }) 
		const assignedToKeys = handlersForTheCase.map((users) => { return users.email} )

		const statusData = filterItemsByKeys(selectedItems, statusKeys).map(status => capitalizeFirstLetter(status.toLowerCase()))
		const categoriesData = filterItemsByKeys(selectedItems, categoriesKeys)
		const assignedToData = filterItemsByKeys(selectedItems, assignedToKeys)

		onDataChange({
			statuses: statusData,
			categories: categoriesData,
			assignedTo: assignedToData
		})
	}, [selectedItems])
	
	useEffect(()=> {
		onSelectedFiltersChange(selectedItems)}, 
	[selectedItems])

	const filtersPlaceholder = isOpenCheckboxes
		? totalCount > 0
			? `${t('hideFilters')} (${totalCount} selected)`
			: t('hideFilters')
		: totalCount > 0
			? `${t('showFilters')} (${totalCount} selected)`
			: t('showFilters')

	return (
		<>
			<View style={styles.filtersContainer}>
				<View style={isSmallLaptop ? styles.headerContainer_laptop : tablet ? styles.headerContainer_tablet : styles.headerContainer}>
				
					<View style={styles.searchContainer}>
						<Input
							placeholder={t('search')} 
							name={'searchValue'} 
							onChange={handleSearch}/>
					</View>
					
					<TouchableOpacity style={tablet ? styles.buttonFilters_tablet : styles.buttonFilters} onPress={() => {setIsOpenCheckboxes(!isOpenCheckboxes)}}>
						<Text style={styles.buttonText}>{filtersPlaceholder}</Text>
					</TouchableOpacity>
			
					<DatePickerRange
						range={range}
						setRange={setRange} />
					<View style={styles.actionButtons}>
						<ExcelExport fileName={generateFileName('cases', 'xlsx')} disabled={cases.length === 0} getFilterItems={getFilterItems} setSnackbar={setSnackbar} />

						{(pathname.split('/')[1] === ViewTypes.COMPANY) &&
						<TouchableOpacity
							style={styles.actionButton}
							onPress={() => { navigate(`/${ViewTypes.COMPANY}/cases/case-new`, { state: { dataForCompany: currentCompany } }) }}
						>
							<Text style={styles.addCaseButton}>{t('addCase')}</Text>
						</TouchableOpacity>
						}
					</View>

				</View>
				{isOpenCheckboxes ? 
					<View style={styles.filtersCheckboxContainer}>
						<View style={styles.filtersHeader}>
							<Text style={styles.titleCheckboxContainer}>{t('filters')}</Text>
							<TouchableOpacity style={styles.buttonClear} onPress={deselectAll}>
								<Text style={styles.buttonClearText}>{t('clearAll')}</Text>
							</TouchableOpacity>
						</View>
						<ScrollView>
							<View style={tablet ? styles.checkboxContainer_tablet : isSmallLaptop ? styles.checkboxContainer_laptop : styles.checkboxContainer}>
								{filtersCheckboxesList.map((category, index) => (
									<CheckboxList
										key={index}
										categoryData={category.items}
										categoryTitle={category.category}
										selectedItems={selectedItems}
										onCheckboxChange={handleCheckboxChange}
										toggleCategorySelection={toggleCategorySelection} 
									/>
								))}
							</View>
						</ScrollView>
					</View>
					: <></>}
			</View>
			<Snackbar
				visible={snackbar.isVisible}
				onDismiss={() => setSnackbar(snackbarInitialState)}
				type={snackbar.type}
				message={snackbar.message}
			/>
		</>
	)
}
