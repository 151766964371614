import { colors, theme } from '@styles/constants'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
	container: {
		flex: 1,
		padding: 20,
	},
	headerContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: 30,
	},
	headerContainer_mobile: {
		flexDirection: 'column',
		marginBottom: 30,
		gap: 12,
	},
	headerText: {
		fontSize: 26,
		fontWeight: '500',
		color: theme.light.colors.titleColor,
	},
	button: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
		gap: 8,
		backgroundColor: theme.light.colors.primaryDark,
		borderRadius: 6,
		paddingVertical: 10,
		paddingHorizontal: 20,
	},
	buttonText: {
		color: colors.white,
		fontWeight: 'bold',
		fontSize: 16,
	},
	showButtonContainer: {
		alignItems: 'center',
		paddingVertical: 16,
	},
	noDataText: {
		padding: 20,
		textAlign: 'center',
	},
})