import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ChangeMasterPassword from '@components/ChangePassword/ChangeMasterPass'
import { rootReducerType } from '@reducers/combineReducers'
import { useSelector } from 'react-redux'
import { isCompanyUserAdmin, isPartnerOrAdvisor } from '@utils/utils'
import { Popup } from '@components/Popup'
import { useTranslation } from 'react-i18next'
import { userForgotMPSendCode } from '@services/apiService'
import { snackbarInitialState } from '@utils/constants'
import { SnackbarInitialState, SnackbarTypes } from '@utils/interfaces'
import { Loader } from '@components/Loader/Loader'

const ChangeMasterPasswordContainer: React.FC<{onClose: () => void}> = ({ onClose }) => {
	const { roles } = useSelector((state: rootReducerType) => state.userReducer)
	const user = useSelector((state: rootReducerType) => state.userReducer)
	const [isEmailSent, setIsEmailSent] = useState(false)
	const [ snackbar, setSnackbar ] = useState<SnackbarInitialState>(snackbarInitialState)
	const [accountPass, setAccountPass] = useState('')
	const [ isLoading, setIsLoading ] = useState(false)
	const navigate = useNavigate()
	const { t } = useTranslation()

	const handleChangeMasterPassword = async (values: { accountPassword: string }) => {
		// call email api
		setAccountPass(values.accountPassword)
		setIsLoading(true)
		const response = await userForgotMPSendCode(user.email)

		if (response?.status === 201) {
			setIsLoading(false)
			setIsEmailSent(true)
		}
		else {
			setSnackbar({
				isVisible: true,
				type: SnackbarTypes.ERROR,
				message: t('emailSentErrorMP')
			})
		}
		
	}

	const redirectVerificationCode = () => {
		if(isEmailSent) {
			if (isCompanyUserAdmin(roles)) {	
				navigate('/company/verify-code', {state: {accountPass: accountPass}})
			}
			else if (isPartnerOrAdvisor(roles)) {
				navigate('/partner/verify-code', {state: {accountPass: accountPass}})
			}
		}
	}
	

	return (
		<>
			{!isEmailSent && !isLoading && <ChangeMasterPassword
				onChangeMasterPasswordSubmit={handleChangeMasterPassword}
				onClose={onClose}
			/>}
			{isLoading && <Loader />}
			{isEmailSent && <Popup 
				isVisible={isEmailSent}
				onClose={redirectVerificationCode}
				onConfirm={redirectVerificationCode}
				popupTitle={t('popupVerificationCode')}
				showCloseButton={false}
				textConfirm={'Ok'} children={null}>
			</Popup>}
		</>
		
	)
}
export default ChangeMasterPasswordContainer