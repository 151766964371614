import React, { useState } from 'react'
import ChangePassword from '@components/ChangePassword/ChangePassword'
import { userChangePassword } from '@services/apiService'
import {useNavigate} from 'react-router-dom'
import {Loader} from '@components/Loader/Loader'
import {theme} from '@styles/constants'
import {styles} from '@components/ChangePassword/styles'
import {View} from 'react-native'
import {useSelector} from 'react-redux'
import {rootReducerType} from '@reducers/combineReducers'
import {isCompanyUserAdmin, isPartner} from '@utils/utils'
import {iv} from '@utils/constants'
import {decryptValueWithAES, encryptValueWithAES,generateKeyFromAccountPassword} from '@utils/encryption'
import {SnackbarInitialState, SnackbarTypes} from '@utils/interfaces'
import {useTranslation} from 'react-i18next'
import { useDispatch } from 'react-redux'
import { patchCompany } from '@actions/companyActions'
import { patchLegalPartner } from '@actions/legalPartnerKeyActions'

const ChangePasswordContainer: React.FC<{
	onClose: () => void, 
	setSnackbar: (snackbar: SnackbarInitialState) => void
}> = ({ onClose, setSnackbar }) => {
	const [isPasswordChanged, setIsPasswordChanged] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const { roles } = useSelector((state: rootReducerType) => state.userReducer)
	const masterPassword = useSelector((state: rootReducerType) => isPartner(roles) ? state.legalPartnerKeyReducer.encodedMasterPassword : state.companyReducer.currentCompany.encodedMasterPassword)
	const isAdminOrPartner = isPartner(roles) || isCompanyUserAdmin(roles)
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const handleChangePassword = async (values: { oldPassword: string;  password: string; confirmPassword: string; }) => {
		try {
			setIsLoading(true)
			const decodedMasterPassword = isAdminOrPartner ? decryptValueWithAES(generateKeyFromAccountPassword(values.oldPassword), iv, masterPassword) : ''
			const encodedMasterPassword = isAdminOrPartner ? encryptValueWithAES(generateKeyFromAccountPassword(values.password), iv, decodedMasterPassword) : ''
			await userChangePassword({ ...values, ...(encodedMasterPassword && { encodedMasterPassword }) })
			setIsPasswordChanged(true)
			setIsLoading(false)
			isPartner(roles) ? dispatch(patchLegalPartner({encodedMasterPassword: encodedMasterPassword})) : isCompanyUserAdmin(roles) ? dispatch(patchCompany({encodedMasterPassword: encodedMasterPassword})) : null
			setSnackbar({
				isVisible: true,
				type: SnackbarTypes.SUCCESS,
				message: t('changePasswordSuccess')
			})
			onClose()
		} catch (error) {
			setIsLoading(false)
			setSnackbar({
				isVisible: true,
				type: SnackbarTypes.ERROR,
				message: error.response.data.message || t('changePasswordError')
			})
			onClose()
		}
	}

	return (
		isLoading ?
			<View style={styles.changePassContainer}>
				<Loader color={theme.light.colors.primaryDark} /> 
			</View> :
			<ChangePassword
				onChangePasswordSubmit={handleChangePassword}
				isPasswordChanged={isPasswordChanged}
				onClose={onClose} />
	)
}

export default ChangePasswordContainer