import { reduxActionType } from '@utils/reduxTypes'

export const setCompanyUserKey = (payload: string): reduxActionType => {
	return {
		type: 'SET_COMPANY_USER_KEY',
		payload,
	}
}

export const setCompanyUserEmail = (payload: string): reduxActionType => {
	return {
		type: 'SET_COMPANY_USER_EMAIL',
		payload,
	}
}