import { SelectDropDown } from '@components/SelectDropDown/SelectDropdown'
import React, { FC } from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import { colors } from '@styles/constants'
import { useTranslation } from 'react-i18next'
import FontAwesome from 'react-native-vector-icons/FontAwesome'
import { usePagination } from '@components/Common/UsePagination'
import { useSelector } from 'react-redux'
import { rootReducerType } from '@reducers/combineReducers'
import { DOTS } from '@utils/constants'
import { resultsPerPage } from '@utils/constants'
import { styles } from './styles'

interface PaginationProps {
  totalRecords: number
  resultsPerPage: number
  page: number
  onPageChange: (pageNumber: number) => void
  onResultsPerPageChange: (fieldName: string, perPage: number) => void
}

const Pagination: FC<PaginationProps> = ({
	totalRecords,
	page,
	onPageChange,
	onResultsPerPageChange,
}) => {
	const { t } = useTranslation()
	const { pageSize } = useSelector((state: rootReducerType) => state.paginationReducer)
	const size = useSelector((state: rootReducerType) => state.paginationReducer.pageSize).toString()
	const paginationRange = usePagination({ totalCount: totalRecords, pageSize, siblingCount: 1, currentPage: page })

	const handlePrevPage = () => {
		if (page > 1) {
			onPageChange(page - 1)
		}
	}

	const handleNextPage = () => {
		if (paginationRange && page < (paginationRange[paginationRange?.length - 1] as number)) {
			onPageChange(page + 1)
		}
	}

	const renderPageNumbers = () => {
		return <View style={styles.pages}>
			{paginationRange?.map((pageNumber, index) => (
				<Text
					key={index}
					{...(pageNumber !== DOTS && { onPress: () => onPageChange(pageNumber as number) })}
					style={[
						styles.paginationText,
						styles.pageNumber,
						page === pageNumber && styles.currentPage,
					]}
				>
					{pageNumber === page ? (
						<Text style={styles.currentPage}>{pageNumber}</Text>
					) : (
						pageNumber
					)}
				</Text>
			))}
		</View> 
	}

	return (
		<View style={styles.paginationContainer}>
			<View style={styles.resultsPerPage}>
				<Text style={styles.resultsPerPageText}>{t('resultsPerPage')}</Text>
				<SelectDropDown data={resultsPerPage} handleFormValueChange={onResultsPerPageChange} defaultButtonText={size} fieldName={'resultsPerPage'} buttonStyle={styles.dropdown} />
			</View>	
			<View style={styles.arrows}>
				<TouchableOpacity onPress={handleNextPage}>
					<FontAwesome color={colors.pureWhite} name='caret-right' style={[styles.paginationText, styles.arrow]} />
				</TouchableOpacity>
				{renderPageNumbers()}
				<TouchableOpacity onPress={handlePrevPage}>
					<FontAwesome color={colors.pureWhite} name='caret-left' style={[styles.paginationText, styles.arrow]} />
				</TouchableOpacity>
			</View>
		</View>
	)
}

export default Pagination