import { patchUserInfo } from '@actions/userActions'
import {rootReducerType} from '@reducers/combineReducers'
import { updateAdvisor, updateCompanyUser } from '@services/apiService'
import {personalInfoSchema} from '@utils/formValidation'
import {isPartner, isAdvisor, checkIfPermissionExist} from '@utils/utils'
import {Formik} from 'formik'
import React, {useState} from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text, Image, TouchableOpacity } from 'react-native'
import { Input } from '@components/Input'
import FontAwesome from 'react-native-vector-icons/FontAwesome'
import {useDispatch} from 'react-redux'
import {useSelector} from 'react-redux'
import { styles } from './style'
import {SnackbarInitialState, SnackbarTypes} from '@utils/interfaces'

interface PersonalInfoProps {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  title: string;
  profilePicture?: string;
  onProfilePictureChange?: () => void;
  isCompany?: boolean | undefined;
  setSnackbar: (snackbar: SnackbarInitialState) => void
}

export const PersonalInfo: React.FC<PersonalInfoProps> = (props) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [isDirty, setIsDirty] = useState(false)
	const user = useSelector((state: rootReducerType) => state.userReducer)
	const { key } = useSelector((state: rootReducerType) => state.setAdvisorKeyReducer ?? {})
	const userPermissions = useSelector((state: rootReducerType) => state.userReducer.permissions)
	const editUser = checkIfPermissionExist(userPermissions,'userSettings','update')
	const companyUserKey = useSelector((state: rootReducerType) => state.companyUserKeyReducer.key)

	const isUserPartner = !props.isCompany && isPartner(user.roles)
	const isUserAdvisor = !props.isCompany && isAdvisor(user.roles)

	let companyKey

	if (props.isCompany) {
		const companyInfo = useSelector((state: rootReducerType) => state.companyReducer.currentCompany ?? {})
		companyKey = companyInfo.companyKey
	}

	const submitChanges = async (values) => {
		const changedValues = Object.entries(values).reduce((result, [key, value]) => {
			if (value !== props[key]) {
				result[key] = value
			}
			return result
		}, {})

		if (props.isCompany) {
			const newUser = await updateCompanyUser(changedValues, companyUserKey, companyKey)
			if(newUser?.status === 200) {
				props.setSnackbar({
					isVisible: true,
					type: SnackbarTypes.SUCCESS,
					message: t('success.editCompanyUser'),
				})
				dispatch(patchUserInfo({
					title: newUser?.data.title,
					firstName: newUser?.data.firstName,
					lastName: newUser?.data.lastName,
					email: newUser?.data.email,
					phone: newUser?.data.phoneNumber
				}))
			}
			else {
				props.setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: t('errors.editCompanyUser'),
				})
			}

		}

		if (isUserAdvisor || isUserPartner) {
			const newUser = await updateAdvisor(changedValues, key)
			if(newUser?.status === 200) {
				props.setSnackbar({
					isVisible: true,
					type: SnackbarTypes.SUCCESS,
					message: t('success.editCompanyUser'),
				})
				dispatch(patchUserInfo({
					title: newUser?.data.title,
					firstName: newUser?.data.firstName,
					lastName: newUser?.data.lastName,
					email: newUser?.data.email,
					phone: newUser?.data.phoneNumber
				}))
			}
			else {
				props.setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: t('errors.editCompanyUser'),
				})
			}
		}
		setIsDirty(false)
	}

	return (
		<View>
			<Formik
				initialValues={{
					title: props.title,
					firstName: props.firstName,
					lastName: props.lastName,
					email: props.email,
					phoneNumber: props.phoneNumber
				}}
				validationSchema={personalInfoSchema}
				onSubmit={(values) => submitChanges(values)}
			>
				{({ handleChange, handleBlur, handleSubmit, values, touched, errors }) => (
					<View style={styles.formContainer}>
						<View>
							<Text style={styles.label}>{t('title')}</Text>
							<Input
								name='title'
								value={values.title} 
								onChange={(name, value) => {
									handleChange('title')(value as string);
									(!isDirty && values.title !== value) && setIsDirty(true)
								}}
								onBlur={() => handleBlur('title')}
								editable={editUser}
							/>
						</View>
						<View>
							<Text style={styles.label}>{t('firstName')}</Text>
							<Input
								name='firstName'
								value={values.firstName} 
								onChange={(name, value) => {
									handleChange('firstName')(value as string);
									(!isDirty && values.firstName !== value) && setIsDirty(true)
								}}
								onBlur={() => handleBlur('firstName')}
								editable={editUser}
							/>
						</View>
						<View>
							<Text style={styles.label}>{t('lastName')}</Text>
							<Input
								name='lastName'
								value={values.lastName}
								onChange={(name, value) => {
									handleChange('lastName')(value as string);
									(!isDirty && values.lastName !== value) && setIsDirty(true)
								}}
								onBlur={() => handleBlur('lastName')} 
								editable={editUser}
							/>
						</View>
						<View>
							<Text style={styles.label}>{t('email')}</Text>
							<Input
								name='email'
								value={values.email} 
								onChange={(name, value) => {
									handleChange('email')(value as string);
									(!isDirty && values.email !== value) && setIsDirty(true)
								}}
								onBlur={() => handleBlur('email')}
								editable={editUser}
							/>
						</View>
						<View>
							<Text style={styles.label}>{t('phone')}</Text>
							<Input
								name='phoneNumber'
								value={values.phoneNumber} 
								onChange={(name, value) => {
									handleChange('phoneNumber')(value as string);
									(!isDirty && values.phoneNumber !== value) && setIsDirty(true)
								}}
								onBlur={() => handleBlur('phoneNumber')}
								editable={editUser}
							/>
						</View>
						<Text style={styles.validationMessage}>{(touched.email || touched.firstName || touched.lastName || touched.phoneNumber) && (errors.firstName || errors.lastName || errors.email || errors.phoneNumber)}</Text>
						{
							editUser &&
							<TouchableOpacity
								style={[styles.button, (!isDirty || Boolean(errors.firstName || errors.lastName || errors.email || errors.phoneNumber)) && styles.disabled]}
								onPress={() => handleSubmit()}
								disabled={!isDirty || Boolean(errors.firstName || errors.lastName || errors.email || errors.phoneNumber)}
							>
								<FontAwesome name="user" color={'white'} size={15} />
								<Text style={styles.buttonText}>{t('saveChanges')}</Text>
							</TouchableOpacity>
						}
						
					</View>
				)}
			</Formik>
		</View>
	)
}