import React, { useEffect, useState } from 'react'
import { Input } from '@components/Input'
import {  Text, TouchableOpacity, View } from 'react-native'
import { colors } from '@styles/constants'
import { useTranslation } from 'react-i18next'
import Icon from 'react-native-vector-icons/FontAwesome'
import { axiosBFFServer } from '@services/connectionServer'
import { Notes, SnackbarInitialState, SnackbarTypes } from '@utils/interfaces'
import { snackbarInitialState } from '@utils/constants'
import { Snackbar } from '@components/Snackbar/Snackbar'
import { styles } from './styles'
import { UseErrorMessages } from '@components/Common/UseErrorMessage'

interface INotes {
	caseKey: string
} 

export const NotesBox = ({caseKey}: INotes) => {

	const { t } = useTranslation()
	const { getErrorMessage } = UseErrorMessages()
	
	const [ snackbar, setSnackbar ] = useState<SnackbarInitialState>(snackbarInitialState)
    
	const [ notes, setNotes ] = useState('')
	const [ responseNotes, setResponseNotes ] = useState()
	const [ notesAdded, setNotesAdded ] = useState<Notes[]>([])
	const [ noteKey, setNoteKey ] = useState('')
	const [isEditing, setIsEditing] = useState(false)
	const [ visibleButtons, setVisibleButtons ] = useState(false)
	const [ editedNote, setEditedNote ] = useState('')
	const[editIndex, setEditIndex] = useState<Notes>()
	const [updatedNote, setUpdateNote] = useState('')

	const handleNotes = (value) => {
		setNotes(value)
	}

	const handleSubmitNotes = () => {
		const reqBody = {
			text: notes
		}
		axiosBFFServer 
			.post(`/case/note/${caseKey}`, reqBody )
			.then((response) => {
				setResponseNotes(response.data)
				setNotesAdded([...notesAdded, response.data])
				setNotes('')
			})
			.catch(error => {
				const errorMessage = getErrorMessage(error, t('noteSubmitError'))
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}

	const getNotes = () => {
		axiosBFFServer
			.get(`/case/note/${caseKey}`)
			.then((resp) =>{ 
				setNotesAdded(resp.data)
				setUpdateNote('')
				setNotes('')
			})
			.catch(error => {
				const errorMessage = getErrorMessage(error, t('noteGetError'))
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
				
			})
	}

	const handleClickOnNote = () => {
		setVisibleButtons(true)
	}

	const handleEditClick = (index) => {
		setIsEditing(true)
		setEditIndex(index)
	}

	const handleChangeNote = (name, value) => {
		setEditedNote(value)
	}

	const handleUpdateNotes = (index) => {
	
		axiosBFFServer 
			.patch(`/case/note/${caseKey}/${noteKey}`, {'text': editedNote} )
			.then((response) => {
				if(response.status === 200)  {
					setSnackbar({
						isVisible: true,
						type: SnackbarTypes.SUCCESS,
						message: t('editNoteSccess')
					})
		
					setUpdateNote( response.data)
					setIsEditing(false)
					setEditedNote('')
				}
			})
			.catch(error => {
				const errorMessage = getErrorMessage(error, t('noteUpdateError'))
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}

	const handleDeleteNote = () => {
		axiosBFFServer 
			.delete(`/case/note/${caseKey}/${noteKey}` )
			.then((response) => {
				response.status === 200 && 
			setSnackbar({
				isVisible: true,
				type: SnackbarTypes.SUCCESS,
				message: t('deleteNoteSuccess')
			})
				const filteredNotes = notesAdded.filter(item => item.noteKey !== noteKey)
				setNotesAdded(filteredNotes)
			})
			.catch(error => {
				const errorMessage = getErrorMessage(error, t('noteDeleteError'))
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}

	useEffect(() => {
		getNotes()
	}, [editedNote])

	return (
		<View style={styles.modalContainer}>
			<View style={styles.contentContainer}>
				<View style={styles.headerContainer}>
					<Text style={styles.headerModalText}>{t('addNote')}</Text>
				</View>
				<View style={styles.inputContainer}>
					<Input
						placeholder={t('notes')}
						multiline={true}
						value={notes}
						name={'notes'}
						onChange={(name, value) => handleNotes(value)}
					/>
				</View>				
				<View style={styles.footer}>
					<TouchableOpacity onPress={handleSubmitNotes} style={styles.button}>
						<Text style={styles.buttonText}>{t('save')}</Text>
					</TouchableOpacity>
				</View> 

				{notesAdded && notesAdded.map((note: Notes, index) => {
					return (
						<View  key={note.noteKey} >
							<TouchableOpacity 
								onPress={() => {handleClickOnNote()
									setEditedNote(note.text)
									setNoteKey(note.noteKey)}
								}
							>
								<View style={styles.noteContainer}>
									{isEditing && note.noteKey === noteKey ?  
										<Input
											placeholder={t('notes')}
											name={editedNote}
											multiline={true}
											value={editedNote}
											onChange={handleChangeNote} />
										: 
										<View style={styles.notesContainer}>
											<Text>{note.text}</Text>
											<Text>{`Note added by: ${note.authorName}` }</Text>
											<Text>{`On date ${note.createdAt}`}</Text>
											
										</View>
									}
								</View> 
								{visibleButtons && !isEditing && note.noteKey === noteKey &&
									<View style={styles.editUpdateBttns}>
										<TouchableOpacity
											onPress={handleDeleteNote}
										>
											<Icon name='trash-o' color={colors.main} size={20} />
										</TouchableOpacity>
										<TouchableOpacity
											onPress={(index) => {
												handleEditClick(index)
											}}
										>
											<Icon name='edit' color={colors.main} size={21} style={{marginLeft: 15}}/>	
										</TouchableOpacity>	
									</View>
								}
								{isEditing && note.noteKey === noteKey && 
									<View style= {styles.buttonsEdit}>
										<View style={styles.buttonEdit}>
											<TouchableOpacity onPress={() => {setIsEditing(false)}}> <Icon name="close" size={18} color={colors.white} /> </TouchableOpacity> 
										</View>
										<View style={styles.buttonEdit}>
											<TouchableOpacity onPress={handleUpdateNotes}> <Icon name="check" size={18} color={colors.white} /> </TouchableOpacity> 
										</View>
									</View>
								}
							</TouchableOpacity>
						</View>
					)
				})}	
						
				<Snackbar
					visible={snackbar.isVisible}
					onDismiss={() => setSnackbar(snackbarInitialState)}
					type={snackbar.type}
					message={snackbar.message}
				/>
				
			</View>
		</View>
	)
}

