import React, { FC, useEffect, useState } from 'react'
import { View, Modal, Text, TouchableOpacity } from 'react-native'
import { useTranslation } from 'react-i18next'
import { CreateCompanyValues, EditCompanyValues, FormValues, SnackbarTypes, initialCompanyFormValues } from '@utils/interfaces'
import { countries } from '@utils/constants'
import useScreenDimensions from '@components/Common/UseScreenDimensions'
import { Input } from '@components/Input'
import { SelectDropDown } from '@components/SelectDropDown/SelectDropdown'
import { phonePrefixes, screenType, languages } from '@utils/constants'
import { axiosBFFServer } from '@services/connectionServer'
import { PhoneInput } from '@components/PhoneInput/PhoneInput'
import { Formik } from 'formik'
import { createCompanySchema, registerCompanySchema, editCompanySchema } from '@utils/formValidation'
import { MultiSelectDropdown } from '@components/MultiSelect'
import { rootReducerType } from '@reducers/combineReducers'
import { useSelector } from 'react-redux'
import { Loader } from '@components/Loader/Loader'
import { Snackbar } from '@components/Snackbar/Snackbar'
import { styles } from './styles'
import { GeneralModal } from '@components/Modal/Modal'
import { InputCUI } from '@components/InputCUI/InputCUI'
import Checkbox from 'expo-checkbox'
import { UseErrorMessages } from '@components/Common/UseErrorMessage'

interface CompanyModalProps {
  visible: boolean;
  editCompany: boolean;
  onClose: () => void;
}

const CompanyModal: FC<CompanyModalProps> = ({ visible, onClose, editCompany }) => {
	const { t } = useTranslation()
	const [showSnackbar, setShowSnackbar] = useState(false)
	const [isCUIValid, setIsCUIValid] = useState(false)
	const [advisors,setAdvisors] = useState([])
	const [ isLoading, setIsLoading ] = useState(editCompany ? true : false)
	const [errorMessage,setErrorMessage] = useState('')
	const companyKey = useSelector((state: rootReducerType) => state.companyReducer.key)
	const legalPartnerKey = useSelector((state: rootReducerType) => state.legalPartnerKeyReducer.key)
	const advisorKey = useSelector((state: rootReducerType) => state.setAdvisorKeyReducer.key)

	const [companySizes, setCompanySizes] = useState([])
	const [editCompanyValues,setEditCompanyValues] = useState<EditCompanyValues>({
		name: '',
		country: '',
		nrOfEmployees: '',
		advisorsKeys: [],
		notes: '',
		phonePrefix: phonePrefixes[0],
		phoneNumber: '',
		street: '',
		city: '',
		zipCode: '',
		vatNr: '',
	})
	const [ isVisible, setIsVisible ] = useState(false)

	const screenDimensions = useScreenDimensions()
	const { getErrorMessage } = UseErrorMessages()
	const isMobile = screenDimensions.width < screenType.phone

	const onSubmit = (values: FormValues) => {
		setIsLoading(true)
		const {
			firstName,
			lastName,
			email,
			phonePrefix: prefix,
			phoneNumber: number,
			name,
			language,
			nrOfEmployees,
			advisors,
			street,
			city,
			zipCode,
			vatNr,
			isStateOwned,
		} = values as CreateCompanyValues

		const companyData = {
			firstName,
			lastName,
			email,
			name,
			country: countries[0],
			phoneNumber: prefix + number,
			legalPartnerKey: legalPartnerKey,
			nrOfEmployees,
			defaultLanguage: language,
			advisorsKeys: advisors,
			city,
			zipCode,
			streetAddress: street,
			vatNumber: vatNr,
			isStateOwned,
		}

		axiosBFFServer
			.post('/company', companyData)
			.then(response => {
				response.status === 201 && onClose()
			})
			.catch(error => {
				const errorMessage = getErrorMessage(error, t('createCompanyError'))
			
				if (error.response.status === 409) {
					setIsLoading(false)
					setIsVisible(true)
					return
				}
                
				setErrorMessage(errorMessage)
				setShowSnackbar(true)
				setIsLoading(false)
			})
	}

	const onSubmitEdit = (values: FormValues) => {
		setIsLoading(true)
		const {
			phonePrefix: prefix,
			phoneNumber: number,
			street,
			vatNr,
			...company
		} = values as EditCompanyValues

		const reqBody = {
			phoneNumber: prefix + number,
			streetAddress: street,
			vatNumber: vatNr,
			...company,
		}

		axiosBFFServer
			.patch(`/company/${companyKey}`, reqBody)
			.then(response => {
				response.status === 200 && onClose()
			})
			.catch(error => {
				const errorMessage = getErrorMessage(error, t('editCompanyError'))				
				setErrorMessage(errorMessage)
				setShowSnackbar(true)
				setIsLoading(false)
			})
	}

	const getAdvisor = () => {
		axiosBFFServer
			.get(`/advisor/list/${legalPartnerKey}`)
			.then(response => {
				setAdvisors(response.data)
			})
			.catch(error => {
				const errorMessage = getErrorMessage(error, t('advisorsListError'))
				setErrorMessage(errorMessage)
				setShowSnackbar(true)
			})
	}

	const getCompany = () => {
		axiosBFFServer
			.get(`/company/${companyKey}`)
			.then(response => {
				setIsLoading(false)
				setEditCompanyValues({
					advisorsKeys: response.data.advisors.map((advisor) => advisor.advisorKey),
					name: response.data.name,
					country: response.data.country,
					nrOfEmployees: response.data.nrOfEmployees,
					notes: response.data.notes,
					phonePrefix: response.data.phoneNumber.slice(0, 3),
					phoneNumber: response.data.phoneNumber.slice(3),
					street: response.data.streetAddress,
					city: response.data.city,
					zipCode: response.data.zipCode,
					vatNr: response.data.vatNumber,
				})
			})
			.catch(error => {
				const errorMessage = getErrorMessage(error, t('getCompanyError'))
				setErrorMessage(errorMessage)
				setShowSnackbar(true)
			})
	}

	const getSizes = () => {
		axiosBFFServer
			.get('/company/sizes')
			.then((response) => {
				setCompanySizes(response.data)
			})
			.catch((error) => {
				const errorMessage = getErrorMessage(error, t('getCompanySizesError'))
				setErrorMessage(errorMessage)
				setShowSnackbar(true)
			})
	}
    
	const redirectWebsite = () => {
		global.location.replace(process.env.WEBSITE_URL)
	}

	useEffect(() => {
		getSizes()
		editCompany && getCompany()
		legalPartnerKey && getAdvisor()
	},[])
  
	return (
		<Modal
			animationType="slide"
			transparent={true}
			visible={visible}
			onRequestClose={onClose}
		>
			<View style={styles.modalContainer}>
				{
					editCompany
						? <Formik
							enableReinitialize={true} 
							initialValues={editCompanyValues} 
							validationSchema={editCompanySchema} 
							onSubmit={onSubmitEdit}>
							{(formikProps) => (
								<>
									{isLoading && <View style={{ position: 'absolute' }}>
										<Loader/> 
									</View>
									}
									<View style={[
										styles.editCompanyContent,
										{...(isLoading && {
											opacity: 0,
										})}
									]}>
										<View style={styles.row}>
											<View style={styles.inputField}>
												<Text style={styles.label}>{t('fieldLabels.companyName')}</Text>
												<Input
													placeholder={t('companyName')}
													name='name'
													value={formikProps.values['name']}
													onChange={formikProps.setFieldValue}
													onBlur={() => formikProps.setFieldTouched('name', true)}
												/>
												{formikProps.touched['name'] && formikProps.errors['name'] && (
													<Text style={styles.errorText}>{`*${formikProps.errors['name']}`}</Text>
												)}
											</View>
											<View style={styles.inputField}>
												<Text style={styles.label}>{t('fieldLabels.vatNr')}</Text>
												<Input
													placeholder={t('fieldLabels.vatNr')}
													name='vatNr'
													value={formikProps.values['vatNr']}
													onChange={formikProps.setFieldValue}
													onBlur={() => formikProps.setFieldTouched('vatNr', true)}
												/>
												{formikProps.touched['vatNr'] && formikProps.errors['vatNr'] && (
													<Text style={styles.errorText}>{`*${formikProps.errors['vatNr']}`}</Text>
												)}
											</View>
										</View>
										<View style={styles.row}>
											<View style={styles.inputField}>
												<Text style={styles.label}>{t('fieldLabels.city')}</Text>
												<Input
													placeholder={t('fieldLabels.city')}
													name='city'
													value={formikProps.values['city']}
													onChange={formikProps.setFieldValue}
													onBlur={() => formikProps.setFieldTouched('city', true)}
												/>
												{formikProps.touched['city'] && formikProps.errors['city'] && (
													<Text style={styles.errorText}>{`*${formikProps.errors['city']}`}</Text>
												)}
											</View>
										</View>
										<View style={styles.row}>
											<View style={styles.inputField}>
												<Text style={styles.label}>{t('fieldLabels.street')}</Text>
												<Input
													placeholder={t('street')}
													name='street'
													value={formikProps.values['street']}
													onChange={formikProps.setFieldValue}
													onBlur={() => formikProps.setFieldTouched('street', true)}
												/>
												{formikProps.touched['street'] && formikProps.errors['street'] && (
													<Text style={styles.errorText}>{`*${formikProps.errors['street']}`}</Text>
												)}
											</View>
											<View style={styles.inputField}>
												<Text style={styles.label}>{t('fieldLabels.zipCode')}</Text>
												<Input
													placeholder={t('fieldLabels.zipCode')}
													name='zipCode'
													value={formikProps.values['zipCode']}
													onChange={formikProps.setFieldValue}
													onBlur={() => formikProps.setFieldTouched('zipCode', true)}
												/>
												{formikProps.touched['zipCode'] && formikProps.errors['zipCode'] && (
													<Text style={styles.errorText}>{`*${formikProps.errors['zipCode']}`}</Text>
												)}
											</View>
										</View>
										<View style={styles.row}>
											<View style={styles.inputField}>
												<Text style={styles.label}>{t('fieldLabels.phone')}</Text>
												<PhoneInput
													name={{
														prefix: 'phonePrefix',
														number: 'phoneNumber',
													}}
													phone={{
														prefix: formikProps.values['phonePrefix'],
														number: formikProps.values['phoneNumber']
													}}
													onChange={formikProps.setFieldValue}
													onBlur={formikProps.setFieldTouched}
												/>
												{formikProps.touched['phonePrefix'] && formikProps.errors['phonePrefix'] && (
													<Text style={styles.errorText}>{`*${formikProps.errors['phonePrefix']}`}</Text>
												)}
												{formikProps.touched['phoneNumber'] && formikProps.errors['phoneNumber'] && (
													<Text style={styles.errorText}>{`*${formikProps.errors['phoneNumber']}`}</Text>
												)}
											</View>
											<View style={styles.inputField}>
												<Text style={styles.label}>{t('fieldLabels.numberOfEmployees')}</Text>
												<SelectDropDown 
													data={companySizes}
													defaultButtonText={t('fieldLabels.numberOfEmployees')}
													fieldName={'nrOfEmployees'}
													value={formikProps.values['nrOfEmployees']}
													handleFormValueChange={formikProps.setFieldValue}
													onBlur={() => formikProps.setFieldTouched('nrOfEmployees', true)}
												/>
												{formikProps.touched['nrOfEmployees'] && formikProps.errors['nrOfEmployees'] && (
													<Text style={styles.errorText}>{`*${formikProps.errors['nrOfEmployees']}`}</Text>
												)}
											</View>
										</View>
										<View style={styles.row}>
											<View style={styles.singleInput}>
												<Text style={styles.label}>{t('fieldLabels.advisors')}</Text>
												<MultiSelectDropdown
													items={advisors}
													selectedItems={formikProps.values['advisorsKeys']}
													onSelectedItemsChange={(selected) => {
														formikProps.setFieldValue('advisorsKeys',selected)
														formikProps.setFieldTouched('advisorsKeys', true)
													}}
													placeholder={t('searchAdvisor')}
													displayKey='email'
													uniqueKey="advisorKey"
													searchText={t('searchAdvisor')}
												/>
												{formikProps.touched['advisorsKeys'] && formikProps.errors['advisorsKeys'] && (
													<Text style={styles.errorText}>{`*${formikProps.errors['advisorsKeys']}`}</Text>
												)}
											</View>	
										</View>
										<View style={styles.inputFieldMultiline}>
											<Text style={styles.label}>{t('notes')}</Text>
											<Input
												placeholder={t('notes')}
												name='notes'
												multiline={true}
												value={formikProps.values['notes']}
												onChange={formikProps.setFieldValue}
												onBlur={() => formikProps.setFieldTouched('notes', true)} />
										</View>
										<View style={styles.buttonsContainer}>
											<TouchableOpacity onPress={onClose} style={styles.cancelButton}>
												<Text style={styles.cancelButtonText}>{t('cancel')}</Text>
											</TouchableOpacity>
											<TouchableOpacity onPress={() => formikProps.handleSubmit()} style={styles.createButton}>
												<Text style={styles.buttonText}>{t('save')}</Text>
											</TouchableOpacity>
										</View>
									</View>
									<Snackbar
										visible={showSnackbar}
										onDismiss={() => setShowSnackbar(false)}
										type={SnackbarTypes.ERROR}
										message={errorMessage}
									/>
								</>
							)}
						</Formik>
						: <Formik
							initialValues={{ ...initialCompanyFormValues, advisors: [ advisorKey ]}}
							validationSchema={registerCompanySchema.concat(createCompanySchema)}
							onSubmit={onSubmit}
						>
							{(formikProps) => (
								<>
									{isLoading && <View style={{ position: 'absolute' }}>
										<Loader/> 
									</View>
									}
									<View style={[
										isMobile ? styles.modalContentMobile : styles.modalContent,
										{...(isLoading && {
											opacity: 0,
										})}
									]}>
										<View style={styles.row}>
											<View style={styles.inputField}>
												<Text style={styles.label}>{t('fieldLabels.firstNameAdministrator')}</Text>
												<Input
													editable
													placeholder={t('fieldLabels.firstNameAdministrator')}
													name='firstName'
													onChange={formikProps.setFieldValue}
													onBlur={() => formikProps.setFieldTouched('firstName', true)}
												/>
												{formikProps.touched['firstName'] && formikProps.errors['firstName'] && (
													<Text style={styles.errorText}>{`*${formikProps.errors['firstName']}`}</Text>
												)}
											</View>
											<View style={styles.inputField}>
												<Text style={styles.label}>{t('fieldLabels.lastNameAdministrator')}</Text>
												<Input
													editable
													placeholder={t('fieldLabels.lastNameAdministrator')}
													name='lastName'
													onChange={formikProps.setFieldValue}
													onBlur={() => formikProps.setFieldTouched('lastName', true)}
												/>
												{formikProps.touched['lastName'] && formikProps.errors['lastName'] && (
													<Text style={styles.errorText}>{`*${formikProps.errors['lastName']}`}</Text>
												)}
											</View>
										</View>
										<View style={styles.row}>
											<View style={styles.inputField}>
												<Text style={styles.label}>{t('fieldLabels.email')}</Text>
												<Input
													editable
													placeholder={t('fieldLabels.email')}
													name='email'
													onChange={formikProps.setFieldValue}
													onBlur={() => formikProps.setFieldTouched('email', true)}
												/>
												{formikProps.touched['email'] && formikProps.errors['email'] && (
													<Text style={styles.errorText}>{`*${formikProps.errors['email']}`}</Text>
												)}
											</View>
											<View style={styles.inputField}>
												<Text style={styles.label}>{t('fieldLabels.phone')}</Text>
												<PhoneInput
													name={{
														prefix: 'phonePrefix',
														number: 'phoneNumber',
													}}
													phone={{
														prefix: formikProps.values['phonePrefix'],
														number: formikProps.values['phoneNumber']
													}}
													onChange={formikProps.setFieldValue}
													onBlur={formikProps.setFieldTouched}
												/>
												{formikProps.touched['phonePrefix'] && formikProps.errors['phonePrefix'] && (
													<Text style={styles.errorText}>{`*${formikProps.errors['phonePrefix']}`}</Text>
												)}
												{formikProps.touched['phoneNumber'] && formikProps.errors['phoneNumber'] && (
													<Text style={styles.errorText}>{`*${formikProps.errors['phoneNumber']}`}</Text>
												)}
											</View>
										</View>
										<View style={styles.gridInputs}>
											<InputCUI
												companySizes={companySizes}
												isLegalPartnerIntern
												formikProps={formikProps}
												setIsCUIValid={setIsCUIValid}
											/>
										</View>
										<View style={styles.row}>
											<View style={styles.inputField}>
												<Text style={styles.label}>{t('fieldLabels.language')}</Text>
												<SelectDropDown 
													data={languages}
													defaultButtonText={t('fieldLabels.language')}
													fieldName={'language'}
													handleFormValueChange={formikProps.setFieldValue}
													onBlur={() => formikProps.setFieldTouched('language', true)}
													displayTranslatedValue={true}
												/>
												{formikProps.touched['language'] && formikProps.errors['language'] && (
													<Text style={styles.errorText}>{`*${formikProps.errors['language']}`}</Text>
												)}
											</View>
										</View>
										<View style={styles.row}>
											<View style={styles.singleInput}>
												<Text style={styles.label}>{t('fieldLabels.advisors')}</Text>
												<MultiSelectDropdown
													items={advisors}
													selectedItems={formikProps.values['advisors']}
													onSelectedItemsChange={(selected) => {
														formikProps.setFieldValue('advisors',selected)
														formikProps.setFieldTouched('advisors', true)
													}}
													placeholder={t('searchAdvisor')}
													displayKey='email'
													uniqueKey="advisorKey"
													searchText={t('searchAdvisor')}
												/>
												{formikProps.touched['advisors'] && formikProps.errors['advisors'] && (
													<Text style={styles.errorText}>{`*${formikProps.errors['advisors']}`}</Text>
												)}
											</View>
										</View>
										<View style={styles.row}>
											<View style={[styles.singleInput, styles.checkboxField]}>
												<Checkbox
													value={formikProps.values['isStateOwned']}
													onValueChange={(value) => formikProps.setFieldValue('isStateOwned',value)}
												/>
												<Text style={styles.checkboxLabel}>{t('fieldLabels.isStateOwned')}</Text>
											</View>
										</View>
										<View style={styles.buttonsContainer}>
											<TouchableOpacity onPress={onClose} style={styles.cancelButton}>
												<Text style={styles.cancelButtonText}>{t('cancel')}</Text>
											</TouchableOpacity>
											<TouchableOpacity disabled={!isCUIValid} onPress={() => formikProps.handleSubmit()} style={[styles.createButton, !isCUIValid && { opacity: 0.5 }]}>
												<Text style={styles.buttonText}>{t('create')}</Text>
											</TouchableOpacity>
										</View>
										{ !isCUIValid &&
											<Text style={styles.text}>{t('cuiInvalidated')}</Text>
										}
									</View>
									<Snackbar
										visible={showSnackbar}
										onDismiss={() => setShowSnackbar(false)}
										type={SnackbarTypes.ERROR}
										message={errorMessage}
									/>
								</>
							)}
						</Formik>
				}
			</View>

			{isVisible && <GeneralModal
				visible={isVisible}
				onClose={() => setIsVisible(false)}
				onSubmit={redirectWebsite}
				messageText={t('accountAlreadyExists')}
				closeButtonText={t('close')}
				submitButtonText={t('goToWebsite')}
			/>}
		</Modal>
	)
}
  
export default CompanyModal

