import { StyleSheet } from 'react-native'
import { colors, theme } from '@styles/constants'
import { button } from '@styles/mixins/button'

export const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		gap: 16
	},
	cardContainer: {
		flexDirection: 'column',
		borderWidth: 1,
		borderColor: theme.light.colors.boxBorder,
		paddingHorizontal: 12,
		paddingVertical: 4,
		gap: 12,
		overflow: 'hidden'
	},
	cardContainerAdvisor: {
		flexDirection: 'column',
		borderBottomWidth: 1,
		borderColor: theme.light.colors.boxBorder,
		padding: 20,
		gap: 16,
		minHeight: 130,
	},
	cardContainerAdvisorMobile: {
		borderWidth: 1,
		borderColor: theme.light.colors.boxBorder,
		padding: 20,
		gap: 16,
		minHeight: 130
	},
	profileAvatar: {
		width: 60,
		height: 60,
		backgroundColor: theme.light.colors.primaryDark,
		borderRadius: 50,
		justifyContent: 'center'
	},
	profileAvatar_mobile: {
		width: 30,
		height: 30,
		backgroundColor: theme.light.colors.primaryDark,
		borderRadius: 50,
		justifyContent: 'center'
	},
	avatarText: {
		fontWeight: 'bold',
		fontSize: 22,
		color: colors.white,
		textAlign: 'center'
	},
	avatarText_mobile: {
		fontWeight: 'bold',
		fontSize: 16,
		color: colors.white,
		textAlign: 'center',
	},
	textContainer: {
		flexDirection: 'column',
		gap: 8,
		justifyContent: 'center',
	},
	textContainer_mobil: {
		overflow: 'hidden',
		width: '95%'
	},
	mainText: {
		fontWeight: '600',
		fontSize: 16
	},
	text: {
		marginLeft: 12
	},
	textIconContainer: {
		flexDirection: 'row'
	},
	infoContainer:{
		width: 200,
		gap: 6
	},
	contentContainer: {
		width: '95%',
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	contentContainerMobile: {
		width: '95%',
		gap: 12
	},
	footer:{
		width: '100%',
		alignItems: 'flex-end'
	},
	button: {
		...({
			...button(theme.light.colors.successDark).button,
		} as object),
		borderRadius: 6,
		paddingVertical: 10,
		paddingHorizontal: 20,
		flexDirection: 'row',
		gap: 8,
		justifyContent: 'center',
		width: 160
	},
	buttonText: {
		color: 'white',
		fontWeight: 'bold' 
	},
	lastChild: {
		borderBottomLeftRadius: 30,
		borderBottomRightRadius: 30, 
		borderBottomWidth: 0
	},
	firstChild: {
		borderTopLeftRadius: 30,
		borderTopRightRadius: 30, 
		borderTopWidth: 0
	}
})