import { StyleSheet } from 'react-native'
import { colors, theme } from '@styles/constants'

export const styles = StyleSheet.create({
	contentContainer: {
		padding: 16,
		borderRadius: 30,
		gap: 10,
		backgroundColor: theme.light.colors.mainContainer,
		shadowColor: theme.light.colors.mainColor,
		shadowOffset: { width: 0, height: 1 },
		shadowOpacity: 0.2,
		shadowRadius: 2,
		elevation: 3,
	},
	headerContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: 10,
	},
	headerModalText: {
		fontSize: 20,
		fontWeight: 'bold',
		color: colors.black,
	},
	assignedUsersContainerTitle: {
		fontSize: 18,
		marginVetical: 10,
		fontWeight: '500',
	},
})
