import { colors, theme } from '@styles/constants'
import { button } from '@styles/mixins/button'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: 'center',
		padding: 20,
		gap: 20,
		backgroundColor: theme.light.colors.appContainer,
	},
	languageContainer: {
		alignSelf: 'flex-end',
		width: 160,
	},
	languageContainer_mobile: {
		width: '100%',
	},
	content: {
		backgroundColor: theme.light.colors.mainContainer,
		minHeight: '80%',
		padding: 20,
		borderRadius: 30,
		gap: 20,
		shadowColor: theme.light.colors.mainColor,
		shadowOffset: { width: 0, height: 1 },
		shadowOpacity: 0.2,
		shadowRadius: 2,
		elevation: 3,
	},
	button: {
		...({
			...button(theme.light.colors.primaryDark).button,
		} as object),
		flexDirection: 'row',
		gap: 8,
		borderRadius: 6,
		paddingVertical: 10,
		paddingHorizontal: 20,
		justifyContent: 'center',
		alignItems: 'center',
		width: 240,
	},
	button_mobile: {
		width: '100%'
	},
	buttonText: {
		color: colors.white,
		fontWeight: 'bold',
	},
	footer: {
		flexDirection: 'row',
		gap: 20,
		justifyContent: 'flex-end',
	},
	footer_mobile: {
		flexDirection: 'column',
		justifyContent: 'center',
		gap: 14,
		marginTop: 6,
	}
})