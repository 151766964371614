import { StyleSheet } from 'react-native'
import { colors, theme } from '@styles/constants'
import { button } from '@styles/mixins/button'

export const styles = StyleSheet.create({
	container: {
		flex: 1,
		padding: 20,
		gap: 8,
		marginBottom: 10,
	},
	header: {
		alignItems: 'flex-start',
		color: theme.light.colors.titleColor,
		padding: 0
	},
	form: {
		backgroundColor: theme.light.colors.mainContainer,
		padding: 20,
		borderRadius: 30,
		gap: 10,
		shadowColor: theme.light.colors.mainColor,
		shadowOffset: { width: 0, height: 1 },
		shadowOpacity: 0.2,
		shadowRadius: 2,
		elevation: 3,
	},
	header_form: {
		justifyContent: 'space-between',
		flexDirection: 'row'
	},
	backButton: {
		paddingVertical: 10,
		paddingHorizontal: 5,
		textAlign: 'left',
		flexDirection: 'row',
		alignItems: 'center',
		gap: 6
	},
	backButtonText: {
		color: theme.light.colors.titleColor,
		fontWeight: 'bold',
		fontSize: 16
	},
	buttonText: {
		color: theme.light.colors.linkColorActive,
		fontWeight: '600',
		fontSize: 16
	},
	title: {
		fontSize: 20,
		color: theme.light.colors.mainColor,
		fontWeight: 'bold'
	},
	backIcon: {
		color: theme.light.colors.titleColor,
	},
	icon: {
		color: theme.light.colors.linkColorActive
	},
	iconDark: {
		color: colors.darkAccent
	},
	formFieldWrapper: {
		width: '100%'
	},
	labelText: {
		fontSize: 16,
		marginBottom: 6,
		paddingLeft: 6,
		paddingTop: 10
	},
	errorText: {
		color: colors.error,
		fontSize: 12,
	},
	radioButtonContainer: {
		flexDirection: 'column'
	},
	dropdownsRow: {
		flexDirection: 'row',
		width: '100%',
		paddingHorizontal: 8
	},
	dropdownBtn: {
		flex: 1,
		width: '95%',
		backgroundColor: colors.white,
		borderRadius: 4,
		borderWidth: 1,
		borderColor: colors.darkShades,
		padding: 8
	},
	dropdownBtnText: {
		color: colors.darkShades,
		textAlign: 'left'
	},
	dropdownStyle: {
		backgroundColor: colors.white
	},
	SelectRow: {
		backgroundColor: colors.white,
		borderBottomColor: colors.darkShades
	},
	dropdownRowTxt: {
		color: colors.darkShades,
		textAlign: 'left'
	},
	iconTextContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		gap: 6,
		marginLeft: 12
	},
	button: {
		...({
			...button(theme.light.colors.primaryDark).button,
		} as object),
		borderRadius: 6,
		paddingVertical: 8,
		paddingHorizontal: 16,
		width: 180,
		flexDirection: 'row',
		gap: 8,
		justifyContent: 'center',
		alignItems: 'center'
	},
	buttonDark: {
		...({
			...button(theme.light.colors.successDark).button,
		} as object),
		borderRadius: 6,
		paddingVertical: 8,
		paddingHorizontal: 16,
		width: 180,
		flexDirection: 'row',
		gap: 8,
		justifyContent: 'center',
		alignItems: 'center'
	},
	buttonTextDark: {
		color: theme.light.colors.linkColorActive,
		fontWeight: '600',
		fontSize: 16
	},
	fileContainer: {
		width: '60%'
	},
	voiceContainer: {
		justifyContent: 'flex-start',
		alignItems: 'flex-start'
	},
	footer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	usersContainer: {
		gap: 5,
	},
})
