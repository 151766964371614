import React, { useEffect, useState } from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { colors, theme } from '@styles/constants'
import { useTranslation } from 'react-i18next'
import useScreenDimensions from '@components/Common/UseScreenDimensions'
import { NewAdvisorModal } from '@components/NewAdvisorModal/NewAdvisorModal'
import UserCard from '@components/UserCard/UserCard'
import FontAwesome from 'react-native-vector-icons/FontAwesome'
import { screenType, snackbarInitialState } from '@utils/constants'
import { axiosBFFServer } from '@services/connectionServer'
import { Snackbar } from '@components/Snackbar/Snackbar'
import { Advisor, SnackbarInitialState, SnackbarTypes} from '@utils/interfaces'
import { rootReducerType } from '@reducers/combineReducers'
import { useSelector } from 'react-redux'
import { checkIfPermissionExist } from '@utils/utils'
import { UseErrorMessages } from '@components/Common/UseErrorMessage'

export const Advisors = () => {
	const { t } = useTranslation()
	const screenDimensions = useScreenDimensions()
	const { getErrorMessage } = UseErrorMessages()

	const isSmallScreen = screenDimensions.width < screenType.phone

	const [snackbar, setSnackbar] = useState<SnackbarInitialState>(snackbarInitialState)
	const [oenCreateModal, setOpenCreateModal] = useState(false)
	
	const [advisors, setAdvisors] = useState<Advisor[]>([])
	const legalPartnerKey =  useSelector((state: rootReducerType) => state.legalPartnerKeyReducer.key)
	const userPermissions = useSelector((state: rootReducerType) => state.userReducer.permissions)

	const getAdvisors = () => {
		axiosBFFServer
			.get(`/advisor/list/${legalPartnerKey}`)
			.then(response => {
				setAdvisors(response.data)
			})
			.catch(error => {
				const errorMessage = getErrorMessage(error, t('advisorsListError'))
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}

	const onSubmitNewAdvisor = () => {
		getAdvisors()
		setOpenCreateModal(false)
	}

	const onCloseModal = () => {
		setOpenCreateModal(false)
	}

	useEffect(() => {
		if (legalPartnerKey) getAdvisors()
	}, [legalPartnerKey])


	return (
		<View style={styles.container}>
			<View style={styles.content}>
				<View style={isSmallScreen ? styles.headerSmallDevice : styles.header}>
					<Text style={styles.title}>{t('advisors')}</Text>
					{checkIfPermissionExist(userPermissions, 'advisor', 'add') && <TouchableOpacity
						style={styles.button}
						onPress={() =>setOpenCreateModal(true)}
					>
						<FontAwesome name='user' color={colors.white} size={18} />
						<Text style={styles.buttonText}>{t('createAdvisor')}</Text>
					</TouchableOpacity> }
				</View>
				
				<View style={styles.usersContainer}>
					{
						advisors.map((advisor, index) =>
							<UserCard
								advisorKey={advisor.advisorKey}
								name={advisor.firstName + ' ' + advisor.lastName}
								email={advisor.email}
								verifiedEmail={advisor.verifiedEmail}
								isAdvisor={true}
								key={advisor.advisorKey}
								isLastItem={index === advisors.length - 1}
								isFirstItem={index === 0}
								description=''/>
						)
					}
				</View>
				
			</View>
			<Snackbar
				visible={snackbar.isVisible}
				onDismiss={() => setSnackbar(snackbarInitialState)}
				type={snackbar.type}
				message={snackbar.message}
			/>
			{
				oenCreateModal && 
					<NewAdvisorModal
						visible={oenCreateModal}
						onClose={onCloseModal}
						onSubmit={onSubmitNewAdvisor} />
			}
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		padding: 20,
	},
	content: {
		height: '90%',
		gap: 30,
	},
	title: {
		fontSize: 26,
		color: theme.light.colors.titleColor,
		fontWeight: '500',
		textAlign: 'center'
	},
	button: {
		borderRadius: 6,
		paddingVertical: 10,
		paddingHorizontal: 20,
		flexDirection: 'row',
		gap: 8,
		textAlign: 'center',
		backgroundColor: theme.light.colors.primaryDark,
	},
	buttonText: {
		fontWeight: 'bold',
		color: theme.light.colors.linkColorActive,
	},
	header: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		borderColor: theme.light.colors.boxBorder
	},
	headerSmallDevice: {
		gap: 12,
		alignItems: 'flex-start'
	},
	usersContainer: {
		backgroundColor: theme.light.colors.mainContainer,
		borderRadius: 30,
		width: '100%',
	}
})