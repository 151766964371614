import { Company } from '@utils/interfaces'
import { reduxActionType } from '@utils/reduxTypes'

export const setCompanyKey = (payload: string): reduxActionType => {
	return {
		type: 'SET_COMPANY_KEY',
		payload,
	}
}

export const setCurrentCompany = (payload: Company): reduxActionType => {
	return {
		type: 'SET_CURRENT_COMPANY',
		payload
	}
}

export const setCompanies = (payload: Company[]): reduxActionType => {
	return {
		type: 'SET_COMPANIES',
		payload
	}
}

export const patchCompany = (payload): reduxActionType => {
	return {
		type: 'PATCH_COMPANY',
		payload,
	}
}

export const setCompanySettings = (payload): reduxActionType => {
	return {
		type: 'SET_COMPANY_SETTINGS',
		payload,
	}
}
