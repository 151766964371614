import { StyleSheet } from 'react-native'
import { colors, theme } from '@styles/constants'

export const styles = StyleSheet.create({
	paginationContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		paddingVertical: 10,
	},
	paginationText: {
		fontSize: 16,
		color: colors.darkGray,
	},
	arrows: {
		display: 'flex',
		flexDirection: 'row-reverse',
		justifyContent: 'center',
		alignItems: 'center',
		width: '20%'
	},
	arrow: {
		paddingHorizontal: 5,
		fontSize: 22,
		fontWeight: 'bold',
	},
	pageNumber: {
		paddingHorizontal: 5,
		paddingVertical: 2,
		color: theme.light.colors.mainColor,
	},
	currentPage: {
		fontWeight: 'bold',
		color: theme.light.colors.linkColorActive,
		backgroundColor: theme.light.colors.primaryDark,
		borderRadius: 4
	},
	resultsPerPagePicker: {
		height: 40,
		width: 150,
	},
	dropdown: {
		width: '20%',
		height: '10px',
		backgroundColor: theme.light.colors.mainContainer,
		borderColor: theme.light.colors.mainColor,
		borderWidth: 1,
		borderRadius: 6,
		padding: 5,
		paddingTop: 15,
		paddingBottom: 15
	},
	pages: {
		display: 'flex',
		flexDirection: 'row',
		marginLeft: 15,
		marginRight: 15
	},
	resultsPerPage: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		width: '40%'
	},
	resultsPerPageText: {
		color: theme.light.colors.mainColor,
		marginRight: '10px',
		fontWeight: 'bold',
	}
});
