import { reduxActionType } from '@utils/reduxTypes'

const initialState = {
	key: '',
	viewKey: '',
	isAdvisorNewCase: false,
	pass: '',
	acceptedCase: '',
	caseType: ''
}

export const setCaseKeyReducer = (
	state = initialState,
	action: reduxActionType
): (typeof initialState) => {
	const newState = JSON.parse(JSON.stringify(state))
	switch (action.type) {
	case 'SET_CASE_KEY': {
		newState.key = action.payload
		return newState
	}
	case 'SET_CASE_VIEW_KEY': {
		newState.viewKey = action.payload
		return newState
	}
	case 'SET_IS_ADVISOR_NEW_CASE': {
		newState.isAdvisorNewCase = action.payload
		return newState
	}
	case 'SET_CASE_PASS': {
		newState.pass = action.payload
		return newState
	}
	case 'SET_CASE_ACCEPTED': {
		newState.acceptedCase = action.payload
		return newState
	}
	default: return state
	}
}