import React, { FC } from 'react'
import { Modal, Text, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import { Input } from '@components/Input'
import { MultiSelectDropdown } from '@components/MultiSelect'
import useScreenDimensions from '@components/Common/UseScreenDimensions'
import { CategoryType, CompanyUser, DepartmentFormValues, DepartmentType } from '@utils/interfaces'
import { initialDepartmentFormValues, screenType } from '@utils/constants'
import { findCommonCategoryKeys, haveCommonItems } from '@utils/utils'
import { styles } from './styles'

interface DepartmentModalProps {
    isVisible: boolean
    onClose: () => void
    onSave: (values: DepartmentFormValues) => void
    department?: DepartmentType
    users: CompanyUser[]
    categories: CategoryType[]
    companyAssignedCategories: {
        departmentKey: string
        categories: CategoryType[]
    }[]
}

interface FormErrors {
    name?: string
    categoriesKeys?: string
}

export const DepartmentModal: FC<DepartmentModalProps> = ({
	isVisible,
	onClose,
	onSave,
	department,
	users,
	categories,
	companyAssignedCategories,
}) => {
	const { t } = useTranslation()    
	const screenDimensions = useScreenDimensions()
	const isMobileScreen = screenDimensions.width < screenType.phone
	const alreadyAssignedCategoriesKeys = companyAssignedCategories
		.filter(categoryItem => !department || categoryItem.departmentKey !== department.departmentKey)
		.flatMap(categoryItem => categoryItem.categories)
		.map(category => category.categoryKey)

	const initialValues = department
		? {
			name: department.name,
			categoriesKeys: department.categories.map(category => category.categoryKey),
			usersKeys: department.users.map(user => user.companyUserKey),
		}
		: initialDepartmentFormValues

	const getCategoryValues = (categoryKeys: string[]) => {
		const values = categoryKeys.reduce((result: string[], key: string) => {
			const category = companyAssignedCategories
				.flatMap(item => item.categories)
				.find(cat => cat.categoryKey === key)
			if(category) {
				result.push(category.value)
			}
			return result
		}, [])
		return values
	}

	return (
		<Modal
			animationType='slide'
			transparent={true}
			visible={isVisible}
			onRequestClose={onClose}
		>
			<View style={styles.modalContainer}>
				<View style={[styles.contentContainer, isMobileScreen && styles.contentContainer_mobile]}>
					<Text style={styles.title}>{department ? t('editDepartment') : t('createDepartment')}</Text>
					<Formik
						initialValues={initialValues}
						validate={({ name, categoriesKeys }) => {
							const errors: FormErrors = {}

							if(!name) {
								errors.name = t('formValidation.requiredInput', { inputName: t('fieldLabels.name') })
							}

							if(haveCommonItems(alreadyAssignedCategoriesKeys, categoriesKeys)) {
								const commonKeys = findCommonCategoryKeys(alreadyAssignedCategoriesKeys, categoriesKeys)
								const alreadyAssignedCategories = getCategoryValues(commonKeys).join(', ')
								errors.categoriesKeys = t('formValidation.assignedCategories', { assignedCategories: alreadyAssignedCategories })
							}

							return errors
						}}
						onSubmit={onSave}
					>
						{formikProps => (
							<>
								<View style={styles.formContainer}>
									<View style={styles.field}>
										<Text style={styles.label}>{t('fieldLabels.name')}</Text>
										<Input
											name='name'
											placeholder={t('inputPlaceholders.name')}
											value={formikProps.values['name']}
											onChange={formikProps.setFieldValue}
											onBlur={() => formikProps.setFieldTouched('name', true)}
										/>
										{formikProps.touched['name'] && formikProps.errors['name'] && (
											<Text style={styles.errorText}>{`*${formikProps.errors['name']}`}</Text>
										)}
									</View>
									<View style={styles.field}>
										<Text style={styles.label}>{t('fieldLabels.categories')}</Text>
										<MultiSelectDropdown
											items={categories}
											selectedItems={formikProps.values['categoriesKeys']}
											onSelectedItemsChange={(selected) => {
												formikProps.setFieldValue('categoriesKeys', selected)
											}}
											uniqueKey='categoryKey'
											displayKey='value'
											placeholder={t('inputPlaceholders.categories')}
											searchText={t('inputPlaceholders.categories')}
										/>
										{formikProps.errors['categoriesKeys'] && (
											<Text style={styles.errorText}>{`*${formikProps.errors['categoriesKeys']}`}</Text>
										)}
									</View>
									<View style={styles.field}>
										<Text style={styles.label}>{t('fieldLabels.users')}</Text>
										<MultiSelectDropdown
											items={users}
											selectedItems={formikProps.values['usersKeys']}
											onSelectedItemsChange={(selected) => {
												formikProps.setFieldValue('usersKeys', selected)
											}}
											uniqueKey='companyUserKey'
											displayKey={'fullName'}
											placeholder={t('inputPlaceholders.users')}
											searchText={t('inputPlaceholders.users')}
										/>
									</View>
								</View>
								<View style={styles.buttonsContainer}>
									<TouchableOpacity onPress={onClose}>
										<Text style={styles.button}>{t('cancel')}</Text>
									</TouchableOpacity>
									<TouchableOpacity onPress={() => formikProps.handleSubmit()}>
										<Text style={[styles.button, styles.saveButton]}>{t('save')}</Text>
									</TouchableOpacity>
								</View>
							</>
						)}
					</Formik>
				</View>
			</View>
		</Modal>
	)
}
