import { StyleSheet } from 'react-native'
import { theme } from '@styles/constants'

export const styles = StyleSheet.create({
	header: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		backgroundColor: theme.light.colors.mainContainer,
		paddingRight: '5%',
		zIndex: 1,
		borderBottomWidth: 1,
		borderBottomColor: theme.light.colors.boxBorder,
		shadowColor: theme.light.colors.mainColor,
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.2,
		shadowRadius: 2,
		elevation: 2,
	},
	headerText: {
		fontSize: 20,
		fontWeight: 'bold',
		color: theme.light.colors.linkColor
	},
	container: {
		flexDirection: 'row',
		gap: 20,
		alignItems: 'center',
		paddingLeft: 18
	},
	logo: {
		width: 60,
		height: 60,
	},
	text: {
		fontSize: 18,
		fontWeight: '500',
		color: theme.light.colors.titleColor
	},
	notificationLogo: {
		width: 25,
		height: 25,
	}
})

