import Layout from '@containers/Layout'
import React, {useEffect, useState} from 'react'
import { Cases } from '@containers/Cases/Cases'
import {setCompanies} from '@actions/companyActions'
import {getAllCompaniesForPartner} from '@services/apiService'
import {useDispatch} from 'react-redux'
import {isPartnerOrAdvisor} from '@utils/utils'
import {useSelector} from 'react-redux'
import {rootReducerType} from '@reducers/combineReducers'
import {Loader} from '@components/Loader/Loader'

export const CasesPage = () => {
	const [isDataLoaded, setIsDataLoaded] = useState(false)
	const dispatch = useDispatch()
	const { roles } = useSelector((state: rootReducerType) => state.userReducer)
	const { companies } = useSelector((state: rootReducerType) => state.companyReducer)

	const setAllCompanies = async () => {
		const companies = await getAllCompaniesForPartner()
		dispatch(setCompanies(companies?.data.pageResults))
		setIsDataLoaded(true)
	}

	useEffect(() => {
		if(isPartnerOrAdvisor(roles) && companies.length === 0) {
			setAllCompanies()
		} else {
			setIsDataLoaded(true)
		}
	}, [])

	return (
		!isDataLoaded ? <Loader /> 
			: 
			<Layout>
				<Cases />
			</Layout>
	)
}