import Table from '@components/Table/Table'
import React, { useEffect, useMemo, useState } from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import { companyCasesTableColumns, partnerCasesTableColumns } from '@utils/constants'
import { colors } from '@styles/constants'
import { Case, Company, CompanyCase, MasterPassowdFormValues, SeverityType, SnackbarInitialState, SnackbarTypes, StatusCaseType, TableColumn, ViewTypes } from '@utils/interfaces'
import useScreenDimensions from '@components/Common/UseScreenDimensions'
import { useTranslation } from 'react-i18next'
import { CasesBox } from '@components/CasesBox/CasesBox'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { setCaseViewKey } from '@actions/caseKeyActions'
import { ListCasesFilter } from '@containers/ListCasesFilter/ListCasesFilter'
import { screenType, snackbarInitialState } from '@utils/constants'
import { useSelector } from 'react-redux'
import { rootReducerType } from '@reducers/combineReducers'
import { axiosBFFServer } from '@services/connectionServer'
import { Loader } from '@components/Loader/Loader'
import { Snackbar } from '@components/Snackbar/Snackbar'
import { formatDate, formatDateRo, getDeadline, getPreviousMonth, isPartnerOrAdvisor } from '@utils/utils'
import { MasterPasswordForm } from '@components/MasterPasswordForm/MasterPasswordForm'
import { setCompanyKey, setCompanySettings, setCurrentCompany } from '@actions/companyActions'
import { styles } from './styles'
import { getCompanySettings, getCurrentCompany } from '@services/apiService'
import { UseErrorMessages } from '@components/Common/UseErrorMessage'

interface CasesType {
	type: string,
	number: number | string,
	icon: boolean
}

export const Cases = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const location = useLocation()
	const screenDimensions = useScreenDimensions()
	const isSmallScreen = screenDimensions.width < screenType.phone
	const { t } = useTranslation()
	const { getErrorMessage } = UseErrorMessages()
	
	const [totalCases, setTotalCases] = useState(10)
	const [ page, setPage ] = useState(1)
	const [cases, setCases] = useState<CompanyCase[]>([{
		id: '',
		subject: '',
		category: '',
		severity: '',
		deadline: '',
		company: '',
		lastActivity: 0,
		caseState: {
			caseStateKey: '',
			state: '',
			status: '',
			translatedStatus: '',
		},
		createdAt: ''
	}])
	const [searchValue, setSearchValue] = useState('')
	const [isLoading, setIsLoading] = useState(true)
	const [selectedFilters, setSelectedFilters] = useState({})
	const [casesType, setCasesType] = useState<CasesType[]>([{
		type: '',
		number: 0 ,
		icon: false
	}])
	const [ snackbar, setSnackbar ] = useState<SnackbarInitialState>(snackbarInitialState)
	const [range, setRange] = useState({ startDate: getPreviousMonth(), endDate: new Date()})
	const [statusData, setStatusData] = useState<string[]>([])
	const [categoriesData, setCategoriesData] = useState<string[]>([])
	const [assignedToData, setAssignedToData] = useState<string[]>([])
	const [response, setResponse] = useState()

	const size = useSelector((state: rootReducerType) => state.paginationReducer.pageSize)
	const sorting = useSelector((state: rootReducerType) => state.sortReducer.cases)
	const roles = useSelector((state: rootReducerType) => state.userReducer.roles)
	const masterPassword = useSelector((state: rootReducerType) => state.masterPasswordReducer.password)
	const { publicKey, encodedPrivateKey } = useSelector((state: rootReducerType) => isPartnerOrAdvisor(roles)
		? state.legalPartnerKeyReducer
		: state.companyReducer.currentCompany
	)
	const { companyKey } = useSelector((state: rootReducerType) => state.companyReducer.currentCompany)
	const companiesData = useSelector((state: rootReducerType) => state.companyReducer.companies)
	const language = useSelector((state: rootReducerType) => state.userReducer.language)
	const isPartnerView = location.pathname.includes(ViewTypes.PARTNER)

	const handleSubmit = (values: MasterPassowdFormValues) => {
		const navigateRoute = location.pathname

		navigate(`${navigateRoute}/check`, { state: {
			masterPassword: values.password.trim(),
			publicKey,
			encodedPrivateKey,
			route: navigateRoute,
		}})
	}
    
	useEffect(() => {
		!!location.state?.errorMessage && setSnackbar({
			isVisible: true,
			type: SnackbarTypes.ERROR,
			message: location.state.errorMessage
		})
	}, [])

	if (!masterPassword) {
		return (
			<View style={styles.container}>
				<View style={styles.masterPasswordContainer}>
					<MasterPasswordForm onSubmit={handleSubmit} />
				</View>
				<Snackbar
					visible={snackbar.isVisible}
					onDismiss={() => setSnackbar(snackbarInitialState)}
					type={snackbar.type}
					message={snackbar.message}
				/>
			</View>
		)
	}

	const companiesKeys = isPartnerOrAdvisor(roles) && companiesData ? companiesData.map((company: {companyKey: string}) => company.companyKey) : []

	const handleSelectedFilters = (selectedItems) => {
		setSelectedFilters(selectedItems)
	}

	const handleDataChange = (data: {statuses: string[], categories: string[], assignedTo: string[]}) => {

		setStatusData(data.statuses)
		setCategoriesData(data.categories)
		setAssignedToData(data.assignedTo)
	}

	const viewCase = async (id: string) => {
		if (isPartnerOrAdvisor(roles)) {
			const companyKey = id.split('_')[0]
			const company = (companiesData as Company[]).find(company => company.companyKey === companyKey)
			const companySettings = await getCompanySettings(companyKey)
			dispatch(setCompanyKey(companyKey))
			company && dispatch(setCurrentCompany(company))
			dispatch(setCompanySettings({
				language: companySettings?.data.language,
			}))
		} else {
			const currentCompany = await getCurrentCompany()
			dispatch(setCurrentCompany(currentCompany?.data))
		}
        
		dispatch(setCaseViewKey(id))
		navigate(`/${ViewTypes.COMPANY}/cases/case-details/${id}`)
	}

	const getFilterItems = () => {
		return {
			creationDateStart: formatDate(range.startDate),
			creationDateEnd: formatDate(range.endDate),
			
			...(isPartnerOrAdvisor(roles) && isPartnerView ? {companyKeys: companiesKeys} : {companyKeys: [companyKey]}), 
			...(statusData.length && {states: statusData }),
			...(assignedToData.length && {userEmails: assignedToData }),
			...(categoriesData.length && { categoryKeys: categoriesData }),
			...(searchValue.length && {tag: searchValue }),
			...(sorting.by && {sort: sorting }),
		}
	}
	
	const getCases = () => {
		const filterItems = getFilterItems()

		axiosBFFServer
			.post(`/case/search?page=${page}&size=${size}`, filterItems)
			.then(response => {
				const caseWithIndex = response.data.pageResults.map((item) => ({ ...item, id: item.tag }))
				setCases(caseWithIndex)
				setTotalCases(response.data.totalResults)
				setIsLoading(false)
			})
			.catch(error => {
				const errorMessage = getErrorMessage(error, t('caseErrors'))
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}
	
	const acceptCase = (id: string) => {
		axiosBFFServer
			.post(`/case/accept/${id}`)
			.then(resp => {
				if(resp.status === 201) {
					setSnackbar({
						isVisible: true,
						type: SnackbarTypes.SUCCESS,
						message: t('caseAccepted')
					})
					setResponse(resp.data)
				}
			})
			.catch(error => {
				const errorMessage = getErrorMessage(error, t('errors.acceptCase'))
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	} 

	const handleCasesType = () => {
		if (cases && cases.length > 0) {
			let newCases = 0
			let openCases = 0
			let closedCases = 0
	
			cases.forEach((item) => {
				if (item.caseState) {
					switch (item.caseState.status) {
					case StatusCaseType.NEW:
						newCases++
						break
					case StatusCaseType.OPEN:
						openCases++
						break
					case StatusCaseType.CLOSED:
						closedCases++
						break
					default:
						break
					}
				}
			})
	
			const updatedCasesType = [
				{
					type: `${StatusCaseType.NEW.toLowerCase()} cases`,
					number: `${newCases}/${totalCases}`,
					icon: false,
				},
				{
					type: `${StatusCaseType.OPEN.toLowerCase()} cases`,
					number: openCases,
					icon: false,
				},
				{
					type: `${StatusCaseType.CLOSED.toLowerCase()} cases`,
					number: closedCases,
					icon: false,
				},
			]
	
			setCasesType(updatedCasesType)
		}
	}

	useEffect(() => {
		getCases()
	},[page, size, sorting, searchValue, range, JSON.stringify(selectedFilters), response, language])

	useMemo(() => {
		handleCasesType()
	}, [cases])

	const renderTableCell = (column: TableColumn, cases: Case) => {
		const lastActivity = cases.lastActivity
		const lastActivityDate = lastActivity && lastActivity.slice(0, 10)

		switch (column.key) {
		case 'status':
			return (
				<View style={styles.itemText}>
					<Text style={styles.grayText}>{cases.caseState.translatedStatus}</Text>
				</View>
			)
		case 'severity':
			return (
				<View style={[
					styles.itemText,
					{borderColor: cases.severity === SeverityType.URGENT || cases.severity === SeverityType.SEVERE
						? colors.danger
						: cases.severity === SeverityType.MINOR
							? colors.secondary
							: colors.yellow,
					backgroundColor:  cases.severity === SeverityType.URGENT || cases.severity === SeverityType.SEVERE
						? colors.dimmedRed
						: cases.severity === SeverityType.MINOR
							? colors.dimmedGreen
							: colors.dimmedYellow,
					}
				]}>
					<Text style={styles.grayText}>{cases.severity}</Text>
				</View>
			)
		case 'last_activity':
			return (
				<View style={styles.itemText}>
					<Text style={styles.grayText}>{getDeadline(lastActivityDate,language)}</Text>
				</View>
			)
		case 'createdAt':
			return (
				<Text style={[ styles.dataText, isSmallScreen && styles.smallScreenDataText ]}>{formatDateRo(cases.createdAt)}</Text>
			)
		case 'deadline':
			return (
				<View style={styles.itemText}>
					<Text style={{
						color: getDeadline(cases.deadline,language).includes(t('ago')) ? colors.danger : colors.secondary,
						borderColor: colors.darkGray,
						fontWeight: '500',
					}}>
						{getDeadline(cases.deadline,language)}
					</Text>
				</View>
			)
		case 'view':
			return (
				<TouchableOpacity onPress={() => {
					viewCase(cases.caseKey)}}>
					<Text style={styles.link}>{t('view')}</Text>
				</TouchableOpacity>
			)
		case 'accept':
			return (
				<TouchableOpacity onPress={() => {
					acceptCase(cases.caseKey)}} disabled={cases.caseState.status === StatusCaseType.CLOSED || cases.caseState.status === StatusCaseType.OPEN || cases.caseState.status === StatusCaseType.REJECTED ? true : false}>
					<Text style={cases.caseState.status === StatusCaseType.CLOSED || cases.caseState.status === StatusCaseType.OPEN || cases.caseState.status === StatusCaseType.REJECTED ? styles.linkDisabled : styles.link}>{cases.caseState.status === StatusCaseType.CLOSED || cases.caseState.status === StatusCaseType.OPEN || cases.caseState.status === StatusCaseType.REJECTED ? t('caseAccepted') : t('accept')}</Text>
				</TouchableOpacity>
			)
		default:
			return (
				<Text style={[styles.dataText, isSmallScreen && styles.smallScreenDataText]}>{cases[column.key]}</Text>
			)
		}
	}

	return (
		<View style={styles.container}>
			
			<View>
				<Text style={styles.headerText}>{t('cases')}</Text>
			</View>
			<View style={styles.headerContainer}>
				<View style={styles.boxContainer}>
					<CasesBox cases={casesType} />
				</View>
			</View>

			{isLoading ?
				<Loader /> 
				: 
				<><View style={styles.listCasesContainer}>
					<ListCasesFilter 
						setSearchValue={setSearchValue} 
						onSelectedFiltersChange={handleSelectedFilters} 
						range={range} 
						setRange={setRange} 
						onDataChange={handleDataChange}
						cases={cases}
						getFilterItems={getFilterItems}
					/>
				</View>
				<Table
					data={cases}
					columns={isPartnerView ? partnerCasesTableColumns : companyCasesTableColumns}
					isSmallScreen={isSmallScreen}
					renderTableCell={renderTableCell}
					totalRecords={totalCases}
					hasPagination={true}
					page={page}
					setPage={setPage}
				/>
				</> 
			}
			<Snackbar
				visible={snackbar.isVisible}
				onDismiss={() => setSnackbar(snackbarInitialState)}
				type={snackbar.type}
				message={snackbar.message}
			/>
		</View>
	)}	
