import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import enTranslations from './en.json'
import roTranslations from './ro.json'
import huTranslations from './hu.json'
import deTranslations from './de.json'

const resources = {
	en: { translation: enTranslations },
	ro: { translation: roTranslations },
	hu: { translation: huTranslations },
	de: { translation: deTranslations },
}

i18n.use(initReactI18next).use(LanguageDetector).init({
	resources,
	fallbackLng: 'en',
	interpolation: {
		escapeValue: false,
	},
})

export default i18n