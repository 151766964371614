import { colors, theme } from '@styles/constants'
import { button, buttonText } from '@styles/mixins/button'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
	label: {
		fontWeight: 'bold',
		marginBottom: 5,
		color: theme.light.colors.titleColor
	},
	button: {
		...({
			...button(theme.light.colors.primaryDark).button,
			...buttonText().buttonText,
		} as object),
		fontSize: 16,
		fontWeight: 'bold',
		paddingVertical: 10,
		paddingHorizontal: 20,
		textAlign: 'center',
		borderRadius: 5,
		padding: 10,
		height: 40,
		alignSelf: 'flex-end',
		marginLeft: 25,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
	},
	buttonText: {
		...({
			...buttonText().buttonText
		} as object),
	},
	changePassword: {
		alignSelf: 'flex-start',
		marginLeft: 0
	},
	validationMessage: {
		color: colors.error,
		marginBottom: 10,
	},
	disabled: {
		opacity: 0.5,
	},
	formContainer: {
		gap: 10,
	},
})