import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, TouchableOpacity, View } from 'react-native'
import { Formik } from 'formik'
import { Input } from '@components/Input'
import { MasterPassowdFormValues } from '@utils/interfaces'
import { masterPassowdFormSchema } from '@utils/formValidation'
import { styles } from './style'

interface MasterPasswordFormProps {
    onSubmit: (values: MasterPassowdFormValues) => void
}

export const MasterPasswordForm: FC<MasterPasswordFormProps> = ({ onSubmit }) => {
	const { t } = useTranslation()

	const initialValues = {
		password: ''
	}

	return (
		<View style={styles.container}>
			<Text style={styles.title}>{t('masterPasswordForm')}</Text>
			<Text  style={styles.subtitle}>{t('masterPasswordForm1')}</Text>
			<Formik initialValues={initialValues} validationSchema={masterPassowdFormSchema} onSubmit={onSubmit}>
				{formikProps => (
					<View style={styles.formContainer}>
						<Input
							name='password'
							placeholder={t('secretKey')}
							value={formikProps.values['password']}
							onChange={formikProps.setFieldValue}
							onBlur={() => formikProps.setFieldTouched('password', true)}
							isSecureEntry={true}
						/>
						{formikProps.touched['password'] && formikProps.errors['password'] && (
							<Text style={styles.errorText}>{`*${formikProps.errors['password']}`}</Text>
						)}
						<TouchableOpacity style={styles.button} onPress={() => formikProps.handleSubmit()}>
							<Text style={styles.buttonText}>{t('confirm')}</Text>
						</TouchableOpacity>
					</View>
				)}
			</Formik>
		</View>
	)
}


