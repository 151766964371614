import storage from 'redux-persist/es/storage'
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import { currentPageReducer } from '@reducers/menuReducer'
import { recordingReducer } from '@reducers/voiceRecordingReducer'
import { setAdvisorKeyReducer } from '@reducers/advisorReducer'
import { companyReducer } from '@reducers/companyReducer'
import { paginationReducer } from '@reducers/paginationReducer'
import { setCaseKeyReducer } from '@reducers/caseKeyReducer'
import { userReducer } from '@reducers/userReducer'
import { sortReducer } from '@reducers/sortingReducer'
import { legalPartnerKeyReducer } from '@reducers/legalPartnerKeyReducer'
import { companyUserKeyReducer } from '@reducers/companyUserReducer'
import { masterPasswordReducer } from '@reducers//masterPassword'
import { paymentReducer } from '@reducers/paymentReducers'
import { loaderReducer } from '@reducers/loaderReducer'
import { notificationReducer } from './notificationReducer'

const persistConfig = {
	key: 'root',
	storage: storage,
	whitelist: [
		'currentPageReducer',
		'recordingReducer',
		'setAdvisorKeyReducer',
		'companyReducer',
		'setCaseKeyReducer',
		'paginationReducer',
		'userReducer',
		'sortReducer',
		'legalPartnerKeyReducer',
		'companyUserKeyReducer',
		'masterPasswordReducer',
		'paymentReducer',
		'loaderReducer',
		'notificationReducer'
	],
}

const appReducers = combineReducers({
	currentPageReducer, recordingReducer, setAdvisorKeyReducer, companyReducer, setCaseKeyReducer, paginationReducer, userReducer, sortReducer, legalPartnerKeyReducer, companyUserKeyReducer, masterPasswordReducer, paymentReducer, loaderReducer, notificationReducer
})

const rootReducer = ( state, action ) => {
	if (action.type === 'RESET_STATE') {
		state = {}
	}
	return appReducers(state, action)
}

export default persistReducer(persistConfig, rootReducer)

export type rootReducerType = ReturnType<typeof rootReducer>;
