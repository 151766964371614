import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Text, TouchableOpacity, View } from 'react-native'
import Icon from 'react-native-vector-icons/FontAwesome'
import { partnerRoutes } from '@utils/router'
import { LogEventTypes, ViewTypes } from '@utils/interfaces'
import {getISOStringFromDate, getUserAgent} from '@utils/utils'
import {useSelector} from 'react-redux'
import {rootReducerType} from '@reducers/combineReducers'
import {createLog} from '@services/apiService'
import { styles } from './styles'

interface FooterBackButtonProps {
    height: number
}

export const FooterBackButton: FC<FooterBackButtonProps> = ({ height }) => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const { email } = useSelector((state: rootReducerType) => state.userReducer)
	const { companyKey } = useSelector((state: rootReducerType) => state.companyReducer.currentCompany)

	const handleBackToPartnerView = async () => {
		const userAgent = getUserAgent()
		const securityLogInfo = {
			companyKey,
			userEmail: email,
			userAgent,
			time: new Date().toISOString(),
			event: LogEventTypes.LOGOUT,
			failureReason: ''
		}
		await createLog(securityLogInfo, false)
		navigate(`/${ViewTypes.PARTNER}/${partnerRoutes[0].path}`)
	}

	return (
		<View style={[styles.container, { height }]}>
			<TouchableOpacity onPress={() => handleBackToPartnerView()}>
				<View style={styles.footerButton}>
					<Icon style={styles.buttonIcon} name={'chevron-left'} size={14} />
					<Text style={styles.buttonText}>{t('menu.back')}</Text>
				</View>
			</TouchableOpacity>
			<Text style={styles.footerInfoText}>{t('companyView').toUpperCase()}</Text>
		</View>
	)
}

