import React, { useEffect, useState } from 'react'
import { Modal, Text, View } from 'react-native'
import { colors, theme } from '@styles/constants'
import Checkbox from 'expo-checkbox'
import FontAwesome from 'react-native-vector-icons/FontAwesome'
import { Tooltip } from '@rneui/themed'
import { PermissionList } from '@utils/interfaces'
import { styles } from './styles'

interface Props {
	text: string
	name: string
	permissionKey: string
	disabled: boolean
	hasTooltip: boolean
	tooltip?: string
	hasDropDown: boolean
	dropdownChecksList?: PermissionList[]
	permissionKeys: string[]
	setPermissionKeys: (value: string[]) => void
	initialPermissions?: string[]
	checked?: boolean
	default?: boolean
}


export const CheckBoxCustom = (props: Props) => {
	const [isChecked, setIsChecked] = useState(false)
	const [isOpened, setIsOpen] = useState(true)
	const [open, setOpen] = useState(false)

	const changePermissionsList = () => {
		isChecked && !props.default ? props.setPermissionKeys([...props.permissionKeys, props.permissionKey]) :
			props.setPermissionKeys(props.permissionKeys.filter((perm) => perm != props.permissionKey))
	}

	useEffect(() => {
		if (props.hasDropDown){
			let newPermissions = props.permissionKeys
			props.dropdownChecksList && props.dropdownChecksList.forEach((child) => {
				if (isChecked) {
					const indexOfPermission = newPermissions.indexOf(child.permissionKey)
					if (indexOfPermission > -1) 
						newPermissions.push(child.permissionKey)
				} else {
					newPermissions = newPermissions.filter((perm) => perm != child.permissionKey)
				}
				props.setPermissionKeys(newPermissions)
			})
		} else changePermissionsList()

	}, [isChecked])

	useEffect(() => {
		props.checked != undefined && setIsChecked(props.checked)
	}, [props.checked])

	useEffect(() => {
		props.initialPermissions && props.initialPermissions.includes(props.permissionKey) && setIsChecked(true)
	},[props.initialPermissions])
	
	return (
		<View style={styles.container}>
			<View style={styles.checkBoxContainer}>
				{!props.hasDropDown && <Checkbox 
					value={isChecked} 
					disabled={props.disabled}
					onValueChange={setIsChecked}
					color={isChecked ? theme.light.colors.mainColor : theme.light.colors.mainColor} />}
				<Text style={props.disabled ? styles.disabled : styles.labelText}>{props.text}</Text>	
				{
					props.tooltip?.length ?
						<Tooltip
							visible={open}
							onOpen={() => {
								setOpen(true)
							}}
							onClose={() => {
								setOpen(false)
							}}
							ModalComponent={Modal}
							backgroundColor={colors.darkAccent}
							popover={
								<Text>{props.tooltip}</Text>
							}
							containerStyle={{ width: 'auto', height: 'auto', marginBottom: 30 }}
						> <FontAwesome 
								name={'info-circle'} 
								color={colors.darkAccent} 
								size={14} />  
						</Tooltip>
						: null }
			
				{props.hasDropDown && 
				<FontAwesome 
					onPress={() => setIsOpen(!isOpened)}
					name={isOpened ? 'chevron-up' : 'chevron-down'} 
					color={props.disabled ? colors.darkGray : colors.darkAccent} 
					size={14} /> 
				}
			</View>
			{
				props.hasDropDown && isOpened &&
				<View style={styles.dropDown}>
					{
						props.dropdownChecksList?.map((item) => 
							<CheckBoxCustom
								text={item.text}
								name={item.name}
								permissionKey={item.permissionKey}
								disabled={item.default === true ? true : props.disabled}
								hasTooltip={item.tooltip.length ? true : false}
								tooltip={item.tooltip}
								hasDropDown={false}
								checked={item.default === true ? true : isChecked}
								permissionKeys={props.permissionKeys}
								setPermissionKeys={props.setPermissionKeys}
								initialPermissions={props.initialPermissions}
								default={item.default}
							/>
						)
					}
				</View>
			}
		</View>
	)
}

