import React from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import { colors } from '@styles/constants'
import FontAwesome from 'react-native-vector-icons/FontAwesome'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useScreenDimensions from '@components/Common/UseScreenDimensions'
import { useDispatch } from 'react-redux'
import {  setEditAdvisorEmail, setEditAdvisorKey } from '@actions/advisorActions'
import { screenType } from '@utils/constants'
import { ViewTypes } from '@utils/interfaces'
import { setCompanyUserEmail, setCompanyUserKey } from '@actions/companyUserActions'
import { styles } from './styles'

interface Props {
	advisorKey?: string;
	userKey?: string
	name: string;
	email?: string;
	verifiedEmail?: boolean;
	description?: string;
	isAdvisor: boolean;
	isLastItem?: boolean;
	isFirstItem?: boolean;
}


const UserCard = (props: Props) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const screenDimensions = useScreenDimensions()
	const isSmallScreen = screenDimensions.width < screenType.tablet
	const isMobileScreen = screenDimensions.width < screenType.phone
	const initials = props.name.charAt(0) + props.name.split(' ')[1]?.charAt(0)
	
	const onClickButton = () => {
		if (props.userKey){
			dispatch(setCompanyUserKey(props.userKey))
			props.email && dispatch(setCompanyUserEmail(props.email))
			navigate(`/${ViewTypes.COMPANY}/edit-companyUser`)
		} else {
			navigate(`/${ViewTypes.PARTNER}/advisor/edit-advisor`)
			props.advisorKey && dispatch(setEditAdvisorKey(props.advisorKey))
			props.email && dispatch(setEditAdvisorEmail(props.email))
		}
	}

	return (
		<View style={[
			props.isAdvisor && isSmallScreen ? styles.cardContainerAdvisorMobile : props.isAdvisor ? styles.cardContainerAdvisor : styles.cardContainer,
			props.isLastItem && styles.lastChild,
			props.isFirstItem && styles.firstChild,
		]}>
			<View style={styles.container}>
				<View style={isMobileScreen ? styles.profileAvatar_mobile : styles.profileAvatar}>
					<Text style={isMobileScreen ? styles.avatarText_mobile : styles.avatarText}>{initials.toUpperCase()}</Text>
				</View>
				<View style={isSmallScreen ? styles.contentContainerMobile : styles.contentContainer}>
					<View style={isMobileScreen ? styles.textContainer_mobil : styles.textContainer}>
						<Text style={styles.mainText}>{props.name}</Text>
						{
							props.description &&
					<Text style={styles.text}>{props.description}</Text>
						}
						{
							props.email && 
					<View style={styles.textIconContainer}>
						<FontAwesome 
							name={'envelope'} 
							color={colors.darkAccent} 
							size={18} /> 
						<Text style={styles.text}>{props.email}</Text>
					</View>
						}
					</View>
					{
						props.isAdvisor &&
				<View style={styles.infoContainer}>
					<View style={styles.textIconContainer}>
						<FontAwesome 
							name={props.verifiedEmail ? 'check' : 'warning'} 
							color={props.verifiedEmail ? colors.darkShades : colors.danger} 
							size={18} /> 
						<Text style={styles.text}>{props.verifiedEmail ? t('emailVerified') : t('emailNotVerified')}</Text>
					</View>
				</View>
					}
				</View>
			</View>
			{
				props.isAdvisor &&
				<View style={styles.footer}>
					<TouchableOpacity 
						style={styles.button}
						onPress={onClickButton}>
						<Text style={styles.buttonText}>{
							props.userKey ? t('editCompanyUser')
								: t('editAdvisor')}</Text>
					</TouchableOpacity>
				</View>
			
			}
		</View>
	)
}



export default UserCard