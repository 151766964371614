import { axiosBFFServer } from '@services/connectionServer'
import { FC, useEffect, useRef } from 'react'

interface UseWebSocketOptions {
	onMessage: (data: string) => void
	userEmail: string
}

export const UseWebSocket: FC<UseWebSocketOptions> = ({ onMessage, userEmail }) => {
	const wsRef = useRef<WebSocket | null>(null)
	const wsUrl = process.env.API_WS_URL

	useEffect(() => {
		const connect = async () => {
			if (wsUrl) {
				await axiosBFFServer.get('/ws')
				wsRef.current = new WebSocket(`${wsUrl}?email=${encodeURIComponent(userEmail)}`)
		
				wsRef.current.onmessage = (event) => {
					onMessage(event.data)
				}
		
				wsRef.current.onerror = (error) => {
					console.error('WebSocket error:', error)
				}
		
				wsRef.current.onclose = () => {
					console.log('WebSocket disconnected')
				}	
			}
		}

		if (!wsRef.current) {
			connect()
		}
		
		return () => {
			wsRef.current && wsRef.current.close()
		}
	}, [])

	return null
}