import React from 'react'
import AudioPlayer from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css'
import { colors } from '@styles/constants'

interface RecordingPlayerProps {
  audioUrl: string
}

const RecordingPlayer: React.FC<RecordingPlayerProps> = ({ audioUrl }) => {
	return (
		<AudioPlayer
			src={audioUrl}
			autoPlayAfterSrcChange={false}
			showSkipControls={false}
			showJumpControls={false}
			style={{ backgroundColor: colors.light, color: colors.main, borderRadius: 8 }}
			layout='horizontal-reverse'
		/>
	)
}

export default RecordingPlayer