import { NotificationDetails } from '@components/Notifications/NotificationDetails'
import Layout from '@containers/Layout'
import React from 'react'

export const NotificationDetailsPage = () => {
	return (
		<Layout>
			<NotificationDetails />
		</Layout>
	)
}