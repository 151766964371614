import { reduxActionType } from '@utils/reduxTypes'

const initialState = {
	password: '',
	passwordToSave: ''
}

export const masterPasswordReducer = (
	state = initialState,
	action: reduxActionType
): (typeof initialState) => {
	switch( action.type) {
	case 'SET_MASTER_PASSWORD': {
		return {
			...state,
			password: action.payload,
		}
	}
	case 'SET_PASSWORD_TO_SAVE': {
		return {
			...state,
			passwordToSave: action.payload,
		}
	}
	default: return state
	}
}
