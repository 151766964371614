import React from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import { styles } from './styles'
import { formatTimestamp, truncateDescription } from '@utils/utils'
import {useTranslation} from 'react-i18next'

export const NotificationToast = ({ notification, onPress }) => {
	const { t } = useTranslation()

	return (
		<TouchableOpacity onPress={() => onPress(notification)}>
			<View style={styles.notificationToast}>
				<View style={styles.content}>
					<View style={{flex: 1, justifyContent: 'space-between', flexDirection: 'row'}}>
						<Text style={styles.title}>{t(`notificationHistoryTypes.${notification.notificationType}`)}</Text>
						<Text style={styles.timestamp}>{formatTimestamp(new Date(notification.timestamp))}</Text>
					</View>
					<Text style={styles.description}>
						{truncateDescription(notification.content, 45)}
					</Text>
				</View>
			</View>
		</TouchableOpacity>
	)
}