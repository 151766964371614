import React, { FC, useState, useEffect, useRef } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Loader } from '@components/Loader/Loader'
import { axiosBFFServer } from '@services/connectionServer'
import { setUserInfo } from '@actions/userActions'
import { rootReducerType } from '@reducers/combineReducers'
import { ProtectedRouteProps, ViewTypes } from '@utils/interfaces'
import { isCompanyAdminOrUser, isPartnerOrAdvisor } from '@utils/utils'
import { subscriptionExpiredStatuses } from '@utils/constants'
import { SubscriptionExpired } from '@containers/SubscriptionExpired'
import { setIsPaymentNeeded } from '@actions/paymentActions'
import { setLoader } from '@actions/loaderActions'
import { UseWebSocket } from '@components/Common/UseWebsocket'
import { Id, toast } from 'react-toastify'
import { Image } from 'react-native'
import { addNotification, readNotificationHistory } from '@actions/notificationActions'
import { NotificationToast } from '@components/Notifications/NotificationToast'
import { readNotification } from '@services/apiService'

export const ProtectedRoute: FC<ProtectedRouteProps> = ({ children }) => {
	const [ isLoading, setIsLoading ] = useState(true)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { t } = useTranslation()
	const { pathname } = useLocation()
	const userKey = useSelector((state: rootReducerType) => state.userReducer.key)
	const userRoles = useSelector((state: rootReducerType) => state.userReducer.roles)
	const userEmail = useSelector((state: rootReducerType) => state.userReducer.email)
	const { isPaymentNeeded } = useSelector((state: rootReducerType) => state.paymentReducer)
	const currentCompanyStatus = useSelector((state: rootReducerType) => !isPartnerOrAdvisor(userRoles) ? state.companyReducer.currentCompany?.status : '')
	const currentSubscription = useSelector((state: rootReducerType) => !isPartnerOrAdvisor(userRoles) ? state.companyReducer.currentCompany.subscription : '')
	const language = useSelector((state: rootReducerType) => state.userReducer.language)
	const loader = useSelector((state: rootReducerType) => state.loaderReducer)
	const companyUserKey = useSelector((state: rootReducerType) => isCompanyAdminOrUser(userRoles) ? state.companyUserKeyReducer.key : state.legalPartnerKeyReducer.key)
	const toastIdRef = useRef<Id | null>(null)
	
	if(pathname.split('/')[1] === ViewTypes.PARTNER && !isPartnerOrAdvisor(userRoles)){
		navigate('/')
	}

	useEffect(() => {

		axiosBFFServer
			.get('/account')
			.then(response => {
				if(response.status === 200) {
					setIsLoading(false)
					!userKey && dispatch(setUserInfo(response.data))
				}
			})
			.catch(error => {
				setIsLoading(false)
				navigate('/login', { state: { errorMessage: t('errors.expiredToken')}})
			})
	}, [pathname])

	useEffect(() => {
		if (!isPartnerOrAdvisor(userRoles)) {
			if((subscriptionExpiredStatuses.includes(currentCompanyStatus) || (currentSubscription && currentSubscription.cancelled)) && !isPaymentNeeded) {
				dispatch(setIsPaymentNeeded(true))
			}
			dispatch(setLoader(false))
		}
	}, [currentCompanyStatus])

	const shouldDisplayModal = isPaymentNeeded && pathname.split('/')[1] !== 'check'
	const notificationRead = async (notificationKey: string) => {
		try {
			const toastId = toastIdRef.current
			if (toastId) {
				toast.dismiss(toastId)
			}
			await readNotification(notificationKey, companyUserKey)
			dispatch(readNotificationHistory(notificationKey))
		} catch (error) {
			console.error(error)
		}
	}

	const onMessage = (data: string) => {
		const parsedData = JSON.parse(data)
		if (parsedData.notificationHistoryKey) {
			dispatch(addNotification(parsedData))
			toastIdRef.current = toast.info(<NotificationToast notification={parsedData} onPress={() => notificationRead(parsedData.notificationHistoryKey)} />, {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				onClick: () => notificationRead(parsedData.notificationHistoryKey),
				pauseOnHover: true,
				draggable: true,
				icon: () => <Image
					source={require('@assets/Logo.webp')}
					style={{width: 25,
						height: 25,}}
				/>,
			})
		}
	}

	UseWebSocket({ onMessage, userEmail })

	return isLoading || loader.isLoading
		? <Loader />
		: <>
			{ shouldDisplayModal && <SubscriptionExpired /> }
			{children}
		</> || <Outlet />
}