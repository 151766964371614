import { colors, theme } from '@styles/constants'
import React from 'react'
import { Text, TouchableOpacity, StyleSheet } from 'react-native'

interface TabProps {
  label: string;
  active: boolean;
  onClick: () => void;
  disabled?: boolean;
}

export const Tab: React.FC<TabProps> = ({ label, active, onClick, disabled }) => {
	return (
		<TouchableOpacity
			style={[styles.tab, active && styles.activeTab, disabled && styles.disabled]}
			onPress={onClick}
			disabled={disabled}
		>
			<Text style={styles.tabText}>{label}</Text>
		</TouchableOpacity>
	)
}

const styles = StyleSheet.create({
	tab: {
		padding: 10,
		borderColor: 'transparent',
		borderWidth: 1,
		marginRight: 10,
	},
	activeTab: {
		backgroundColor: theme.light.colors.primary,
		borderBottomWidth: 1,
		borderBottomColor: theme.light.colors.primaryDark,
	},
	tabText: {
		color: theme.light.colors.titleColor,
		fontWeight: '500',
		fontSize: 16
	},
	disabled: {
		opacity: 0.5
	}
})
