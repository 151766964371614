import { Sorting } from '@utils/interfaces'
import { reduxActionType } from '@utils/reduxTypes'

export const setCompaniesSort = (payload: Sorting): reduxActionType => {
	return {
		type: 'SET_COMPANIES_SORTING',
		payload,
	}
}

export const setCasesSort = (payload: Sorting): reduxActionType => {
	return {
		type: 'SET_CASES_SORTING',
		payload,
	}
}
