import { colors, theme } from '@styles/constants'
import { button } from '@styles/mixins/button'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: colors.shadeBackground
	},
	content: {	
		backgroundColor: theme.light.colors.mainContainer,
		width: '40%',
		minWidth: 300,
		maxWidth: 600,
		gap: 24,
		justifyContent: 'space-between',
		padding: 32,
		borderRadius: 30,
		textAlign: 'justify',
	},
	text: {
		fontSize: 16,
	},
	icon: {
		color: colors.white
	},
	button: {
		...({
			...button(theme.light.colors.primaryDark).button,
		} as object),
		borderRadius: 6,
		paddingVertical: 10,
		paddingHorizontal: 20,
		textAlign: 'center',
		flexDirection: 'row',
		borderWidth: 0,
		gap: 8
	},
	buttonText: {
		color: 'white',
		fontWeight: 'bold',
	},
	cancelButton: {
		backgroundColor: theme.light.colors.dangerDark,
	},
	buttonsContainer: {
		flexDirection: 'row',
		gap: 8,
		justifyContent: 'flex-end'
	},
	buttonsContainer_mobile: {
		flexDirection: 'column',
		justifyContent: 'center',
		gap: 14,
		marginTop: 6
	}
})