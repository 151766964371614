import { theme } from '@styles/constants'
import { button } from '@styles/mixins/button'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
	container: {
		width: '100%'
	},
	button: {
		...({
			...button(theme.light.colors.warningDark).button,
		} as object),
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		paddingVertical: 8,
		paddingHorizontal: 16,
		gap: 8,
		borderRadius: 6,
		width: 240,
	},
	button_mobile: {
		width: 'auto',
	},
	buttonText: {
		fontWeight: '600',
		fontSize: 16
	},
	recordingItem: {
		flexDirection: 'row',
		alignItems: 'flex-start',
		marginTop: 10,
		width: 500,
		gap: 10
	},
	recordingItem_mobile: {
		flexDirection: 'row',
		alignItems: 'flex-start',
		marginTop: 10,
		gap: 10,
		width: 'auto'
	},
	troubleshoot: {
		fontSize: 12,
		fontStyle: 'italic'
	}
})