import React, { FC, useEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { Loader } from '@components/Loader/Loader'
import { axiosBFFServer } from '@services/connectionServer'
import { companyRoutes, partnerRoutes } from '@utils/router'
import { clearStorage, formatPublicKey, getISOStringFromDate, getUserAgent, isCompanyUserAdmin, isPartner, isPartnerOrAdvisor, objectHasKey } from '@utils/utils'
import { setUserInfo, setUserSettings, patchUserInfo, setUserLanguage } from '@actions/userActions'
import { LogEventTypes, SnackbarInitialState, ViewTypes } from '@utils/interfaces'
import { useDispatch } from 'react-redux'
import {setAdvisorKey, setAdvisors} from '@actions/advisorActions'
import { setLPSettings, setLegalPartner, setLegalPartnerKey, setLegalPartnerKeys } from '@actions/legalPartnerKeyActions'
import {setCompanyUserKey} from '@actions/companyUserActions'
import { getCompanyUserByEmail, getCurrentCompany, getCompanySettings, getPartnerSettings, getLegalPartner, getLegalPartnerByLink, getUserSettings, createLog } from '@services/apiService'
import { encryptValueWithAES, generateKeyFromAccountPassword, generateMasterPassword, generateRSAKeyPair } from '@utils/encryption'
import forge from 'node-forge'
import { StyleSheet, View } from 'react-native'
import { MasterPasswordModal } from '@components/MasterPasswordModal/MasterPasswordModal'
import { theme } from '@styles/constants'
import {setCompanyKey, setCompanySettings, setCurrentCompany} from '@actions/companyActions'
import { Snackbar } from '@components/Snackbar/Snackbar'
import { iv, snackbarInitialState } from '@utils/constants'
import { useTranslation } from 'react-i18next'
import i18n from '@assets/translation/i18n'
import { resetState } from '@actions/resetActions'

export const Authorize: FC = () => {
	const [ isLoading, setIsLoading ] = useState(true)
	const [ masterPasswordModal, setMasterPasswordModal ] = useState<{
        isVisible: boolean
        password?: string
        encodedPassword?: string
    }>({ isVisible: false })
	const [ keys, setKeys ] = useState<{
        publicKey: string
        privateKey: string
    }>()
	const [ navigateRoute, setNavigateRoute ] = useState('')
	const [ snackbar, setSnackbar ] = useState<SnackbarInitialState>(snackbarInitialState)

	const navigate = useNavigate()
	const dispatch = useDispatch()
	const location = useLocation()
	const { t } = useTranslation()
	
	const handleCompanyUser = async (account) => {
		const { roles, email } = account

		const currentCompany = await getCurrentCompany()
		const companyUser = await getCompanyUserByEmail(email,currentCompany?.data.companyKey)
		const currentUser = currentCompany?.data.users.find(user => user.email === email)
		const userSettingsResponse = await getUserSettings(companyUser?.data.companyUserKey)
		const firmSettingsResponse = await getCompanySettings(currentCompany?.data.companyKey)

		if (currentCompany?.data.advisors.length) {
			const legalPartner = await getLegalPartnerByLink(currentCompany?.data?.advisors?.[0]?.legalPartnerLink)
			dispatch(setLegalPartnerKeys({
				publicKey: formatPublicKey(legalPartner?.data.publicKey),
				encodedPrivateKey: legalPartner?.data?.encodedPrivateKey,
			}))
		} else {
			dispatch(setLegalPartnerKeys({
				publicKey: formatPublicKey(currentCompany?.data.publicKey),
				encodedPrivateKey: currentCompany?.data.encodedPrivateKey,
			}))
		}

		dispatch(setCurrentCompany(currentCompany?.data))
		dispatch(patchUserInfo({ phone: currentUser.phoneNumber, title: currentUser.title }))
		dispatch(setCompanyKey(currentCompany?.data?.companyKey))
		dispatch(setCompanyUserKey(companyUser?.data.companyUserKey))
		dispatch(setUserLanguage(userSettingsResponse?.data.language.toLocaleLowerCase()))
		i18n.changeLanguage(userSettingsResponse?.data.language.toLocaleLowerCase() || 'en')
		dispatch(setUserSettings(userSettingsResponse?.data.notificationSettings))
		dispatch(setCompanySettings({
			language: firmSettingsResponse?.data.language,
		}))

		// if it's admin's first login
		if (isCompanyUserAdmin(roles) && !objectHasKey(currentCompany?.data, 'encodedMasterPassword')) {
			generateKeyPairAndMP(email, currentCompany?.data?.companyKey)
		} else {
			setIsLoading(false)
		}

		const navigateRoute = `/${ViewTypes.COMPANY}/${companyRoutes[0].path}`
		setNavigateRoute(navigateRoute)
	}

	const handlePartnerOrAdvisor = async (account) => {
		const { email, roles } = account
		const advisorResponse = await axiosBFFServer.get(`/advisor/email/${email}`)
		const legalPartnerInfo = await getLegalPartner(advisorResponse.data.legalPartnerKey)
		const userSettingsResponse = await getUserSettings(advisorResponse.data.advisorKey)
		const firmSettingsResponse = await getPartnerSettings(advisorResponse.data.legalPartnerKey)

		dispatch(setUserLanguage(userSettingsResponse?.data.language.toLocaleLowerCase()))
		i18n.changeLanguage(userSettingsResponse?.data.language.toLocaleLowerCase() || 'en')
		dispatch(setUserSettings(userSettingsResponse?.data.notificationSettings))
		dispatch(setLegalPartner(legalPartnerInfo?.data))
		dispatch(patchUserInfo({ firstName: advisorResponse.data.firstName, lastName: advisorResponse.data.lastName, phone: advisorResponse?.data.phoneNumber, title: advisorResponse.data.title }))
		dispatch(setLegalPartnerKey(advisorResponse.data.legalPartnerKey))
		dispatch(setAdvisorKey(advisorResponse.data.advisorKey))
		dispatch(setAdvisors(legalPartnerInfo?.data?.advisors))
		dispatch(setLegalPartnerKeys({
			publicKey: formatPublicKey(legalPartnerInfo?.data?.publicKey),
			encodedPrivateKey: legalPartnerInfo?.data?.encodedPrivateKey,
		}))
		dispatch(setLPSettings({
			language: firmSettingsResponse?.data.language,
		}))

		// if it's partner's first login
		if (isPartner(roles) && !objectHasKey(legalPartnerInfo?.data, 'encodedMasterPassword')) {
			generateKeyPairAndMP(email, advisorResponse.data.legalPartnerKey)
		} else {
			setIsLoading(false)
		}

		const navigateRoute = `/${ViewTypes.PARTNER}/${partnerRoutes[0].path}`
		setNavigateRoute(navigateRoute)
	}

	const fetchUserData = async () => {
		try {
			const response = await axiosBFFServer.get('/account')
			if (response.status === 200) {
				const { roles } = response.data
				dispatch(setUserInfo(response.data))
	
				const hasPartnerView = isPartnerOrAdvisor(roles)
				if (hasPartnerView) {
					handlePartnerOrAdvisor(response.data)
				} else {
					handleCompanyUser(response.data)
				}
			}
		} catch (error) {
			navigate('/login')
		}
	}
	
	useEffect(() => {
		fetchUserData()
	}, [])

	const handleClose = () => {
		navigate('/check', { state: {
			masterPassword: masterPasswordModal?.password,
			encodedMasterPassword: masterPasswordModal?.encodedPassword,
			publicKey: keys?.publicKey,
			privateKey: keys?.privateKey,
			route: navigateRoute,
		}})
	}

	const generateKeyPairAndMP = async (email, key) => {
		if(!location.state?.userPassword) {
			navigate('/login', { state: { errorMessage: t('errors.login') } })
		} else {
			try {
				const keyPair = generateRSAKeyPair()
				setKeys({
					publicKey: keyPair.publicKey,
					privateKey: keyPair.privateKey,
				})
    
				const secretKey = forge.random.getBytesSync(32)
				const masterPassword = generateMasterPassword(secretKey)
				const encodedMasterPassword = encryptValueWithAES(generateKeyFromAccountPassword(location.state.userPassword), iv, masterPassword)
            
				setMasterPasswordModal({
					isVisible: true,
					password: masterPassword,
					encodedPassword: encodedMasterPassword,
				})
			} catch(error) {
				const userAgent = getUserAgent()
				const securityLogInfo = {
					companyKey: key,
					userEmail: email,
					userAgent,
					time: new Date().toISOString(),
					event: LogEventTypes.LOGOUT,
					failureReason: ''
				}
				clearStorage()
				dispatch(resetState())
				await createLog(securityLogInfo, true)
				navigate('/login', { state: { errorMessage: t('errors.updateCompanyKeys') } })
			}
		}
	}

	return (
		<View style={styles.container}>
			{isLoading
				? <Loader color={theme.light.colors.titleColor} /> :
				<Navigate to={navigateRoute} />
			}
			{(masterPasswordModal.isVisible && !!masterPasswordModal.password) && <MasterPasswordModal
				masterPassword={masterPasswordModal.password}
				isVisible={masterPasswordModal.isVisible}
				onClose={handleClose}
			/>}
			<Snackbar
				visible={snackbar.isVisible}
				onDismiss={() => setSnackbar(snackbarInitialState)}
				type={snackbar.type}
				message={snackbar.message}
			/>
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		backgroundColor: theme.light.colors.appContainer,
		height: '100%',
		justifyContent: 'center',
		alignItems: 'center',
	},
})
