import { useSelector } from 'react-redux'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import Icon from 'react-native-vector-icons/FontAwesome'
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css'
import 'react-calendar/dist/Calendar.css'
import './style.css'
import { rootReducerType } from '@reducers/combineReducers'
import { colors } from '@styles/constants'

export const DatePickerRange = ({ range, setRange }) => {
	const language = useSelector((state: rootReducerType) => state.userReducer.language)

	const now = new Date()
	const minDate = new Date(`${now.getFullYear() - 5}-01-01`)
	const maxDate = new Date(`${now.getFullYear() + 5}-12-31`)

	return (
		<DateRangePicker
			locale={language}
			clearIcon={null}
			calendarIcon={
				<Icon name='calendar' size={18} color={colors.black} />
			}
			format='dd/MM/yyyy'
			minDate={minDate}
			maxDate={maxDate}
			rangeDivider='-'
			maxDetail='month'
			minDetail='month'
			value={[range.startDate, range.endDate]}
			onChange={value => {
				value && setRange({
					startDate: value[0],
					endDate: value[1],
				})
			}}
		/>
	)
}
