import React, {useEffect, useState} from 'react'
import { View, Text, TouchableOpacity, Modal } from 'react-native'
import { PersonalInfo } from '../PersonalInfo/PersonalInfo'
import { useSelector } from 'react-redux'
import {rootReducerType} from '@reducers/combineReducers'
import { useTranslation } from 'react-i18next'
import {OverwriteSettings} from '../OverwriteSettings/OverwriteSettings'
import {SnackbarInitialState, UserNotification} from '@utils/interfaces'
import {updateUserSettings} from '@services/apiService'
import {useDispatch} from 'react-redux'
import {setUserSettings} from '@actions/userActions'
import { styles } from './style'
import ChangePasswordContainer from '@containers/ChangePassword'
import ChangeMasterPasswordContainer from '@containers/ChangeMasterPass'
import FontAwesome from 'react-native-vector-icons/FontAwesome'
import { isCompanyUserAdmin, isPartner, mapStringsToTypes } from '@utils/utils'

const MyAccount: React.FC<{ 
	isCompany?: boolean, 
	userNotifications: Array<UserNotification>, 
	setSnackbar: (snackbar: SnackbarInitialState) => void
}> = ({ isCompany, userNotifications, setSnackbar }) => {
	const user = useSelector((state: rootReducerType) => state.userReducer)
	const useKeyByRole = useSelector((state: rootReducerType) => isCompany ? state.companyUserKeyReducer.key : state.setAdvisorKeyReducer.key)
	const [isOpen, setIsOpen] = useState<boolean>(false)
	const [isOverwriteButtonEnabled, setIsOverwriteButtonEnabled] = useState<boolean>(false)
	const [updatedUserSettings, setUpdatedUserSettings] = useState<UserNotification[]>(null)
	const [isOpenChangePasswordModal, setIsOpenChangePasswordModal] = useState(false)
	const [isOpenChangeMasterPasswordModal, setIsOpenChangeMasterPasswordModal] = useState(false)
	const dispatch = useDispatch()

	useEffect(() => {
		const isEnabled = JSON.stringify(user.settings) !== JSON.stringify(updatedUserSettings)
		setIsOverwriteButtonEnabled(isEnabled)
	}, [user.settings, updatedUserSettings])

	useEffect(() => {
		setUpdatedUserSettings(user.settings)
	}, [user.settings])

	const openModal = () => {
		setIsOpen(true)
	}

	const { t } = useTranslation()

	const toggleNotification = (notificationType: string, communicationType: string) => {
		const updatedSettings = [...updatedUserSettings]

		const index = updatedSettings.findIndex((notification) => {
			return notification.notificationType === notificationType && notification.communicationType === communicationType
		})

		if (index === -1) {
			updatedSettings.push({
				notificationType: notificationType,
				communicationType: communicationType,
			})
		} else {
			updatedSettings.splice(index, 1)
		}

		setUpdatedUserSettings(updatedSettings)
	}

	const handleOverwriteSettings = async () => {
		const addedSettings = updatedUserSettings.filter((updatedSetting) => {
			return !user.settings.some((userSetting: UserNotification) =>
				userSetting.notificationType === updatedSetting.notificationType &&
			userSetting.communicationType === updatedSetting.communicationType
			)
		})
		
		const removedSettings = user.settings.filter((userSetting: UserNotification) => {
			return !updatedUserSettings.some((updatedSetting) =>
				userSetting.notificationType === updatedSetting.notificationType &&
			userSetting.communicationType === updatedSetting.communicationType
			)
		})
		
		const updatedSettings = [
			...user.settings.filter((userSetting: UserNotification) => {
				return !removedSettings.some((removedSetting: UserNotification) =>
					userSetting.notificationType === removedSetting.notificationType &&
				userSetting.communicationType === removedSetting.communicationType
				)
			}),
			...addedSettings,
		]

		const mappedNotificationSettings = mapStringsToTypes(t, updatedSettings)
		const response = await updateUserSettings(mappedNotificationSettings, useKeyByRole)

		if (response?.status === 200) {
			dispatch(setUserSettings(updatedSettings))
			setIsOpen(false)
		}
	}

	const handleChangePassword = () => {
		setIsOpenChangePasswordModal(true)
	}

	const handleChangeMasterPassword = () => {
		setIsOpenChangeMasterPasswordModal(true)
	}

	const closeModal = () => {
		setIsOpenChangePasswordModal(false)
		setIsOpenChangeMasterPasswordModal(false)
	}

	return (
		<View style={styles.myAccount}>
			<View style={styles.row}>
				<View style={styles.left}>
					<Text style={styles.title}>{ t('personalInformation') }</Text>
					<Text style={styles.description}>{ t('personalInfoDescription') }</Text>
				</View>
				<View style={styles.right}>
					<PersonalInfo firstName={user.firstName} lastName={user.lastName} email={user.email} phoneNumber={user.phone} title={user.title || ''} {...isCompany && { isCompany }} setSnackbar={setSnackbar} />
				</View>
			</View>

			<View style={styles.separator}></View>

			<View style={styles.row}>
				<View style={styles.left}>
					<Text style={styles.title}>{ t('password') }</Text>
					<Text style={styles.description}>{t('changePasswordDescription')}</Text>
				</View>
				<View style={styles.right}>
					<TouchableOpacity style={styles.button} onPress={handleChangePassword}>
						<FontAwesome name="user" color={'white'} size={15} />
						<Text style={styles.buttonText}>{ t('changePassword') }</Text> 
					</TouchableOpacity>
				</View>
			</View>

			{isCompanyUserAdmin(user.roles) || isPartner(user.roles) ?
				<><View style={styles.separator}></View><View style={styles.row}>
					<View style={styles.left}>
						<Text style={styles.title}>{t('masterPass')}</Text>
						<Text style={styles.description}>{t('changeMasterPasswordDescription')}</Text>
					</View>
					<View style={styles.right}>
						<TouchableOpacity style={styles.button} onPress={handleChangeMasterPassword}>
							<FontAwesome name="user" color={'white'} size={15} style={styles.iconMasterPass} />
							<Text style={styles.buttonText}>{t('changeMasterPasswordBttn')}</Text>
						</TouchableOpacity>
					</View>
				</View></>
				: <></>
			}

			<View style={styles.separator}></View>

			<View style={styles.row}>
				<View style={styles.left}>
					<Text style={styles.title}>{t('notificationsTitle')}</Text>
					<Text style={styles.description}>{t('notificationsSubtitle')}</Text>
				</View>
				<View style={styles.right}>
					<Text style={styles.title}>{t('notificationsOverwrite')}</Text>
					<Text style={styles.description}>
						{t('notificationsSettings')}
					</Text>
					<TouchableOpacity style={styles.button} onPress={openModal}>
						<Text style={styles.buttonText}>{t('editSettings')}</Text>
					</TouchableOpacity>
				</View>
				<OverwriteSettings
					isOpen={isOpen}
					onClose={() => setIsOpen(false)}
					notifications={userNotifications}
					onToggleNotification={toggleNotification}
					isButtonEnabled={isOverwriteButtonEnabled}
					checkedNotifications={updatedUserSettings}
					handleOverwriteSettings={handleOverwriteSettings} />
			</View>

			<Modal visible={isOpenChangePasswordModal ? isOpenChangePasswordModal : isOpenChangeMasterPasswordModal} animationType='fade' transparent={true}>
				{isOpenChangePasswordModal ? 
					(
						<View style={styles.modalContainer}>
							<ChangePasswordContainer setSnackbar={setSnackbar} onClose={closeModal} />
						</View>
					)
					: (
						<View style={styles.modalContainer}>
							<ChangeMasterPasswordContainer onClose={closeModal} />
						</View>
					)
				}
				
			</Modal>


		</View>
	)
}



export default MyAccount