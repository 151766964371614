import { colors, theme } from '@styles/constants'
import { button, buttonText } from '@styles/mixins/button'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		padding: 30,
		backgroundColor: theme.light.colors.appContainer,
	},
	contentContainer: {
		width: '97%',
		maxWidth: 400,
		paddingHorizontal: 20,
		paddingVertical: 40,
		borderRadius: 10,
		backgroundColor: theme.light.colors.mainContainer,
		shadowColor: colors.black,
		shadowOffset: { width: 0, height: 4 },
		shadowOpacity: 0.1,
		shadowRadius: 4,
		elevation: 4,
		alignItems: 'center',
	},
	title: {
		fontSize: 24,
		fontWeight: 'bold',
		marginBottom: 8,
		color: theme.light.colors.titleColor,
	},
	formContainer: {
		alignSelf: 'stretch',
		padding: 16,
		gap: 16,
	},
	label: {
		fontSize: 16,
	},
	field: {
		gap: 8,
	},
	errorText: {
		color: colors.error,
		fontSize: 12,
	},
	submitButton: {
		...({
			...button(theme.light.colors.buttonLg).button,
		} as object),
		borderRadius: 6,
		paddingVertical: 12,
		justifyContent: 'center',
		marginTop: 24,
		flexGrow: 1
	},
	submitButtonText: {
		...({
			...buttonText().buttonText,
		} as object),
		fontSize: 18,
		fontWeight: 'bold'
	}
})