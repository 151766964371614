import { StyleSheet } from 'react-native'
import { colors, theme } from '@styles/constants'
import { button, buttonText } from '@styles/mixins/button'

export const styles = StyleSheet.create({
	filtersContainer: {
		borderRadius: 5,
		justifyContent: 'center',
		paddingVertical: 20,
		paddingHorizontal: 20
	},
	categoryCounts: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignSelf: 'flex-end',
		height: 30,
		color: colors.black
	},
	headerContainer: {
		flex: 1,
		flexWrap: 'wrap',
		flexDirection: 'row',
		alignItems: 'center',
		gap: 5,
		zIndex: 999,
	},
	headerContainer_laptop: {
		flex: 1,
		flexWrap: 'wrap',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		gap: 5
	},
	headerContainer_tablet: {
		flex: 1,
		flexWrap: 'wrap',
		flexDirection: 'row',
		justifyContent: 'space-around',
		gap: 20,
		margin: 'auto',
		width: '50%',
	},
	buttonFilters: {
		height: 35,
		justifyContent: 'center',
		backgroundColor: theme.light.colors.primaryDark,
		textAlign: 'center',
		borderRadius: 4,
		paddingHorizontal: 15,
		shadowColor: theme.light.colors.mainColor,
		shadowOffset: { width: 0, height: 1 },
		shadowOpacity: 0.2,
		shadowRadius: 2,
		elevation: 3,
	},
	buttonFilters_tablet: {
		color: 'white',
		backgroundColor: theme.light.colors.primaryDark,
		fontWeight: 'bold',
		marginTop: 5,
		textAlign: 'center',
		alignSelf: 'flex-start',
		justifyContent: 'center',
		width: 80,
		height: 50,
		borderRadius: 4,
		marginBottom: 7,
	},
	buttonText: {
		color: 'white',
		fontWeight: 'bold'
	},
	actionButton: {
		display: 'flex',
		flexDirection: 'row',
		alignSelf: 'flex-end',
		marginLeft: 'auto',
		shadowColor: theme.light.colors.mainColor,
		shadowOffset: { width: 0, height: 1 },
		shadowOpacity: 0.2,
		shadowRadius: 2,
		elevation: 3,
		borderRadius: 6,
	},
	addCaseButton: {
		...({
			...button(theme.light.colors.successDark).button,
			...buttonText().buttonText,
		} as object),
		padding: 10
	},
	importCaseButton: {
		...({
			...button(theme.light.colors.primaryDark).button,
			...buttonText().buttonText,
		} as object),
		width: 150,
		paddingHorizontal: 0
	},
	optionsInput: {
		marginRight: 30,
		width: 220,
	},
	optionsInput_tablet: {
		marginRight: 30,
		width: 220,
		marginTop: 20
	},
	searchContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		borderWidth: 1,
		borderColor: theme.light.colors.boxBorder,
		borderRadius: 4,
		width: 220,
		height: 32
	},
	searchIcon: {
		padding: 5,
		marginRight: 8,
	},
	searchLabel: {
		marginBottom: '0px'
	},
	checkboxContainer: {
		flexDirection: 'row',
		gap: 10,
		padding: 15,
		paddingTop: 5,
	},
	buttonClear: {
		marginRight: 30,
		marginTop: 10,
	},
	buttonClearText: {
		color: theme.light.colors.linkColor,
		fontWeight: '500',
		fontSize: 16,
	},
	checkboxContainer_tablet: {
		flexDirection: 'row',
		flexWrap: 'wrap'
	},
	checkboxContainer_laptop: {
		flexDirection: 'row',
		flexWrap: 'wrap'
	},
	categoryTitle: {
		fontSize: 18
	},
	button: {
		backgroundColor: 'transparent',
	},
	filtersCheckboxContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: 5,
		marginTop: 10,
	},
	titleCheckboxContainer: {
		color: theme.light.colors.mainColor,
		paddingLeft: 20,
		paddingTop: 10,
		fontSize: 18,
		fontWeight: '500',
	},
	filtersHeader: {
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'row',
		borderBottomWidth: 1,
		borderBottomColor: theme.light.colors.boxBorder,
	},
	actionButtons: {
		display: 'flex',
		flexDirection: 'row',
		alignSelf: 'flex-end',
		marginLeft: 'auto',
		gap: 10,
	},
})
