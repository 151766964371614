import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, TouchableOpacity, View } from 'react-native'
import { Formik } from 'formik'
import { Input } from '@components/Input'
import { VerificationCodeMPFormValues } from '@utils/interfaces'
import { verificationCodeMPSchema } from '@utils/formValidation'
import { styles } from './style'

interface VerificationCodeMPFormProps {
	onChangeVerificationMPSubmit: (values: VerificationCodeMPFormValues) => void
}

const ValidationCodeMP: FC<VerificationCodeMPFormProps> = ({ onChangeVerificationMPSubmit }) => {

	const { t } = useTranslation()

	const initialValues = {
		verificationCode: ''
	}


	return (
		<View style={styles.container}>
			<Text style={styles.title}>{t('enterVerificationCode')}</Text>
			<Formik initialValues={initialValues} validationSchema={verificationCodeMPSchema} onSubmit={values => onChangeVerificationMPSubmit(values)}>
				{formikProps => (
					<View style={styles.formContainer}>
						<Input
							name='verificationCode'
							placeholder={t('verificationCode')}
							value={formikProps.values['verificationCode']}
							onChange={formikProps.setFieldValue}
							onBlur={() => formikProps.setFieldTouched('verificationCode', true)}
						/>
						{formikProps.touched['verificationCode'] && formikProps.errors['verificationCode'] && (
							<Text style={styles.errorText}>{`*${formikProps.errors['verificationCode']}`}</Text>
						)}
						<TouchableOpacity style={styles.button} onPress={() => formikProps.handleSubmit()}>
							<Text style={styles.buttonText}>{t('confirm')}</Text>
						</TouchableOpacity>
					</View>
				)}
			</Formik>
		</View>
	)
}

export default ValidationCodeMP