import { colors } from '@styles/constants'
import { snackbarInitialState } from '@utils/constants'
import { SnackbarInitialState, SnackbarTypes } from '@utils/interfaces'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TouchableOpacity, View } from 'react-native'
import { Text } from 'react-native-paper'
import FontAwesome from 'react-native-vector-icons/FontAwesome'
import { useNavigate } from 'react-router-dom'
import * as Clipboard from 'expo-clipboard'
import { axiosBFFServer } from '@services/connectionServer'
import { useSelector } from 'react-redux'
import { rootReducerType } from '@reducers/combineReducers'
import { Snackbar } from '@components/Snackbar/Snackbar'
import { styles } from './styles'
import { UseErrorMessages } from '@components/Common/UseErrorMessage'


export const Marketing = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { getErrorMessage } = UseErrorMessages()
	const legalPartnerKey = useSelector((state: rootReducerType) => state.legalPartnerKeyReducer.key)
	
	const [companyLink,setCompanyLink] = useState('')
	const [link,setLink] = useState('')
	const [ snackbar, setSnackbar ] = useState<SnackbarInitialState>(snackbarInitialState)

	const copyLinkToClipboard = async () => {
		await Clipboard.setStringAsync(companyLink)
		setSnackbar({
			isVisible: true,
			type: SnackbarTypes.INFO,
			message: t('copyLink')
		})
	}

	const getLink = () => {
		axiosBFFServer
			.get(`/partner/${legalPartnerKey}`, {})
			.then(response => {
				if (response.status === 200){
					const link = response.data.link
					setLink(response.data.link)
					setCompanyLink(`${process.env.REACT_NATIVE_APP_BASE_URL}/register/${link}`)
				} else setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: t('errors.companyLink')
				})
			
			})
			.catch(error => {
				const	errorMessage = getErrorMessage(error, t('errors.companyLink'))
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}

	useEffect(() => {
		getLink()
	},[])
	
	return (
		<View style={styles.container}>
			<View style={styles.content}>
				<Text style={styles.title}>{t('marketing')}</Text>
				<View style={styles.containerItem}>
					<View style={styles.header}>
						<Text style={styles.subTitle}>{t('marketingSubtitle')}</Text>
						<TouchableOpacity 
							style={styles.button}
							onPress={() => navigate(`/register/${link}`)}>
							<FontAwesome 
								name={'link'} 
								color={colors.white} 
								size={18} /> 
							<Text style={styles.buttonText}>{t('openLink')}</Text>
						</TouchableOpacity>
					</View>
					<Text style={styles.text}>{t('marketingText')}</Text>
					<View style={styles.sectionContent}>
						<Text selectable={true}>{companyLink}</Text>
						<View style={styles.linkButtonsContainer}>
							<TouchableOpacity style={styles.linkButton} onPress={copyLinkToClipboard}>
								<FontAwesome 
									name={'clipboard'} 
									color={colors.gray} 
									size={18} /> 
							</TouchableOpacity>
						</View>
					</View>
				</View>
			</View>
			<Snackbar
				visible={snackbar.isVisible}
				onDismiss={() => setSnackbar(snackbarInitialState)}
				type={snackbar.type}
				message={snackbar.message}
			/>
		</View>
	)
}
