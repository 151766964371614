import React, { useState } from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { colors, theme } from '@styles/constants'
import { useTranslation } from 'react-i18next'
import { ReportTypes, radioItem } from '@utils/interfaces'

interface Props {
	key: number,
	item: radioItem 
	checked: string
	setChecked: (value: ReportTypes) => void
	isCaseRelated?: boolean
}


const RadioButtons = (props: Props) => {
	const { t } = useTranslation()
	const [showMore, setShowMore] = useState(true)
	const radioPress = () => {
		props.setChecked(props?.item?.value)
	}

	return (
		<View style={styles.contentContainer}>
			<View style={styles.radioButtonContainer}>
				<TouchableOpacity  
					onPress={radioPress}>
					<View style={styles.radioContainer}>
						{
							props?.checked == props?.item?.value && (
								<View style={styles.radioComponent} />
							)
						}
					</View>
				</TouchableOpacity>
				<Text style={styles.label}>
					{props?.item?.label}
				</Text>
			</View>
			{!props.isCaseRelated &&
				<View style={styles.textContainer}>
					<Text style={styles.description}>
						{showMore ? props?.item?.description : props?.item?.descriptionLong}
					</Text>
					<TouchableOpacity 
						style={styles.button}
						onPress={() => setShowMore(!showMore)}>
						<Text style={styles.buttonText}>{showMore ? t('showMore') : t('showLess')}</Text>
					</TouchableOpacity>
				</View>
			}
			
		</View>
	)
}

const styles = StyleSheet.create({
	contentContainer: {
		flexDirection: 'column',
		margin:5,
	},
	radioButtonContainer: {
		flexDirection: 'row',
		alignItems: 'center'
	},
	textContainer: {
		flexDirection: 'column',
		width: '90%',
		marginLeft: 12,
		marginTop: 6
	},
	label: {
		marginLeft: 5,
		fontWeight:'500',
		fontSize: 16
	},
	description: {
		marginLeft: 12,
		fontSize: 14
	},
	radioComponent: {
		height: 12,
		width: 12,
		borderRadius: 6,
		backgroundColor: theme.light.colors.titleColor,
	},
	radioContainer: {
		height: 20,
		width: 20,
		borderRadius: 12,
		borderWidth: 2,
		borderColor: colors.darkAccent,
		alignItems: 'center',
		justifyContent: 'center',
	},
	button: {
		paddingVertical: 10,
		paddingHorizontal: 5,
		textAlign: 'left',
		flexDirection: 'row',
		justifyContent: 'flex-end'
	},
	buttonText: {
		color: colors.darkAccent,
		fontSize: 16
	},
})

export default RadioButtons