import React, { useEffect, useState } from 'react'
import { View, Text, TouchableOpacity, Modal, Image } from 'react-native'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FormValues, LoginFormValues, SnackbarInitialState, SnackbarTypes, initialLoginFormValues, loginFields } from '@utils/interfaces'
import Form from '@components/Form/Form'
import { Loader } from '@components/Loader/Loader'
import { Snackbar } from '@components/Snackbar/Snackbar'
import { snackbarInitialState } from '@utils/constants'
import { theme } from '@styles/constants'
import { axiosBFFServer } from '@services/connectionServer'
import ForgotPasswordContainer from '../ForgotPassword'
import { styles } from './styles'
import { Crisp } from 'crisp-sdk-web'

const LoginContainer = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const location = useLocation()
	const [ snackbar, setSnackbar ] = useState<SnackbarInitialState>(snackbarInitialState)
	const [ isLoading, setIsLoading ] = useState(false)
	const [isPasswordForgotten, setIsPasswordForgotten] = useState(false)

	const handleLoginSubmit = (values: FormValues) => {
		const { email, password } = values as LoginFormValues
		setIsLoading(true)

		axiosBFFServer
			.post('/auth/advisor/login', {
				email,
				password
			})
			.then(response => {
				if(response.status === 201) {
					navigate('/', { state: { userPassword: password } })
					Crisp.session.reset(true)
				}
			})
			.catch(error => {
				setIsLoading(false)
				let errorMessage = t('errors.login')

				if (error.response.status === 401) {
					if (error.response.data.message === 'ACCOUNT.NOT.ACTIVE') {
						errorMessage = t('errors.inactiveAccount')
					} else {
						errorMessage = t('errors.incorrectCredentials')
					}

				}

				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}

	const handleForgotPassword = () => {
		setIsPasswordForgotten(true)
	}

	const closeForgotPasswordModal = () => {
		setIsPasswordForgotten(false)
	}

	useEffect(() => {
		!!location.state?.errorMessage && setSnackbar({
			isVisible: true,
			type: SnackbarTypes.ERROR,
			message: location.state.errorMessage
		})					
	}, [])
    
	return (
		<View style={styles.container}>
			{isLoading && <View style={{ position: 'absolute' }}>
				<Loader color={theme.light.colors.titleColor} />
			</View>}
			<View style={[
				styles.formContainer,
				{...(isLoading && {
					opacity: 0,
				})}
			]}>
				<Image
					source={require('@assets/Logo.webp')}
					style={styles.logo}
				/>
				<Text style={styles.appName}>{t('appName')}</Text>
				<Text style={styles.title}>{t('signInToYourAccount')}</Text>
				<Form
					formType="login"
					initialValues={initialLoginFormValues}
					onSubmit={handleLoginSubmit}
					submitButtonText={t('signIn')}
					fields={loginFields}
				/>
				<TouchableOpacity style={styles.forgotPasswordLink} onPress={handleForgotPassword}><Text style={styles.forgotPassword}>{ t('forgotPassword') }</Text></TouchableOpacity>
			</View>
			<Modal visible={isPasswordForgotten} animationType='fade' transparent={true}>
				<View style={styles.modalContainer}>
					<ForgotPasswordContainer onClose={closeForgotPasswordModal} setIsPasswordForgotten={setIsPasswordForgotten} />
				</View>
			</Modal>
			<Snackbar
				visible={snackbar.isVisible}
				onDismiss={() => setSnackbar(snackbarInitialState)}
				type={snackbar.type}
				message={snackbar.message}
			/>
		</View>
	)
}

export default LoginContainer