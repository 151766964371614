import { colors, theme } from '@styles/constants'
import { button, buttonText } from '@styles/mixins/button'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: colors.shadeBackground,
	},
	content: {
		backgroundColor: theme.light.colors.mainContainer,
		borderRadius:30,
		height: '80%',
		width: '50%',
		padding: 30,
		gap: 16,
		overflowY: 'auto'
	},
	content_smallLaptop: {
		backgroundColor: theme.light.colors.mainContainer,
		borderRadius:30,
		height: '70%',
		width: '60%',
		padding: 20,
		gap: 16,
		overflowY: 'auto'
	},
	content_smallDevice: {
		backgroundColor: theme.light.colors.mainContainer,
		borderRadius:30,
		height: '90%',
		width: '100%',
		padding: 20,
		gap: 16,
		overflowY: 'auto'
	},
	title: {
		fontSize: 18,
		fontWeight: 'bold',
		marginBottom: 6
	},
	textFieldContainer: {
		width: '90%',
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	formFieldWrapper: {
		width: '90%',
		gap: 6
	},
	formFieldWrapperSmall: {
		width: '49%',
		gap: 6
	},
	labelText: {
		fontSize: 16
	},
	contentText: {
		fontSize: 14
	},
	icon: {
		color: colors.white
	},
	textContainer: {
		paddingHorizontal: 20,
		paddingVertical: 30
	},
	button: {
		...({
			...button(theme.light.colors.primaryDark).button,
		} as object),
	},
	...buttonText(),
	footer: {
		flexDirection: 'row',
		gap: 8,
		justifyContent: 'flex-end'
	},
	footer_mobile: {
		flexDirection: 'column',
		justifyContent: 'center',
		gap: 14,
		marginTop: 6
	},
	checkBoxContainer:{
		width: '90%',
		flexDirection: 'row'
	},
	checkBoxContainerSmallScreen:{
		width: '90%',
		gap: 12
	},
	columnCheckItems:{
		width: '50%',
		gap: 8
	},
	columnCheckItemsSmallScreen:{
		width: '100%',
		gap: 8
	},
	previewAdvisor: {
		width: '90%',
		height: '50%',
		backgroundColor: colors.darkAccent,
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 4
	},
	advisorCard: {
		backgroundColor: colors.white,
		width: '90%',
		margin: 'auto',
		padding: 20,
		borderRadius: 4
	},
	labelInfo: {
		flexDirection: 'row',
		gap: 8
	},
	errorText: {
		color: colors.error,
		fontSize: 12
	},
	cancelButton: {
		...({
			...button(theme.light.colors.mainContainer).button,
		} as object),
	},
	cancelIcon: {
		color: theme.light.colors.mainColor
	},
	cancelButtonText: {
		color: theme.light.colors.mainColor,
		fontWeight: 'bold'
	}
})
