import { StyleSheet } from 'react-native'
import { colors, theme } from '@styles/constants'

export const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: colors.shadeBackground,
	},
	content: {
		backgroundColor: theme.light.colors.mainContainer,
		width: '40%',
		minWidth: 300,
		maxWidth: 600,
		gap: 24,
		justifyContent: 'space-between',
		padding: 32,
		borderRadius: 20,
		textAlign: 'justify',
	},
	title: {
		fontSize: 18,
		fontWeight: 'bold',
		marginBottom: 5,
	},
	button: {
		backgroundColor: theme.light.colors.mainColor,
		borderRadius: 6,
		paddingVertical: 10,
		paddingHorizontal: 20,
		textAlign: 'center',
		flexDirection: 'row',
		borderWidth: 0,
		gap: 8,
	},
	buttonText: {
		color: 'white',
		fontWeight: 'bold',
	},
	confirmButton: {
		backgroundColor: theme.light.colors.primaryDark,
	},
	buttonsContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginTop: 20,
	},
	disabled: {
		opacity: 0.5
	},
	paymentOption: {
		display: 'flex',
		flexDirection: 'row',
		gap: 10,
	},
	tooltip: {
		fontSize: 12,
		color: theme.light.colors.primaryDarkDimmed
	},
	paymentText: {
		display: 'flex',
		flexDirection: 'column',
		gap: 10
	}
})