import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { routes, partnerRoutes, companyRoutes, reportRoutes } from '@utils/router'
import { ProtectedRoute } from '@components/ProtectedRoute/ProtectedRoute'
import { Authorize } from '@pages/Authorize'
import { RouteType, ViewTypes } from '@utils/interfaces'

const AppRouter: React.FC = () => {
	const renderRoute = (route: RouteType, index: number, parentRoute = '') => {
		return (
			<Route
				key={parentRoute+index}
				path={route.path}
				element={
					route.protectedRoute
						? <ProtectedRoute>{route.element}</ProtectedRoute>
						: route.element
				}
			/>
		)
	}

	return (
		<Router>
			<Routes>
				{routes.map((route, index) => renderRoute(route, index))}
				<Route path={`/${ViewTypes.PARTNER}`}>
					{partnerRoutes.map((route, index) => renderRoute(route, index, ViewTypes.PARTNER))}
				</Route>
				<Route path={`/${ViewTypes.COMPANY}`}>
					{companyRoutes.map((route, index) => renderRoute(route, index, ViewTypes.COMPANY))}
				</Route>
				<Route path={`/${ViewTypes.REPORT}`}>
					{reportRoutes.map((route, index) => renderRoute(route, index, ViewTypes.REPORT))}
				</Route>
				<Route path="*" element={<Authorize />} />
			</Routes>
		</Router>
	)
}

export default AppRouter