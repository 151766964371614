import { colors } from '@styles/constants'
import React from 'react'
import { View, Text, TextInput, TouchableOpacity } from 'react-native'
import { useTranslation } from 'react-i18next'
import FontAwesome from 'react-native-vector-icons/FontAwesome'
import { Formik } from 'formik'
import { passwordSchema } from '@utils/formValidation'
import { styles } from './styles'
import {Snackbar} from '@components/Snackbar/Snackbar'
import {SnackbarTypes} from '@utils/interfaces'

interface ChangePasswordProps {
  onChangePasswordSubmit: (values: { oldPassword: string, password: string; confirmPassword: string; }) => void;
  isPasswordChanged: boolean;
  onClose: () => void;
}

const ChangePassword: React.FC<ChangePasswordProps> = ({
	onChangePasswordSubmit,
	isPasswordChanged,
	onClose,
}) => {
	const { t } = useTranslation()

	return (
		<View style={styles.changePassContainer}>
			<Formik
				initialValues={{
					oldPassword: '',
					password: '',
					confirmPassword: '',
				}}
				validationSchema={passwordSchema}
				onSubmit={values => onChangePasswordSubmit(values)}
			>
				{({ handleChange, handleBlur, handleSubmit, values, touched, errors }) => (
					<View style={styles.container}>
						<TouchableOpacity style={styles.closeButton} onPress={onClose}>
							<FontAwesome name="times" color={colors.darkShades} size={20} />
						</TouchableOpacity>
						<Text style={styles.title}>{t('changePassword')}</Text>
						<View style={styles.sendForm}>
							<View style={styles.form}>
								<View>
									<Text style={styles.label}>{t('oldPassword')}</Text>
									<TextInput
										style={styles.input}
										placeholder={t('oldPassword')}
										secureTextEntry
										onChangeText={handleChange('oldPassword')}
										onBlur={handleBlur('oldPassword')}
										value={values.oldPassword}
									/>
									{touched.oldPassword && errors.oldPassword && (
										<Text style={styles.validationMessage}>{`*${errors.oldPassword}`}</Text>
									)}
								</View>
								<View>
									<Text style={styles.label}>{t('newPassword')}</Text>
									<TextInput
										style={styles.input}
										placeholder={t('newPassword')}
										secureTextEntry
										onChangeText={handleChange('password')}
										onBlur={handleBlur('password')}
										value={values.password}
									/>
									{touched.password && errors.password && (
										<Text style={styles.validationMessage}>{`*${errors.password}`}</Text>
									)}
								</View>
								<View>
									<Text style={styles.label}>{t('confirmNewPassword')}</Text>
									<TextInput
										style={styles.input}
										placeholder={t('confirmNewPassword')}
										secureTextEntry
										onChangeText={handleChange('confirmPassword')}
										onBlur={handleBlur('confirmPassword')}
										value={values.confirmPassword}
									/>
									{touched.confirmPassword && errors.confirmPassword && (
										<Text style={styles.validationMessage}>{`*${errors.confirmPassword}`}</Text>
									)}
								</View>
								<TouchableOpacity
									style={styles.button}
									onPress={() => handleSubmit()}
								>
									<FontAwesome name="lock" color={colors.pureWhite} size={14} />
									<Text style={styles.btnText}>{t('changePassword')}</Text>
								</TouchableOpacity>
								{isPasswordChanged && (
									<Text style={styles.successMessage}>{t('changePasswordSuccess')}</Text>
								)}
							</View>
						</View>
					</View>
				)}
			</Formik>
		</View>
	)
}

export default ChangePassword