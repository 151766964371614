import { reduxActionType } from '@utils/reduxTypes'

export const setCurrentPage = (payload: string): reduxActionType => {
	return {
		type: 'SET_CURRENT_PAGE',
		payload,
	}
}

export const setIsExpanded = (payload: boolean): reduxActionType => {
	return {
		type: 'SET_EXPANDED',
		payload,
	}
}