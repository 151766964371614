import Layout from '@containers/Layout'
import React from 'react'
import { CaseDetails } from '@containers/CaseDetails/CaseDetails'

export const CaseDetailsPage = () => {
	return (
		<Layout>
			<CaseDetails />
		</Layout>
	)
}