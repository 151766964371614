import { reduxActionType } from '@utils/reduxTypes'

export const setLegalPartnerKey = (payload: string): reduxActionType => {
	return {
		type: 'SET_LP_KEY',
		payload,
	}
}

export const setLegalPartnerKeys = (payload: { publicKey: string, encodedPrivateKey: string }): reduxActionType => {
	return {
		type: 'SET_LP_KEYS',
		payload,
	}
}

export const setLegalPartner = (payload): reduxActionType => {
	return {
		type: 'SET_LP',
		payload,
	}
}

export const patchLegalPartner = (payload): reduxActionType => {
	return {
		type: 'PATCH_LEGAL_PARTNER',
		payload,
	}
}

export const setLPSettings = (payload): reduxActionType => {
	return {
		type: 'SET_LP_SETTINGS',
		payload,
	}
}
