import { StyleSheet } from 'react-native'
import { colors, theme } from '@styles/constants'
import { button, buttonText } from '@styles/mixins/button'

export const styles = StyleSheet.create({
	modalContainer: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: colors.shadeBackground,
	},
	container: {
		padding: 16,
		width: '50%',
		backgroundColor: theme.light.colors.mainContainer,
	},
	subtitle: {
		fontSize: 20,
		fontWeight: 'bold',
	},
	headerText: {
		fontSize: 18,
	},
	notificationList: {
		flexDirection: 'column',
	},
	tableHeader: {
		flexDirection: 'row',
		alignItems: 'center',
		marginBottom: 8,
		marginTop: 16,
		borderBottomWidth: 1,
		borderBottomColor: colors.lightGray
	},
	notificationTypeHeader: {
		flex: 1,
		fontSize: 16,
		fontWeight: '500',
		color: colors.gray,
		padding: 8,
	},
	communicationTypeHeader: {
		flex: 1,
		fontSize: 14,
		fontWeight: '500',
		color: colors.gray,
		padding: 8,
		textAlign: 'center'
	},
	notificationItem: {
		flexDirection: 'row',
		alignItems: 'center',
		marginBottom: 8,
	},
	notificationName: {
		fontSize: 16,
		flex: 1,
		padding: 8,
	},
	communicationTypes: {
		flexDirection: 'row',
		justifyContent: 'center',
		flex: 1,
		textAlign: 'center',
		padding: 8
	},
	table: {
		backgroundColor: colors.pureWhite,
		marginBottom: 16,
	},
	buttonContainer: {
		flexDirection: 'row',
		justifyContent: 'flex-end',
		width: '50%',
		padding: 15,
		backgroundColor: colors.pureWhite,
	},
	cancelButton: {
		color: theme.light.colors.mainColor,
		fontSize: 16,
		fontWeight: 'bold',
		paddingVertical: 8,
		paddingHorizontal: 10,
		textAlign: 'center',
		borderRadius: 5,
		overflow: 'hidden',
		marginRight: 10,
	},
	overwriteButton: {
		...({
			...button(theme.light.colors.primaryDark).button,
			...buttonText().buttonText
		} as object),
		fontSize: 16,
		fontWeight: 'bold',
		paddingVertical: 8,
		paddingHorizontal: 10,
		textAlign: 'center',
		overflow: 'hidden',
	},
})
