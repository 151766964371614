import Layout from '@containers/Layout'
import ValidationCodeContainer from '@containers/ValidationCodeMP/ValidationCodeMP'
import React from 'react'

export const VerifyCodePage = () => {
	return (
		<Layout>
			<ValidationCodeContainer />
		</Layout>
	)
}