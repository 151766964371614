import * as Yup from 'yup'
import i18n from '@assets/translation/i18n'

export const loginSchema = Yup.object().shape({
	email: Yup.string()
		.email(i18n.t('formValidation.invalidInput', { inputName: i18n.t('fieldLabels.email') }))
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.email') })),
	password: Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.password') }))
		.min(6, i18n.t('formValidation.invalidPassword')),
})
  
export const registerCompanySchema = Yup.object().shape({
	firstName: Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.firstName') })),
	lastName: Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.lastName') })),
	email: Yup.string()
		.email(i18n.t('formValidation.invalidInput', { inputName: i18n.t('fieldLabels.email') }))
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.email') })),
	name: Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.companyName') })),
	phonePrefix: Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.phonePrefix') })),
	phoneNumber: Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.phoneNumber') }))
		.min(9, i18n.t('formValidation.invalidInput', { inputName: i18n.t('fieldLabels.phoneNumber') }))
		.max(9, i18n.t('formValidation.invalidInput', { inputName: i18n.t('fieldLabels.phoneNumber') })),
	language: Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.language') })),
	nrOfEmployees: Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.numberOfEmployees') })),
	street: Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.street') })),
	city: Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.city') })),
	zipCode: Yup.string()
		.min(6, i18n.t('formValidation.invalidInput', { inputName: i18n.t('fieldLabels.zipCode') }))
		.max(6, i18n.t('formValidation.invalidInput', { inputName: i18n.t('fieldLabels.zipCode') })),
	vatNr: Yup.string()
		.matches(/^(ro)?.{2,10}$/i, {
			message: i18n.t('formValidation.invalidVATNumber')
		})
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.vatNr') })),
	isStateOwned: Yup.boolean()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.isStateOwned') })),
})

export const createCompanySchema = Yup.object().shape({
	advisors: Yup.array()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.advisors') }))
})

export const editCompanySchema = Yup.object().shape({
	name: Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.companyName') })),
	advisorsKeys: Yup.array()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.advisors') })),
	nrOfEmployees: Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.numberOfEmployees') })),
	phonePrefix: Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.phonePrefix') })),
	phoneNumber: Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.phoneNumber') }))
		.min(9, i18n.t('formValidation.invalidInput', { inputName: i18n.t('fieldLabels.phoneNumber') }))
		.max(9, i18n.t('formValidation.invalidInput', { inputName: i18n.t('fieldLabels.phoneNumber') })),
	street: Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.street') })),
	city: Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.city') })),
	zipCode: Yup.string()
		.min(6, i18n.t('formValidation.invalidInput', { inputName: i18n.t('fieldLabels.zipCode') }))
		.max(6, i18n.t('formValidation.invalidInput', { inputName: i18n.t('fieldLabels.zipCode') })),
	vatNr: Yup.string()
		.matches(/^(ro)?.{2,10}$/i, {
			message: i18n.t('formValidation.invalidVATNumber')
		})
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.vatNr') })),
})

export const categorySchema = Yup.object().shape({
	value: Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.name') })),
	isActive: Yup.boolean()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.active') })),
})

export const passwordSchema = Yup.object().shape({
	oldPassword: Yup.string()
		.min(6, i18n.t('formValidation.invalidPassword'))
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('oldPassword') })),
	password: Yup.string()
		.min(6, i18n.t('formValidation.invalidPassword'))
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('newPassword') })),
	confirmPassword: Yup.string()
		.oneOf([Yup.ref('password'), ''], i18n.t('formValidation.matchPasswords'))
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('confirmNewPassword') })),
})

export const masterPasswordSchema = Yup.object().shape({
	accountPassword: Yup.string()
		.min(6, i18n.t('formValidation.invalidPassword'))
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('accountPassword') }))
})

export const verificationCodeMPSchema = Yup.object().shape({
	verificationCode: Yup.string()
		.min(6, i18n.t('formValidation.invalidPassword'))
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('verificationCode') }))
})


export const personalInfoSchema = Yup.object().shape({
	title: Yup.string(),
	firstName: Yup.string(),
	lastName: Yup.string(),
	email: Yup.string()
		.email(i18n.t('formValidation.invalidInput', { inputName: i18n.t('fieldLabels.email') }))
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.email') })),
	phoneNumber: Yup.string().matches(/^\+40[0-9]{9}$/, {
		message: i18n.t('formValidation.invalidInput', { inputName: i18n.t('fieldLabels.phoneNumber') }),
	})
})

export const editCompanyUserSchema = Yup.object().shape({
	firstName: Yup.string()
		.matches(/^[aA-zZ\s]+$/,  i18n.t('formValidation.invalidInput', { inputName: i18n.t('fieldLabels.firstName') }))
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.firstName') })),
	lastName: Yup.string()
		.matches(/^[aA-zZ\s]+$/,  i18n.t('formValidation.invalidInput', { inputName: i18n.t('fieldLabels.lastName') }))
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.lastName') })),
	email: Yup.string()
		.email(i18n.t('formValidation.invalidInput', { inputName: i18n.t('fieldLabels.email') }))
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.email') })),
	phone: Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.phoneNumber') }))
		.min(9, i18n.t('formValidation.invalidInput', { inputName: i18n.t('fieldLabels.phoneNumber') }))
		.max(9, i18n.t('formValidation.invalidInput', { inputName: i18n.t('fieldLabels.phoneNumber') })),
})

export const createReportSchema =Yup.object().shape({
	category: Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.category') })),
	email: Yup.string()
		.email(i18n.t('formValidation.invalidInput', { inputName: i18n.t('fieldLabels.email') })),
	number: Yup.string()
		.min(9, i18n.t('formValidation.invalidInput', { inputName: i18n.t('fieldLabels.phoneNumber') }))
		.max(9, i18n.t('formValidation.invalidInput', { inputName: i18n.t('fieldLabels.phoneNumber') })),
})

export const createCaseSchema = Yup.object().shape({
	subject: Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.subject') })),	
	source: Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.source') })),
	description:  Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.description') })),
	category: Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.category') })),
	firstName: Yup.string(),
	lastName: Yup.string(),
	email: Yup.string(),
	phoneNo: Yup.string()
		.min(9, i18n.t('formValidation.invalidInput', { inputName: i18n.t('fieldLabels.phoneNumber') }))
		.max(9, i18n.t('formValidation.invalidInput', { inputName: i18n.t('fieldLabels.phoneNumber') })),
})

export const masterPassowdFormSchema = Yup.object().shape({
	password: Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.password') })),
})

export const passowdFormSchema = Yup.object().shape({
	password: Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.password') })),
})

export const loginClientSchema = Yup.object().shape({
	clientId: Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.clientId') })),
	clientSecret: Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.clientSecret') })),
})

export const createLegalPartnerSchema = Yup.object().shape({
	firstName: Yup.string()
		.matches(/^[aA-zZ\s]+$/,  i18n.t('formValidation.invalidInput', { inputName: i18n.t('fieldLabels.firstName') }))
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.firstName') })),
	lastName: Yup.string()
		.matches(/^[aA-zZ\s]+$/,  i18n.t('formValidation.invalidInput', { inputName: i18n.t('fieldLabels.lastName') }))
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.lastName') })),
	name: Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.name') })),
	email: Yup.string()
		.email(i18n.t('formValidation.invalidInput', { inputName: i18n.t('fieldLabels.email') }))
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.email') })),
	number: Yup.string()
		.matches(/^7[0-9]{8}$/, i18n.t('formValidation.invalidPhone'))
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.phoneNumber') })),
	street: Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.street') })),
	city: Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.city') })),
	zipCode: Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.zipCode') }))
		.min(6, i18n.t('formValidation.invalidInput', { inputName: i18n.t('fieldLabels.zipCode') }))
		.max(6, i18n.t('formValidation.invalidInput', { inputName: i18n.t('fieldLabels.zipCode') })),
	vatNr: Yup.string()
		.matches(/^(ro)?.{2,10}$/i, {
			message: i18n.t('formValidation.invalidVATNumber')
		})
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.vatNr') })),
	language: Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.language') })),
	isStateOwned: Yup.boolean()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.isStateOwned') })),
})

export const createMessageSchema =Yup.object().shape({
	message: Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.message') })),
})

export const createStateSchema = Yup.object().shape({
	status: Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.status') })),
	state: Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.state') })),
})

export const createAdvisorSchema = Yup.object().shape({
	firstName: Yup.string()
		.matches(/^[aA-zZ\s]+$/,  i18n.t('formValidation.invalidInput', { inputName: i18n.t('fieldLabels.firstName') }))
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.firstName') })),
	lastName: Yup.string()
		.matches(/^[aA-zZ\s]+$/,  i18n.t('formValidation.invalidInput', { inputName: i18n.t('fieldLabels.lastName') }))
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.lastName') })),
	email: Yup.string()
		.email(i18n.t('formValidation.invalidInput', { inputName: i18n.t('fieldLabels.email') }))
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.email') })),
	phone: Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.phoneNumber') }))
		.min(9, i18n.t('formValidation.invalidInput', { inputName: i18n.t('fieldLabels.phoneNumber') }))
		.max(9, i18n.t('formValidation.invalidInput', { inputName: i18n.t('fieldLabels.phoneNumber') })),
	title: Yup.string()
		.matches(/^[aA-zZ\s]+$/,  i18n.t('formValidation.invalidInput', { inputName: i18n.t('title') }))
})

export const updateCompanyInfo = Yup.object().shape({
	name: Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.companyName') })),
	vatNumber: Yup.string()
		.matches(/^(ro)?.{2,10}$/i, {
			message: i18n.t('formValidation.invalidVATNumber')
		})
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.vatNr') })),
	email: Yup.string()
		.email(i18n.t('formValidation.invalidInput', { inputName: i18n.t('fieldLabels.email') }))
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.email') })),
	phoneNumber: Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.phoneNumber') }))
		.matches(/^\+40[0-9]{9}$/, { message: i18n.t('formValidation.invalidInput', { inputName: i18n.t('fieldLabels.phoneNumber') }) }),
	city: Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.city') })),
	streetAddress: Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.street') })),
	zipCode: Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.zipCode') }))
		.min(6, i18n.t('formValidation.invalidInput', { inputName: i18n.t('fieldLabels.zipCode') }))
		.max(6, i18n.t('formValidation.invalidInput', { inputName: i18n.t('fieldLabels.zipCode') })),
})

export const firmSettingsSchema = Yup.object().shape({
	language: Yup.string()
		.required(i18n.t('formValidation.requiredInput', { inputName: i18n.t('fieldLabels.language') })),
})
