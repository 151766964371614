/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useScreenDimensions from '@components/Common/UseScreenDimensions'
import Table from '@components/Table/Table'
import Icon from 'react-native-vector-icons/FontAwesome'
import { CaseDocumentsColumns } from '@utils/constants'
import { CaseDetails, Documents, SnackbarInitialState, TableColumn, ViewTypes } from '@utils/interfaces'
import { caseDetailsInitialState, createCaseFields, iv, screenType, snackbarInitialState } from '@utils/constants'
import {useSelector} from 'react-redux'
import {rootReducerType} from '@reducers/combineReducers'
import {getCaseByKey} from '@services/apiService'
import { Messages } from '@components/Messages/Messages'
import { decryptValueWithAES } from '@utils/encryption'
import forge from 'node-forge'
import { decryptDocs, downloadFile, formatDateRo } from '@utils/utils'
import { Snackbar } from '@components/Snackbar/Snackbar'
import { styles } from './styles'

export const ViewReport = () => {
	const { t } = useTranslation() 
	const screenDimensions = useScreenDimensions()
	const isMobileScreen = screenDimensions.width < screenType.phone
	const isSmallScreen = screenDimensions.width < screenType.tablet
	const masterPassword = useSelector((state: rootReducerType) => state.masterPasswordReducer.passwordToSave)
	const [ files, setFiles ] = useState<Documents[]>([])
	const [secretKey, setSecretKey] = useState<string>('')
	const [caseKey, password] = masterPassword.split(':')
	const [snackbar, setSnackbar] = useState<SnackbarInitialState>(snackbarInitialState)
	const [caseDetails, setCaseDetails] = useState<CaseDetails>(caseDetailsInitialState)
	const navigate = useNavigate()
	const { reportLink } = useSelector((state: rootReducerType) => state.companyReducer.currentCompany)

	const getCaseDetails = () => {
		getCaseByKey(caseKey).then(async (response) => {
			const dataToDecrypt = createCaseFields.reduce((acc, key) => {
				if (Object.prototype.hasOwnProperty.call(response?.data, key)) {
					acc[key] = response?.data[key]
				}
				if (Object.prototype.hasOwnProperty.call(response?.data.whistleblower, key)) {
					acc[key] = response?.data.whistleblower[key]
				}
				return acc
			}, {})	
			const originalData = response?.data
			const decryptedPrivateKey = decryptValueWithAES(password, iv, originalData.whistleblower.encodedPrivateKey)
			const privateKeyObj = forge.pki.privateKeyFromPem(decryptedPrivateKey)
			const decryptedSecretKey = privateKeyObj.decrypt(forge.util.hexToBytes(originalData.caseEncryptionKey.whistleblowerEncryptedKey))
			setSecretKey(decryptedSecretKey)
			const decryptedResponse = decryptValueWithAES(decryptedSecretKey, iv, dataToDecrypt)
			setCaseDetails({
				...originalData,
				...decryptedResponse,
				status: originalData.caseState.state,
				category: originalData.category.value,
				handlers: [
					...originalData.advisors.map(({ advisorKey, email }) =>({ id: advisorKey, email })),
					...originalData.companyUsers.map(({ companyUserKey, email }) =>({ id: companyUserKey, email })),
				]
			})
			const decryptedDocs = await decryptDocs(response?.data.documents, decryptedSecretKey, iv)
			const decryptedFiles = decryptedDocs.map((item, index) => ({
				...item,
				id: (index + 1).toString(),
				fileName: item.fileName,
			}))
			setFiles(decryptedFiles)
		})
	}

	useEffect(() => {
		if (caseKey) {
			getCaseDetails()
		} else {
			navigate(`/${ViewTypes.REPORT}/${reportLink}`)
		}
	}, [])

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const renderTableCell = (column: TableColumn, item: any) => {
		switch (column.title){
		case 'Documents':
			return(
				<Text onPress={() => downloadFile(item.file)}>
					{item.fileName}
				</Text>)
		default: 
			return (
				<Text style={[styles.dataText, isSmallScreen && styles.smallScreenDataText]}>{item[column.key]}</Text>
			)
		}
	}

	const renderAssignedUsers = () => {
		return (
			caseDetails?.handlers?.length
				? <View style={styles.caseValue}>
					{caseDetails.handlers.map(handler => <Text key={handler.id}>{handler.email}</Text>)}
				</View>
				: <Text style={styles.caseValue}>{t('noAssignedhandlers')}</Text>
							
		)
	}

	const dataRenderLeft = 	[
		{ caseDetails: 'ID', caseDetailsValues: caseDetails && caseDetails.tag },
		{ caseDetails: t('companyName'), caseDetailsValues: caseDetails && caseDetails.companyName },
		{ caseDetails: t('firstName'), caseDetailsValues: caseDetails && caseDetails?.firstName || '' },
		{ caseDetails: t('lastName'), caseDetailsValues: caseDetails && caseDetails?.lastName || '' },
		{ caseDetails: t('email'), caseDetailsValues: caseDetails && caseDetails?.email || '' },
		{ caseDetails: t('fieldLabels.phoneNumber'), caseDetailsValues: caseDetails && caseDetails?.phoneNumber || '' },
	]
    
	const dataRenderRight = [
		{ caseDetails: t('subject'), caseDetailsValues: caseDetails && caseDetails.subject },
		{ caseDetails: t('category'), caseDetailsValues: caseDetails && caseDetails.category },
		{ caseDetails: t('status'), caseDetailsValues: caseDetails && caseDetails.status },
		{caseDetails: t('deadline'), caseDetailsValues: caseDetails && formatDateRo(caseDetails.deadline) },
		{ caseDetails: t('handlers'), caseDetailsValues: renderAssignedUsers() }

	]

	const dataRenderForMobile = [ ...dataRenderLeft, ...dataRenderRight ]

	return (
		<View style={isMobileScreen ? styles.container_mobile : styles.container}>
			<View style={styles.content}>
				<View style={styles.header}>
					<TouchableOpacity 
						style={styles.backButton}
						onPress={() => navigate(`/${ViewTypes.REPORT}/${reportLink}`)}>
						<Icon style={styles.icon} name="arrow-left" size={18} />
						<Text style={styles.backButtonText}>{t('back')}</Text>
					</TouchableOpacity>
					<Text style={[styles.title, styles.pageTitle]}>{t('caseDetails')}</Text>
				</View>
	
				{isMobileScreen ? 
					<View style={styles.detailsContainerMobile}>
						<View style={styles.detailsColumns}>
							<View style={styles.column}>
								{dataRenderForMobile.map((entry, index) => (
									<View key={index} style={styles.row}>
										<Text style={styles.caseLabel}>{entry.caseDetails}:</Text>
										<Text style={styles.caseValue}>{entry.caseDetailsValues}</Text>
									</View>
								))}
							</View>
						</View>
						<View style={styles.row}>
							<Text style={styles.caseLabel}>{t('description')}:</Text>
							<Text style={[styles.caseValue, styles.descriptionValue]}>{caseDetails?.description}</Text>
						</View>
					</View>
					:
					<View style={styles.tableContainer}>
						<View style={styles.detailsContainer}>
							<View style={styles.detailsColumns}>
								<View style={styles.column}>
									{dataRenderLeft.map((entry, index) => (
										<View key={index} style={styles.row}>
											<Text style={styles.caseLabel}>{entry.caseDetails}:</Text>
											<Text style={styles.caseValue}>{entry.caseDetailsValues}</Text>
										</View>
									))}
								</View>
								<View style={styles.column}>
									{dataRenderRight.map((entry, index) => (
										<View key={index} style={styles.row}>
											<Text style={styles.caseLabel}>{entry.caseDetails}:</Text>
											<Text style={styles.caseValue}>{entry.caseDetailsValues}</Text>
										</View>
									))}
								</View>
							</View>
							<View style={styles.row}>
								<Text style={styles.caseLabel}>{t('description')}:</Text>
								<Text style={[styles.caseValue, styles.descriptionValue]}>{caseDetails?.description}</Text>
							</View>
						</View>
					</View>
				}
				<View style={isMobileScreen ? styles.columnsContainer_mobile : styles.columnsContainer}>
					<View style={styles.columnLeft}>
						{secretKey && caseKey && <Messages
							isLargeComponent={true}
							caseKey={caseKey}
							setSnackbar={setSnackbar}
							secretKey={secretKey}
							getCaseDocs={getCaseDetails}
						/>}
					</View>
					<View style={isMobileScreen ? styles.columnRight_mobile : styles.columnRight}>
						<Table
							data={files}
							columns={CaseDocumentsColumns}
							isSmallScreen={isSmallScreen && isMobileScreen}
							renderTableCell={renderTableCell}
							hasPagination={false}
						/>
					</View>
				</View>
				<Snackbar
					visible={snackbar.isVisible}
					onDismiss={() => setSnackbar(snackbarInitialState)}
					type={snackbar.type}
					message={snackbar.message}
				/>
			</View>
		</View>
	)
}

