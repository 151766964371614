import React, {FC, useEffect, useState} from 'react'
import { View, TouchableOpacity, Text, Image } from 'react-native'
import Icon from 'react-native-vector-icons/Feather'
import { theme } from '@styles/constants'
import {LanguageSelector} from '@components/LanguageSelector'
import { styles } from './styles'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { rootReducerType } from '@reducers/combineReducers'
import { NotificationBell } from '@components/Notifications/NotificationsBell'
import { isCompanyAdminOrUser } from '@utils/utils'
import { getNotificationHistory } from '@services/apiService'
import { useDispatch } from 'react-redux'
import { setNotifications } from '@actions/notificationActions'
import { Notification } from '@utils/interfaces'

interface HeaderProps {
	isOpen: boolean,
	toggleMenu: () => void
    headerHeight: number
}

const Header: FC<HeaderProps> = ({ isOpen, toggleMenu, headerHeight }) => {
	const { t } = useTranslation()
	const user = useSelector((state: rootReducerType) => state.userReducer)
	const { notifications } = useSelector((state: rootReducerType) => state.notificationReducer)
	const userKey = useSelector((state: rootReducerType) => isCompanyAdminOrUser(user.roles) ? state.companyUserKeyReducer.key : state.legalPartnerKeyReducer.key)
	const dispatch = useDispatch()

	const [unreadNotificationCount, setUnreadNotificationCount] = useState(notifications.filter((notification: Notification) => notification.isRead).length)
	
	const getNotifications = async () => {
		try {
			const notifications = await getNotificationHistory(userKey)
			dispatch(setNotifications(notifications.data))
			setUnreadNotificationCount(countUnreadNotifications(notifications.data))
		} catch (error) {
			console.error(error)
		}
	}

	const countUnreadNotifications = (notifications: Notification[]) => {
		return notifications.filter(notification => notification.isRead).length
	}

	useEffect(() => {
		getNotifications()
	}, [notifications.length])

	return (
		<View style={[styles.header, { height: headerHeight }]}>
			<View style={styles.container}>
				<TouchableOpacity onPress={toggleMenu}>
					<Icon name={isOpen ? 'x' : 'menu'} size={24} color={theme.light.colors.linkColor} />
				</TouchableOpacity>
				<Image
					source={require('@assets/Logo.webp')}
					style={styles.logo}
				/>
				<Text style={styles.headerText}>{t('appName')}</Text>
			</View>
			<View style={styles.container}>
				<NotificationBell notifications={notifications} unreadCount={unreadNotificationCount} />
				<Text style={styles.text}>
					{
						user.firstName ?
							`${t('hello')}, ${user.firstName} ${user.lastName}!` : ''
					}
				</Text>
				<LanguageSelector/>
			</View>
		</View>
	)
}
  
export default Header