import React from 'react'
import { ActivityIndicator, View, StyleSheet } from 'react-native'
import { colors, theme } from '@styles/constants'

interface Props {
	color?: string
	size?: 'small' | 'large' | number
}

export const Loader = (props: Props) => {
	return (
		<View style={styles.container}>
			<ActivityIndicator size={props.size || 'large'} color={props.color ? props.color : theme.light.colors.titleColor} />
		</View>
	)
}

export const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: 'center',
	}
})
