import Layout from '@containers/Layout'
import React from 'react'
import { CaseNew } from '@containers/CaseNew/CaseNew'

export const CaseNewPage = () => {
	return (
		<Layout>
			<CaseNew />
		</Layout>
	)
}