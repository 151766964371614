import React from 'react'
import { View, Text, Switch, Modal, TouchableOpacity } from 'react-native'
import { UserNotification } from '@utils/interfaces'
import { useTranslation } from 'react-i18next'
import {formatTitleCaseString} from '@utils/utils'
import { styles } from './styles'
import { theme } from '@styles/constants'
  
interface OverwriteSettingsProps {
	notifications: UserNotification[];
	onToggleNotification: (type: string, communication: string) => void;
	isOpen: boolean;
	onClose: () => void;
	isButtonEnabled: boolean;
	checkedNotifications: UserNotification[];
	handleOverwriteSettings: () => void;
}

export const OverwriteSettings: React.FC<OverwriteSettingsProps> = ({ isOpen, onClose, notifications, onToggleNotification, isButtonEnabled, checkedNotifications, handleOverwriteSettings }) => {
	const { t } = useTranslation()

	const groupedNotifications = notifications.reduce((acc, notification) => {
		if (!acc[notification.notificationType]) {
			acc[notification.notificationType] = []
		}
		acc[notification.notificationType].push(notification)
		return acc
	}, {})

	const notificationTypes = Object.keys(groupedNotifications)

	const isNotificationSelected = (
		notificationType: string,
		communicationType: string,
		checkedNotifications: { notificationType: string; communicationType: string }[]
	): boolean => {
		return checkedNotifications ? checkedNotifications.some(
			(notification) =>
				notification.notificationType === notificationType &&
		notification.communicationType === communicationType
		) : false
	}
	
	return (
		<Modal
			animationType="fade"
			visible={isOpen}
			onRequestClose={onClose}
			transparent={true}
		>
			<View style={styles.modalContainer}>
				<View style={styles.container}>
					<Text style={styles.subtitle}>{t('notificationsHandle')}</Text>
					<View style={styles.notificationList}>
						<View style={styles.table}>
							<View style={styles.tableHeader}>
								<Text style={styles.notificationTypeHeader}>{t('notificationType')}</Text>
								<Text style={styles.communicationTypeHeader}>SYSTEM</Text>
								<Text style={styles.communicationTypeHeader}>EMAIL</Text>
								<Text style={styles.communicationTypeHeader}>SMS</Text>
							</View>
							{notificationTypes.map((notificationType) => (
								<View key={notificationType} style={styles.notificationItem}>
									<Text style={styles.notificationName}>{formatTitleCaseString(notificationType)}</Text>
									<View style={styles.communicationTypes}>
										<Switch
											value={isNotificationSelected(notificationType, 'SYSTEM', checkedNotifications)}
											trackColor={{ false: theme.light.colors.moreListShadow, true: theme.light.colors.primaryDarkDimmed }}
											thumbColor={theme.light.colors.primaryDark}
											activeThumbColor={theme.light.colors.primaryDark}
											onValueChange={() =>
												onToggleNotification(notificationType, 'SYSTEM')
											}
										/>
									</View>
									<View style={styles.communicationTypes}>
										<Switch
											value={isNotificationSelected(notificationType, 'EMAIL', checkedNotifications)}
											trackColor={{ false: theme.light.colors.moreListShadow, true: theme.light.colors.primaryDarkDimmed }}
											thumbColor={theme.light.colors.primaryDark}
											activeThumbColor={theme.light.colors.primaryDark}
											onValueChange={() =>
												onToggleNotification(notificationType, 'EMAIL')
											}
										/>
									</View>
									<View style={styles.communicationTypes}>
										<Switch
											value={isNotificationSelected(notificationType, 'SMS', checkedNotifications)}
											trackColor={{ false: theme.light.colors.moreListShadow, true: theme.light.colors.primaryDarkDimmed }}
											thumbColor={theme.light.colors.primaryDark}
											activeThumbColor={theme.light.colors.primaryDark}
											onValueChange={() =>
												onToggleNotification(notificationType, 'SMS')
											}
										/>
									</View>
								</View>
							))}
						</View>
					</View>
				</View>
				<View style={styles.buttonContainer}>
					<TouchableOpacity onPress={onClose}>
						<Text style={styles.cancelButton}>{t('cancel')}</Text>
					</TouchableOpacity>
					<TouchableOpacity {...(!isButtonEnabled && { style: {opacity: 0.2} })} disabled={!isButtonEnabled} onPress={() => handleOverwriteSettings()}>
						<Text style={styles.overwriteButton}>{t('notificationsHandle')}</Text>
					</TouchableOpacity>
				</View>
			</View>
		</Modal>
	)
}


