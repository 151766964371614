import { colors, theme } from '@styles/constants'
import {buttonText} from '@styles/mixins/button'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
	field: {
		gap: 2,
	},
	fieldHeader: {
		display: 'flex',
		flexDirection: 'row',
		gap: 5
	},
	label: {
		fontSize: 16,
	},
	errorText: {
		color: colors.error,
		fontSize: 12,
	},
	dropdownDisabled: {
		flex: 1,
		width: '100%',
		backgroundColor: theme.light.colors.boxBorder,
		borderColor: theme.light.colors.mainColor,
		borderWidth: 1,
		borderRadius: 4,
		padding: 8,
		paddingTop: 6,
		paddingBottom: 10,
		maxHeight: 50
	},
	tooltip: {
		padding: 5,
		borderRadius: 50
	},
	validateButton: {
		justifyContent: 'center',
		shadowColor: theme.light.colors.mainColor,
		shadowOffset: { width: 0, height: 1 },
		shadowOpacity: 0.2,
		shadowRadius: 2,
		elevation: 3,
		borderRadius: 6,
		backgroundColor: theme.light.colors.primaryDark,
	},
	validateText: {
		textAlign: 'center',
		...({
			...buttonText().buttonText,
		} as object),
		paddingVertical: 10,
		paddingHorizontal: 20,
	},
	disabled: {
		opacity: 0.5,
	},
	validate: {
		flexDirection: 'row',
		gap: 20,
	},
})