import React from 'react'
import Layout from '@containers/Layout'
import { ReportingLink } from '@containers/ReportingLink/ReportingLink'

export const ReportingLinkPage = () => {
	return (
		<Layout>
			<ReportingLink />
		</Layout>
	)
}
