import { StyleSheet } from 'react-native'
import { colors, theme } from '@styles/constants'

export const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: colors.shadeBackground,
	},
	content: {
		backgroundColor: theme.light.colors.mainContainer,
		height: '80%',
		width: '50%',
		padding: 30,
		borderRadius: 30,
		gap: 16,
		overflowY: 'auto'
	},
	content_smallLaptop: {
		backgroundColor: theme.light.colors.mainContainer,
		height: '70%',
		width: '60%',
		padding: 20,
		borderRadius: 30,
		gap: 16,
		overflowY: 'auto'
	},
	content_smallDevice: {
		backgroundColor: theme.light.colors.mainContainer,
		height: '90%',
		width: '100%',
		padding: 20,
		borderRadius: 30,
		gap: 16,
		overflowY: 'auto'
	},
	title: {
		fontSize: 18,
		fontWeight: 'bold',
		marginBottom: 6
	},
	textFieldContainer: {
		width: '90%',
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	formFieldWrapper: {
		width: '90%',
		gap: 6
	},
	formFieldWrapperSmall: {
		width: '49%',
		gap: 6
	},
	labelText: {
		fontSize: 16
	},
	contentText: {
		fontSize: 14
	},
	icon: {
		color: colors.white
	},
	textContainer: {
		paddingHorizontal: 20,
		paddingVertical: 30
	},
	cancelButton: {
		borderRadius: 6,
		paddingVertical: 10,
		paddingHorizontal: 20,
		textAlign: 'center',
		flexDirection: 'row',
		gap: 8
	},
	cancelButtonText: {
		color: theme.light.colors.linkColor,
		fontWeight: '600'
	},
	button: {
		backgroundColor: theme.light.colors.primaryDark,
		borderRadius: 6,
		paddingVertical: 10,
		paddingHorizontal: 20,
		textAlign: 'center',
		flexDirection: 'row',
		gap: 8
	},
	buttonText: {
		color: theme.light.colors.linkColorActive,
		fontWeight: 'bold',
		lineHeight: 16
	},
	footer: {
		flexDirection: 'row',
		gap: 8,
		justifyContent: 'flex-end'
	},
	footer_mobile: {
		flexDirection: 'column',
		justifyContent: 'center',
		gap: 14,
		marginTop: 6
	},
	checkBoxContainer: {
		width: '100%',
		flexDirection: 'row',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		flexWrap: 'wrap'
	},
	checkBoxContanerSmall: {
		flexDirection: 'column'
	},
	checkElement: {
		width: '50%',
		marginBottom: 12
	},
	checkElementSmall: {
		width: '100%',
		marginBottom: 12
	},
	previewAdvisor: {
		width: '90%',
		height: '50%',
		backgroundColor: colors.darkAccent,
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 4
	},
	advisorCard: {
		backgroundColor: colors.white,
		width: '90%',
		margin: 'auto',
		padding: 20,
		borderRadius: 4
	},
	labelInfo: {
		flexDirection: 'row',
		gap: 8
	},
	errorText: {
		color: colors.error,
		fontSize: 12,
	}
})
