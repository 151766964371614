import { StyleSheet } from 'react-native'
import { colors, theme } from '@styles/constants'

export const styles = StyleSheet.create({
	notification: {
		backgroundColor: theme.light.colors.mainContainer,
		padding: 10,
		marginBottom: 10,
		borderRadius: 5,
		flexDirection: 'row',
		alignItems: 'center',
		shadowColor: theme.light.colors.mainColor,
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.2,
		shadowRadius: 2,
		elevation: 2,
	},
	content: {
		flex: 1,
	},
	title: {
		fontWeight: 'bold',
		marginBottom: 5,
	},
	description: {
		marginBottom: 5,
	},
	container: {
		flex: 1,
		flexDirection: 'column',
		padding: 30,
		gap: 20,
		borderRadius: 5,
		backgroundColor: theme.light.colors.mainContainer,
		shadowColor: theme.light.colors.mainColor,
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.2,
		shadowRadius: 2,
		elevation: 2,
		marginTop: 30
	},
	headerContainer: {
		flexDirection: 'row',
		gap: 30,
	},
	mainContainer: {
		flex: 1,
		flexDirection: 'row',
		gap: 20,
		alignItems: 'center',
		flexWrap: 'wrap',
		paddingTop: 10
	},
	headerText: {
		fontSize: 24,
		fontWeight: 'bold',
		color: theme.light.colors.mainColor,
		alignSelf: 'center'
	},
	backButton: {
		paddingVertical: 10,
		paddingHorizontal: 5,
		textAlign: 'left',
		flexDirection: 'row',
		alignItems: 'center',
		gap: 6,
	},
	backButtonText: {
		color: theme.light.colors.mainColor,
		fontWeight: 'bold',
		fontSize: 16
	},
	titleContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	unreadContainer: {
		position: 'relative',
	},
	bellContainer: {
		paddingHorizontal: 10,
		paddingVertical: 5,
	},
	dot: {
		position: 'absolute',
		top: 3,
		right: 10,
		backgroundColor:  colors.error,
		width: 10,
		height: 10,
		borderRadius: '50%',
		justifyContent: 'center',
		alignItems: 'center',
	},
	dotText: {
		color: theme.light.colors.mainContainer,
		fontWeight: 'bold',
		fontSize: 14,
	},
	notificationContainer: {
		backgroundColor: theme.light.colors.mainContainer,
		padding: 10,
		borderRadius: 5,
		borderWidth: 1,
		borderColor: theme.light.colors.boxBorder,
		zIndex: 100,
		width: 300,
		shadowColor: theme.light.colors.mainColor,
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.2,
		shadowRadius: 2,
		elevation: 2,
		maxHeight: 500,
		overflowY: 'scroll',
		scrollbarWidth: 'none'
	},
	expandedContainer: {
		position: 'absolute',
		top: 40,
		right: '-300%',
	},
	unreadNotification: {
		padding: 3,
		borderBottomColor: theme.light.colors.boxBorder,
		borderBottomWidth: 1,
	},
	notificationText: {
		fontWeight: 'bold',
	},
	closeText: {
		color: 'blue',
	},
	notificationItem: {
		display: 'flex',
		flexDirection: 'row',
		alignContent: 'center',
		gap: 10,
		padding: 5
	},
	notificationItemDot: {
		alignSelf: 'center',
		backgroundColor: colors.error,
		width: 8,
		height: 8,
		borderRadius: '50%',
	},
	bellDescription: {
		color: theme.light.colors.buttonLg,
		fontSize: 12,
		paddingBottom: 5
	},
	noNotifications: {
		color: colors.gray,
		fontWeight: 500,
		padding: 10,
		textAlign: 'center'
	},
	unreadDot: {
		backgroundColor: colors.error,
		width: 10,
		height: 10,
		borderRadius: 5,
		justifyContent: 'center',
		alignItems: 'center',
	},
	readNotification: {
		opacity: 0.7,
	},
	notificationToast: {
		padding: 10,
	},
	timestamp: {
		fontSize: 10,
		color: colors.gray,
		fontWeight: 500,
		alignSelf: 'center'
	},
	notificationIcon: {
		justifyContent: 'center',
		alignItems: 'center',
		borderRightWidth: 3,
		padding: 20,
		borderColor: colors.gray,
		opacity: 0.7
	},
	detailsContent: {
		width: '90%',
		justifyContent: 'center'
	},
	download: {
		flexDirection: 'row',
		gap: 10,
		alignItems: 'center',
		marginTop: 20,
		height: 35,
		width: 130,
		padding: 10,
		justifyContent: 'center',
		backgroundColor: theme.light.colors.primaryDark,
		textAlign: 'center',
		borderRadius: 4,
		paddingHorizontal: 15,
		shadowColor: theme.light.colors.mainColor,
		shadowOffset: { width: 0, height: 1 },
		shadowOpacity: 0.2,
		shadowRadius: 2,
		elevation: 3,
	},
	icon: {
		color: colors.gray
	},
	downloadText: {
		color: colors.white,
		fontWeight: '500',
		fontSize: 14
	}
})