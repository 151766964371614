import Layout from '@containers/Layout'
import React from 'react'
import { CaseImportForm } from '@containers/CaseImportForm'

export const CaseImportPage = () => {
	return (
		<Layout>
			<CaseImportForm />
		</Layout>
	)
}