import { colors, theme } from '@styles/constants'
import { button } from '@styles/mixins/button'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
	container: {
		height: '100%',
	},
	contentContainer: {
		flex: 1,
		padding: 30,
		gap: 20,
		width: '70%',
		margin: 'auto',
		backgroundColor: theme.light.colors.mainContainer,
		maxHeight: '65%',
		borderRadius: 30,
		justifyContent: 'space-between',
		shadowColor: theme.light.colors.mainColor,
		shadowOffset: { width: 0, height: 1 },
		shadowOpacity: 0.2,
		shadowRadius: 2,
		elevation: 3,
	},
	contentContainer_mobile: {
		width: '100%',
		maxHeight: 'auto'
	},
	textContainer: {
		flexDirection: 'row',
		gap: 6
	},
	textContainer_mobile: {
		flexDirection: 'column',
		gap: 6,
		textAlign: 'justify'
	},
	inputContainer: {
		flexDirection: 'row',
		gap: 15,
		justifyContent: 'space-between'
	},
	inputContainer_mobile: {
		flexDirection: 'column',
		gap: 15,
		justifyContent: 'space-between'
	},
	bttnsContainer: {
		flexDirection: 'row',
	},
	bttnsContainer_mobile: {
		flexDirection: 'row',
		gap: 6,
		alignItems: 'flex-end'
	},
	checkBoxContainer: {
		flexDirection: 'row',
		alignItems: 'center'
	},
	title: {
		fontSize: 18,
		color: theme.light.colors.titleColor,
		fontWeight: 'bold',
	},
	title_mobile: {
		fontSize: 18,
		color: theme.light.colors.titleColor,
		fontWeight: 'bold',
		textAlign: 'justify'
	},
	errorText: {
		fontSize: 16,
		color: colors.error,
		fontWeight: 'bold'
	},
	icon: {
		color: colors.white
	},
	formFieldText: {
		fontSize: 16,
		borderRadius: 4,
		borderWidth: 1,
		borderColor: colors.darkAccent,
		padding: 6,
		backgroundColor: colors.white,
		width: '100%',
		outlineStyle: 'none',
		color: theme.light.colors.mainColor,
	},
	labelText: {
		fontSize: 16,
		paddingLeft: 6
	},
	labelTextBold: {
		fontSize: 16,
		marginBottom: 6,
		fontWeight: '500'
	},
	button: {
		...({
			...button(theme.light.colors.successDark).button,
		} as object),
		borderRadius: 6,
		paddingVertical: 10,
		paddingHorizontal: 20,
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center'
	},
	downloadButton: {
		backgroundColor: theme.light.colors.dangerDark,
	},
	buttonSubmitt: {
		...({
			...button(theme.light.colors.primaryDark).button,
		} as object),
		borderRadius: 6,
		paddingVertical: 10,
		paddingHorizontal: 20,
		width: 140,
		flexDirection: 'row',
		gap: 6,
		justifyContent: 'center',
		alignItems: 'center',
		alignSelf: 'flex-end',
	},
	buttonText: {
		color: 'white',
		fontWeight: '600',
		fontSize: 16
	},
	sectionContainer: {
		gap: 5,
	}
})