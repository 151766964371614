import React from 'react'
import Layout from '@containers/Layout'
import { Categories } from '@containers/Categories/Categories'

export const CategoriesPage = () => {
	return (
		<Layout>
			<Categories />
		</Layout>
	)
}
