import { StyleSheet } from 'react-native'
import { colors, theme } from '@styles/constants'

export const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		padding: 20,
		backgroundColor: theme.light.colors.appContainer,
	},
	formContainer: {
		width: '60%',
		maxWidth: 800,
		paddingHorizontal: 20,
		paddingVertical: 40,
		borderRadius: 30,
		backgroundColor: theme.light.colors.mainContainer,
		shadowColor: colors.black,
		shadowOffset: { width: 0, height: 4 },
		shadowOpacity: 0.1,
		shadowRadius: 4,
		elevation: 4,
		alignItems: 'center',
	},
	formTitle: {
		fontSize: 24,
		fontWeight: 'bold',
		marginBottom: 20,
		color: theme.light.colors.titleColor,
	},
	appName: {
		fontSize: 20,
		fontWeight: '500',
		color: theme.light.colors.titleColor
	},
	logo: {
		width: 100,
		height: 100,
		borderRadius: 50,
		justifyContent: 'center',
		alignItems: 'center'
	},
	login: {
		alignSelf: 'flex-end',
		marginTop: 16,
		flexDirection: 'row',
	},
	loginText: {
		fontWeight: '500',
		color: theme.light.colors.titleColor,
	},
	loginLink: {
		textDecorationLine: 'underline',
	},
})
