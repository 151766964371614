import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, TouchableOpacity } from 'react-native'
import { utils, writeFile } from 'xlsx'
import { styles } from './styles'
import { decryptValueWithAES } from '@utils/encryption'
import { formatDateToCustomString, formatHeaderLabel, isPartnerOrAdvisor } from '@utils/utils'
import { useSelector } from 'react-redux'
import { rootReducerType } from '@reducers/combineReducers'
import { iv } from '@utils/constants'
import forge from 'node-forge'
import { FilterItems, SnackbarInitialState, SnackbarTypes } from '@utils/interfaces'
import { axiosBFFServer } from '@services/connectionServer'
import { Loader } from '@components/Loader/Loader'
import { theme } from '@styles/constants'
import { UseErrorMessages } from '@components/Common/UseErrorMessage'

interface ExcelExportProps {
	fileName: string;
	disabled: boolean;
	getFilterItems: () => FilterItems
	setSnackbar: (snackbar: SnackbarInitialState) => void
  }
  
export const ExcelExport = ({ fileName, disabled, getFilterItems, setSnackbar }: ExcelExportProps) => {
	const [isLoading, setIsLoading] = useState(false)
	const { t } = useTranslation()
	const { getErrorMessage } = UseErrorMessages()
	const { roles } = useSelector((state: rootReducerType) => state.userReducer)
	const masterPassword = useSelector((state: rootReducerType) => state.masterPasswordReducer.password)
	const { publicKey, encodedPrivateKey } = useSelector((state: rootReducerType) => isPartnerOrAdvisor(roles)
		? state.legalPartnerKeyReducer
		: state.companyReducer.currentCompany
	)

	const decryptedPrivateKey = masterPassword && decryptValueWithAES(masterPassword, iv, encodedPrivateKey)
	const privateKeyObj = decryptedPrivateKey && forge.pki.privateKeyFromPem(decryptedPrivateKey)

	const exportXlsx = (cases) => {
		const casesToExportData = cases && cases.pageResults.length > 0 ? cases.pageResults.map(({ tag, subject, description, category, companyName, severity, deadline, lastActivity, status, caseEncryptionKey, caseState }) => ({
			tag,
			subject,
			description,
			category,
			companyName,
			severity,
			deadline,
			lastActivity,
			status,
			caseEncryptionKey,
			caseState
		})) : []

		const decryptedData = casesToExportData.length > 0 ? casesToExportData.map((item) => {	
			const caseEncryptedKey = item.caseEncryptionKey[isPartnerOrAdvisor(roles) ? 'partnerEncryptedKey' : 'companyEncryptedKey']
				
			const decryptedSecretKey = privateKeyObj.decrypt(forge.util.hexToBytes(caseEncryptedKey))
			const decryptedSubject = item.subject ? decryptValueWithAES(decryptedSecretKey, iv, item.subject) : null
			const decryptedDescription = item.description ? decryptValueWithAES(decryptedSecretKey, iv, item.description) : null
			const categoryValue = item.category ? item.category.value : null

			const { caseEncryptionKey, category, subject, caseState, status, description, tag, lastActivity, ...decryptedWithoutKeys } = item
			const decryptedResult = {
				id: tag,
				status: caseState.translatedStatus,
				category: categoryValue,
				subject: decryptedSubject,
				description: decryptedDescription,
				lastActivity: formatDateToCustomString(lastActivity),
				...decryptedWithoutKeys,
			}

			return decryptedResult
		}) : []

		const sheetData = utils.json_to_sheet(decryptedData)
		const book = utils.book_new()

		const colWidths = Object.keys(decryptedData[0] || {}).map((key, index) => {
			const maxContentLength = decryptedData.reduce(
				(max, item) => Math.max(max, String(item[key]).length),
				key.length
			)
			return { wch: maxContentLength }
		})
		sheetData['!cols'] = colWidths

		const headerLabels = Object.keys(decryptedData[0] || {}).map((key) => t(formatHeaderLabel(key)))
		
		headerLabels.forEach((label, index) => {
			const cellAddress = utils.encode_cell({ c: index, r: 0 })
		
			if (index === 0) {
				sheetData[cellAddress].s = '{ font: { bold: true } }'
			}
		
			sheetData[cellAddress].v = label
		})

		utils.book_append_sheet(book, sheetData, fileName)
		const finalFileName = fileName.endsWith('.xlsx') ? fileName : `${fileName}.xlsx`
		writeFile(book, finalFileName, { bookType: 'xlsx', bookSST: true, type: 'binary' })
	}

	const handleExportCases = async () => {
		setIsLoading(true)
		const filterItems = getFilterItems()
		try {
			const response = await axiosBFFServer.post('/case/search?page=1&size=100', filterItems)
			exportXlsx(response.data)
			setIsLoading(false)
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			const errorMessage = getErrorMessage(error, t('caseErrors'))
			setSnackbar({
				isVisible: true,
				type: SnackbarTypes.ERROR,
				message: errorMessage,
			})
			setIsLoading(false)
		}
	} 

	return (
		<TouchableOpacity disabled={disabled || isLoading} style={(disabled || isLoading) ? [styles.exportLogs, styles.disabled] : styles.exportLogs} onPress={() => handleExportCases()}>
			{isLoading ? <Loader size={38} color={theme.light.colors.mainContainer} /> : <Text style={styles.buttonText}>{ t('exportCases') }</Text>}
		</TouchableOpacity>
	)
}
