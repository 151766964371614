import { Address, Permission, PermissionList, ReportTypes, UserRole, UserRoles } from '@utils/interfaces'
import { t } from 'i18next'
import jsPDF from 'jspdf'
import moment from 'moment'
import { decryptFilesWithAES, encryptFilesWithAES } from './encryption'
import { companyPermissionsArray, partnerPermissionsArray, wbFields, statisticsColors } from './constants'
import localStorage from 'redux-persist/es/storage'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { theme } from '@styles/constants'
pdfMake.vfs = pdfFonts.pdfMake.vfs

export const capitalizeFirstLetter = (word: string) => {
	return word.charAt(0).toUpperCase() + word.slice(1)
}

export const formatPublicKey = (key) => {
	return key?.replace(/\\r\\n/g, '\r\n')
}

export const isPartner = (roles: UserRole[]): boolean => {
	return roles.some(role => role.name.toLowerCase() === UserRoles.PARTNER)
}

export const isAdvisor = (roles: UserRole[]): boolean => {
	return roles.some(role => role.name.toLowerCase() === UserRoles.ADVISOR)
}

export const isPartnerOrAdvisor = (roles: UserRole[]): boolean => {
	return isPartner(roles) || isAdvisor(roles)
}

export const isCompanyUserAdmin = (roles: UserRole[]): boolean => {
	return roles.some(role => role.name.toLowerCase() === UserRoles.COMPANY_ADMIN)
}

export const isCompanyUser = (roles: UserRole[]): boolean => {
	return roles.some(role => role.name.toLowerCase() === UserRoles.COMPANY_USER)
}

export const isCompanyAdminOrUser = (roles: UserRole[]): boolean => {
	return isCompanyUserAdmin(roles) || isCompanyUser(roles)
}

export const exportSecurityLogsToPDF = (securityLogs) => {
	try {
		const doc = new jsPDF()
  
		doc.setProperties({
			title: 'Security Logs',
			subject: 'Security Logs PDF',
		})
  
		doc.setFontSize(8)
		doc.text('Security Logs', 15, 15)
	
		const headers = ['User Email', 'User Agent', 'IP', 'Time', 'Event']
	
		let yPosition = 30
		const pageHeight = 290
		const columnWidth = 40
		const cellHeight = 20

		headers.forEach((header, index) => {
			doc.text(header, 15 + (index * columnWidth), yPosition)
		})
		
		securityLogs.forEach((log) => {
			const { userEmail, userAgent, ip, time, event } = log
	
			if (yPosition + cellHeight > pageHeight) {
				doc.addPage()
				yPosition = 30
				headers.forEach((header, index) => {
					doc.text(header, 15 + (index * columnWidth), yPosition)
				})
			}
	
			doc.text(userEmail, 15, yPosition + cellHeight, { maxWidth: columnWidth })
			doc.text(userAgent, 15 + columnWidth, yPosition + cellHeight, { maxWidth: columnWidth })
			doc.text(ip, 15 + 2 * columnWidth, yPosition + cellHeight, { maxWidth: columnWidth })
			doc.text(time, 15 + 3 * columnWidth, yPosition + cellHeight, { maxWidth: columnWidth })
			doc.text(event, 15 + 4 * columnWidth, yPosition + cellHeight, { maxWidth: columnWidth })
	
			yPosition += cellHeight
		})
	
		doc.save('security_logs.pdf')
  
	} catch (error) {
		console.error('Error exporting PDF:', error)
	}
}

export const haveCommonItems = (array1, array2) => {
	return array1.some(item => array2.includes(item))
}

export const findCommonCategoryKeys = (array1, array2) => {
	return array1.filter((key) => array2.includes(key))
}

export const getDeadline = (date: string, language) => {
	const deadline = moment(date)
	const now = moment()
	const diffDays = deadline.format('YYYY-MM-DD') === now.format('YYYY-MM-DD') ?
		'today' : deadline.format('YYYY-MM-DD') < now.format('YYYY-MM-DD') ?
			moment.utc(deadline.diff(now,'days')) : moment.utc(deadline.diff(now,'days') + 1)
	
	let result = diffDays === 'today' ? t('today') : `${diffDays} ${t('days')}`
	result === '0 days' ? result = t('today') : result
	result === '1 days' ? 
		result = `1 ${t('day')}` : result === '-1 days' || result === '-1 zile' ? 
			result = t('oneDayAgo') : (result[0] === '-' && language === 'ro') ?
				result = `${t('ago')} ${result.slice(1)}` : (result[0] === '-' && language === 'en') ? 
					result = `${result.slice(1)} ${t('ago')}`
					: result
	
	return result
}

export const  getPreviousMonth = () => {
	const now = new Date()
	now.setMonth(now.getMonth() - 1)

	return now
}

export const formatDate = (date: Date) => {
	const offset = date.getTimezoneOffset()
	date = new Date(date.getTime() - (offset*60*1000))
	return date.toISOString().split('T')[0]
}

export const formatDateRo = (date: string| Date) => {
	const dateObj = date instanceof Date ? date : new Date(date)
	const month = (dateObj.getMonth() + 1).toString().padStart(2, '0')
	return `${dateObj.getDate()}/${month}/${dateObj.getFullYear()}`
}

export const textFunction = (text) => {
	return capitalizeFirstLetter(text.split(/(?=[A-Z])/).join(' '))
}

export const mapAdvisorCheckboxTree = (permissions) => {
	const buildPermissionsTree = (requiredNodes) => {
		return requiredNodes.map(node => ({
			text: textFunction(t(node)), 
			name: node, 
			tooltip: '', 
			childs: [],
			permissionKey: ''
		}))
	}

	const companyPermissionsTree: PermissionList[] = buildPermissionsTree(companyPermissionsArray)
	const partnerPermissionsTree: PermissionList[] = buildPermissionsTree(partnerPermissionsArray)

	permissions.forEach((perm) => {
		const permissionToAdd = {
			text: textFunction(perm.description),
			name: perm.action,
			tooltip: '',
			permissionKey: perm.permissionKey,
			childs: [],
			default: perm.default
		}
		if (companyPermissionsArray.find((entry) => entry === perm.subject)) {
			companyPermissionsTree.find((item) => item.name === perm.subject)?.childs.push(permissionToAdd)
			return
		}
		if (partnerPermissionsArray.find((entry) => entry === perm.subject)){
			partnerPermissionsTree.find((item) => item.name === perm.subject)?.childs.push(permissionToAdd)
			return
		}
	
	})
	return {
		companyTree: companyPermissionsTree,
		partnerTree: partnerPermissionsTree
	}
}

export const formatTitleCaseString = (inputString: string) => {
	return inputString.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase())
}

export const fromCamelCaseToTitle = (inputString: string) => {
	const withSpaces = inputString.replace(/([A-Z])/g, ' $1')
	const titleCase = withSpaces.charAt(0).toUpperCase() + withSpaces.slice(1)
	return titleCase
}

export const getDate = (currentDate) => {
	const date = new Date(currentDate)
	return date.toLocaleDateString()
} 

export const removeEmptyValues = (obj) => {
	const filteredObj = {}

	for (const key in obj) {
		if (obj[key] === undefined) {
			continue
		} else if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
			const nestedObj = removeEmptyValues(obj[key])
			if (Object.keys(nestedObj).length > 0) {
				filteredObj[key] = nestedObj
			}
		} else if (obj[key] !== '') {
			filteredObj[key] = obj[key]
		}
	}

	return filteredObj
}

export const objectHasKeys = (obj: object, key1: string, key2: string) : boolean => {
	return Object.hasOwnProperty.call(obj, key1) &&
    obj[key1] &&
    obj[key2]
}

export const objectHasKey = (obj: object, key: string) => {
	return Object.prototype.hasOwnProperty.call(obj, key) && obj[key]
}

export const textTransform = (text) => {
	return capitalizeFirstLetter(text.replaceAll('_',' ').toLowerCase())
}

export const getStatisticsData = (casesList, field, statistics, itemValue, name, hasColors) => {
	const caseDates = casesList.map((item) => item[field])
	const frequency = new Map()

	for (const date of caseDates){
		let currentDateCount = frequency.get(date)
		if(currentDateCount != undefined) {
			frequency.delete(date)
			frequency.set(date, ++currentDateCount)
		} else frequency.set(date,1)
	}

	const uniqueDates = caseDates.filter((value, index, array) => array.indexOf(value) === index)
	
	hasColors ? uniqueDates.forEach((value,index) => statistics.push({
		[itemValue]: value ? textTransform(value.toString()) : value,
		[name]: frequency.get(value),
		color: statisticsColors[index],
		legendFontSize: 14
	})) : uniqueDates.forEach((value) => statistics.push({
		[itemValue]: value ? textTransform(value.toString()) : value,
		[name]: frequency.get(value)
	}))

	if (field === 'createdAt') {
		statistics.map(statItem => ({ ...statItem, [itemValue]: formatDateRo(statItem) }))
	}

	return statistics
}

export const getChartData = (statisticsData,name,value) => {
	const chartData = {
		labels: statisticsData.map((item) => item[name]),
		datasets: [
			{
				data: statisticsData.map((item) => item[value])
			}
		]
	}

	return chartData
}

export const getDateAndTime = (date) => {
	const dateHour = new Date(date).toLocaleString([], { hour12: true})
	return dateHour
}

async function fetchDocument(documentPath) {
	try {
		const response = await fetch(documentPath)
  
		if (!response.ok) {
			throw new Error(`Failed to fetch document: ${response.statusText}`)
		}
  
		const content = await response.blob()
  
		return content
	} catch (error) {
		console.error('Error fetching document:', error)
		throw error
	}
}

export const decryptDocs = async (documents, decryptedSecretKey, iv) => {
	if (documents.length){
		const decryptPromises = documents.map(async (document) => {	
			const content = await fetchDocument(document.documentPath)
			const decryptedDoc = await decryptFilesWithAES(decryptedSecretKey, iv, content, document.fileName)
			return { ...document, file: decryptedDoc }
		})
		const decryptedDocuments = await Promise.all(decryptPromises)
		return decryptedDocuments
	} else return []
}

export const downloadFile = (file) => {
	const element = global.document.createElement('a')
	element.setAttribute('href', `data:${file.contentType}` + file.data)
	element.setAttribute('download', file.fileName)
	element.style.display = 'none'
	global.document.body.appendChild(element)
	element.click()
	global.document.body.removeChild(element)
}

export const getObjectDifference = (obj1, obj2) => {
	return Object.fromEntries(
		Object.entries(obj1).filter(([key, value]) => obj2[key] !== value)
	)
}

export const getCaseType = (obj) => {
	if (obj){
		const wbObject = Object.fromEntries(
			Object.entries(obj).filter(([key, value]) => wbFields.includes(key))
		)
		
		return Object.values(wbObject).every(value => !value)
			? capitalizeFirstLetter(ReportTypes.ANONYMOUSLY)
			: capitalizeFirstLetter(ReportTypes.CONFIDENTIALLY)
	
	} return ReportTypes.ANONYMOUSLY
}

export const clearStorage = () => {
	localStorage.removeItem('persist:root')
	localStorage.removeItem('i18nextLng')
}

export const generateFileName = (filename: string, extension: string) => {
	const timestamp = new Date().getTime()
	return `${filename}_${timestamp}.${extension}`
}

export const getLinkAndDocumentsForm = async (files, secretKey, iv) => {
	const areMultipleDocuments = files.length > 1
	const fileDataToSend = new FormData()
	const fileEncodingProcesses = files.map(file => encryptFilesWithAES(secretKey, iv, file))
	const blobUploadedFiles = await Promise.all(fileEncodingProcesses)

	let uploadDocUrl = ''
	if (areMultipleDocuments) {
		uploadDocUrl = '/case/documents'
		blobUploadedFiles.forEach(file => fileDataToSend.append('files', file))
	} else {
		uploadDocUrl = '/case/document'
		fileDataToSend.append('file', blobUploadedFiles[0])
	}
	return {
		uploadDocUrl,
		fileDataToSend
	}
}

export const parseDateTime = (dateTime: string) => {
	const [datePart, timePart] = dateTime.split('T')
	const [year, month, day] = datePart.split('-').map(Number)
	const [hour, minute, second] = timePart.split(':').map(Number)
  
	return new Date(year, month - 1, day, hour, minute, second)
}

export const formatDateToCustomString = (dateTime: string) => {
	const dateTimeResult = parseDateTime(dateTime)
	const day = dateTimeResult.getDate().toString().padStart(2, '0')
	const month = (dateTimeResult.getMonth() + 1).toString().padStart(2, '0')
	const year = dateTimeResult.getFullYear()
	const hours = dateTimeResult.getHours().toString().padStart(2, '0')
	const minutes = dateTimeResult.getMinutes().toString().padStart(2, '0')
  
	return `${day}/${month}/${year} ${hours}:${minutes}`
}

export const sanitizeUserAgent = (userAgent: string) => {
	const regex = /(Firefox|Chrome|Safari|Edge|IE|Opera|Mozilla)[/\s](\d+(\.\d+)*)/i
	const matches = regex.exec(userAgent)
  
	if (matches && matches.length >= 3) {
		const browserName = matches[1]
		const browserVersion = matches[2]
		return `${browserName} ${browserVersion}`
	}
  
	return userAgent
}

export const getUserAgent = () => {
	return global.navigator.userAgent
}
export const formatTemplateContent = (content) => {
	return content.replace(/\\n/g, '\n')
}

export const createUserPermissionsList = (permissionData: Permission[]) => {
	const permissionList: PermissionList[]  = [{
		text: 'Company',
		name: 'company',
		tooltip: '',
		childs: [],
		permissionKey: '',
		default: false
	},{
		text: 'Cases',
		name: 'case',
		tooltip: '',
		childs: [],
		permissionKey: '',
		default: false
	},{
		text: 'Company user',
		name: 'companyUser',
		tooltip: '',
		childs: [],
		permissionKey: '',
		default: false
	},{
		text: 'Department',
		name: 'department',
		tooltip: '',
		childs: [],
		permissionKey: '',
		default: false
	},{
		text: 'Category',
		name: 'category',
		tooltip: '',
		childs: [],
		permissionKey: '',
		default: false
	},
	{
		text: 'User settings',
		name: 'userSettings',
		tooltip: '',
		childs: [],
		permissionKey: '',
		default: false
	},	{
		text: 'Company settings',
		name: 'companySettings',
		tooltip: '',
		childs: [],
		permissionKey: '',
		default: false
	},{
		text: 'Security logs',
		name: 'securityLogs',
		tooltip: '',
		childs: [],
		permissionKey: '',
		default: false
	},{
		text: 'Subscription',
		name: 'subscription',
		tooltip: '',
		childs: [],
		permissionKey: '',
		default: false
	},{
		text: 'Statistics',
		name: 'companyStatistics',
		tooltip: '',
		childs: [],
		permissionKey: '',
		default: false
	}]

	permissionData && permissionData.forEach((perm) => {
		const permissionToAdd:PermissionList = {
			text: textFunction(perm.description),
			name: perm.action,
			tooltip: '',
			permissionKey: perm.permissionKey,
			childs: [],
			default: perm.default
		} 
		switch (perm.subject){
		case 'company':
		case 'companyUser':
		case 'department':
		case 'category':
		case 'case':
		case 'securityLogs':
		case 'subscription':
		case 'companyStatistics':
		case 'userSettings':
		case 'companySettings':
			permissionList.find((item) => item.name === perm.subject)?.childs.push(permissionToAdd)
			break
		}
	})

	return permissionList
}

export const getISOStringFromDate = () => {
	const newDate = new Date()
	const offset = newDate.getTimezoneOffset()
	const date = new Date(newDate.getTime() - (offset*60*1000))
	return date.toISOString()
}

export const checkIfPermissionExist = (permissions,subject,action) => {
	const result = permissions.find((perm: Permission) => perm.subject === subject && perm.action === action)
	if (result){
		return true
	} return false
}

export const getCompanyAdmin = (users) => {
	return users.reduce((earliest, current) => {
		return new Date(current.createdAt) < new Date(earliest.createdAt) ? current : earliest
	}, users[0])	  
}

export const removeDuplicatesByCriteria = (array, criteria) => {
	return [...new Map(array.map(item => [item[criteria], item])).values()]
}

export const getSizeInterval = (value, intervals) => {
	const numericValue = parseInt(value, 10)

	for (const interval of intervals) {
		if (interval.includes('+')) {
			const start = parseInt(interval, 10)
			if (!isNaN(numericValue) && numericValue >= start) {
				return interval
			}
		} else {
			const [start, end] = interval.split('-').map(Number)
			if (!isNaN(numericValue) && start <= numericValue && numericValue <= end) {
				return interval
			}
		}
	}
	return null
}

export const downloadMasterPasswordPDF = (secretKey: string, companyName: string, reportLink?: string) => {
	const logoSrc = `${process.env.REACT_NATIVE_APP_BASE_URL}${require('@assets/Logo.png')}`
	let title, explanatoryText, instructionsText, instructions, secretKeyText, advice
	
	if (reportLink) { // wb file
		title = t('downloadMasterPassWB.title'),
		explanatoryText = t('downloadMasterPassWB.explanatoryText'),
		instructionsText = t('downloadMasterPassWB.instructionsText'),
		instructions = t('downloadMasterPassWB.instructions', { reportLink }),
		secretKeyText = t('downloadMasterPassWB.secretKeyText'),
		advice = t('downloadMasterPassWB.advice')
	} else { // company admin/LP file
		title = t('downloadMasterPass.title'),
		explanatoryText = t('downloadMasterPass.explanatoryText'),
		instructionsText = t('downloadMasterPass.instructionsText'),
		instructions = t('downloadMasterPass.instructions'),
		secretKeyText = t('downloadMasterPass.secretKeyText'),
		advice = t('downloadMasterPass.advice')
	}

	const docDefinition = {
		info: {
			title: `${t('appName')} - Secret key - ${companyName}`,
			subject: `${t('appName')} - Secret key PDF - ${companyName}`,
			author: t('appName'),
		},
		pageSize: 'A4', // w:595.28, h:841.89
		pageOrientation: 'portrait',
		pageMargins: [60, 100],
		header: {
			columns: [
				{
                        
					image: 'logo',
					width: 40,
				},
				{
					text: t('appName'),
					fontSize: 12,
					bold: true,
					margin: [7, 14],
				},
			],
			margin: [30, 30],
		},
		content: [
			{
				text: title,
				fontSize: 24,
				bold: true,
				alignment: 'center',
				margin: [0, 30, 0, 60],
			},
			{
				text: explanatoryText,
				fontSize: 16,
				alignment: 'justify',
			},
			{
				text: instructionsText,
				margin: [0, 40, 0, 10],
			},
			{
				ol: [
					...instructions.split('|').map(instruction => ({
						text: instruction,
						fontSize: 12,
						noWrap: true,
						margin: [0, 5],
					})),
				],
			},
			{
				text: secretKeyText,
				fontSize: 20,
				margin: [0, 40, 0, 10],
			},
			{
				canvas: [
					{
						type: 'rect',
						x: 0,
						y: 0,
						w: 475, // page w - page maring * 2 (595 - 2 * 60)
						h: reportLink ? 60 : 36,
						color: theme.light.colors.appContainer,
					},
				],
			},
			{
				text: secretKey,
				fontSize: 12,
				bold: true,
				alignment: 'center',
				margin: [0, reportLink ? -42 : -24, 0, 0],
			},
			{
				text: advice,
				margin: [0, 60],
			},
		],
		defaultStyle: {
			fontSize: 14,
		},
		images: {
			logo: logoSrc,
		},
	}

	pdfMake.createPdf(docDefinition).download(`${replaceDotsWithUnderscores(companyName)}_secret_key`)
}

export const formatHeaderLabel = (label: string) => {
	const formattedLabel = label
		.replace(/([a-z])([A-Z])/g, '$1 $2')
		.replace(/([A-Z])/g, '$1')
		.trim()

	return formattedLabel.charAt(0).toUpperCase() + formattedLabel.slice(1)
}

export const downloadQRCode = (url: string | null, companyName: string) => {
	const logoSrc = `${process.env.REACT_NATIVE_APP_BASE_URL}${require('@assets/Logo.png')}`

	const title = t('downloadQRCode.qrcode')
	const textQR = t('downloadQRCode.qrcodeText')
	const qrcodeExplanatoryText = t('downloadQRCode.qrcodeExplanatoryText')

	const docDefinition = {
		info: {
			title: `${companyName} - QR Code`,
			subject: `${companyName} - QR Code PDF`,
			author: t('appName'),
		},
		pageSize: 'A4', // w:595.28, h:841.89
		pageOrientation: 'portrait',
		pageMargins: [60, 100],
		header: {
			columns: [
				{             
					image: 'logo',
					width: 40,
				},
				{
					text: t('appName'),
					fontSize: 12,
					bold: true,
					margin: [7, 14],
				},
			],
			margin: [30, 30],
		},
		content: [
			{
				text: title,
				fontSize: 24,
				bold: true,
				alignment: 'center',
				margin: [0, 30, 0, 60],
			},
			{
				text: textQR,
				fontSize: 16,
				alignment: 'justify',
				margin: [0, 40, 0, 10],
			},
			{
				text: qrcodeExplanatoryText,
				fontSize: 16,
				alignment: 'justify',
				margin: [0, 40, 0, 10],
			},
			{
				image: url,
				width: 200, 
				alignment: 'center',
			},
		],
		styles: {
			header: {
				fontSize: 16,
				bold: true,
				margin: [0, 20, 0, 10], 
			},
		},
		images: {
			logo: logoSrc,
		},
	}

	pdfMake.createPdf(docDefinition).download(`${replaceDotsWithUnderscores(companyName)}_QR_code`)
}

export const normalizeCountryName = (countryName: string) => {
	if (countryName) {
		return countryName.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
	}
	return ''
}

export const isCUIValid = (cui: string): boolean => {

	if (cui.length > 10 || cui.length < 2) {
		return false
	}

	const parsedCui = parseInt(cui.trim(), 10)
	const c1 = parsedCui % 10
	let identificationNumber = Math.floor(parsedCui / 10)
	let cuiTestKey = 753217532

	let t = 0
	while (identificationNumber > 0) {
		t += (identificationNumber % 10) * (cuiTestKey % 10)
		identificationNumber = Math.floor(identificationNumber / 10)
		cuiTestKey = Math.floor(cuiTestKey / 10)
	}

	let c2 = (t * 10) % 11

	if (c2 === 10) {
		c2 = 0
	}

	return c1 === c2
}

export const combineAddressParts = (address: Address) => {
	return Object.entries(address)
		.map(([key, value]) => value.trim() !== '' ? `${t(`address.${key}`)} ${value.trim()}` : '')
		.filter(part => part !== '')
		.join(', ')
}

export const isTodayBeforeDate = (targetDate) => {
	const currentDate = new Date()
	const [year, month, day] = targetDate.split('-').map(Number)
	const targetDateObject = new Date(year, month - 1, day)
  
	return currentDate < targetDateObject
}

export const mapStringsToTypes = (t, notifications) => {
	return notifications.map(notification => ({
		...notification,
		notificationType: t(`notificationTypes.${notification.notificationType.replace(/\s/g, '')}`)
	}))
}
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    $crisp: any[] | null;
    CRISP_WEBSITE_ID: string;
	CRISP_TOKEN_ID: string;
  }
}

export const truncateDescription = (description, maxLength) => {
	if (description.length <= maxLength) {
		return description
	}
	return `${description.substring(0, maxLength)}...`
}

export const notifications = [
	{
		id: 1,
		title: 'New Message',
		description: 'You have received a new message.',
		read: false
	},
	{
		id: 2,
		title: 'Reminder',
		description: 'Don\'t forget to submit your assignment by tomorrow.',
		read: true
	},
	{
		id: 3,
		title: 'Event Invitation',
		description: 'You\'ve been invited to a party this weekend.',
		read: false
	},
	{
		id: 4,
		title: 'Meeting Reminder',
		description: 'Reminder: meeting at 10:00 AM today.',
		read: false
	},
	{
		id: 5,
		title: 'Weather Update',
		description: 'Expect heavy rain tomorrow. Don\'t forget your umbrella!',
		read: true
	},
	{
		id: 6,
		title: 'Payment Reminder',
		description: 'Your utility bill payment is due in 2 days.',
		read: false
	},
	{
		id: 7,
		title: 'Job Opportunity',
		description: 'New job opening in your field. Check it out!',
		read: true
	},
	{
		id: 8,
		title: 'Hiking Group',
		description: 'Join our hiking group this Saturday. Meet at 8:00 AM.',
		read: false
	},
	{
		id: 9,
		title: 'Health Tips',
		description: '10 tips for a healthier lifestyle.',
		read: true
	},
	{
		id: 10,
		title: 'Birthday Reminder',
		description: 'It\'s your friend\'s birthday tomorrow. Don\'t forget to wish them!',
		read: false
	}
]

export const replaceDotsWithUnderscores = (inputString) => {
	return inputString.replace(/\./g, '_')
}

export const downloadStatistics = (url1: string | null, url2: string | null, url3: string | null, url4: string | null, companyName: string, totalCases: number, openCases: number, closedCases: number, startDate, endDate, setIsGenerating) => {
	const logoSrc = `${process.env.REACT_NATIVE_APP_BASE_URL}${require('@assets/Logo.png')}`

	const title = t('downloadReport.downloadTitle', { companyName })
	const textReport = t('downloadReport.textReport', { startDate, endDate })
	const totalNoCases = t('downloadReport.totalNoCases', { totalCases })
	const openNoCases = t('downloadReport.openCases', { openCases })
	const closedNoCases =t('downloadReport.closedCases', { closedCases }) 

	const docDefinition = {
		info: {
			title: `${companyName} - Statistics informations`,
			subject: `${companyName} - Statistics informations PDF`,
			author: t('appName'),
		},
		pageSize: 'A4', // w:595.28, h:841.89
		pageOrientation: 'landscape',
		pageMargins: [60, 100],
		header: {
			columns: [
				{             
					image: 'logo',
					width: 40,
				},
				{
					text: t('appName'),
					fontSize: 12,
					bold: true,
					margin: [7, 14],
				},
			],
			margin: [30, 30],
		},
		content: [
			{
				text: title,
				fontSize: 24,
				bold: true,
				alignment: 'center',
				margin: [0, 30, 0, 60],
			},
			{
				text: textReport,
				fontSize: 16,
				alignment: 'justify',
				margin: [0, 10, 0, 10],
			},
			{
				text: totalNoCases,
				fontSize: 16,
				alignment: 'justify',
				margin: [20, 10, 0, 10],
			},
			{
				text: openNoCases,
				fontSize: 16,
				alignment: 'justify',
				margin: [20, 10, 0, 10],
			},
			{
				text: closedNoCases,
				fontSize: 16,
				alignment: 'justify',
				margin: [20, 10, 0, 10],
			},
			{
				image: url1,
				fit: [800, 500], 
				alignment: 'center',
			},
			{
				image: url2,
				fit: [800, 500],
				alignment: 'center',
			},
			{
				image: url3,
				fit: [800, 500], 
				alignment: 'center',
			},
			{
				image: url4,
				fit: [800, 500],
				alignment: 'center',
			},
		],
		styles: {
			header: {
				fontSize: 16,
				bold: true,
				margin: [0, 20, 0, 10], 
			},
		},
		images: {
			logo: logoSrc,
		},
	}

	pdfMake.createPdf(docDefinition).download(`${replaceDotsWithUnderscores(companyName)}_statistics_info`)
	setIsGenerating(false)
}

export const formatTimestamp = (timestamp) => {
	const day = ('0' + timestamp.getDate()).slice(-2)
	const month = ('0' + (timestamp.getMonth() + 1)).slice(-2)
	const year = timestamp.getFullYear()
	const hours = ('0' + timestamp.getHours()).slice(-2)
	const minutes = ('0' + timestamp.getMinutes()).slice(-2)
	return `${day}/${month}/${year} ${hours}:${minutes}`
}
