import React, { useEffect, useState } from 'react'
import { GeneralModal } from '@components/Modal/Modal'
import { useTranslation } from 'react-i18next'
import { createLog } from '@services/apiService'
import { clearStorage, getUserAgent, isCompanyUser, isCompanyUserAdmin, isPartnerOrAdvisor, isTodayBeforeDate } from '@utils/utils'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { rootReducerType } from '@reducers/combineReducers'
import { LogEventTypes, Permission } from '@utils/interfaces'
import { useDispatch } from 'react-redux'
import { resetState } from '@actions/resetActions'
import { axiosBFFServer } from '@services/connectionServer'
import { setIsPaymentNeeded } from '@actions/paymentActions'
import {Linking} from 'react-native'

export const SubscriptionExpired = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { pathname } = useLocation()
	const dispatch = useDispatch()
	const { roles, permissions, email, language } = useSelector((state: rootReducerType) => state.userReducer)
	const { isPaymentNeeded } = useSelector((state: rootReducerType) => state.paymentReducer)
	const hasSubscriptionPermission = permissions.find((perm: Permission) => perm.subject === 'subscription' && perm.action === 'update')
	const canViewSubscription = hasSubscriptionPermission && pathname.split('/').includes('settings') && (isCompanyUserAdmin(roles) || isPartnerOrAdvisor(roles))
	const { companyKey, status, nrOfEmployees, isStateOwned, subscription } = useSelector((state: rootReducerType) => state.companyReducer.currentCompany)
	const { key } = useSelector((state: rootReducerType) => state.legalPartnerKeyReducer)

	const [modalMessage, setModalMessage] = useState('')
	const [infoModal, setInfoModal] = useState(false)
	const [isCancelled, setIsCancelled] = useState(false)

	const hasUpdateSubscriptionPermission = permissions.find((perm: Permission) => perm.subject === 'subscription' && perm.action === 'update')

	const onLoginRedirect = async () => {
		const userAgent = getUserAgent()
		const securityLogInfo = {
			companyKey: companyKey || key,
			userEmail: email,
			userAgent,
			time: new Date().toISOString(),
			event: LogEventTypes.LOGOUT,
			failureReason: ''
		}
		clearStorage()
		dispatch(resetState())
		await createLog(securityLogInfo, true)
		navigate('/login')
	}

	const onClose = async () => {
		await onLoginRedirect()
	}

	const onSubmit = async () => {
		if (isCompanyUserAdmin(roles)) {
			if (subscription && subscription?.paymentType === 'PAYMENT_ORDER') {
				Linking.openURL(`${process.env.WEBSITE_URL}/en/contact`)
			} else {
				navigate('/company/settings')
			}
		}
		else if (isPartnerOrAdvisor(roles)) {
			navigate('/partner/settings')
		}
		else if (hasSubscriptionPermission) {
			navigate('/company/settings')
		} else {
			await onLoginRedirect()
		}
	}

	const getSubscriptionDetails = async () => {
		let cancelled = false
		await axiosBFFServer
			.get(`/company/${companyKey}`)
			.then(response => {
				if(response.data.status === 'ACTIVE' && response.data.isStateOwned === true) {
					dispatch(setIsPaymentNeeded(false))
				} else if(response.data.status === 'PAYMENT_DETAILS_REQUIRED' && response.data.isStateOwned === true) {
					dispatch(setIsPaymentNeeded(true))
				}

				if (response.data.subscription){
					if (response.data.subscription.cancelled){
						const activeDays = isTodayBeforeDate(response.data.subscription.nextPaymentDate)
						if (!activeDays) {
							cancelled = true
							dispatch(setIsPaymentNeeded(true))
						} else cancelled = false
					} else cancelled = false
				} else cancelled = false
				isPaymentNeeded && setIsPaymentNeeded(false)
			})
			.catch(error => {
				console.log(error)
			})

		setIsCancelled(cancelled) 
	}

	const setModalMessageBasedOnStatus = () => {
		if (isCancelled) {
			handleCancelledStatus()
		} else if (status === 'EXPIRED') {
			handleExpiredStatus()
		} else if (status === 'PAYMENT_DETAILS_REQUIRED') {
			handlePaymentDetailsRequiredStatus()
		}
	}
	
	const handleCancelledStatus = () => {
		if (isCompanyUserAdmin(roles) || hasUpdateSubscriptionPermission) {
			handleAdminCancelledStatus()
		} else {
			handleUserCancelledStatus()
		}
	}
	
	const handleAdminCancelledStatus = () => {
		if (subscription?.paymentType === 'PAYMENT_ORDER') {
			setModalMessage(t('paymentOrderCancelled'))
		}
		else {
			setModalMessage(t('subscriptionMessageCancelled'))
		}
	}
	
	const handleUserCancelledStatus = () => {
		if (subscription?.paymentType === 'PAYMENT_ORDER') {
			setModalMessage(t('paymentOrderExpired'))
		} else {
			setModalMessage(t('subscriptionMessage'))
			setInfoModal(true)
		}
	}
	
	const handleExpiredStatus = () => {
		if (isCompanyUserAdmin(roles) || hasUpdateSubscriptionPermission) {
			handleAdminExpiredStatus()
		} else {
			handleUserExpiredStatus()
		}
	}
	
	const handleAdminExpiredStatus = () => {
		if (subscription?.paymentType === 'PAYMENT_ORDER') {
			setModalMessage(t('paymentOrderCancelled'))
		} else {
			setModalMessage(t('subscriptionMessage'))
		}
	}
	
	const handleUserExpiredStatus = () => {
		if (subscription?.paymentType === 'PAYMENT_ORDER') {
			setModalMessage(t('paymentOrderExpired'))
		} else {
			setModalMessage(t('subscriptionMessage'))
			setInfoModal(true)
		}
	}
	
	const handlePaymentDetailsRequiredStatus = () => {
		if (isCompanyUserAdmin(roles) || hasUpdateSubscriptionPermission) {
			handleAdminPaymentDetailsRequiredStatus()
		} else {
			handleUserPaymentDetailsRequiredStatus()
		}
	}
	
	const handleAdminPaymentDetailsRequiredStatus = () => {
		if (isStateOwned) {
			setModalMessage(t('subscriptionMessageCustomPlanStateCompany'))
		} else if (nrOfEmployees === '500+') {
			setModalMessage(t('subscriptionMessageCustomPlans'))
		} else {
			setModalMessage(t('subscriptionMessageActivateTrial'))
		}
	}
	
	const handleUserPaymentDetailsRequiredStatus = () => {
		setModalMessage(t('subscriptionMessageActivateTrialUser'))
		setInfoModal(true)
	}
	
	const customMessage = () => {
		setModalMessageBasedOnStatus()
	}
    
	useEffect(() => {
		customMessage()
	}, [language, isCancelled, status])
	
	useEffect(() => {
		getSubscriptionDetails()
	}, [pathname])

	return (
		<GeneralModal
			visible={canViewSubscription ? false : isPaymentNeeded && modalMessage ? true : false}
			onClose={onClose}
			onSubmit={onSubmit}
			messageText={modalMessage}
			opacity={true}
			infoModal={infoModal}
		/>
	)
}