import { theme } from '@styles/constants'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: theme.light.colors.appContainer,
	},
	header: {
		flexDirection: 'row',
		alignItems: 'center',
		height: 80,
		paddingLeft: '4%',
		backgroundColor: theme.light.colors.mainContainer,
		zIndex: 1,
		borderBottomWidth: 1,
		borderBottomColor: theme.light.colors.boxBorder,
		shadowColor: theme.light.colors.mainColor,
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.2,
		shadowRadius: 2,
		elevation: 2,
	},
	headerText: {
		fontSize: 20,
		fontWeight: 'bold',
		color: theme.light.colors.linkColor,
		paddingHorizontal: 10,
		paddingLeft: 18,
	},
	content: {
		flex: 1,
		paddingHorizontal: '10%'
	},
	logo: {
		width: 60,
		height: 60,
	}
})