import { StyleSheet } from 'react-native'
import { theme } from '@styles/constants'
import { button, buttonText } from '@styles/mixins/button'

export const styles = StyleSheet.create({
	container: {
		gap: 20,
	},
	header: {
		marginTop: 20,
		justifyContent: 'space-between',
		flexDirection: 'row',
		zIndex: 999,
	},
	sectionContainer: {
		gap: 10,
		backgroundColor: theme.light.colors.mainContainer,
		borderRadius: 30,
		padding: 20,
		marginBottom: 20,
	},
	filterSection: {
		flexDirection: 'row',
		gap: 10
	},
	selectContainer: {
		width: '32.7%'
	},
	subtitle: {
		fontSize: 24,
		fontWeight: '500',
		color: theme.light.colors.mainColor
	},
	title: {
		fontSize: 26
	},
	text: {
		fontSize: 18,
		color: theme.light.colors.titleColor,
		marginTop: 20
	},
	elementsContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		gap: 10
	},
	elementsContainerMobile: {
		flexDirection: 'column',
		gap: 20
	},
	barChart: {
		borderRadius: 4,
		width: '100%'
	},
	lineChart: {
		width: '100%',
		borderRadius: 4
	},
	chart: {
		borderRadius: 4,
	},
	chartItem: {
		gap: 10
	},
	generateReportContainer: {
		flexDirection: 'row',
		gap: 20,
		justifyContent:'space-evenly',
		alignItems: 'center'
	},
	generateReportButton: {
		backgroundColor: theme.light.colors.primaryDark,
		alignItems: 'center',
		justifyContent: 'center',
		alignSelf: 'center',
		borderRadius: 6,
		paddingHorizontal: 12,
		paddingVertical: 6,
		width: 200,
		shadowColor: theme.light.colors.mainColor,
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.3,
		shadowRadius: 2,
		elevation: 3,
	},
	buttonText: {
		...({
			...buttonText().buttonText,
		} as object),
		fontSize: 16,
		fontWeight: '500',
		padding: 4
	},
})
