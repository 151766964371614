import React, { useState } from 'react'
import { Modal, Text, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import Icon from 'react-native-vector-icons/FontAwesome'
import { styles } from './styles'
import {theme} from '@styles/constants'
import {useDispatch} from 'react-redux'
import {setPaymentType} from '@actions/paymentActions'

interface Props {
  visible: boolean;
  onClose: () => void;
  onPaymentSelect: (paymentMethod: string) => void;
}

export const PaymentOptionsModal = (props: Props) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('')

	const handleConfirmPaymentOption = () => {
		dispatch(setPaymentType(selectedPaymentMethod))
		props.onPaymentSelect(selectedPaymentMethod)
		props.onClose()
	}

	return (
		<Modal animationType="slide" transparent={true} visible={props.visible} onRequestClose={props.onClose}>
			<View style={styles.container}>
				<View style={styles.content}>
					<Text style={styles.title}>{t('choosePaymentMethod')}</Text>

					<TouchableOpacity style={styles.paymentOption} onPress={() => setSelectedPaymentMethod('CARD')}>
						<Icon color={theme.light.colors.primaryDarkDimmed} name={selectedPaymentMethod === 'CARD' ? 'dot-circle-o' : 'circle-o'} size={18} />
						<View style={styles.paymentText}>
							<Text>{t('paymentByCard')}</Text>
							{selectedPaymentMethod === 'CARD' && <Text style={styles.tooltip}>{t('cardTooltip')}</Text>}
						</View>
					</TouchableOpacity>

					<TouchableOpacity style={styles.paymentOption} onPress={() => setSelectedPaymentMethod('PAYMENT_ORDER')}>
						<Icon color={theme.light.colors.primaryDarkDimmed} name={selectedPaymentMethod === 'PAYMENT_ORDER' ? 'dot-circle-o' : 'circle-o'} size={18} />
						<View style={styles.paymentText}>
							<Text>{t('paymentOrder')}</Text>
							{selectedPaymentMethod === 'PAYMENT_ORDER' && <Text style={styles.tooltip}>{t('orderTooltip')}</Text>}
						</View>
					</TouchableOpacity>

					<View style={styles.buttonsContainer}>
						<TouchableOpacity style={styles.button} onPress={props.onClose}>
							<Text style={styles.buttonText}>{t('cancel')}</Text>
						</TouchableOpacity>

						<TouchableOpacity disabled={!selectedPaymentMethod} style={[styles.button, styles.confirmButton, !selectedPaymentMethod && styles.disabled]} onPress={() => handleConfirmPaymentOption()}>
							<Text style={styles.buttonText}>{t('confirm')}</Text>
						</TouchableOpacity>
					</View>
				</View>
			</View>
		</Modal>
	)
}