import React, { useEffect, useRef, useState } from 'react'
import { View, TouchableOpacity, Text } from 'react-native'
import Icon from 'react-native-vector-icons/FontAwesome'
import * as Clipboard from 'expo-clipboard'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Loader } from '@components/Loader/Loader'
import { Snackbar } from '@components/Snackbar/Snackbar'
import { QRCodeModal } from '@components/QRCodeModal/QRCodeModal'
import { TextEditor } from '@components/TextEditor/TextEditor'
import { rootReducerType } from '@reducers/combineReducers'
import { axiosBFFServer } from '@services/connectionServer'
import { formatTemplateContent, isPartnerOrAdvisor } from '@utils/utils'
import { SnackbarInitialState, SnackbarTypes, PageTemplate, ViewTypes } from '@utils/interfaces'
import { reportingPageTitle, snackbarInitialState, templateInitialState } from '@utils/constants'
import {downloadQRCode} from '@utils/utils'
import { colors } from '@styles/constants'
import { styles } from './styles'
import { UseErrorMessages } from '@components/Common/UseErrorMessage'

export const ReportingLink = () => {
	const [ snackbar, setSnackbar ] = useState<SnackbarInitialState>(snackbarInitialState)
	const [ isLoading, setIsLoading ] = useState(true)
	const [ reportLink, setReportLink ] = useState('')
	const [ openQRModal, setOpenQRModal ] = useState(false)
	const [ templates, setTemplates ] = useState<PageTemplate[]>([])
	const [ template, setTemplate ] = useState<PageTemplate>(templateInitialState)
	const [ language, setLanguage ] = useState('en')
	const [capturedImageUri, setCapturedImageUri] = useState(null)
	const { t } = useTranslation()
	const userRoles = useSelector((state: rootReducerType) => state.userReducer.roles)
	const companyKey = useSelector((state: rootReducerType) => state.companyReducer.key)
	const { name } = useSelector((state: rootReducerType) => state.companyReducer.currentCompany)

	const { getErrorMessage } = UseErrorMessages()

	const getCompany = async () => {
		if (isPartnerOrAdvisor(userRoles)) {
			return await axiosBFFServer.get(`/company/${companyKey}`)
		} else {
			return await axiosBFFServer.get('/company/current')
		}
	}

	const getCompanyTemplates = async () => {
		await axiosBFFServer
			.get(`/template/list?companyKey=${companyKey}&size=100`)
			.then(response => {
				if (response.status === 200) {
					const templates = response.data.pageResults
						.filter(template => template.title === reportingPageTitle)
						.map(template => {
							const { pageTemplateKey, content, language } = template
							return {
								pageTemplateKey,
								content: formatTemplateContent(content),
								language: language.toLowerCase(),
							}
						})
					const template = templates?.find(template => template.language.toLowerCase() === language)

					setTemplates(templates)
					template && setTemplate(template)
				} else throw new Error()
			})
			.catch(error => {
				const errorMessage = getErrorMessage(error, t('errors.templates')) 
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}

	const createTemplate = async (reqBody) => {
		await axiosBFFServer
			.post(`/template?companyKey=${companyKey}`, reqBody)
			.then(response => {
				if (response.status === 201) {
					setSnackbar({
						isVisible: true,
						type: SnackbarTypes.SUCCESS,
						message: t('success.createTemplate')
					})
					getCompanyTemplates()
				} else throw new Error()
			})
			.catch(error => {
				const errorMessage = getErrorMessage(error, t('errors.createTemplate')) 
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}

	const editTemplate = async (reqBody, templateKey) => {
		await axiosBFFServer
			.patch(`/template/update/${templateKey}?companyKey=${companyKey}`, reqBody)
			.then(response => {
				if (response.status === 200) {
					setSnackbar({
						isVisible: true,
						type: SnackbarTypes.SUCCESS,
						message: t('success.editTemplate')
					})
					getCompanyTemplates()
				} else throw new Error()
			})
			.catch(error => {
				const errorMessage = getErrorMessage(error, t('errors.editTemplate')) 
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}

	useEffect(() => {
		getCompany()
			.then(response => {
				if(response.status === 200) {
					const companyReportLink = response.data.reportLink
					companyReportLink && setReportLink(`${process.env.REACT_NATIVE_APP_BASE_URL}/${ViewTypes.REPORT}/${companyReportLink}`)
				} else throw new Error()
			})
			.catch((error) => {
				const errorMessage = getErrorMessage(error, t('errors.reportLink')) 
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
		getCompanyTemplates()

		setIsLoading(false)
	}, [])

	useEffect(() => {
		const template = templates?.find(template => template.language === language)
		setTemplate(template || { content: '', language })
	}, [language])

	const copyLinkToClipboard = async () => {
		await Clipboard.setStringAsync(reportLink)
		setSnackbar({
			isVisible: true,
			type: SnackbarTypes.INFO,
			message: t('copyLink')
		})
	}

	const handleSave = async (content: string) => {
		if (template.pageTemplateKey) {
			const reqBody = { content }

			await editTemplate(reqBody, template.pageTemplateKey)
		} else {
			const reqBody = {
				title: reportingPageTitle,
				content,
				language,
			}
            
			await createTemplate(reqBody)
		}
	}

	const handleImageCaptured = (uri) => {
		setCapturedImageUri(uri)
	}

	const handleDownloadQR = () => {
		if (capturedImageUri) {
			downloadQRCode(capturedImageUri, name)
			setOpenQRModal(false)
		} else {
			setSnackbar(
				{
					isVisible: true,
					type: SnackbarTypes.INFO,
					message: t('downloadQRCode.downloadQRCodeError'),
				}
			)
		}
	}

	return (
		<View style={styles.container}>
			<View style={styles.headerContainer}>
				<Text style={styles.headerText}>{t('reportingPage')}</Text>
			</View>
			<View style={styles.textContainer}>
				<Text style={styles.explanatoryText}>{t('reportingLinkExplanation')}</Text>
				<Text style={styles.explanatoryText}>{t('reportingLinkUrge')}</Text>
				<Text style={styles.explanatoryText}>{t('optionsToDistribute')}</Text>
			</View>
			{isLoading
				? <Loader color={colors.white} />
				: <View style={styles.content}>
					<View style={styles.section}>
						<Text style={styles.sectionTitle}>{t('reportingLink')}</Text>
						<View style={styles.sectionContent}>
							<Text selectable={true}>{reportLink}</Text>
							<View style={styles.linkButtonsContainer}>
								<TouchableOpacity style={styles.linkButton} onPress={copyLinkToClipboard}>
									<Icon
										name={'clipboard'} 
										color={colors.gray}
										size={18}
									/>
								</TouchableOpacity>
								<TouchableOpacity style={styles.linkButton} onPress={() => setOpenQRModal(true)}>
									<Icon
										name={'qrcode'} 
										color={colors.gray}
										size={18}
									/>
								</TouchableOpacity>
							</View>
						</View>
						<View style={styles.buttonContainer}>
							<View style={styles.openButton}>
								<Link to={reportLink} target='blank' style={styles.buttonText}>
									{t('openPage')}
								</Link>
							</View>
						</View>
					</View>
					<View style={styles.section}>
						<Text style={styles.sectionTitle}>{t('customizeReportingPage')}</Text>
						<Text>{t('customizeReportingPageDescription')}</Text>
						<TextEditor
							id='report-page-editor'
							template={template}
							setLanguage={setLanguage}
							onSave={handleSave}
						/>
					</View>
				</View>
			}
			{
				openQRModal && reportLink && 
					<QRCodeModal 
						value={reportLink}
						isVisible={openQRModal}
						onClose={() => setOpenQRModal(false)}
						onImageCaptured={handleImageCaptured}
						handleDownloadQR={handleDownloadQR}
					/>
			}
			<Snackbar
				visible={snackbar.isVisible}
				onDismiss={() => setSnackbar(snackbarInitialState)}
				type={snackbar.type}
				message={snackbar.message}
			/>
		</View>
	)
}


