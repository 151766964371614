import React from 'react'
import { LegalPartnerContainer } from '@containers/LegalPartner'
import { legalPartnerType } from '@utils/constants'
import { useLocation } from 'react-router-dom'
import i18n from '@assets/translation/i18n'

export const LegalPartnerInternPage = () => {
	const location = useLocation()
	i18n.changeLanguage(location.pathname.split('/')[2] || 'en')
    
	return (
		<LegalPartnerContainer  type={legalPartnerType[1]}/>
	)
}
