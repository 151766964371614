import { StyleSheet } from 'react-native'
import { colors, theme } from '@styles/constants'
import { button } from '@styles/mixins/button'

export const styles = StyleSheet.create({
	container: {
		flex: 1,
		flexDirection: 'column',
		paddingVertical: 20,
		paddingHorizontal: 20,
		gap: 20
	},
	headerContainer: {
		gap: 10
	},
	mainContainer: {
		flex: 1,
		flexDirection: 'row',
		gap: 20
	},
	containerLeft: {
		flex: 1,
		gap: 20
	},
	containerRight: {
		display: 'flex',
		flexDirection: 'column',
		gap: 20,
		maxWidth: 370
	},
	headerContainer_mobile: {
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: 30,
		gap: 12
	},
	headerText: {
		fontSize: 26,
		fontWeight: 'bold',
		color: theme.light.colors.mainColor
	},
	dataText: {
		fontSize: 14,
	},
	smallScreenDataText: {
		fontSize: 14,
	},
	ellipsis: {
		width: '110px',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden'
	},
	logValue: {
		display: 'flex',
		flexDirection: 'row'
	},
	tableCellIcon: {
		flexDirection: 'row',
		gap: 12
	},
	toolContainer: {
		backgroundColor: colors.themedGray,
		padding: 20,
		borderRadius: 4,
		gap: 12
	},
	sectionTitle: {
		fontSize: 18,
		fontWeight: '500',
		marginBottom: 20
	},
	inputField: {
		flex: 1,
		gap: 8
	},
	label: {
		fontSize: 16
	},
	backButton: {
		paddingVertical: 10,
		paddingHorizontal: 5,
		textAlign: 'left',
		flexDirection: 'row',
		alignItems: 'center',
		gap: 6,
	},
	backButtonText: {
		color: theme.light.colors.mainColor,
		fontWeight: 'bold',
		fontSize: 16
	},
	titleContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	button: {
		...({
			...button(theme.light.colors.successDark).button,
		} as object),
		borderRadius: 6,
		paddingVertical: 8,
		paddingHorizontal: 16,
		textAlign: 'center',
		width: 100,
		flexDirection: 'row',
		gap: 8,
		justifyContent: 'center',
	},
	buttonText: {
		color: colors.white,
		fontWeight: '600',
		fontSize: 16
	},
	detailsContainer: {
		width: '100%',
		backgroundColor: theme.light.colors.mainContainer,
		padding: 20,
		borderRadius: 30, 
		alignItems: 'flex-start',
		gap: 10,
		shadowColor: theme.light.colors.mainColor,
		shadowOffset: { width: 0, height: 1 },
		shadowOpacity: 0.2,
		shadowRadius: 2,
		elevation: 3,
	},
	detailsColumns: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-around',
		alignItems: 'flex-start',
		width: '100%'
	},
	column: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-around',
		gap: 10
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
	},
	descriptionValue: {
		flex: 5,
		maxWidth: 'calc(100%/6*5)',
	},
	caseLabel: {
		color: theme.light.colors.linkColor,
		alignItems: 'flex-start',
		flex: 1,
		fontWeight: 'bold'
	},
	caseValue: {
		color: theme.light.colors.linkColor,
		alignItems: 'flex-start',
		flex: 2,
	},
	masterPasswordContainer: {
		height: '100%',
		alignItems: 'center',
		justifyContent: 'center',
	},
	headerTextLogs: {
		fontSize: 20,
		fontWeight: 'bold',
		color: theme.light.colors.mainColor,
		marginBottom: 8,
		marginLeft: 16,
	},
})
