import { StyleSheet } from 'react-native'
import { colors, theme } from '@styles/constants'
import { button } from '@styles/mixins/button'

export const styles = StyleSheet.create({
	editContainer: {
		flexDirection: 'column',
		minWidth: 400,
		paddingVertical: 20,
		paddingHorizontal: 20,
		gap: 20,
	},
	editContainer_mobile : {
		flexDirection: 'column',
	},
	actionContainer: {
		flexDirection: 'column',
		flex: 1,
	},
	handlersContainer: {
		flexDirection: 'column',
		flex: 1,
		maxWidth: 370,
	},
	header: {
		alignItems: 'flex-start',
	},
	content: {
		display: 'flex',
		flexDirection: 'row',
		gap: 20,
	},
	form: {
		backgroundColor: theme.light.colors.mainContainer,
		marginBottom: 10,
		padding: 16,
		borderRadius: 30,
		gap: 10,
		shadowColor: theme.light.colors.mainColor,
		shadowOffset: { width: 0, height: 1 },
		shadowOpacity: 0.2,
		shadowRadius: 2,
		elevation: 3,
	},
	header_form:{
		justifyContent: 'space-between',
		flexDirection: 'row',
	},
	formFieldWrapper: {
		width: '95%',
	},
	labelText: {
		fontSize: 16,
		marginBottom: 6,
		paddingLeft: 6,
		paddingTop: 10,
	},
	backButton: {
		paddingVertical: 10,
		paddingHorizontal: 5,
		textAlign: 'left',
		flexDirection: 'row',
		alignItems: 'center',
		gap: 6,
	},
	backIcon: {
		color: theme.light.colors.mainColor,
	},
	backText: {
		color: theme.light.colors.mainColor,
		fontWeight: 'bold',
		fontSize: 16
	},
	buttonText: {
		color: theme.light.colors.linkColorActive,
		fontWeight: '600',
		fontSize: 16,
	},
	title: {
		fontSize: 20,
		color: theme.light.colors.mainColor,
		fontWeight: 'bold'
	},
	icon: {
		color: theme.light.colors.linkColorActive,
	},
	errorText: {
		color: colors.error,
		fontSize: 12,
	},
	button: {
		...({
			...button(theme.light.colors.primaryDark).button,
		} as object),
		paddingVertical: 8,
		paddingHorizontal: 16,
		width: 200,
		flexDirection: 'row',
		gap: 8,
		justifyContent: 'center',
		alignItems: 'center',
	},
	footer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	buttonDark: {
		...({
			...button(theme.light.colors.successDark).button,
		} as object),
		paddingVertical: 8,
		paddingHorizontal: 16,
		width: 200,
		flexDirection: 'row',
		gap: 8,
		justifyContent: 'center',
		alignItems: 'center',
	},
	fileContainer: {
		width: '100%',
	},
})
