import { colors, theme } from '@styles/constants'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
	categoryContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		paddingVertical: 16,
		paddingHorizontal: 32,
		backgroundColor: theme.light.colors.mainContainer,
		borderWidth: 1,
		borderColor: theme.light.colors.mainContainer,
		marginBottom: 5,
		borderRadius: 30,
	},
	categoryContainer_mobile: {
		flexDirection: 'column',
		height: 60,
		paddingVertical: 8,
		paddingHorizontal: 16,
	},
	categoryColumn: {
		flexDirection: 'row',
		alignItems: 'center',
		minWidth: 120,
	},
	categoryColumnSmall: {
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center',
		gap: 8,
	},
})