import {subscriptionPlans} from '@utils/interfaces'
import { reduxActionType } from '@utils/reduxTypes'

export const setIsPaymentNeeded = (payload: boolean): reduxActionType => {
	return {
		type: 'SET_IS_PAYMENT_NEEDED',
		payload,
	}
}

export const setSubscriptionToActivate = (payload: subscriptionPlans): reduxActionType => {
	return {
		type: 'SET_SUBSCRIPTION_TO_ACTIVATE',
		payload
	}
}

export const setPaymentType = (payload: string): reduxActionType => {
	return {
		type: 'CHANGE_PAYMENT_TYPE',
		payload
	}
}