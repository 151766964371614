import { StyleProp, ViewStyle } from 'react-native'
import SelectDropdown from 'react-native-select-dropdown'
import FontAwesome from 'react-native-vector-icons/FontAwesome'
import { colors, theme } from '@styles/constants'
import { styles } from './styles'
import { useTranslation } from 'react-i18next'

interface Props{
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	dropDownItems?: any;
	data?: string[] | number[];
	defaultButtonText?: string;
	fieldName: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleFormValueChange: (fieldName: string, values: any) => void;
	onBlur?: () => void;
	buttonStyle?: StyleProp<ViewStyle>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	value?: string | number | any;
    areItemsObjects?: true;
	disabled?: boolean;
    displayTranslatedValue?: boolean;
}

export const SelectDropDown = (props: Props) => {
	const { t } = useTranslation()
	const renderDropdownIcon = (isOpened: boolean) => {
		return <FontAwesome 
			name={isOpened ? 'caret-down' : 'caret-right'} 
			color={colors.lightGrayIcon} 
			style={props.buttonStyle ? { marginRight: 0 } : { marginRight: 20, minWidth: 12, color: theme.light.colors.mainColor }}
			size={20} />
	}

	return (
		<SelectDropdown
			data={props.data
				? props.data
				: props.areItemsObjects
					? props.dropDownItems
					: props.dropDownItems.map((item) => item.label)}
			defaultButtonText={props.defaultButtonText ?? ' '}
			buttonStyle={props.buttonStyle ? props.buttonStyle : styles.dropdownBtn}
			buttonTextStyle={styles.dropdownBtnText}
			dropdownOverlayColor={theme.light.colors.moreListShadow}
			renderDropdownIcon={isOpened => renderDropdownIcon(isOpened)}
			dropdownIconPosition={'right'}
			dropdownStyle={styles.dropdownStyle}
			rowStyle={styles.SelectRow}
			rowTextStyle={styles.dropdownRowTxt}
			onSelect={(selectedItem) => {
				const selectedOption = props.areItemsObjects ? selectedItem.value : selectedItem
				props.handleFormValueChange(props.fieldName, selectedOption)
			}}
			onBlur={props.onBlur}
			buttonTextAfterSelection={(selectedItem) => {
				const buttonText = props.areItemsObjects ? selectedItem.label : selectedItem
				return props.displayTranslatedValue ? t(buttonText) : buttonText
			}}
			rowTextForSelection={(item) => {
				const rowText = props.areItemsObjects ? item.label : item
				return props.displayTranslatedValue ? t(rowText) : rowText
			}}
			defaultValue={props.value}
			disabled={props.disabled !== undefined ? props.disabled : false}
		/>
	)
}

