import { StyleSheet } from 'react-native'
import { colors, theme } from '@styles/constants'

export const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	subscriptionItem: {
		paddingHorizontal: 20,
		paddingVertical: 30,
		borderRadius: 20,
		alignItems: 'center',
		backgroundColor: theme.light.colors.mainContainer,
		borderColor: theme.light.colors.boxBorder,
		borderWidth: 1,
		gap: 12,
		width: '32%',
		justifyContent: 'space-between',
	},
	subscriptionItemActive: {
		shadowColor: theme.light.colors.mainColor,
		shadowOffset: { width: 0, height: 4 },
		shadowOpacity: 0.4,
		shadowRadius: 4,
		elevation: 4,
	},
	price: {
		fontSize: 38,
		fontWeight: 'bold',
		color: theme.light.colors.mainColor
	},
	title: {
		fontSize: 24,
		fontWeight: 'bold',
		textAlign: 'center',
		color: theme.light.colors.mainColor,
	},
	logo: {
		width: 100,
		height: 100,
		borderRadius: 50,
		justifyContent: 'center',
		alignItems: 'center'
	},
	textContainer: {
		padding: 10,
		gap: 10,
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		width: '100%',
		marginBottom: 10,
	},
	text: {
		color: theme.light.colors.mainColor,
		fontSize: 14,
	},
	textBold: {
		fontWeight: '500'
	},
	button: {
		flexDirection: 'row',
		gap: 8,
		backgroundColor: theme.light.colors.primaryDark,
		borderRadius: 6,
		paddingVertical: 10,
		paddingHorizontal: 20,
		justifyContent: 'center',
		alignItems: 'center',
		shadowColor: theme.light.colors.mainColor,
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.2,
		shadowRadius: 2,
		elevation: 3,
	},
	buttonText: {
		textAlign: 'center',
		color: 'white',
		fontWeight: 'bold',
		fontSize: 16
	},
	priceContainer: {
		flexDirection: 'row',
		alignItems: 'flex-end',
		gap: 5
	},
	priceValut: {
		fontWeight: 'bold',
		fontSize: 18,
		color: theme.light.colors.mainColor,
		paddingBottom: 6,
	},
	active: {
		backgroundColor: colors.dimmedGreen,
		color: colors.success,
		paddingHorizontal: 20,
		paddingVertical: 10,
		borderRadius: 4,
		fontWeight: 'bold',
		fontSize: 16,
		borderColor: colors.success,
		borderWidth: 1,
	},
	activeUntil: {
		backgroundColor: colors.dimmedYellow,
		color: colors.yellow,
		paddingHorizontal: 20,
		paddingVertical: 10,
		borderRadius: 4,
		fontWeight: 'bold',
		fontSize: 16,
		borderColor: colors.yellow,
		borderWidth: 1,
		textAlign: 'center'
	},
	benefitsItem: {
		flexDirection: 'row',
		gap: 5,
	},
	subscriptionItemInfo: {
		width: '100%',
		gap: 12,
		alignItems: 'center',
	},
})
