import { StyleSheet } from 'react-native'
import { colors, theme } from '@styles/constants'
import { casesTypes } from '@utils/constants'

export const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		gap: 10
	},
	boxCaseTypeContainer: {
		flexDirection: 'row',
		gap: 5
	},
	boxCaseTypeText: {
		fontSize: 18,
		fontWeight: '500',
		color: theme.light.colors.secondaryColor
	},
	boxCaseNumberText: {
		fontSize: 36,
		fontWeight: '600',
		color: theme.light.colors.linkColor
	},
	boxCaseContainerCustom: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	containerText: {
		gap: 5
	}
})

export const boxCaseContainer = (type: keyof typeof casesTypes) => {
	let bgColor = ''

	switch (type) {
	case casesTypes.newCases:
		bgColor = theme.light.colors.primary
		break
	case casesTypes.openCases:
		bgColor = theme.light.colors.success
		break
	case casesTypes.closedCases:
		bgColor = theme.light.colors.warning
		break
	case casesTypes.rejectedCases:
		bgColor = theme.light.colors.danger
		break
	}
	return {
		backgroundColor: bgColor,
		flex: 1,
		padding: 20,
		gap: 10,
		borderRadius: 30
	}
}
