import { colors, theme } from '@styles/constants'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
	stateCase: {
		flexDirection: 'column',
		padding: 50,
		backgroundColor: theme.light.colors.mainContainer,
		borderRadius: 30,
	},
	left: {
		flex: 1,
		padding: 10,
		justifyContent: 'space-between'
	},
	right: {
		flex: 2,
		padding: 10,
		justifyContent: 'space-between',
		gap: 50
	},
	title: {
		fontSize: 24,
		fontWeight: 'bold',
		color: theme.light.colors.titleColor,
		marginBottom: 10,
	},
	description: {
		marginBottom: 20,
		color: theme.light.colors.titleColor,
	},
	row: {
		flexDirection: 'row',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		marginBottom: 20,
	}
})
