import { reduxActionType } from '@utils/reduxTypes'

const initialState = {
	companies: {
		by: '',
		order: ''
	},
	cases: {
		by: 'DEADLINE',
		order: 'DESC'
	}
}

export const sortReducer = (
	state = initialState,
	action: reduxActionType
): (typeof initialState) => {
	switch (action.type) {
	case 'SET_COMPANIES_SORTING': {
		return {
			...state,
			companies: {
				by: action.payload.by,
				order: action.payload.order
			}
		}
	}
	case 'SET_CASES_SORTING': {
		return {
			...state,
			cases: {
				by: action.payload.by,
				order: action.payload.order
			}
		}
	}
	default: return state
	}
}
