import { reduxActionType } from '@utils/reduxTypes'

const initialState = {
	pageSize: 10
}

export const paginationReducer = (
	state = initialState,
	action: reduxActionType
): (typeof initialState) => {
	switch (action.type) {
	case 'SET_PAGE_SIZE': {
		return { ...state, pageSize: parseInt(action.payload) }

	}
	default: return state
	}
}