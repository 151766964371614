import { theme } from '@styles/constants'

export const button = ( bgColor = theme.light.colors.buttonLg ) => {
	return {
		button: {
			backgroundColor: bgColor,
			borderRadius: 6,
			paddingVertical: 10,
			paddingHorizontal: 20,
			textAlign: 'center',
			flexDirection: 'row',
			gap: 8
		}
	}
}

export const buttonText = () => {
	return {
		buttonText: {
			color: theme.light.colors.linkColorActive,
			fontWeight: 'bold'
		}
	}
}
