import React, { FC, useState } from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import { colors, theme } from '@styles/constants'
import { combineAddressParts, getSizeInterval, isCUIValid } from '@utils/utils'
import { getCompanyDetails } from '@services/apiService'
import { styles } from './styles'
import { ItemWithTooltip } from '@components/ItemWithTooltip'
import { Loader } from '@components/Loader/Loader'
import { SelectDropDown } from '@components/SelectDropDown/SelectDropdown'
import { t } from 'i18next'
import FontAwesome from 'react-native-vector-icons/FontAwesome'
import { FormikProps } from 'formik'
import { Input } from '@components/Input'

interface InputProps {
	companySizes: string[]
	isLegalPartnerIntern: boolean
	formikProps: FormikProps<any>
	setIsCUIValid: (value: boolean) => void
}

interface Company {
	vatNr: string,
	name: string,
	nrOfEmployees: string,
	city: string,
	street: string,
	zipCode: string
}

export const InputCUI: FC<InputProps> = ({ companySizes, isLegalPartnerIntern, formikProps, setIsCUIValid }) => {
	const initialCompanyState = {
		vatNr: '',
		name: '',
		nrOfEmployees: '',
		city: '',
		street: '',
		zipCode: ''
	}

	const [selectedCompany, setSelectedCompany] = useState<Company>(initialCompanyState)
	const [isLoading, setIsLoading] = useState(false)
	const [responseStatus, setResponseStatus] = useState('')

	const handleCompanyDetails = (companyData) => {
		const { cod_fiscal, nume, nrOfEmployees, adresa_localitate, adresa_strada, adresa_nr_strada, adresa_cod_postal, adresa_bloc, adresa_scara, adresa_etaj, adresa_apartament } = companyData
		const address = combineAddressParts({
			number: adresa_nr_strada,
			building: adresa_bloc, 
			staircase: adresa_scara, 
			floor: adresa_etaj,
			apartment: adresa_apartament
		})
		const updatedCompany = {
			vatNr: cod_fiscal,
			name: nume.trim(),
			nrOfEmployees: getSizeInterval(nrOfEmployees, companySizes) ,
			city: adresa_localitate.trim(),
			street: `${adresa_strada}${address !== '' ? ', ' : ''} ${address}`,
			zipCode: formatZipCode(adresa_cod_postal.trim()),
		}
		
		setSelectedCompany(updatedCompany)
		formikProps.setValues({ ...formikProps.values, ...updatedCompany })
		setResponseStatus('Success')
	}
	
	const getCompanyDetailsByCUI = async (vatNumber: string) => {
		setIsLoading(true)
		try {
			const company = await getCompanyDetails(vatNumber)
			if (company && Object.keys(company.data).length > 0) {
				handleCompanyDetails(company.data)
			} else {
				setResponseStatus(t('cuiStatus'))
				setIsCUIValid(false)
			}
		} catch (error) {
			setResponseStatus(error.response?.data.message || t('cuiStatus'))
		} finally {
			setIsLoading(false)
		}
	}
	
	const onChangeVat = (vatNumber) => {
		const lowerCasedNumber = vatNumber.toLowerCase()
		const validVatNumber = lowerCasedNumber.includes('ro') ? lowerCasedNumber.replace('ro', '') : lowerCasedNumber
	
		if (isCUIValid(validVatNumber)) {
			getCompanyDetailsByCUI(validVatNumber)
			setIsCUIValid(true)
		} else {
			setIsCUIValid(false)
			setResponseStatus(t('formValidation.invalidVATNumber'))
			selectedCompany.nrOfEmployees && setSelectedCompany(initialCompanyState)
		}
	
		formikProps.validateForm()
	}
	
	const onChangeField = (field, value) => {
		const fieldValue = selectedCompany[field] ? (field === 'nrOfEmployees' ? getSizeInterval(selectedCompany[field], companySizes) : selectedCompany[field]) : value
		formikProps.setFieldValue(field, fieldValue)
	}

	const handleValidateCUI = () => {
		const cui = formikProps.values['vatNr']
		if (responseStatus === 'Success' || responseStatus === 'Invalid' || responseStatus === t('formValidation.invalidVATNumber') || responseStatus === t('cuiStatus')) {
			setIsCUIValid(false)
			formikProps.setValues({ ...formikProps.values, ...initialCompanyState })
			setSelectedCompany(initialCompanyState)
			setResponseStatus('')
		} else {
			onChangeVat(cui)
		}
	}

	const computeButtonText = () => {
		switch(responseStatus) {
		case 'Success':
		case 'Invalid':
		case t('formValidation.invalidVATNumber'):
		case t('cuiStatus'):
			return t('changeCUI')
		case '':
			return t('validateCUI')
		default: 
			return t('validateCUI')
		}
	}

	const formatZipCode = (zipCode: string) => {
		if (zipCode && zipCode.length === 5) {
			return '0' + zipCode
		}
		return zipCode || ''
	}

	const isDisabled = isLoading || (formikProps.values['vatNr'].length > 10 || formikProps.values['vatNr'].length < 2)

	return (
		<>
			<View style={styles.field}>
				<View style={styles.fieldHeader}>
					<Text style={styles.label}>{t('fieldLabels.vatNr')}</Text>
					<ItemWithTooltip iconName='info-circle' tooltip={t('inputPlaceholders.vatNumberTooltip')} style={styles.tooltip} size={15} />
				</View>
				<View style={styles.validate}>
					<View style={{ position: 'absolute', right: '38%', top: '25%' }}>
						{ 
							isLoading && <Loader color={theme.light.colors.primaryDark} size={'small'} /> 
						}
						{
							responseStatus ? 
								<FontAwesome 
									name={responseStatus === 'Success' ? 'check' : 'remove'} 
									color={responseStatus === 'Success' ? theme.light.colors.primaryDark : colors.danger} 
									size={20} /> : <></>
						}
					</View>
					<Input
						editable={!isLoading && computeButtonText() !== t('changeCUI')}
						name='vatNr'
						value={formikProps.values['vatNr']}
						onChange={(name, value) => onChangeField(name, value)}
						onBlur={() => { 
							formikProps.setFieldTouched('vatNr', true)
						}}
					/>
					<TouchableOpacity onPress={() => handleValidateCUI()} disabled={isDisabled} style={[styles.validateButton, isDisabled && styles.disabled]}>
						<Text style={styles.validateText}>{computeButtonText()}</Text>
					</TouchableOpacity>
				</View>
				{((formikProps.touched['vatNr'] && formikProps.errors['vatNr']) || (responseStatus && responseStatus !== 'Success')) && (
					<Text style={styles.errorText}>{(responseStatus && responseStatus !== 'Success') ? `*${responseStatus}` : `*${formikProps.errors['vatNr']}`}</Text>
				)}
			</View>
			<View style={styles.field}>
				<View style={styles.fieldHeader}>
					<Text style={styles.label}>{t('fieldLabels.name')}</Text>
					<ItemWithTooltip iconName='info-circle' tooltip={t('inputPlaceholders.companyNameTooltip')} style={styles.tooltip} size={15} />
				</View>
				<Input
					editable
					name='name'
					value={formikProps.values['name']}
					onChange={formikProps.setFieldValue}
					onBlur={() => formikProps.setFieldTouched('name', true)}
				/>
				{formikProps.touched['name'] && formikProps.errors['name'] && (
					<Text style={styles.errorText}>{`*${formikProps.errors['name']}`}</Text>
				)}
			</View>	
			{
				isLegalPartnerIntern &&
							<View style={styles.field}>
								<View style={styles.fieldHeader}>
									<Text style={styles.label}>{t('fieldLabels.numberOfEmployees')}</Text>
									<ItemWithTooltip iconName='info-circle' tooltip={t('inputPlaceholders.employeesNumberTooltip')} style={styles.tooltip} size={15} />
								</View>
								<SelectDropDown 
									buttonStyle={!!selectedCompany.nrOfEmployees && styles.dropdownDisabled}
									disabled={!!selectedCompany.nrOfEmployees}
									data={companySizes}
									defaultButtonText={t('fieldLabels.numberOfEmployees')}
									fieldName={'nrOfEmployees'}
									value={selectedCompany.nrOfEmployees ? getSizeInterval(selectedCompany.nrOfEmployees, companySizes) : formikProps.values['nrOfEmployees']}
									handleFormValueChange={formikProps.setFieldValue}
									onBlur={() => formikProps.setFieldTouched('nrOfEmployees', true)}
								/>
								{formikProps.touched['nrOfEmployees'] && formikProps.errors['nrOfEmployees'] && (
									<Text style={styles.errorText}>{`*${formikProps.errors['nrOfEmployees']}`}</Text>
								)}
							</View>
			}
			<View style={styles.field}>
				<View style={styles.fieldHeader}>
					<Text style={styles.label}>{t('fieldLabels.city')}</Text>
					<ItemWithTooltip iconName='info-circle' tooltip={t('inputPlaceholders.cityTooltip')} style={styles.tooltip} size={15} />
				</View>
				<Input
					editable
					name='city'
					value={formikProps.values['city']}
					onChange={formikProps.setFieldValue}
					onBlur={() => formikProps.setFieldTouched('city', true)}
				/>
				{formikProps.touched['city'] && formikProps.errors['city'] && (
					<Text style={styles.errorText}>{`*${formikProps.errors['city']}`}</Text>
				)}
			</View>
			<View style={styles.field}>
				<View style={styles.fieldHeader}>
					<Text style={styles.label}>{t('fieldLabels.address')}</Text>
					<ItemWithTooltip iconName='info-circle' tooltip={t('inputPlaceholders.streetAddressTooltip')} style={styles.tooltip} size={15} />
				</View>
				<Input
					editable
					name='street'
					value={formikProps.values['street']}
					onChange={formikProps.setFieldValue}
					onBlur={() => formikProps.setFieldTouched('street', true)}
				/>
				{formikProps.touched['street'] && formikProps.errors['street'] && (
					<Text style={styles.errorText}>{`*${formikProps.errors['street']}`}</Text>
				)}
			</View>
			<View style={styles.field}>
				<View style={styles.fieldHeader}>
					<Text style={styles.label}>{t('fieldLabels.zipCode')}</Text>
					<ItemWithTooltip iconName='info-circle' tooltip={t('inputPlaceholders.zipCodeTooltip')} style={styles.tooltip} size={15} />
				</View>
				<Input
					editable
					name='zipCode'
					value={formikProps.values['zipCode']}
					onChange={formikProps.setFieldValue}
					onBlur={() => formikProps.setFieldTouched('zipCode', true)}
				/>
				{formikProps.touched['zipCode'] && formikProps.errors['zipCode'] && (
					<Text style={styles.errorText}>{`*${formikProps.errors['zipCode']}`}</Text>
				)}
			</View>
		</>
	)
}


