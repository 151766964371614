import React, { useEffect, useState } from 'react'
import { StyleSheet, Text, TouchableOpacity, View, TextInput, Modal } from 'react-native'
import { colors } from '@styles/constants'
import { useTranslation } from 'react-i18next'
import * as Clipboard from 'expo-clipboard'
import Icon from 'react-native-vector-icons/FontAwesome'
import Checkbox from 'expo-checkbox'
import { useSelector } from 'react-redux'
import { rootReducerType } from '@reducers/combineReducers'
import { useNavigate } from 'react-router-dom'
import { ViewTypes } from '@utils/interfaces'

interface ModalProps {
  isModalVisible: boolean;
  closeModal: () => void;
}

export const CaseSubmitted = ({isModalVisible, closeModal}: ModalProps) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const [isChecked, setIsChecked] = useState(false)
	const [errorPasswordConfirmation, setErrorPasswordConfirmation] = useState(false)

	const passwordCase = useSelector((state: rootReducerType) => state.setCaseKeyReducer.pass)

	const copyToClipboard = async () => {
		await Clipboard.setStringAsync(inputValues.password)
	}

	const [inputValues, setInputValues] = useState({
		password: passwordCase,
		email: ''
	})

	const handleFormValueChange = (key, value) => {
		setInputValues(
			{
				...inputValues,
				[key]: value
			}
		)
	} 

	const submitForm = () => {
		if (isChecked){
			navigate(`/${ViewTypes.COMPANY}/cases`)
		} else {
			setErrorPasswordConfirmation(true)
		}
	}

	useEffect(() => {
		isChecked && setErrorPasswordConfirmation(false)
	}, [isChecked])

	return (
		<Modal visible={isModalVisible} transparent={true} animationType="slide">
			<View style={styles.container}>
				<Text style={styles.title}>{t('submittedReportTitle')}</Text>
				<View style={styles.textContainer}>
					<Text style={styles.errorText}>{t('important')}</Text>
					<Text style={styles.labelText}>{t('submittedReportImportantMessage')}</Text>
				</View>
				<Text style={styles.labelTextBold}>{t('savePasswordMessage')}</Text>
				<View style={styles.inputContainer}>
					<TextInput
						style={styles.formFieldText}
						value={inputValues.password}
						editable={false}
					/>
					<TouchableOpacity 
						style={styles.button}
						onPress={copyToClipboard}>
						<Icon name="copy" size={18} color={colors.white} />
						<Text style={styles.buttonText}>{t('copy')}</Text>
					</TouchableOpacity>
			
				</View>
				<TouchableOpacity 
					style={styles.button}
					onPress={() => {}}>
					<Icon style={styles.icon} name="download" size={18} />
					<Text style={styles.buttonText}>{t('download')}</Text>
				</TouchableOpacity>

				<View style={styles.formFieldWrapper}>
					<Text style={styles.labelText}>{t('emailOptional')}</Text>
					<TextInput
						placeholder={t('emailOptional')}
						style={styles.formFieldText}
						onChange={(event) => handleFormValueChange('email', event.nativeEvent.text)}
					/>
				</View>
				<View style={styles.textFooter}>
					<Text style={styles.labelText}>{t('emailDescription')}</Text>
					<Text style={styles.labelText}>{t('emailMessageReceive')}</Text>
					<Text style={styles.labelText}>{t('updatedStatus')}</Text>
					<Text style={styles.labelText}>{t('newCase')}</Text>
					<Text style={styles.labelText}>{t('notEnterEmail')}</Text>
					<Text style={styles.labelTextBold}>{t('passwordEmailWarning')}</Text>
				</View>
			
				<View style={styles.checkBoxContainer}>
					<Checkbox 
						value={isChecked} 
						onValueChange={setIsChecked}
						color={isChecked ? colors.main : errorPasswordConfirmation ? colors.error : colors.darkAccent} />
					<Text style={styles.labelText}>{t('storedPassword')}</Text>	
				</View>
				{errorPasswordConfirmation && (
					<Text style={styles.errorText}>{t('storedPasswordError')}</Text>
				)}

				<TouchableOpacity 
					style={styles.buttonSubmitt}
					onPress={submitForm}>
					<Icon style={styles.icon} name="check" size={18} />
					<Text style={styles.buttonText}>{t('continue')}</Text>
				</TouchableOpacity>
			
			</View>
		</Modal>
	)
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		padding: 30,
		gap: 20,
		width: '70%',
		margin: 'auto',
		backgroundColor: colors.lightGray,
		height: '80%',
		borderRadius: 6
	},
	textContainer: {
		flexDirection: 'row',
		gap: 6
	},
	inputContainer: {
		flexDirection: 'row',
		gap: 6,
		width: '90%',
		justifyContent: 'space-between'
	},
	checkBoxContainer: {
		flexDirection: 'row',
		width: '95%',
		margin: 'auto',
		alignItems: 'center'
	},
	title: {
		fontSize: 18,
		color: colors.darkShades,
		fontWeight: 'bold',
		marginBottom: 16
	},
	errorText: {
		fontSize: 16,
		color: colors.error,
		fontWeight: 'bold'
	},
	icon: {
		color: colors.white
	},
	iconDark: {
		color: colors.darkAccent
	},
	formFieldWrapper: {
		width: '90%',
		gap: 6
	},
	formFieldText: {
		fontSize: 16,
		borderRadius: 4,
		borderWidth: 1,
		borderColor: colors.darkAccent,
		padding: 12,
		backgroundColor: colors.white,
		width: '100%'
	},
	formFieldText_large: {
		fontSize: 16,
		borderRadius: 4,
		borderColor: colors.darkAccent,
		height: 170,
		borderWidth: 1,
		padding: 12,
		backgroundColor: colors.white,
	},
	labelText: {
		fontSize: 16,
		paddingLeft: 6
	},
	labelTextBold: {
		fontSize: 16,
		marginBottom: 6,
		paddingLeft: 6,
		fontWeight: '500'
	},
	button: {
		backgroundColor: colors.darkShades,
		borderRadius: 6,
		paddingVertical: 10,
		paddingHorizontal: 20,
		width: 120,
		height: 48,
		flexDirection: 'row',
		gap: 8,
		justifyContent: 'center',
		alignItems: 'center'
	},
	buttonSubmitt: {
		backgroundColor: colors.main,
		borderRadius: 6,
		paddingVertical: 10,
		paddingHorizontal: 20,
		width: 120,
		height: 48,
		flexDirection: 'row',
		gap: 8,
		justifyContent: 'center',
		alignItems: 'center'
	},
	buttonText: {
		color: 'white',
		fontWeight: 'bold',
		fontSize: 16
	},
	textFooter: {
		flexDirection: 'column',
		gap: 4
	}
})