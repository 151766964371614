import { colors } from '@styles/constants'
import { ViewTypes } from '@utils/interfaces'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import Icon from 'react-native-vector-icons/FontAwesome'
import { useNavigate } from 'react-router-dom'

export const CaseImportForm = () => {
	const navigate = useNavigate()
	const { t }= useTranslation()

	return (
		<View style={styles.container}>

			<View style={styles.header}>
				<TouchableOpacity 
					style={styles.backButton}
					onPress={() => navigate(`/${ViewTypes.COMPANY}/cases`)}>
					<Icon style={styles.icon} name="arrow-left" size={18} />
					<Text style={styles.buttonText}>{t('back')}</Text>
				</TouchableOpacity>
			</View>
			<View>
				<View style={styles.form}>
					<View style={styles.header_form}>
						<Text style={styles.title}>{t('ImportCaseTitle')}</Text>
					</View>
					<View style={styles.firstContainer}>
						<View style={styles.stepOneContainer}>
							<View>
								<Text style={[styles.labelText, styles.importantText]}>{t('downloadCSV')}</Text>
								<Text style={styles.labelText}>{t('additionalInfosForDownload')}</Text>
							</View>
			
							<TouchableOpacity 
								style={styles.button}
								onPress={() => {}}>
								<Icon style={styles.icon} name="download" size={18} />
								<Text style={styles.buttonText}>{t('download')}</Text>
							</TouchableOpacity>
						</View>

						<View>
							<Icon style={styles.iconFile} name="file-text" size={100} />
						</View>
					</View>

			

					<View style={styles.stepTwoContainer}>
						<View>
							<Text style={[styles.labelText, styles.importantText]}>{t('fillData')}</Text>
							<Text style={[styles.labelText, styles.importantText]}>{t('FillDataNotice')}</Text>
							<Text style={styles.labelText}>{t('FillDataText')}</Text>
						</View>
					</View>

				</View>
			</View>
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		padding: 20,
		gap: 8,
		width: '80%',
		margin: 'auto',
		marginBottom: 20
	},
	header: {
		alignItems: 'flex-start',
		padding: 0
	},
	form: {
		backgroundColor: colors.lightGray,
		height: '97%',
		marginBottom: 10,
		padding: 20,
		borderRadius: 6,
		gap: 10
	},
	header_form:{
		justifyContent: 'space-between',
		flexDirection: 'row'
	},
	backButton: {
		paddingVertical: 10,
		paddingHorizontal: 5,
		textAlign: 'left',
		flexDirection: 'row',
		alignItems: 'center',
		gap: 6
	},
	buttonText: {
		color: colors.white,
		fontWeight: 'bold',
		fontSize: 16
	},
	title: {
		fontSize: 18,
		color: colors.darkShades,
		fontWeight: 'bold'
	},
	icon: {
		color: colors.white
	},
	iconFile: {
		color: colors.darkShades,
		marginLeft: '20px'
	},
	labelText: {
		fontSize: 16,
		marginBottom: 6,
		paddingLeft: 6,
		paddingTop: 10,
		color: colors.darkShades
	},
	button: {
		backgroundColor: colors.darkShades,
		borderRadius: 6,
		paddingVertical: 10,
		paddingHorizontal: 20,
		width: 120,
		height: 48,
		flexDirection: 'row',
		gap: 8,
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: 10
	},
	stepOneContainer: {
		width: '60%',
		flex: 1,
		flexDirection: 'column'
	},
	importantText: {
		fontWeight: '500'
	},
	firstContainer: {
		display: 'flex',
		flexDirection: 'row'
	},
	stepTwoContainer: {

	},
	containerDragAndDrop: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
	},
	dropZone: {
		width: 200,
		height: 200,
		backgroundColor: 'lightgray',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 10,
	},
	text: {
		textAlign: 'center',
	},
})