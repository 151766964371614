import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'react-native'
import { CaseStateBox } from './CaseStateBox/CaseStateBox'
import { styles } from './styles'

export const CaseStateTab = () => {
	const { t } = useTranslation()

	return (
		<View style={styles.stateCase}>
			<View style={styles.row}>
				<View style={styles.left}>
					<Text style={styles.title}>{ t('caseStateInfo') }</Text>
					<Text style={styles.description}>{ t('caseStateDescription') }</Text>
				</View>
				<View style={styles.right}>
					<CaseStateBox />
				</View>
			</View>
		</View>
	)
}


