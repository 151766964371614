import { reduxActionType } from '@utils/reduxTypes'

const initialState = {
	isPaymentNeeded: false,
	subscriptionToActivate: { subscriptionType: '', price: 0 },
	paymentType: ''
}

export const paymentReducer = (
	state = initialState,
	action: reduxActionType
): (typeof initialState) => {
	switch (action.type) {
	case 'SET_IS_PAYMENT_NEEDED': {
		return { ...state, isPaymentNeeded: action.payload }
	}
	case 'SET_SUBSCRIPTION_TO_ACTIVATE': {
		return { ...state, subscriptionToActivate: action.payload }
	}
	case 'CHANGE_PAYMENT_TYPE': {
		return { ...state, paymentType: action.payload }
	}
	default: return state
	}
}