import { colors, theme } from '@styles/constants'
import { button } from '@styles/mixins/button'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
	container: {
		minHeight: '40%',
		backgroundColor: theme.light.colors.mainContainer,
		borderRadius: 30,
		width: '80%',
		margin: 'auto',
		paddingHorizontal: 16,
		paddingVertical: 16,
		gap: 16,
		shadowColor: theme.light.colors.mainColor,
		shadowOffset: { width: 0, height: 1 },
		shadowOpacity: 0.2,
		shadowRadius: 2,
		elevation: 3,
	},
	containerLarge: {
		width: '100%',
		backgroundColor: theme.light.colors.mainContainer,
		borderRadius: 30,
		margin: 'auto',
		paddingHorizontal: 16,
		paddingVertical: 16,
		gap: 10,
		shadowColor: theme.light.colors.mainColor,
		shadowOffset: { width: 0, height: 1 },
		shadowOpacity: 0.2,
		shadowRadius: 2,
		elevation: 3,
	},
	sectionTitle: {
		fontSize: 20,
		fontWeight: 'bold',
		marginBottom: 10,
		color: colors.darkAccent,
	},	
	button: {
		...({
			...button(theme.light.colors.primaryDark).button,
		} as object),
		flexDirection: 'row',
		borderRadius: 6,
		paddingVertical: 8,
		paddingHorizontal: 16,
		textAlign: 'center',
		width: 240,
		gap: 8,
		justifyContent: 'center',
	},
	button_mobile: {
		width: '100%'
	},
	buttonDark: {
		...({
			...button(theme.light.colors.successDark).button,
		} as object),
		borderRadius: 6,
		paddingVertical: 8,
		paddingHorizontal: 16,
		textAlign: 'center',
		width: 240,
		flexDirection: 'row',
		gap: 8,
		justifyContent: 'center',
	},
	buttonDark_mobile: {
		width: '100%'
	},
	buttonText: {
		color: colors.white,
		fontWeight: '600',
		fontSize: 16
	},
	messagesContainer: {
		width: '100%',
		margin: 'auto',
		gap: 10,
	},
	noMessagesContainer: {
		justifyContent: 'center',
		alignItems: 'center'
	},
	formFieldWrapper: {
		width: '100%',
		margin: 'auto'
	},
	labelText: {
		fontSize: 18,
		marginBottom: 6,
		paddingLeft: 6,
		paddingTop: 10,
		fontWeight: 'bold',
		color: theme.light.colors.warningDark
	},
	labelTextSmall: {
		fontSize: 14,
		marginBottom: 6,
		paddingLeft: 6,
		paddingTop: 10
	},
	icon: {
		color: colors.white
	},
	iconDark: {
		color: colors.darkAccent
	},
	buttonsContainer: {
		width: '80%',
		gap: 8,
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	buttonsContainer_smallScreen: {
		gap: 8,
		flexDirection: 'column'
	},
	footer: {
		justifyContent: 'space-between',
		alignItems: 'flex-start',
		gap: 12,
		width: '100%',
		margin: 'auto'
	},
	footer_smallScreen: {
		width: '100%',
		margin: 'auto',
		gap: 12
	},
	voiceContainer: {
		width: '60%'
	},
	voiceContainer_mobile: {
		width: '100%'
	},
	errorText: {
		color: colors.error,
		fontSize: 12,
	}
})