import React,{ FC, useState } from 'react'
import { View, TouchableOpacity, Text} from 'react-native'
import { styles } from './styles'
import Icon from 'react-native-vector-icons/Feather'
import { useNavigate } from 'react-router-dom'
import { Notification, ViewTypes } from '@utils/interfaces'
import {theme} from '@styles/constants'
import { formatTimestamp, isCompanyAdminOrUser, truncateDescription } from '@utils/utils'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { rootReducerType } from '@reducers/combineReducers'
import {readNotification} from '@services/apiService'
import {readNotificationHistory} from '@actions/notificationActions'
import {useDispatch} from 'react-redux'

interface Props {
	unreadCount: number,
	notifications: Notification[],
}

export const NotificationBell: FC<Props> = ({ notifications }) => {
	const [expanded, setExpanded] = useState(false)
  
	const navigate = useNavigate()
	const { roles } = useSelector((state: rootReducerType) => state.userReducer)
	const { t } = useTranslation()
	const isCompany = isCompanyAdminOrUser(roles)
	const dispatch = useDispatch()
	const userKey = useSelector((state: rootReducerType) => isCompany ? state.companyUserKeyReducer.key : state.legalPartnerKeyReducer.key)

	const handleBellPress = () => {
		setExpanded(!expanded)
	}
	
	const unreadNotifications = notifications.filter(notification => !notification.isRead).sort((a, b) => {
		const timestampA = new Date(a.timestamp).getTime()
		const timestampB = new Date(b.timestamp).getTime()
		return timestampB - timestampA
	})
	
	const handleNotificationRead = async (selectedNotification) => {
		if (!selectedNotification.isRead) {
			try {
				const notification = await readNotification(selectedNotification.notificationHistoryKey, userKey)
				dispatch(readNotificationHistory(notification.data.notificationHistoryKey))
				navigate(isCompany ?`/${ViewTypes.COMPANY}/notifications/${selectedNotification.notificationHistoryKey}` : `/${ViewTypes.PARTNER}/notifications/${selectedNotification.notificationHistoryKey}`)
			} catch (error) {
				console.error(error)
			}
		}
	}

	return (
		<View style={styles.unreadContainer}>
			<TouchableOpacity onPress={handleBellPress}>
				<View style={{...styles.bellContainer, ...(expanded && {backgroundColor: theme.light.colors.primary, borderRadius: '50%'})}}>
					<Icon name='bell' size={24} color={'black'} />
					{unreadNotifications && unreadNotifications.length > 0 && <View style={styles.dot} />}
				</View>
			</TouchableOpacity>
			{expanded && (
				<View style={styles.expandedContainer}>
					<View style={styles.notificationContainer}>
						{unreadNotifications && unreadNotifications.length > 0 ? (
							unreadNotifications.map((notification, index) => (
								<TouchableOpacity key={index} style={styles.unreadNotification} onPress={() => handleNotificationRead(notification)}>
									<View style={styles.notificationItem}>
										<View style={styles.notificationItemDot} />
										<View style={{flex: 1, justifyContent: 'space-between', flexDirection: 'row'}}>
											<Text style={styles.notificationText}>{t(`notificationHistoryTypes.${notification.notificationType}`)}</Text>
											<Text style={styles.timestamp}>{formatTimestamp(new Date(notification.timestamp))}</Text>
										</View>
									</View>
									<Text style={styles.bellDescription}>{truncateDescription(notification.content, 45)}</Text>
								</TouchableOpacity>
							))
						) : (
							<Text style={styles.noNotifications}>{t('noNotifications')}</Text>
						)}
					</View>
				</View>
			)}
		</View>
	)
}