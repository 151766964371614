import React, {useEffect} from 'react'
import { View, Text, ScrollView, TouchableOpacity } from 'react-native'
import { useTranslation } from 'react-i18next'
import useScreenDimensions from '@components/Common/UseScreenDimensions'
import { screenType } from '@utils/constants'
import Checkbox from 'expo-checkbox'
import { styles } from './styles'

export const CheckboxList = ({ categoryData, categoryTitle, selectedItems,  onCheckboxChange, toggleCategorySelection  }) => {
	const { t } = useTranslation()
	const screenDimensions = useScreenDimensions()

	const tablet = screenDimensions.width < screenType.tablet

	const toggleSelection = () => {
		toggleCategorySelection(categoryTitle)
	}
	const handleCheckboxChange = (itemName) => {
		const updatedItems = {...selectedItems,
			[itemName]: !selectedItems[itemName],
		}
		onCheckboxChange(itemName, updatedItems)
	}

	return (
		<ScrollView style={tablet ? styles.categoryContainer_mobile : styles.categoryContainer}
			contentContainerStyle={styles.categoryContent}>
			<ScrollView style={styles.containerFilters}>
			
				<View style={styles.titleContainer}>
					<Text style={styles.categoryTitle}>{categoryTitle}</Text>
					<TouchableOpacity style={styles.button} onPress={toggleSelection}>
						<Text style={styles.buttonText}>{t('selectAll')}</Text>
					</TouchableOpacity>
				</View>
				
				{categoryData.map((item) => (
					<View key={item.id} style={styles.checkBoxContainer}>
						<Checkbox
							value={selectedItems[item.name]}
							onValueChange={() => handleCheckboxChange(item.name)}
						/>
						<Text>{item.text}</Text>
						
					</View>
				))}
			</ScrollView>
		</ScrollView>
	)
}
