import { colors } from '@styles/constants'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
	formField: {
		flex: 1,
		flexDirection: 'row',
		gap: 6,
	},
	dropdownButton: {
		backgroundColor: colors.pureWhite,
		borderRadius: 4,
		borderWidth: 1,
		width: '20%',
		height: 35
	},
	dropdownBttn_mobile: {
		margin: 0,
		paddingRight: 3,
		backgroundColor: colors.pureWhite,
		borderRadius: 4,
		borderWidth: 1,
		height: 35,

	},
	dropdownButtonText: {
		fontSize: 16,
		textAlign: 'left',
		color: colors.darkShades, 
	},
	dropdownStyle: {
		backgroundColor: colors.pureWhite,
	},
	dropdown: {
		backgroundColor: colors.pureWhite,
	},
	dropdownRow: {
		backgroundColor: colors.pureWhite, 
		borderBottomColor: colors.darkShades,
	},
	dropdownRowText: {
		textAlign: 'left',
		color: colors.darkShades, 
	},
	textField: {
		fontSize: 16,
		borderRadius: 4,
		padding: 6,
		backgroundColor: colors.pureWhite,
		borderWidth: 1,
		width: '80%',
		outlineStyle: 'none',
		height: 35,
		color: colors.darkShades,
	}
})
