import React, {  useState } from 'react'
import ValidationCodeMP from '@components/ValidationCodeMP/ValidationCode'
import { rootReducerType } from '@reducers/combineReducers'
import { useSelector } from 'react-redux'
import { verifyCodeMP } from '@services/apiService'
import { decryptValueWithAES, generateKeyFromAccountPassword } from '@utils/encryption'
import { iv, snackbarInitialState } from '@utils/constants'
import { useLocation, useNavigate } from 'react-router-dom'
import { MasterPasswordModal } from '@components/MasterPasswordModal/MasterPasswordModal'
import { SnackbarInitialState, SnackbarTypes, ViewTypes } from '@utils/interfaces'
import { useTranslation } from 'react-i18next'
import { isPartner } from '@utils/utils'
import { Snackbar } from '@components/Snackbar/Snackbar'


const ValidationCodeContainer = () => {
	const user = useSelector((state: rootReducerType) => state.userReducer)
	const [ masterPasswordModal, setMasterPasswordModal ] = useState<{
  isVisible: boolean
  password?: string
  encodedPassword?: string
}>({ isVisible: false })
	const [ snackbar, setSnackbar ] = useState<SnackbarInitialState>(snackbarInitialState)
	const { roles } = useSelector((state: rootReducerType) => state.userReducer)
	const encodedMasterPasswordLP  = useSelector((state: rootReducerType) => state.legalPartnerKeyReducer.encodedMasterPassword)
	const encodedMasterPassword = useSelector((state: rootReducerType) => state.companyReducer.currentCompany.encodedMasterPassword)

	const location = useLocation()
	const navigate = useNavigate()
	const { accountPass } = location.state
	const { t } = useTranslation()

	const onChangeVerificationMPSubmit = async (values: {verificationCode: string}) => {
		const reqBody = {...values, email: user.email}
		const response = await verifyCodeMP(reqBody)

		const secretKey = generateKeyFromAccountPassword(accountPass)

		if (response?.status === 201) {
		
			const masterPass = decryptValueWithAES(secretKey, iv, isPartner(roles) ? encodedMasterPasswordLP : encodedMasterPassword)
			
			setMasterPasswordModal({
				isVisible: true,
				password: masterPass,
			})
		}
	
		else {
			setSnackbar({
				isVisible: true,
				type: SnackbarTypes.ERROR,
				message: t('errors.wrongVerifCode')
			})
		}
	}

	const handleClose = () => {
		isPartner(roles) ? navigate('/partner/settings') : navigate('/company/settings')
	}

	return (
		<>
			<ValidationCodeMP onChangeVerificationMPSubmit={onChangeVerificationMPSubmit} />
			{(masterPasswordModal.isVisible && !!masterPasswordModal.password) && <MasterPasswordModal
				masterPassword={masterPasswordModal.password}
				isVisible={masterPasswordModal.isVisible}
				onClose={handleClose}
			/>}
			<Snackbar
				visible={snackbar.isVisible}
				onDismiss={() => setSnackbar(snackbarInitialState)}
				type={snackbar.type}
				message={snackbar.message}
			/>
		</>
	)
}
export default ValidationCodeContainer