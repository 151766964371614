import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { SelectDropDown } from './SelectDropDown/SelectDropdown'
import i18n from '@assets/translation/i18n'
import { setUserLanguage, setUserSettings } from '@actions/userActions'
import { useDispatch, useSelector } from 'react-redux'
import { rootReducerType } from '@reducers/combineReducers'
import { axiosBFFServer } from '@services/connectionServer'
import { languages } from '@utils/constants'
import { useTranslation } from 'react-i18next'
import { getUserSettings } from '@services/apiService'
import {useNavigate} from 'react-router-dom'

interface Props{
	disabled?: boolean
}

export const LanguageSelector = (props: Props) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const storedLanguage = useSelector((state: rootReducerType) => state.userReducer.language)
	const companyUserKey = useSelector((state: rootReducerType) => state.companyUserKeyReducer.key)
	const advisorKey = useSelector((state: rootReducerType) => state.setAdvisorKeyReducer.key)
	const navigate = useNavigate()
	const [selectedLanguage, setSelectedLanguage] = useState('')
	
	const handleLanguageChange = async (index, selectedLanguage) => {
		const selectedLang = languages.find((language) => language == selectedLanguage)
		selectedLang && dispatch(setUserLanguage(selectedLang))
		selectedLang ? i18n.changeLanguage(selectedLang) : i18n.changeLanguage('en')
		selectedLang  && selectedLang !== storedLanguage && updateLanguage(selectedLang)
					
		const userSettingsResponse = await getUserSettings(advisorKey ? advisorKey : companyUserKey)
		if (userSettingsResponse?.response && userSettingsResponse?.response?.status === 401) {
			navigate('/login', { state: { errorMessage: t('errors.expiredToken')}})
		}
		else {
			dispatch(setUserSettings(userSettingsResponse?.data.notificationSettings))
		}
	}

	const loadLanguage = () => {
		if (storedLanguage && languages.find((item) => item === storedLanguage.toLocaleLowerCase())) {
			const selectedLang = languages.find((language) => language === storedLanguage) || 'en'
			setSelectedLanguage(selectedLang) 
			handleLanguageChange(0, selectedLang)
		}
	}

	const updateLanguage = (language) => {
		axiosBFFServer
			.put('/settings/user', {
				userKey: advisorKey ? advisorKey : companyUserKey,
				language: language
			}).catch(error => {
				if (error?.response?.status === 401) {
					navigate('/login', { state: { errorMessage: t('errors.expiredToken')}})
				}
			})
	}
	
	useEffect(() => {
		loadLanguage()
	},[])

	return (
		<View>
			<SelectDropDown 
				defaultButtonText={selectedLanguage ? t(selectedLanguage) : t('selectLanguage')} 
				fieldName={selectedLanguage} 
				data={languages} 
				handleFormValueChange={handleLanguageChange} 
				disabled={props.disabled !== undefined ? props.disabled : false}
				displayTranslatedValue={true}	
			/>
		</View>
	)
}