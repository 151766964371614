import React, { useEffect, useState } from 'react'
import { Platform, Text, TouchableOpacity, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import Icon from 'react-native-vector-icons/FontAwesome'
import { ReportModal } from '@components/ViewReportModal/ViewReportModal'
import { SelectDropDown } from '@components/SelectDropDown/SelectDropdown'
import { Snackbar } from '@components/Snackbar/Snackbar'
import { Loader } from '@components/Loader/Loader'
import useScreenDimensions from '@components/Common/UseScreenDimensions'
import { rootReducerType } from '@reducers/combineReducers'
import { setUserLanguage } from '@actions/userActions'
import { axiosBFFServer } from '@services/connectionServer'
import i18n from '@assets/translation/i18n'
import { PageTemplate, SnackbarInitialState, SnackbarTypes, ViewTypes } from '@utils/interfaces'
import { languages as allLanguages, reportingPageTitle, screenType, snackbarInitialState, templateInitialState } from '@utils/constants'
import { formatTemplateContent } from '@utils/utils'
import { colors } from '@styles/constants'
import { styles } from './styles'
import { setCurrentCompany } from '@actions/companyActions'
import { UseErrorMessages } from '@components/Common/UseErrorMessage'

export const Report = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { getErrorMessage } = UseErrorMessages()
	const { pathname } = useLocation()
	const reportLink = pathname.split('/')[2]
	const screenDimensions = useScreenDimensions()
	const isSmallScreen = screenDimensions.width < screenType.phone
	const [showModal,setShowModal] = useState(false)
	const [passwordError,setPasswordError] = useState(false)
	const [ snackbar, setSnackbar ] = useState<SnackbarInitialState>(snackbarInitialState)
	const [ isLoading, setIsLoading ] = useState(true)
	const [ companyKey, setCompanyKey ] = useState('')
	const [ languages, setLanguages ] = useState<string[]>([])
	const [ template, setTemplate ] = useState<PageTemplate>(templateInitialState)

	const dispatch = useDispatch()
	const storedLanguage = useSelector((state: rootReducerType) => state.userReducer.language)

	const getCompany = async () => {
		return await axiosBFFServer
			.get(`/company/link/${reportLink}`)
	}

	const getLanguages = async (companyKey) => {
		await axiosBFFServer
			.get(`/template/languages?companyKey=${companyKey}`)
			.then(response => {
				if (response.status === 200) {
					const availableLanguages = allLanguages.filter(language => response.data.includes(language.toUpperCase()))
					setLanguages(availableLanguages)
				} else throw new Error()
			})
			.catch(error => {
				const errorMessage = getErrorMessage(error, t('errors.templatesLanguages')) 
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}

	const getTemplate = async (companyKey, language) => {
		await axiosBFFServer
			.get(`/template/list?companyKey=${companyKey}&size=100`)
			.then(response => {
				if (response.status === 200) {
					const template = response.data.pageResults
						.find(template => template.title === reportingPageTitle && template.language.toLowerCase() === language)
					setTemplate({
						content: formatTemplateContent(template?.content ?? ''),
						language: language.toLowerCase(),
					})
					setIsLoading(false)
				} else throw new Error()
			})
			.catch(error => {
				const errorMessage = getErrorMessage(error, t('errors.templates')) 
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}


	const onCloseModal = () => {
		setShowModal(false)
	}

	useEffect(() => {
		if(reportLink === 'new') navigate(`/${ViewTypes.REPORT}`)
        
		getCompany()
			.then(response => {
				if(response.status === 200) {
					dispatch(setCurrentCompany(response.data))
					return response.data.companyKey
				} else throw new Error()
			})
			.then(responseCompanyKey => {
				setCompanyKey(responseCompanyKey)
				getLanguages(responseCompanyKey)
				i18n.changeLanguage(storedLanguage)
				getTemplate(responseCompanyKey, storedLanguage)
			})
			.catch(error => {
				const errorMessage = getErrorMessage(error, t('getCompanyError')) 
				setSnackbar({
					isVisible: true,
					type: SnackbarTypes.ERROR,
					message: errorMessage
				})
			})
	}, [])


	return (
		<View style={styles.container}>
			<View style={[styles.languageContainer, isSmallScreen && styles.languageContainer_mobile]}>
				<SelectDropDown 
					data={languages}
					value={languages.find(item => item === storedLanguage)}
					fieldName='language'
					handleFormValueChange={(fieldName, value) => {
						dispatch(setUserLanguage(value))
						i18n.changeLanguage(value)
						getTemplate(companyKey, value)
					}}
					displayTranslatedValue={true}
				/>
			</View>
			<View style={styles.content}>
				{   
					isLoading
						? <Loader />
						: Platform.OS === 'web' && <div
							dangerouslySetInnerHTML={{ __html: template.content }}
						/>
				}
				<View style={isSmallScreen ? styles.footer_mobile : styles.footer}>
					<TouchableOpacity 
						style={isSmallScreen ? [styles.button, styles.button_mobile] : styles.button}
						onPress={() => navigate(`/${ViewTypes.REPORT}/${reportLink}/new`)}
					>
						<Icon color={colors.pureWhite} name="plus" size={16} />
						<Text style={styles.buttonText}>{t('createReport')}</Text>
					</TouchableOpacity>
					<TouchableOpacity 
						onPress={() => setShowModal(true)}
						style={isSmallScreen ? [styles.button, styles.button_mobile] : styles.button}>
						<Text style={styles.buttonText}>{t('followUpReport')}</Text>
					</TouchableOpacity>

					<Snackbar
						visible={snackbar.isVisible}
						onDismiss={() => setSnackbar(snackbarInitialState)}
						type={snackbar.type}
						message={snackbar.message}
					/>
				</View>
			</View>
			{
				showModal && <ReportModal 
					visible={showModal}
					passwordError={passwordError}
					onClose={onCloseModal}
				/>
			}
			
		</View>
	)
}

