import React from 'react'
import Layout from '@containers/Layout'
import { CompanyUsers } from '@containers/CompanyUsers/CompanyUsers'

export const CompanyUsersPage = () => {
	return (
		<Layout>
			<CompanyUsers />
		</Layout>
	)
}
