import { StyleSheet } from 'react-native'
import { colors, theme } from '@styles/constants'

export const styles = StyleSheet.create({
	categoryContainer: {
		flex: 1,
		flexDirection: 'row',
		flexWrap: 'wrap',
		borderWidth: 1,
		borderColor: theme.light.colors.boxBorder,
		borderRadius: 20,
		margin: 10,
		maxHeight: 200,
		backgroundColor: theme.light.colors.mainContainer,
		width: '30%'
	},
	categoryContainer_mobile: {
		width: '55%',
		flexDirection: 'column',
		borderWidth: 1,
		borderColor: theme.light.colors.boxBorder,
		borderRadius: 20,
		margin: 10,
		maxHeight: 200,
		backgroundColor: theme.light.colors.mainContainer,
	},
	checkBoxContainer: {
		flexDirection: 'row',
		flex: 1,
		gap: 10,
		marginBottom: 10
	},
	container: {
		gap: 8,
		flexWrap: 'wrap',
	},
	titleContainer: {
		marginBottom: 10,
		justifyContent: 'flex-start'
	},
	categoryContent: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		alignItems: 'flex-start',
	},
	containerFilters: {
		flex: 1,
		display: 'flex',
		marginTop: 16,
		marginLeft: 10,
		minWidth: '250px'
	},
	checkboxContainer: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		padding: 15,
		backgroundColor: theme.light.colors.boxBorder,
	},
	categoryTitle: {
		fontSize: 18,
		fontWeight: '500'
	},
	button: {
		backgroundColor: 'transparent',
	},
	buttonText: {
		color: colors.black,
		justifyContent: 'flex-end'
	},
})
